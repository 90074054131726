import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  BookedService,
  BookService,
  CarService,
  ExpensesService,
  FlightService,
  NgxToastrService,
  TrainService,
} from "@core/services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { BookedDeliveryNoteComponent } from "@shared/component/booked-delivery-note/booked-delivery-note.component";
import { BookedsViewComponent } from "@shared/component/bookeds/bookeds-view/bookeds-view.component";
import { CreateCarComponent } from "app/modules/components/cars/custom/create-car/create-car.component";
import { ExpensesComponent } from "app/modules/components/expenses/expenses.component";
import { CreateFlightTrainComponent } from "app/modules/components/flight-train/create-flight-train/create-flight-train.component";
import { CreateCustomHotelComponent } from "app/modules/components/hotel/create-custom-hotel/create-custom-hotel.component";
import moment from "moment";
import { forkJoin, of, Subscription } from "rxjs";
import { catchError, take } from "rxjs/operators";

@Component({
  selector: "app-book-card",
  templateUrl: "./book-card.component.html",
  styleUrls: ["./book-card.component.scss"],
})
export class BookCardComponent implements OnInit {
  @Input() booked: any;
  @Input() companyUuid: string;
  @Input() uuid: string;
  @Input() isUser = true;
  @Input() requestCancelBooked: boolean;
  @Input() requestCancelFlight: boolean;
  @Input() type: string;
  @Input() userUuid: string;
  @Input() isEmployee: boolean;
  @Input() nextTravelsChecked;
  @Input() index: number;
  @Output() loadType: EventEmitter<any> = new EventEmitter();
  loading = false;
  creator: any;
  subscriptions: Subscription[] = [];
  icon: string;

  defaultImage: string;
  @Input() groupRestrictionUuid: string;

  constructor(
    private modalService: NgbModal,
    private ngxToastrService: NgxToastrService,
    private translate: TranslateService,
    private bookService: BookService,
    private expensesService: ExpensesService,
    private flightService: FlightService,
    private trainService: TrainService,
    private carService: CarService,
    private bookedService: BookedService,
  ) {}

  ngOnInit(): void {
    if (this.type?.toLowerCase() === "all" || this.type?.toLowerCase() === "hotel") {
      this.type = this.bookService.getType(this.booked);
    }
    this.getImage();
    this.getServiceIcon();
    if(this.booked?.hotel){
      this.getAddress();
    }
  }

  getImage() {
    switch (this.type?.toLowerCase()) {
      case "car":
        this.defaultImage = "assets/img/gallery/default-car.png";
        break;
      case "flight":
        this.defaultImage = "assets/img/gallery/default-flight.png";
        break;
      case "train":
        this.defaultImage = "assets/img/gallery/default-train.png";
        break;
      case "expense":
        this.defaultImage = "assets/img/svg/default-expense.svg";
        break;
      default:
        this.defaultImage = "assets/img/gallery/image_not_available.png";
        break;
    }
  }

  onChangeTravel() {
    this.isEmployee
      ? this.bookService.onChangeTravel(
          this.booked,
          this.isUser,
          this.companyUuid,
          this.userUuid,
          this.nextTravelsChecked
        )
      : this.isUser
      ? this.bookService.onChangeTravel(
          this.booked,
          this.isUser,
          this.companyUuid,
          this.userUuid,
          this.nextTravelsChecked
        )
      : this.bookService.onChangeTravel(
          this.booked,
          this.isUser,
          this.companyUuid,
          this.userUuid,
          this.nextTravelsChecked
        );
  }

  hasPdf() {
    return this.bookService.hasPdf(this.booked, this.type);
  }

  onGetPDF() {
    this.bookService.onGetPDF(this.booked, this.type);
  }

  advicePdf() {
    this.bookService.advicePdf();
  }

  canCancel() {
    let res = false;
    switch (this.type?.toLowerCase()) {
      case "bookingapireference":
      case "custom-booking":
        const today = moment();
        res = this.isEmployee &&
          this.booked.hotel &&
          this.booked.status !== "cancelled" &&
          today.isSameOrBefore(this.booked.maxCancellationDate) &&
          this.requestCancelBooked &&
          +this.booked.price >= (this.booked.penality || 0) * 100;
        break;
      case "car":
        res =
          this.isEmployee &&
          !this.booked.deleted &&
          this.booked.status !== "cancelled" &&
          moment(this.booked.departDate).isAfter(moment());
        break;
      case "flight":
        res = this.isEmployee &&
          this.requestCancelFlight &&
          this.booked.status !== "cancelled" &&
          moment(this.booked.departDate).isAfter(moment());
        break;
      case "train":
        res =
          !this.booked.trenymasTicketId &&
          this.isEmployee &&
          this.booked.status !== "cancelled" &&
          moment(this.booked.departDate).isAfter(moment());
        break;
      case "expense":
        res = this.isEmployee && !this.booked.deleted && this.booked.status !== "cancelled" ;
        break;
      default:
        break;
    }
    return res;
  }

  onCancel() {
    this.bookService.onCancel(
      this.booked,
      this.type,
      this.isUser ? null : this.userUuid
    );
  }

  getServiceIcon() {
    switch (this.type?.toLowerCase()) {
      case "flight":
        this.icon = "assets/img/svg/flight-black.svg";
        break;
      case "train":
        this.icon = "assets/img/svg/sidebar/train.svg";
        break;
      case "expense":
        this.icon = "assets/img/svg/black-car.svg";
        break;
      case "car":
        this.icon = "assets/img/svg/black-car.svg";
        break;
      default:
        this.icon = "assets/img/svg/black-car.svg";
        break;
    }
  }

  getAddress(): string {
    let { address, destinationName } = this.booked?.hotel;
    if (!address) return '';
  
    const originalAddress = address;
    const normalizedDestinationName = destinationName
      ? destinationName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
      : '';
  
    if (normalizedDestinationName) {
      const regex = new RegExp(`\\b${normalizedDestinationName}\\b`, 'i');
      const parts = originalAddress.split(',').map(part => part.trim());
      const filteredParts = parts.filter(part => {
        const normalizedPart = part.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
        return !regex.test(normalizedPart);
      });
      address = filteredParts.join(', ').replace(/\s*,\s*,/g, ',').replace(/,\s*$/, '').trim();
    }
    this.booked.hotel.address = address;
    return this.booked.hotel.address;
  }

  onEditGetPDF(booked) {
    const downloadRequests = [];
    const fileNameRequests = [];
  
    if (this.type?.toLowerCase() === "hotel" || this.type?.toLowerCase() === "bookingapireference") {
      for (let fileUrl of booked.bonoFileUrl) {
        if (typeof fileUrl === 'string') {
          const uuid = fileUrl.split('/media/')[1];
          
          fileNameRequests.push(this.bookedService.getFileName(uuid).pipe(take(1)));
          downloadRequests.push(this.bookedService.getBookedsPdf(fileUrl).pipe(take(1)));
        }
      }
  
      if (downloadRequests.length > 0) {
        this.subscriptions.push(
          forkJoin({
            responses: forkJoin(downloadRequests),
            fileNames: forkJoin(fileNameRequests.map(request =>
              request.pipe(
                catchError(() => of({ fileName: null }))
              )
            ))
          }).subscribe(
            ({ responses, fileNames }) => {
              const blobUrls = responses.map((res) => URL.createObjectURL(res));
        
              const files = responses.map((res, index) => {
                const fileType = res.type.split("/")[1];
                const defaultFileName = `hotel_${index + 1}.${fileType}`;
                const name = fileNames[index]?.fileName || defaultFileName;
        
                return {
                  blob: res,
                  name: name,
                  url: booked.bonoFileUrl[index],
                };
              });
        
              this.onEdit(blobUrls, files);
            },
            (error) => {
              this.onEdit();
            }
          )
        );
      }else{
        this.onEdit();
      }
    } else if (this.type?.toLowerCase() === "custom-booking") {
      const downloadRequests = [];
      for (let file of booked.voucherFile) {
        downloadRequests.push(
          this.bookedService
            .getCustomBookingPdf(`/media/${file.uuid}/custom`)
            .pipe(take(1))
        );
      }
    
      if (downloadRequests && downloadRequests.length > 0) {
        this.subscriptions.push(
          forkJoin(downloadRequests).subscribe(
            (responses) => {
              const blobUrls = responses.map((res) => URL.createObjectURL(res));
              const files = responses.map((res, index) => {
                
                return {
                  blob: res,
                  name: `${booked.voucherFile[index].fileName}`,
                  url: `/media/${booked.voucherFile[index].uuid}`,
                };
              });
    
              this.onEdit(blobUrls, files);
            },
            (error) => {
              this.onEdit();
            }
          )
        );
      } else {
        this.onEdit();
      }
    } else {
      const fileUrls = booked.voucherFileUrl || booked.bonoFileUrl;
      if (Array.isArray(fileUrls) && fileUrls.length > 0) {
        for (let fileUrl of fileUrls) {
          downloadRequests.push(this.bookedService.getBookedsPdf(fileUrl).pipe(take(1)));
        }

        if (downloadRequests.length > 0) {
          this.subscriptions.push(
            forkJoin(downloadRequests).subscribe(
              (responses) => {
                const blobUrls = responses.map((res) => URL.createObjectURL(res));
                this.translate.get(`common.${this.type}`).subscribe((translatedType) => {
                  const files = responses.map((res, index) => {
                  const fileType = res.type.split("/")[1];
                  const fileName = booked.voucherFile && booked.voucherFile[index] && booked.voucherFile[index].fileName
                    ? booked.voucherFile[index].fileName
                    : `${translatedType}_${index + 1}.${fileType}`;
                    return {
                      blob: res,
                      name: fileName,
                      url: fileUrls[index],
                    };
                  });
                  this.onEdit(blobUrls, files);
                });
              },
              (error) => {
                this.onEdit();
              }
            )
          );
        }
      }else if (fileUrls && !Array.isArray(fileUrls)) {
        this.subscriptions.push(
          this.bookedService
            .getBookedsPdf(fileUrls)
            .pipe(take(1))
            .subscribe(
              (res) => {
                const blobUrl = URL.createObjectURL(res);
                const file = res;
                const fileType = file.type.split("/")[1];
                const fileName = booked.voucherFile && booked.voucherFile[0] && booked.voucherFile[0].fileName
                    ? booked.voucherFile[0].fileName
                    : `${this.type}.${fileType}`;
                file["name"] = fileName;
                this.onEdit(blobUrl, file);
              },
              (error) => {
                this.onEdit();
              }
            )
        );
      }
    }
    if(booked.voucherFileUuid) {
      let mediaUrl = this.type?.toLowerCase() === "flight" ? "/media/flight/" : (this.type?.toLowerCase() === "expense" ? "/media/expense/" : (this.type?.toLowerCase() === "train" ? "/media/train/" : (this.type?.toLowerCase() === "car" ? "/media/car/" : '/media/')))
      this.bookedService.getBookedsPdf(this.type?.toLowerCase() !== "custom-booking" ? mediaUrl + booked.voucherFileUuid : "/media/"+booked.voucherFileUuid+"/custom" ).pipe(take(1)).subscribe(
        (res) => {
          const blobUrl = URL.createObjectURL(res);
          const file = res;
          const fileType = file.type.split("/")[1];
          const fileName = booked.voucherFile && booked.voucherFile[0] && booked.voucherFile[0].fileName
              ? booked.voucherFile[0].fileName
              : `${this.type}.${fileType}`;
          file["name"] = fileName;
          this.onEdit(blobUrl, file);
        },
        (error) => {
          this.onEdit();
        }
      )
    }
  }

  reduceComments(message: string) {
    return message.length > 40 ? `${message.substring(0, 39)}...` : message;
  }

  onView() {
    const transactions = this.bookService.getData('transactions');
    let transactionFound
    if(this.isEmployee && transactions){
      transactionFound = transactions.find(t => t.bookedObject.uuid === this.booked.uuid);
      if(transactionFound){
        this.booked.order = transactionFound.order;
      }
    }
    const editCar = this.modalService.open(BookedsViewComponent, {
      size: "lg",
      centered: true,
    });
    if(this.type?.toLowerCase() == "car"){
      editCar.componentInstance.type = this.booked.carType;
    }  
    editCar.componentInstance.booking = this.booked;
    editCar.componentInstance.type = this.type;
    editCar.componentInstance.isUser = this.isUser;
    editCar.componentInstance.isEmployee = this.isEmployee;
    if (!this.isUser && !this.booked.seatTicketIds) {
      editCar.componentInstance.pnr = this.booked.pnr;
    }
  }

  onEdit(blobUrl?, file?) {
    switch (this.type?.toLowerCase()) {
      case "car":
        const editCar = this.modalService.open(CreateCarComponent, {
          size: "lg",
          centered: true,
        });
        editCar.componentInstance.isAdmin = !this.isUser;
        editCar.componentInstance.loading = this.loading;
        editCar.componentInstance.companyUuid = this.companyUuid;
        editCar.componentInstance.userId = this.userUuid ? this.userUuid : this.booked.userUuid;
        editCar.componentInstance.data = this.booked;
        editCar.componentInstance.isEdit = true;
        if (blobUrl && file) {
          editCar.componentInstance.blobUrl = blobUrl;
          editCar.componentInstance.fileBack = file;
        }
        editCar.componentInstance.sendInfo.subscribe((info) => {
          editCar.componentInstance.loading = true;
          const formData = new FormData();
          if(info.file){
            if (info.file && Array.isArray(info.file)) {
              info.file.forEach((file: any, index: number) => {
                if (file.blob && file.name) {
                  const newFile = new File([file.blob], file.name, { type: file.blob.type });
                  formData.append("file", newFile, newFile.name);
                } 
                else if (file instanceof File) {
                  formData.append("file", file, file.name);
                } 
                else if (file instanceof Blob) {
                  const newFile = new File([file], `file_${index}.pdf`, { type: file.type });
                  formData.append("file", newFile, newFile.name);
                } 
              });
            } 
          }

          if (info.filesToRemove && info.filesToRemove.length > 0) {
            info.filesToRemove.forEach((fileUrl, index) => {
              formData.append(`filesToRemove[]`, fileUrl);
            });
          }
          
          formData.append("userUuid", info.userUuid);
          formData.append("from", info.from);
          formData.append("to", info.to);
          formData.append("pickupPlaceFrom", info.pickupPlaceFrom);
          formData.append("pickupPlaceTo", info.pickupPlaceTo);
          formData.append("price", info.price || info.amount);
          formData.append("age", info.age);
          if (info.travelName) {
            formData.append("travelName", info.travelName);
          }
          formData.append("customCode", info.customCode);
          formData.append("costCenter", info.costCenter);
          formData.append("freeTaxes", info.freeTaxes);
          formData.append("departDate", info.departDate);
          formData.append("returnDate", info.returnDate);
          formData.append("provider", info.provider);
          formData.append("carType", info.carType);
          formData.append("insuranceWithExcess", info.insuranceWithExcess);
          formData.append("comments", info.comments);
          if(info.passenger){
            formData.append("passenger", info.passenger);
          }
          this.carService.editCar(formData, this.booked.uuid).subscribe(
            (result) => {
              editCar.componentInstance.loading = false;
              editCar.dismiss("Send Info");
              this.translate
                .get(["common.congrat", `car.edit-car.edited-car`])
                .subscribe((resp) => {
                  this.ngxToastrService.typeSuccess(
                    resp["common.congrat"],
                    resp[`car.edit-car.edited-car`]
                  );
                  this.loadType.emit(this.type);
                });
            },
            (err) => {
              editCar.componentInstance.loading = false;
              this.translate
                .get(`car.edit-car.error-edit-car`)
                .subscribe((result) => {
                  this.ngxToastrService.typeError(result, err.error.message);
                });
            }
          );
        });

        break;
      case "flight":
        const editFlight = this.modalService.open(CreateFlightTrainComponent, {
          size: "lg",
          centered: true,
        });
        editFlight.componentInstance.type = this.type;
        editFlight.componentInstance.data = this.booked;
        editFlight.componentInstance.userId = this.userUuid ? this.userUuid : this.booked.userUuid;
        editFlight.componentInstance.isAdmin = !this.isUser;
        editFlight.componentInstance.loading = this.loading;
        editFlight.componentInstance.companyUuid = this.companyUuid;
        if (blobUrl && file) {
          editFlight.componentInstance.blobUrl = blobUrl;
          editFlight.componentInstance.fileBack = file;
        }
        editFlight.componentInstance.sendInfo.subscribe((info) => {
          editFlight.componentInstance.loading = true;
          const formData = new FormData();
          if(info.file){
            if (info.file && Array.isArray(info.file)) {
              info.file.forEach((file: any, index: number) => {
                if (file.blob && file.name) {
                  const newFile = new File([file.blob], file.name, { type: file.blob.type });
                  formData.append("file", newFile, newFile.name);
                } 
                else if (file instanceof File) {
                  formData.append("file", file, file.name);
                } 
                else if (file instanceof Blob) {
                  const newFile = new File([file], `file_${index}.pdf`, { type: file.type });
                  formData.append("file", newFile, newFile.name);
                } 
              });
            } 
          }

          if (info.filesToRemove && info.filesToRemove.length > 0) {
            info.filesToRemove.forEach((fileUrl, index) => {
              formData.append(`filesToRemove[]`, fileUrl);
            });
          }

          formData.append("companyUuid", info.companyUuid);
          formData.append("userUuid", info.userId);
          formData.append("from", info.origin);
          formData.append("to", info.destination);
          formData.append("departDate", info.departure);
          formData.append("observations", info.annotations);
          formData.append("price", info.price);
          formData.append("oneWay", info.oneWay);
          formData.append("freeTaxes", info.freeTaxes);
          if (!info.oneWay) {
            formData.append("returnDate", info.return);
          }
          formData.append("customCode", info.customCode);
          formData.append("costCenter", info.costCenter);
          if(info.passenger){
            formData.append("passenger", info.passenger)
          }
          
          this.flightService.editFlight(this.booked.uuid, formData).subscribe(
            (result) => {
              editFlight.componentInstance.loading = false;
              editFlight.dismiss("Send Info");
              this.translate
                .get([
                  "common.congrat",
                  `${this.type?.toLowerCase()}.edit-${this.type?.toLowerCase()}.edited-${this.type?.toLowerCase()}`,
                ])
                .subscribe((resp) => {
                  this.ngxToastrService.typeSuccess(
                    resp["common.congrat"],
                    resp[
                      `${this.type?.toLowerCase()}.edit-${this.type?.toLowerCase()}.edited-${this.type?.toLowerCase()}`
                    ]
                  );
                  this.loadType.emit(this.type);
                });
            },
            (err) => {
              editFlight.componentInstance.loading = false;
              this.translate
                .get(
                  `${this.type?.toLowerCase()}.edit-${this.type?.toLowerCase()}.error-edit-${this.type?.toLowerCase()}`
                )
                .subscribe((result) => {
                  this.ngxToastrService.typeError(result, err.error.message);
                });
            }
          );
        });
        break;
      case "train":
        const editTrain = this.modalService.open(CreateFlightTrainComponent, {
          size: "lg",
          centered: true,
        });
        editTrain.componentInstance.type = this.type;
        editTrain.componentInstance.data = this.booked;
        editTrain.componentInstance.userId = this.userUuid ? this.userUuid : this.booked.userUuid;
        editTrain.componentInstance.isAdmin = !this.isUser;
        editTrain.componentInstance.loading = this.loading;
        editTrain.componentInstance.companyUuid = this.companyUuid;
        if (blobUrl && file) {
          editTrain.componentInstance.blobUrl = blobUrl;
          editTrain.componentInstance.fileBack = file;
        }
        /*this.trainService.editTrain(booked.uuid, formData)*/
        editTrain.componentInstance.sendInfo.subscribe((info) => {
          editTrain.componentInstance.loading = true;
          const formData = new FormData();
          if(info.file){
            if (info.file && Array.isArray(info.file)) {
              info.file.forEach((file: any, index: number) => {
                if (file.blob && file.name) {
                  const newFile = new File([file.blob], file.name, { type: file.blob.type });
                  formData.append("file", newFile, newFile.name);
                } 
                else if (file instanceof File) {
                  formData.append("file", file, file.name);
                } 
                else if (file instanceof Blob) {
                  const newFile = new File([file], `file_${index}.pdf`, { type: file.type });
                  formData.append("file", newFile, newFile.name);
                } 
              });
            } 
          }
          if (info.filesToRemove && info.filesToRemove.length > 0) {
            info.filesToRemove.forEach((fileUrl, index) => {
              formData.append(`filesToRemove[]`, fileUrl);
            });
          }
          formData.append("companyUuid", info.companyUuid);
          formData.append("userUuid", info.userId);
          formData.append("from", info.origin);
          formData.append("to", info.destination);
          formData.append("departDate", info.departure);
          formData.append("observations", info.annotations);
          formData.append("price", info.price);
          formData.append("oneWay", info.oneWay);
          formData.append("freeTaxes", info.freeTaxes);
          if (!info.oneWay) {
            formData.append("returnDate", info.return);
          }
          formData.append("customCode", info.customCode);
          formData.append("costCenter", info.costCenter);
          if(info.passenger){
            formData.append("passenger", info.passenger)
          }
          
          this.trainService.editTrain(this.booked.uuid, formData).subscribe(
            (result) => {
              editTrain.componentInstance.loading = false;
              editTrain.dismiss("Send Info");
              this.translate
                .get([
                  "common.congrat",
                  `${this.type?.toLowerCase()}.edit-${this.type?.toLowerCase()}.edited-${this.type?.toLowerCase()}`,
                ])
                .subscribe((resp) => {
                  this.ngxToastrService.typeSuccess(
                    resp["common.congrat"],
                    resp[
                      `${this.type?.toLowerCase()}.edit-${this.type?.toLowerCase()}.edited-${this.type?.toLowerCase()}`
                    ]
                  );
                  this.loadType.emit(this.type);
                });
            },
            (err) => {
              editTrain.componentInstance.loading = false;
              this.translate
                .get(
                  `${this.type?.toLowerCase()}.edit-${this.type?.toLowerCase()}.error-edit-${this.type?.toLowerCase()}`
                )
                .subscribe((result) => {
                  this.ngxToastrService.typeError(result, err.error.message);
                });
            }
          );
        });
        break;
      case "expense":
        const editExpense = this.modalService.open(ExpensesComponent, {
          size: "lg",
          centered: true,
        });

        editExpense.componentInstance.userUuid = this.userUuid ? this.userUuid : this.booked.userUuid;
        editExpense.componentInstance.companyUuid = this.companyUuid;
        editExpense.componentInstance.groupRestrictionUuid =
          this.groupRestrictionUuid;
        editExpense.componentInstance.expense = this.booked;
        if (blobUrl && file) {
          editExpense.componentInstance.blobUrl = blobUrl;
          editExpense.componentInstance.fileBack = file;
        }
        editExpense.componentInstance.sendInfo.subscribe((body) => {
          const formData = new FormData();
          if(body.file){
            if (body.file && Array.isArray(body.file)) {
              body.file.forEach((file: any, index: number) => {
                if (file.blob && file.name) {
                  const newFile = new File([file.blob], file.name, { type: file.blob.type });
                  formData.append("file", newFile, newFile.name);
                } 
                else if (file instanceof File) {
                  formData.append("file", file, file.name);
                } 
                else if (file instanceof Blob) {
                  const newFile = new File([file], `file_${index}.pdf`, { type: file.type });
                  formData.append("file", newFile, newFile.name);
                } 
              });
            } 
          }
          if (body.filesToRemove && body.filesToRemove.length > 0) {
            body.filesToRemove.forEach((fileUrl, index) => {
              formData.append(`filesToRemove[]`, fileUrl);
            });
          }
          formData.append("groupRestrictionUuid", body.groupRestrictionUuid);
          formData.append("companyUuid", body.companyUuid);
          formData.append("userUuid", body.userUuid);
          formData.append("price", body.price || body.amount);
          formData.append("beginDate", body.beginDate);
          formData.append("endDate", body.endDate);
          formData.append("type", body.type);
          formData.append("freeTaxes", body.freeTaxes);
          formData.append("customCode", body.customCode);
          formData.append("costCenter", body.costCenter);
          if(body.passenger){
            formData.append("passenger", body.passenger);
          }
          this.subscriptions.push(
            this.expensesService
              .editExpense(this.booked.uuid, formData)
              .subscribe(
                (s) => {
                  this.translate.get("expenses.success").subscribe((result) => {
                    this.ngxToastrService.typeSuccess(null, result);
                  });
                  editExpense.dismiss();
                  this.loadType.emit(this.type);
                },
                (err) => {
                  this.translate.get("expenses.error").subscribe((result) => {
                    this.ngxToastrService.typeError(result, err.error.message);
                    editExpense.componentInstance.loading = false;
                  });
                }
              )
          );
        });
        break;
      case "hotel":
      case "custom-booking":
      case "bookingapireference":
        const editHotel = this.modalService.open(CreateCustomHotelComponent, {
          size: "lg",
          centered: true,
        });
        editHotel.componentInstance.type = "hotel";
        editHotel.componentInstance.data = this.booked;
        editHotel.componentInstance.userId = this.userUuid ? this.userUuid : this.booked.userUuid;
        editHotel.componentInstance.isAdmin = !this.isUser;
        editHotel.componentInstance.companyUuid = this.companyUuid;
        editHotel.componentInstance.loading = this.loading;
        editHotel.componentInstance.editing = true;
        if (blobUrl && file) {
          editHotel.componentInstance.blobUrl = blobUrl;
          editHotel.componentInstance.fileBack = file;
        }
        editHotel.componentInstance.sendInfo.subscribe((body) => {
          const formData = new FormData();
          formData.append("companyUuid", this.companyUuid);
          formData.append("userUuid", this.userUuid ? this.userUuid : this.booked.userUuid);
          formData.append("comments", body.comments);
          formData.append("price", body.price);
          if (!this.booked.hotel) {
            formData.append("city", body.city);
            formData.append("hotel", body.hotel);
          }else {
            formData.append("destinationName", body.city);
          }

           if (body.file) {
            if (body.file && Array.isArray(body.file)) {
              body.file.forEach((file: any, index: number) => {
                if (file.blob && file.name) {
                  const newFile = new File([file.blob], file.name, { type: file.blob.type });
                  formData.append("file", newFile, newFile.name);
                } 
                else if (file instanceof File) {
                  formData.append("file", file, file.name);
                } 
                else if (file instanceof Blob) {
                  const newFile = new File([file], `file_${index}.pdf`, { type: file.type });
                  formData.append("file", newFile, newFile.name);
                } 
              });
            } 
           }
          if (body.filesToRemove && body.filesToRemove.length > 0) {
            body.filesToRemove.forEach((fileUrl, index) => {
              formData.append(`filesToRemove[]`, fileUrl);
            });
          }
          formData.append("checkIn", body.checkIn);
          formData.append("checkOut", body.checkOut);
          formData.append("customCode", body.customCode);
          formData.append("costCenter", body.costCenter);
          if(body.freeTaxes){
            formData.append("freeTaxes", body.freeTaxes);
          }
          if(body.traveler){
            formData.append("traveler", body.traveler)
          }
          if(this.booked.hotel && body.address){
            formData.append("address", body.address)
          }
          const action = this.booked.hotel
            ? this.bookedService.putBooking(this.booked.uuid, formData)
            : this.bookedService.putCustomBooking(this.booked.uuid, formData);
          action.subscribe(
            (res) => {
              editHotel.componentInstance.loading = false;
              editHotel.dismiss("Send Info");
              this.translate
                .get(["common.congrat", `hotel.edited-success`])
                .subscribe((resp) => {
                  this.ngxToastrService.typeSuccess(
                    resp["common.congrat"],
                    resp[`hotel.edited-success`]
                  );
                  this.loadType.emit(this.type);
                });
            },
            (err) => {
              editHotel.componentInstance.loading = false;
              this.translate.get(`hotel.edited-error`).subscribe((result) => {
                this.ngxToastrService.typeError(result, err.error.message);
              });
            }
          );
        });
        break;
      default:
        break;
    }
  }

  parseDateTime(date: string) {
    return moment(date);
  }

  getLogo(value: string) {
    return `assets/img/airlines/small/${value}.png`;
  }

  getDate(date) {
    return moment(date).tz('Europe/Madrid').format('DD/MM/YYYY HH:mm');
  }

  deletePermanently(booking: any) {
    this.bookService.deleteForever(booking).subscribe(
      (res) => {
        this.translate.get(`hotel.deleted-success`).subscribe((successMessage) => {
          this.ngxToastrService.typeSuccess(successMessage, "");
        });
        this.loadType.emit(this.type);
      },
      (err) => {
        this.translate.get(`hotel.deleted-error`).subscribe((result) => {
          this.ngxToastrService.typeError(result, err.error.message);
        });
      }
    );
  }

  onDeliveryNote(booked: any) {
    const modalRef = this.modalService.open(BookedDeliveryNoteComponent, {
      windowClass: 'custom-modal-size'
    });
    modalRef.componentInstance.booked = booked;
    modalRef.componentInstance.type = this.type;
    modalRef.componentInstance.fileName = 'tile';
  }
}
