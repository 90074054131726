import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Car } from "@core/models";
import { environment } from "@env/environment";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { DialogAssignProjectComponent } from "@shared/component/dialog-assign-project/dialog-assign-project.component";
import { ViewPdfModalComponent } from "app/modules/businessEntities/user/user-detail/view-pdf-modal/view-pdf-modal.component";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import moment from "moment";
import { BehaviorSubject, forkJoin, Observable, Subject, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { default as Swal, default as swal } from "sweetalert2";
import { BookedService } from "./booked.service";
import { CarService } from "./car.service";
import { ExpensesService } from "./expenses.service";
import { FlightLleegoService } from "./flight-llego.service";
import { FlightService } from "./flight.service";
import { NgxToastrService } from "./ngx.toastr.service";
import { TokenService } from "./token.service";
import { TrainService } from "./train.service";
import { TravelUtilsService } from "./travel-utils.service";
import { TravelService } from "./travel.service";

@Injectable({
  providedIn: "root",
})
export class BookService {
  subscriptions: Subscription[] = [];
  loading: Subject<any> = new Subject<any>();
  reload: Subject<any> = new Subject<any>();
  private cachedData: { [key: string]: any } = {};
  isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private modalService: NgbModal,
    private translateService: TranslateService,
    private ngxToastrService: NgxToastrService,
    private http: HttpClient,
    private tokenService: TokenService,
    private travelService: TravelService,
    private carService: CarService,
    private bookedService: BookedService,
    private expensesService: ExpensesService,
    private flightLleegoService: FlightLleegoService,
    private flightService: FlightService,
    private trainService: TrainService,
    private dialog: MatDialog,
    private travelUtilsService: TravelUtilsService,
  ) {}

  setData(key: string, data: any): void {
    this.cachedData[key] = data;
  }

  getData(key: string): any {
    return this.cachedData[key];
  }

  clearCache() {
    this.cachedData = {};
  }

  getAllData(): { [key: string]: any } {
    return this.cachedData;
  }

  getType(booking: any) {
    let type = "";
    switch (true) {
      case booking.hasOwnProperty("hotel"):
        type = "bookingApiReference";
        break;
      case booking.hasOwnProperty("hotelName"):
        type = "custom-booking";
        break;
      case booking.hasOwnProperty("lleegoReservationUuid"):
        type = "flight";
        break;
      case booking.hasOwnProperty("seatTicketIds"):
        type = "train";
        break;
      case booking.hasOwnProperty("type"):
        type = "expense";
        break;
      case booking.hasOwnProperty("carType"):
        type = "car";
        break;
      default:
        type = "flight";
        break;
    }
    return type;
  }

  onChangeTravel(
    booking: any,
    isUser: boolean,
    companyUuid?: string,
    userUuid?: string,
    nextTravelsChecked?
  ) {
    const type: string = this.getType(booking);

    const params = {};
    switch (type) {
      case "bookingApiReference":
        params["bookingApiReference"] = booking.uuid;
        break;
      case "custom-booking":
        params["customBookingUuids"] = booking.uuid;
        break;
      case "flight":
        params["flightUuids"] = booking.uuid;
        break;
      case "train":
        params["trainUuids"] = booking.uuid;
        break;
      case "expense":
        params["expenseUuids"] = booking.uuid;
        break;
      case "car":
        params["carUuids"] = booking.uuid;
        break;
      default:
        break;
    }
    if (companyUuid) {
      params["companyUuid"] = companyUuid;
    }
    this.isLoading.next(true);
    this.subscriptions.push(
      this.travelService.getTravelsWithParams(params).subscribe((travel) => {
        this.isLoading.next(false);
        booking["initDate"] =
          booking["checkIn"] || booking["initDate"] || booking["departDate"];
        booking["endDate"] =
          booking["checkOut"] || booking["endDate"] || booking["returnDate"];
        const data: any = {
          bookingToAssign: true,
          travelToEdit: travel.docs.length > 0 ? travel.docs[0] : {},
        };
        data.bookingData = JSON.parse(JSON.stringify(booking));
        data.bookingData.bookingUuid = booking.uuid;
        data.bookingData.type = type;
        data.bookingData.userUuid =
          userUuid != null ? userUuid : booking.userUuid;
        if (companyUuid) {
          data.companyUuid = companyUuid;
        }
        const modalRef = this.dialog.open(DialogAssignProjectComponent, {
          data,
          panelClass: "new-dialog",
        });

        modalRef.afterClosed().subscribe((dataTravel) => {
          if (dataTravel != null &&
            !dataTravel.project?.flightUuids?.includes(dataTravel.travelData.uuid) &&
            !dataTravel.project?.bookingUuids?.includes(dataTravel.travelData.uuid) &&
            !dataTravel.project?.customBookingUuids?.includes(dataTravel.travelData.uuid) &&
            !dataTravel.project?.trainUuids?.includes(dataTravel.travelData.uuid) &&
            !dataTravel.project?.expenseUuids?.includes(dataTravel.travelData.uuid) &&
            !dataTravel.project?.carUuids?.includes(dataTravel.travelData.uuid)) {
            this.loading.next(true);
            // modalRef.componentInstance.loading = true;

            if (
              dataTravel.travelData &&
              dataTravel.travelData.type !== "none"
            ) {
              if (travel.docs.length > 0) {
                this.removeItemTravel(
                  type,
                  booking,
                  travel.docs[0].uuid,
                  isUser,
                  userUuid
                );
              }
              const body: any = {
                item: {
                  type: type,
                  initDate: moment(booking.initDate).format("YYYY/MM/DD"),
                  uuid: booking.apiReference
                    ? booking.apiReference
                    : data.bookingData.bookingUuid,
                },
              };
              if (booking.endDate != null) {
                body.item["endDate"] =
                  booking.endDate != null
                    ? moment(booking.endDate).format("YYYY/MM/DD")
                    : null;
              } else {
                body.item["endDate"] = moment(booking.initDate).format(
                  "YYYY/MM/DD"
                );
              }
              body["userUuid"] = userUuid;
              body["companyUuid"] = companyUuid;
              
              if (dataTravel.isCreate === true) {
                this.travelUtilsService.createTravel(
                  dataTravel.travelData,
                  body,
                  dataTravel.addBody.type,
                  () => {
                    const cachedBookeds = this.getData('travels');
                    if(cachedBookeds && Object.keys(cachedBookeds).length > 0 && !isUser){
                      this.loadTravels(userUuid, companyUuid);
                    }
                    this.loading.next(false);
                  }
                );
              }  else {
                this.subscriptions.push(
                  this.travelService
                    .addItemTravel(body, dataTravel.projectUuid)
                    .subscribe((s) => {
                      const cachedBookeds = this.getData('travels');
                      if(cachedBookeds && Object.keys(cachedBookeds).length > 0 && !isUser){
                        this.loadTravels(userUuid, companyUuid);
                      }
                      this.subscriptions.push(
                        this.translateService
                          .get("project.my-projects.change.success")
                          .subscribe(
                            (result) => {
                              this.loading.next(false);
                              this.ngxToastrService.typeSuccess("OK", result);
                              setTimeout(() => {
                                if (nextTravelsChecked === false) {
                                  console.log(nextTravelsChecked)
                                  this.reload.next(true);
                                }
                              }, 3000);
                            },
                            (err) => {
                              this.loading.next(false);
                              this.subscriptions.push(
                                this.translateService
                                  .get("project.my-projects.change.error")
                                  .subscribe((result2) => {
                                    this.ngxToastrService.typeInfo(
                                      "KO",
                                      result2
                                    );
                                  })
                              );
                              setTimeout(() => {
                                if (nextTravelsChecked === false) {
                                  this.reload.next(true);
                                }
                              }, 3000);
                            }
                          )
                      );
                    })
                );
              }
            } else {
              this.removeItemTravel(
                type,
                booking,
                dataTravel.travelData.uuid,
                isUser,
                userUuid
              );
            }
          }
        });
      })
    );
  }

  private removeItemTravel(type, booking, travelUuid, isUser, userUuid) {
    const removeBody = {
      item: {
        type: type,
        uuid: booking.apiReference ? booking.apiReference : booking.uuid,
      },
    };
    if (isUser === false) {
      removeBody["userUuid"] = userUuid;
    }
    this.subscriptions.push(
      this.travelService.removeItemTravel(removeBody, travelUuid).subscribe(
        () => {
          this.loading.next(false);
          this.translateService
            .get("project.my-projects.change.success")
            .subscribe((result) => {
              this.loading.next(false);
              this.ngxToastrService.typeSuccess(null, result);
            });
          setTimeout(() => {
            const cachedBookeds = this.getData('travels');
            if(!(cachedBookeds && Object.keys(cachedBookeds).length > 0)){
              this.reload.next(true);
            }
          }, 3000);
        },
        (err) => {
          this.loading.next(false);
          this.subscriptions.push(
            this.translateService
              .get("project.my-projects.change.error")
              .subscribe((result) => {
                this.ngxToastrService.typeInfo(err, result);
              })
          );
        }
      )
    );
  }

  hasPdf(booked: any, type: string) {
    const hasBonoFileUrl = Array.isArray(booked.bonoFileUrl) && booked.bonoFileUrl.length > 0;
    const hasVoucherFileUrl =
      Array.isArray(booked.voucherFileUrl) &&
      booked.voucherFileUrl.length > 0 &&
      booked.voucherFileUrl.some(
        (url) => url !== "" && !url.includes("undefined")
      );
  
    const hasVoucherFile =
      Array.isArray(booked.voucherFile) &&
      booked.voucherFile.length > 0 &&
      booked.voucherFile.some(
        (file) => file.uuid && file.uuid !== "" && file.uuid !== "undefined"
      );

    const hasVoucherUuid = booked.voucherFileUuid;
    
    return hasBonoFileUrl || hasVoucherFileUrl || hasVoucherFile || hasVoucherUuid;
  }

  onGetPDF(booked: any, type: any) {
    const downloadRequests: any[] = [];
    const fileNames: string[] = [];
  
    if (type === 'hotel' || type === 'bookingApiReference') {
      if (Array.isArray(booked.bonoFileUrl)) {
        for (let fileUrl of booked.bonoFileUrl) {
          downloadRequests.push(this.bookedService.getBookedsPdf(fileUrl).pipe(take(1)));
          fileNames.push(booked.apiReference || fileUrl);
        }
      }
    } else if (type === 'custom-booking') {
      if (Array.isArray(booked.voucherFile)) {
        for (let file of booked.voucherFile) {
          const customUrl = `/media/${file.uuid}/custom`;
          downloadRequests.push(this.bookedService.getCustomBookingPdf(customUrl).pipe(take(1)));
          fileNames.push(file.fileName || file.uuid);
        }
      }
    } else {
      if (Array.isArray(booked.voucherFileUrl)) {
        for (let fileUrl of booked.voucherFileUrl) {
          downloadRequests.push(this.bookedService.getBookedsPdf(fileUrl).pipe(take(1)));
          fileNames.push(fileUrl);
        }
      }
    }
    if(booked.voucherFileUuid) {
      let mediaUrl = type === "flight" ? "/media/flight/" : (type === "expense" ? "/media/expense/" : (type === "train" ? "/media/train/" : (type === "car" ? "/media/car/" : '/media/')))
      downloadRequests.push(this.bookedService.getBookedsPdf(type !== "custom-booking" ? mediaUrl + booked.voucherFileUuid : "/media/"+booked.voucherFileUuid+"/custom" ).pipe(take(1)));
        fileNames.push(booked.voucherFileUuid);
    }
    if (downloadRequests.length > 0) {
      forkJoin(downloadRequests).subscribe(
        (responses) => {
          const blobUrls = responses.map((res) => URL.createObjectURL(res));
          const modalRef = this.modalService.open(ViewPdfModalComponent, {
            size: 'lg',
            centered: true,
          });
          if (blobUrls.length > 1) {
            modalRef.componentInstance.blobUrls = blobUrls;
            modalRef.componentInstance.fileNames = fileNames;
          } else {
            modalRef.componentInstance.blobUrl = blobUrls[0];
            modalRef.componentInstance.fileName = fileNames[0];
          }
        },
        () => {
          this.translateService
            .get(['common.error-pdf'])
            .pipe(take(1))
            .subscribe(() => {
              this.advicePdf();
            });
        }
      );
    }
  }

  getBookPdf(bonoFileUrl: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.get(`${environment.baseUrlApi}${bonoFileUrl}`, {
      headers: reqHeaders,
      responseType: "blob",
    });
  }

  advicePdf() {
    this.subscriptions.push(
      this.translateService
        .get(["common.generating-pdf", "common.generating-pdf-body"])
        .subscribe((result) => {
          swal.fire({
            title: result["common.generating-pdf"],
            text: result["common.generating-pdf-body"],
            imageUrl: "assets/img/svg/timePdf.svg",
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
            backdrop: true,
          });
        })
    );
  }

  onCancel(booked: any, type: string, userUuid?: string) {
    this.translateService
      .get([
        "company.booked.cancel-booked",
        "company.booked.action-no-undo",
        "common.confirm",
        "common.cancel",
        "company.booked.booked-canceled",
        "company.booked.error-cancel-booked",
      ])
      .subscribe((result) => {
        Swal.fire({
          title: result["company.booked.cancel-booked"],
          text: result["company.booked.action-no-undo"],
          showCancelButton: true,
          confirmButtonText: result["common.confirm"],
          cancelButtonText: result["common.cancel"],
          customClass: {
            confirmButton: "btn btn-success btn-raised",
            cancelButton: "btn btn-danger btn-raised  mr-5",
          },
          buttonsStyling: false,
        }).then((action) => {
          if (action.value) {
            this.loading.next(true);
            switch (type) {
              case "bookingApiReference":
              case "custom-booking":
                this.onCancelHotel(booked);
                break;
              case "car":
                this.onCancelCar(booked);
                break;
              case "flight":
                this.onCancelFlightTrain(
                  booked,
                  "Flight",
                  userUuid ? userUuid : null
                );
                break;
              case "train":
                this.onCancelFlightTrain(booked, "Train");
                break;
              case "expense":
                this.onCancelExpense(booked.uuid);
                break;
              default:
                break;
            }
          }
        });
      });
  }

  onCancelHotel(booked: any) {
    this.bookedService
      .updateBooked(booked.uuid)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.translateService
            .get("company.booked.booked-canceled")
            .subscribe((result2) => {
              this.loading.next(false);
              this.reload.next(true);
              this.ngxToastrService.typeSuccess(null, result2);
            });
        },
        (error) => {
          this.loading.next(false);
          this.ngxToastrService.typeInfo(null, error.error.message);
        }
      );
  }

  onCancelCar(car: Car) {
    this.carService.updateCarStatus(car.uuid).subscribe(
      (res) => {
        this.translateService
          .get(`user.details.car-canceled`)
          .subscribe((resp) => {
            this.loading.next(false);
            this.reload.next(true);
            this.ngxToastrService.typeSuccess(null, resp);
          });
      },
      (err) => {
        this.translateService
          .get(`user.details.error-cancel-car`)
          .subscribe((resp) => {
            this.loading.next(false);
            if (err.error != null && err.error.message != null) {
              this.ngxToastrService.typeInfo(resp, err.error.message);
            } else {
              this.ngxToastrService.typeInfo(resp, "");
            }
          });
      }
    );
  }

  onCancelExpense(uuid: string) {
    this.subscriptions.push(
      this.expensesService.updateExpenseStatus(uuid).subscribe(
        () => {
          this.translateService
            .get(`user.details.booked-canceled`)
            .subscribe((resp) => {
              this.loading.next(false);
              this.reload.next(true);
              this.ngxToastrService.typeSuccess(null, resp);
            });
        },
        (err) => {
          this.translateService
            .get(`user.details.error-cancel-booked`)
            .subscribe((resp) => {
              this.loading.next(false);
              this.ngxToastrService.typeInfo(resp, err.error.message);
            });
        }
      )
    );
  }

  onCancelFlightTrain(booked: any, typeBooked: string, userUuid?: string) {
    const cancelBooked =
      typeBooked === "Flight"
        ? booked.lleegoReservationUuid
          ? this.flightLleegoService.putFlightCancel(
              booked.lleegoReservationUuid,
              booked.uuid,
              userUuid,
              booked.pnr
            )
          : this.flightService.cancelFlight(booked.uuid)
        : this.trainService.cancelTrain(booked.uuid);
    cancelBooked.subscribe(
      (res) => {
        if (res.status >= 400) {
          this.translateService
            .get(`user.details.error-cancel-${typeBooked?.toLowerCase()}`)
            .subscribe((resp) => {
              this.loading.next(false);
              this.reload.next(true);
              this.ngxToastrService.typeInfo(resp, "");
            });
        } else {
          this.translateService
            .get(`user.details.${typeBooked?.toLowerCase()}-canceled`)
            .subscribe((resp) => {
              this.loading.next(false);
              this.reload.next(true);
              this.ngxToastrService.typeSuccess(null, resp);
            });
        }
      },
      (err) => {
        this.translateService
          .get(`user.details.error-cancel-${typeBooked?.toLowerCase()}`)
          .subscribe((resp) => {
            this.loading.next(false);
            if (err.error != null && err.error.message != null) {
              this.ngxToastrService.typeInfo(resp, err.error.message);
            } else {
              this.ngxToastrService.typeInfo(resp, "");
            }
          });
      }
    );
  }

  async getBonusArray(travel: any, isAdmin: boolean, user) {
    const bookings = isAdmin
      ? travel.bookings
      : travel.bookings.filter((b) => b.userUuid === user);
    const customBookings = isAdmin
      ? travel.customBookings
      : travel.customBookings.filter((b) => b.userUuid === user);
    const expenses = isAdmin
      ? travel.expenses
      : travel.expenses.filter((b) => b.userUuid === user);
    const cars = isAdmin
      ? travel.cars
      : travel.cars.filter((b) => b.userUuid === user);
    const flights = isAdmin
      ? travel.flights
      : travel.flights.filter((b) => b.userUuid === user);
    const trains = isAdmin
      ? travel.trains
      : travel.trains.filter((b) => b.userUuid === user);
    try {
      if (
        bookings.length <= 0 &&
        customBookings.length <= 0 &&
        cars.length <= 0 &&
        flights.length <= 0 &&
        trains.length <= 0 &&
        expenses.length <= 0
      ) {
        this.translateService
          .get(["project.not-export-bonus"])
          .pipe(take(1))
          .subscribe((value) =>
            this.ngxToastrService.typeInfo(
              null,
              value["project.not-export-bonus"]
            )
          );
      } else {
        switch (true) {
          case bookings.length > 0:
            const hotelsArray = [];
            for (const book of bookings.filter((b) => b.bonoFileUrl)) {
              this.getBookPdf(book.bonoFileUrl)
                .pipe(take(1))
                .subscribe(
                  async (res) => {
                    hotelsArray.push(res);

                    if (bookings.indexOf(book) === bookings.length - 1) {
                      await this.downloadBonusZip(
                        await Promise.all(hotelsArray),
                        "hotels"
                      );
                    }
                  },
                  (err) => {
                    this.translateService
                      .get(["common.error-pdf"])
                      .pipe(take(1))
                      .subscribe((value) =>
                        this.ngxToastrService.typeInfo(
                          null,
                          value["common.error-pdf"]
                        )
                      );
                  }
                );
            }
            case customBookings.length > 0:
              const customArray = [];
              for (const customBooking of customBookings.filter((b) => Array.isArray(b.voucherFile) && b.voucherFile.length > 0)) {
                for (const voucher of customBooking.voucherFile) {
                  this.getBookPdf(`/media/${voucher.uuid}/custom`)
                    .pipe(take(1))
                    .subscribe(
                      async (res) => {
                        customArray.push(res);
            
                        const isLastBooking = customBookings.indexOf(customBooking) === customBookings.length - 1;
                        const isLastVoucher = customBooking.voucherFile.indexOf(voucher) === customBooking.voucherFile.length - 1;
            
                        if (isLastBooking && isLastVoucher) {
                          await this.downloadBonusZip(
                            await Promise.all(customArray),
                            "custom-hotels"
                          );
                        }
                      },
                      (err) => {
                        this.translateService
                          .get(["common.error-pdf"])
                          .pipe(take(1))
                          .subscribe((value) =>
                            this.ngxToastrService.typeInfo(
                              null,
                              value["common.error-pdf"]
                            )
                          );
                      }
                    );
                }
              }
          case expenses.length > 0:
            const expensesArray = [];
            for (const expense of expenses.filter((b) => b.voucherFileUrl && b.voucherFileUrl !== '/media/expense/undefined')) {
              this.getBookPdf(expense.voucherFileUrl)
                .pipe(take(1))
                .subscribe(
                  async (res) => {
                    expensesArray.push(res);

                    if (expenses.indexOf(expense) === expenses.length - 1) {
                      await this.downloadBonusZip(
                        await Promise.all(expensesArray),
                        "expenses"
                      );
                    }
                  },
                  (err) => {
                    this.translateService
                      .get(["common.error-pdf"])
                      .pipe(take(1))
                      .subscribe((value) =>
                        this.ngxToastrService.typeInfo(
                          null,
                          value["common.error-pdf"]
                        )
                      );
                  }
                );
            }
          case cars.length > 0:
            const carsArray = [];
            for (const car of cars.filter((b) => b.voucherFileUrl && b.voucherFileUrl !== '/media/car/undefined')) {
              this.getBookPdf(car.voucherFileUrl)
                .pipe(take(1))
                .subscribe(
                  async (res) => {
                    carsArray.push(res);

                    if (cars.indexOf(car) === cars.length - 1) {
                      await this.downloadBonusZip(
                        await Promise.all(carsArray),
                        "cars"
                      );
                    }
                  },
                  (err) => {
                    this.translateService
                      .get(["common.error-pdf"])
                      .pipe(take(1))
                      .subscribe((value) =>
                        this.ngxToastrService.typeInfo(
                          null,
                          value["common.error-pdf"]
                        )
                      );
                  }
                );
            }
          case flights.length > 0:
            const flightsArray = [];
            for (const flight of flights.filter((b) => b.voucherFileUrl && b.voucherFileUrl !== '/media/flight/undefined')) {
              this.getBookPdf(flight.voucherFileUrl)
                .pipe(take(1))
                .subscribe(
                  async (res) => {
                    flightsArray.push(res);

                    if (flights.indexOf(flight) === flights.length - 1) {
                      await this.downloadBonusZip(
                        await Promise.all(flightsArray),
                        "flights"
                      );
                    }
                  },
                  (err) => {
                    flights.push();
                  }
                );
            }
          case trains.length > 0:
            const trainsArray = [];
            for (const train of trains.filter((b) => b.voucherFileUrl && b.voucherFileUrl !== '/media/train/undefined')) {
              this.getBookPdf(train.voucherFileUrl)
                .pipe(take(1))
                .subscribe(
                  async (res) => {
                    trainsArray.push(res);

                    if (trains.indexOf(train) === trains.length - 1) {
                      await this.downloadBonusZip(
                        await Promise.all(trainsArray),
                        "trains"
                      );
                    }
                  },
                  (err) => {
                    this.translateService
                      .get(["common.error-pdf"])
                      .pipe(take(1))
                      .subscribe((value) =>
                        this.ngxToastrService.typeInfo(
                          null,
                          value["common.error-pdf"]
                        )
                      );
                  }
                );
            }
            break;
          default:
            break;
        }
      }
    } catch (error) {
      this.translateService
        .get(["common.error-pdf"])
        .pipe(take(1))
        .subscribe((value) =>
          this.ngxToastrService.typeInfo(null, value["common.error-pdf"])
        );
    }
  }

  async downloadBonusZip(files: any[], type) {
    const zip = new JSZip();
    for (let i = 0; i < files.length; i++) {
      zip.file(`bono-${type}-${i + 1}.pdf`, files[i]);
      if (i === files.length - 1) {
        await zip
          .generateAsync({ type: "blob" })
          .then(async function (content) {
            await saveAs(content, `bonos-${type}.zip`);
          });
      }
    }
  }

  deleteForever(booking: any): Observable<any> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    let bookingType = this.getType(booking);
    if (bookingType === "bookingApiReference" && !booking.apiReference) {
      bookingType = "booking";
    }
    return this.http.delete(
      `${environment.baseUrlApi}/bookeds/${bookingType}/${booking.uuid}`,
      {
        headers: reqHeaders,
      }
    );
  }

  loadTravels(userUuid ?: string, companyUuid ?: string) {
    const params = {};
    if (companyUuid) {
      params["companyUuid"] = companyUuid;
    } else {
      params["userUuid"] = userUuid;
    }

    this.subscriptions.push(this.travelService
      .getTravelsWithParams(params)
      .subscribe(
        (travels: any) => {
          this.setData('travels', travels.docs);
        },
        (err) => {
          this.translateService
            .get("my-bookeds.user.error-get-booked")
            .subscribe((result) => {
              this.ngxToastrService.typeInfo(result, err.error.error);
            });
        }
      ));
  }

}
