import { Location } from "@angular/common";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Company, GroupRestriction, User } from "@models";
import { NgbActiveModal, NgbModal, NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {
  AuthService,
  CompanyService,
  GroupService,
  MediaService,
  NgxToastrService,
  UserService,
  UtilsService,
} from "@services";
import { SearchPipe } from "@shared/pipes/search.pipe";
import { AppState } from "app/app.reducer";
import * as _lodash from "lodash-es";
import * as moment from "moment";
import { merge, Observable, Subject, Subscription } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  take,
} from "rxjs/operators";
import countryJson from "../../../../../assets/utils/amadeus/countries.json";
import municipalitiesJson from "../../../../../assets/utils/resident-municipalities.json";
import { UserCardModalComponent } from "@shared/component/user-card-modal/user-card-modal.component";
import { ValidateAdult } from "app/validators/birthdate.validator";

@Component({
  selector: "app-user-form",
  templateUrl: "./user-form.component.html",
  styleUrls: ["./user-form.component.scss"],
})
export class UserFormComponent implements OnInit, OnDestroy {
  user: User;
  externalUser: any;
  group: GroupRestriction;
  company?: Company;
  agency?: String;
  hub?: String;
  loading: Boolean;
  firstUser: boolean;
  fromCompanyDetails;
  fromCompanyHomepage: boolean;
  signup?: boolean;

  @ViewChild("instanceGroup") instanceGroup: NgbTypeahead;
  isManager: boolean;
  externalUuid: string;
  groups: GroupRestriction[] = [];
  form: UntypedFormGroup;
  focusGroup$ = new Subject<string>();
  clickGroup$ = new Subject<string>();
  genderOptions = [
    { value: "male", text: "common.male" },
    { value: "female", text: "common.female" },
  ];
  residentOptions = [
    { value: "none", text: "Ninguno" },
    { value: "CN", text: "Canarias" },
    { value: "CE", text: "Ceuta" },
    { value: "BL", text: "Baleares" },
    { value: "ML", text: "Melilla" },
  ];
  date = new Date();
  minDateNgStruct = {
    year: this.date.getFullYear() - 100,
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  maxDateNgStruct = {
    year: this.date.getFullYear(),
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  canUseTRM = false;
  isAdmin = false;
  auth: any;
  canGroupAdmin = false;
  countriesList = countryJson;
  munipalitiesList = municipalitiesJson;
  subcriptions: Subscription[] = [];
  image: any;
  isImageSaved: boolean;
  cardImageBase64: string;
  imageError: string;
  previewImagePath: string;
  profileImage: any;
  isCreateUser: boolean = this.router.url.includes("create-user");
  companyUuid: string;
  color: string;
  isUser = false;
  isCompany = false;
  isInvalid: boolean = false;
  isSuperAdmin = false;
  selectedFfCard: any;
  selectedPaymentCard: any;
  /* selectedPersonalPaymentCard: any; */
  canEditPaymentCards: boolean = false;
  tokenPersonalCardUpdated: any[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private groupService: GroupService,
    private store: Store<AppState>,
    private userService: UserService,
    private router: Router,
    private location: Location,
    public translate: TranslateService,
    private ngxToastrService: NgxToastrService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private utilsService: UtilsService,
    private mediaService: MediaService,
    private modalService: NgbModal,
    private companyService: CompanyService
  ) {
    this.activatedRoute.paramMap.subscribe((data: any) => {
      this.externalUuid = data.params.id;
    });
    const navigation = this.router.getCurrentNavigation();

    if (navigation && navigation.extras.state != null) {
      if (navigation.extras.state.company) {
        this.company = this.router.getCurrentNavigation().extras.state.company;
      }
      if (navigation.extras.state.group) {
        this.group = this.router.getCurrentNavigation().extras.state.group;
      }
      if (navigation.extras.state.loading) {
        this.loading = this.router.getCurrentNavigation().extras.state.loading;
      }
      if (navigation.extras.state.externalUser) {
        this.externalUser =
          this.router.getCurrentNavigation().extras.state.externalUser;
      }
      if (navigation.extras.state.fromCompanyDetails) {
        this.fromCompanyDetails =
          this.router.getCurrentNavigation().extras.state.fromCompanyDetails;
      }
      if (navigation.extras.state.agency) {
        this.agency = this.router.getCurrentNavigation().extras.state.agency;
      }
      if (navigation.extras.state.fromCompanyHomepage) {
        this.fromCompanyHomepage =
          this.router.getCurrentNavigation().extras.state.fromCompanyHomepage;
      }
      if (navigation.extras.state.hub) {
        this.hub = this.router.getCurrentNavigation().extras.state.hub;
      }
      if (navigation.extras.state.user) {
        this.user = this.router.getCurrentNavigation().extras.state.user;
      }

      this.storeParams();
    } else {
      this.getFromLocalStorage();
    }

   
  }

  getFromLocalStorage() {
    this.agency = this.utilsService.getLocalStorageItem("agency")
      ? this.utilsService.getLocalStorageItem("agency").value
      : null;
    this.hub = this.utilsService.getLocalStorageItem("hub")
      ? this.utilsService.getLocalStorageItem("hub").value
      : null;
    this.firstUser = this.utilsService.getLocalStorageItem("firstUser")
      ? this.utilsService.getLocalStorageItem("firstUser").value
      : null;
    this.signup = this.utilsService.getLocalStorageItem("signup")
      ? this.utilsService.getLocalStorageItem("signup").value
      : null;
    this.group = this.utilsService.getLocalStorageItem("group")
      ? this.utilsService.getLocalStorageItem("group").value
      : null;
    this.company = this.utilsService.getLocalStorageItem("companyData")
      ? this.utilsService.getLocalStorageItem("companyData").value
      : null;
    this.loading = this.utilsService.getLocalStorageItem("loading")
      ? this.utilsService.getLocalStorageItem("loading").value
      : null;
    this.externalUser = this.utilsService.getLocalStorageItem("externalUser")
      ? this.utilsService.getLocalStorageItem("externalUser").value
      : null;
    this.fromCompanyDetails = this.utilsService.getLocalStorageItem(
      "fromCompanyDetails"
    )
      ? this.utilsService.getLocalStorageItem("fromCompanyDetails").value
      : null;
    if (typeof this.company === "string" || this.company instanceof String) {
      const companyString: any = this.company;
      this.company = JSON.parse(companyString);
    }
  }

  storeParams() {
    if(this.agency) {
      this.utilsService.storeParamInLocalStorage({
        title: "agency",
        value: this.agency,
      });
    }
    if(this.hub) {
      this.utilsService.storeParamInLocalStorage({
        title: "hub",
        value: this.hub,
      });
    }
    if(this.firstUser) {
      this.utilsService.storeParamInLocalStorage({
        title: "firstUser",
        value: this.firstUser,
      });
    }
    if(this.signup) {
      this.utilsService.storeParamInLocalStorage({
        title: "signup",
        value: this.signup,
      });
    }
    if(this.group) {
      this.utilsService.storeParamInLocalStorage({
        title: "group",
        value: this.group,
      });
    }
    if(this.company) {
      this.utilsService.storeParamInLocalStorage({
        title: "companyData",
        value: this.company,
      });
    }
    if(this.loading) {
      this.utilsService.storeParamInLocalStorage({
        title: "loading",
        value: this.loading,
      });
    }
    if(this.externalUser) {
      this.utilsService.storeParamInLocalStorage({
        title: "externalUser",
        value: this.externalUser,
      });
    }
    if(this.agency) {this.fromCompanyDetails
      this.utilsService.storeParamInLocalStorage({
        title: "fromCompanyDetails",
        value: this.fromCompanyDetails,
      });
    }
  }

  ngOnInit() {
    this.store
      .select("auth")
      .pipe(take(1))
      .subscribe((auth: any) => {
        this.auth = auth;
        if (
          auth && auth.user?.type
            ? auth.user?.type?.toUpperCase() !== "USER" && auth.user?.type?.toUpperCase() !== "COMPANY"
            : auth.user?.user.accessType?.toLowerCase() !== "user" &&
            auth.user?.user.accessType?.toLowerCase() !== "company"
        ) {
          this.isAdmin = true;
        }
        if(auth && auth.user?.type ? auth.user?.type?.toUpperCase() !== "USER" : auth.user?.user.accessType?.toLowerCase() !== "user"){
          this.canEditPaymentCards = true;
        }
        if (auth && auth.user && auth.user.type && auth.user.type?.toUpperCase() === "EMPLOYEE") {
          this.isSuperAdmin = true;
        }
        if (this.isCreateUser) {
          this.companyUuid = this.company
            ? this.company.uuid
            : this.auth
              ? this.auth.user?.companyUuid
              : auth.user?.companyUuid;
        }

        if (
          this.auth.user &&
          this.auth.user.image &&
          this.auth.user.image !== "undefined" &&
          !this.isCreateUser &&
          !this.externalUuid
        ) {
          let profileImage;
          if(this.auth.user.user){
            profileImage = this.auth.user.user.image;
          }else{
            profileImage = this.auth.user.image;
          }
          this.subcriptions.push(
            this.mediaService.getMedia(profileImage).subscribe((s) => {
              const reader = new FileReader();
              const reader1 = new FileReader();
              reader.readAsDataURL(s);
              let base64data;
              reader.onload = () => {
                base64data = reader.result;
                this.isImageSaved = true;
                this.cardImageBase64 = base64data;
              };
              reader1.readAsArrayBuffer(s);
            })
          );
        } else {
          this.cardImageBase64 = "assets/img/gallery/image_not_available.png";
        }
        this.canUseTRM = auth.user?.type
          ? auth.user?.plan && auth.user?.plan.permissions.canUseTRM
          : auth.user?.user.plan && auth.user?.user.plan.permissions.canUseTRM;
        this.canGroupAdmin = !this.isAdmin && this.canUseTRM;
        if (this.externalUuid == null && !this.isCreateUser) {
          this.authService.getProfile().subscribe((res: any) => {
            if(res.user){
              this.user = res.user;
            }else{
              this.user = res;
            }
            this.isUser = res.accessType?.toLowerCase() === "user";
            this.isManager = this.user.accessType?.toLowerCase() === "company" ? true : false;
            this.isCompany = this.user.accessType?.toLowerCase() === "company" ? true : false;
            this.createForm();
          });
        } else if (this.isCreateUser) {
          this.createForm();
        } else {
          this.userService.getUser(this.externalUuid).subscribe((res) => {
            this.user = res;
            this.isManager = this.user.accessType?.toLowerCase() === "company" ? true : false;
            this.isCompany = this.user.accessType?.toLowerCase() === "company" ? true : false;
            if (this.user.image && this.user.image !== "undefined") {
              this.subcriptions.push(
                this.mediaService.getMedia(this.user.image).subscribe((s) => {
                  const reader = new FileReader();
                  const reader1 = new FileReader();
                  reader.readAsDataURL(s);
                  let base64data;
                  reader.onload = () => {
                    base64data = reader.result;
                    this.isImageSaved = true;
                    this.cardImageBase64 = base64data;
                  };
                  reader1.readAsArrayBuffer(s);
                })
              );
            }
            this.createForm();
            this.form.get('resident').valueChanges.subscribe(val => {
              this.form.get('city').setValue(null);
            });
          });
        }
        const params = { ...this.activatedRoute.snapshot.queryParams };
        if (params && Object.keys(params).length > 0) {
          const cardName = localStorage.getItem('cardName');
          if (cardName) {
            params.cardName = cardName;
            localStorage.removeItem('cardName');
          }
          /* if (params.personal === 'true') {
            this.userService
            .postUserNewTokenRedsys(this.externalUuid, params)
            .subscribe(
              (result) => {
                this.user = result;
                this.tokenPersonalCardUpdated = result.personalPaymentCards;
                if(this.form){
                  this.form.setControl(
                    'personalPaymentCard',
                    this.formBuilder.array(this.initPersonalPaymentCards())
                  );
                }
                this.translate.get("company.card.card-added").subscribe((resp) => {
                  this.ngxToastrService.typeSuccess(null, resp);
                });
              },
              (err) => {
                this.translate.get("company.card.error").subscribe((resp) => {
                  this.ngxToastrService.typeInfo(null, resp);
                });
              }
            );
          } */
          if (params.personal !== 'true') {
            this.companyService
            .postCompanyNewTokenRedsys(this.company.uuid, params)
            .subscribe(
              (result) => {
                this.company = result;
                this.translate.get("company.card.card-added").subscribe((resp) => {
                  this.ngxToastrService.typeSuccess(null, resp);
                });
              },
              (err) => {
                this.translate.get("company.card.error").subscribe((resp) => {
                  this.ngxToastrService.typeInfo(null, resp);
                });
              }
            );
          }
        }
      });
    // this.fillCountries();
  }

  get f() {
    return this.form.controls;
  }

  createForm() {
    if (!this.signup && (this.company || this.user)) {
      if(this.company?.uuid || this.user?.companyUuid){
        this.groupService
        .getGroups(
          this.company && !this.isCompany
            ? this.company.uuid
            : this.user.companyUuid
        )
        .subscribe((data: GroupRestriction[]) => {
          this.groups = data;
        });
      }
    }
    let birthdate = "";
    let passportExpires = "";
    let passportIssuanceDate = "";
    let dniIssuanceDate = "";
    let nieIssuanceDate = "";
    let dniExpirationDate = "";
    let nieExpirationDate = "";
    // const u: any = this.user;

    /* if(this.router.url.includes("create-user")){
      this.user = new User();
    } */

    if (
      this.user &&
      this.user.birthdate &&
      this.user.birthdate !== "" &&
      this.user.birthdate !== null
    ) {
      birthdate = moment(this.user.birthdate.split("T")[0]).format(
        "YYYY-MM-DD"
      );
    }
    if (
      this.user &&
      this.user.passportExpires &&
      this.user.passportExpires !== "" &&
      this.user.passportExpires !== null &&
      this.user.passportExpires !== "Invalid date"
    ) {
      passportExpires = moment(this.user.passportExpires.split("T")[0]).format(
        "YYYY-MM-DD"
      );
    }
    if (
      this.user &&
      this.user.passportIssuanceDate &&
      this.user.passportIssuanceDate !== "" &&
      this.user.passportIssuanceDate !== null &&
      this.user.passportIssuanceDate !== "Invalid date"
    ) {
      passportIssuanceDate = moment(
        this.user.passportIssuanceDate.split("T")[0]
      ).format("YYYY-MM-DD");
    }
    if (
      this.user &&
      this.user.dniIssuanceDate &&
      this.user.dniIssuanceDate !== "" &&
      this.user.dniIssuanceDate !== null
    ) {
      dniIssuanceDate = moment(this.user.dniIssuanceDate.split("T")[0]).format(
        "YYYY-MM-DD"
      );
    }
    if (
      this.user &&
      this.user.nieIssuanceDate &&
      this.user.nieIssuanceDate !== "" &&
      this.user.nieIssuanceDate !== null
    ) {
      nieIssuanceDate = moment(this.user.nieIssuanceDate.split("T")[0]).format(
        "YYYY-MM-DD"
      );
    }
    if (
      this.user &&
      this.user.dniExpirationDate &&
      this.user.dniExpirationDate !== "" &&
      this.user.dniExpirationDate !== null
    ) {
      dniExpirationDate = moment(
        this.user.dniExpirationDate.split("T")[0]
      ).format("YYYY-MM-DD");
    }
    if (
      this.user &&
      this.user.nieExpirationDate &&
      this.user.nieExpirationDate !== "" &&
      this.user.nieExpirationDate !== null
    ) {
      nieExpirationDate = moment(
        this.user.nieExpirationDate.split("T")[0]
      ).format("YYYY-MM-DD");
    }

    this.form = this.formBuilder.group({
      name: [
        this.user ? this.user.name : "",
        [
          Validators.required,
        ],
      ],
      lastname: [
        this.user ? this.user.lastname : "",
        [
          Validators.required,
        ],
      ],
      email: [
        this.user ? this.user.email : "",
        [Validators.required, Validators.email],
      ],
      group: [this.user ? this.user.groupRestrictionUuid : "",],
      identificationType: [this.user && this.user.identificationType ? this.user.identificationType : "DNI", [Validators.required]],
      // DNI regex para españa, se quita al internacionalizar y no coincidir Validators.pattern('^([0-9]{8,8}[A-Za-z]|[XYZ][0-9]{7}[A-Z])$')
      dni: [this.user && this.user.dni ? this.user.dni : "", []],
      nie: [this.user && this.user.nie ? this.user.nie : '', []],
      dniIssuanceDate: [
        this.user && this.user.dniIssuanceDate
          ? {
            year: parseInt(dniIssuanceDate.split("-")[0], 10),
            month: parseInt(dniIssuanceDate.split("-")[1], 10),
            day: parseInt(dniIssuanceDate.split("-")[2], 10),
          }
          : null,
      ],
      nieIssuanceDate: [
        this.user && this.user.nieIssuanceDate
          ? {
            year: parseInt(nieIssuanceDate.split("-")[0], 10),
            month: parseInt(nieIssuanceDate.split("-")[1], 10),
            day: parseInt(nieIssuanceDate.split("-")[2], 10),
          }
          : null,
      ],
      dniExpirationDate: [
        this.user && this.user.dniExpirationDate
          ? {
            year: parseInt(dniExpirationDate.split("-")[0], 10),
            month: parseInt(dniExpirationDate.split("-")[1], 10),
            day: parseInt(dniExpirationDate.split("-")[2], 10),
          }
          : null,
      ],
      nieExpirationDate: [
        this.user && this.user.nieExpirationDate
          ? {
            year: parseInt(nieExpirationDate.split("-")[0], 10),
            month: parseInt(nieExpirationDate.split("-")[1], 10),
            day: parseInt(nieExpirationDate.split("-")[2], 10),
          }
          : null,
      ],
      passport: [this.user ? this.user.passport : ""],
      passportIssuanceDate: [
        this.user &&
          this.user.passportIssuanceDate &&
          passportIssuanceDate !== ""
          ? {
            year: parseInt(passportIssuanceDate.split("-")[0], 10),
            month: parseInt(passportIssuanceDate.split("-")[1], 10),
            day: parseInt(passportIssuanceDate.split("-")[2], 10),
          }
          : null,
      ],
      passportExpires: [
        this.user && this.user.passportExpires && passportExpires !== ""
          ? {
            year: parseInt(passportExpires.split("-")[0], 10),
            month: parseInt(passportExpires.split("-")[1], 10),
            day: parseInt(passportExpires.split("-")[2], 10),
          }
          : null,
      ],
      phone: [
        this.user ? (this.user.phone ? this.user.phone : "") : "",
        [Validators.required, Validators.pattern("[- +()0-9]+")],
      ],
      birthdate: [
        this.user
          ? this.user.birthdate
            ? {
              year: parseInt(birthdate.split("-")[0], 10),
              month: parseInt(birthdate.split("-")[1], 10),
              day: parseInt(birthdate.split("-")[2], 10),
            }
            : null
          : '',
        [Validators.required, ValidateAdult],
      ],
      image: [this.user ? this.user.image : null],
      gender: [this.user ? this.user.gender : "", [Validators.required]],
      resident: [this.user ? this.user.resident : ""],
      address: [this.user ? this.user.address : ""],
      city: [this.user ? this.user.city : ""],
      province: [this.user ? this.user.province : ""],
      postalCode: [this.user ? this.user.postalCode : ""],
      country: [this.user ? this.user.country : ""],
      nationality: [
        this.user ? this.user.nationality : "",
        [Validators.required],
      ],
      passportCountryEmitter: [
        this.user ? this.user.passportCountryEmitter : "",
      ],
      currentCountry: [this.user ? this.user.currentCountry : ""],
      employeeNumber: [this.user ? this.user.employeeNumber : ""],
      isGroupAdmin: [
        this.user ? this.user.isGroupAdmin : false,
        [Validators.required],
      ],
      isManager: [
        this.user
          ? this.user &&
          this.user.accessType &&
          this.user.accessType?.toLowerCase() === "company"
          : false,
        [Validators.required],
      ],
      renfeCard: [this.user ? this.user.renfeCard : null],
      ffCard: this.formBuilder.array(this.initFfCards()),
      paymentCard: this.formBuilder.array(this.initPaymentCards()),
      /* personalPaymentCard: this.formBuilder.array(this.initPersonalPaymentCards()), */
      notifications: this.formBuilder.group({
        company: [this.user && this.user.notifications ? this.user.notifications.company : false, [Validators.required]],
        group: [this.user && this.user.notifications ? this.user.notifications.group : false, [Validators.required]],
        user: [this.user && this.user.notifications ? this.user.notifications.user : true, [Validators.required]],
      }),
    });

    if (this.isSuperAdmin) {
      this.form.addControl(
        "annotations",
        this.formBuilder.control(
          this.user && this.user.annotations
            ? this.user.annotations
            : ''
        )
      );
    }

    if (this.router.url.includes("profile")) {
      if(this.user?.groupRestrictionUuid){
        this.subcriptions.push(
          this.userService.getGroupUser(this.user.uuid).subscribe((resp) => {
            this.group = resp;
          })
        );
      }
    }

    this.onIdentificationTypeChange();
  }


  initFfCards(): FormGroup[] {
    if (this.user && this.user.ffCard && Array.isArray(this.user.ffCard)) {
      return this.user.ffCard
        .filter(card => card && card.cardName && card.cardNumber)
        .map(card =>
          this.formBuilder.group({
            cardName: [card.cardName, Validators.required],
            cardNumber: [card.cardNumber, Validators.required]
          })
        );
    } else {
      return [];
    }
  }

  get ffCards(): FormArray {
    return this.form.get('ffCard') as FormArray;
  }

  initPaymentCards(): FormGroup[] {
    if (this.user && this.user.paymentCards && Array.isArray(this.user.paymentCards)) {
      return this.user.paymentCards
        .filter(card => card && card.name && card.token)
        .map(card =>
          this.formBuilder.group({
            cardName: [card.name, Validators.required],
          })
        );
    } else {
      return [];
    }
  }

/*   initPersonalPaymentCards(): FormGroup[] {
    if(this.tokenPersonalCardUpdated && this.tokenPersonalCardUpdated.length > 0){
      this.user.personalPaymentCards = this.tokenPersonalCardUpdated;
    }
    if (this.user && this.user.personalPaymentCards && Array.isArray(this.user.personalPaymentCards)) {
      return this.user.personalPaymentCards
        .filter(card => card && card.name && card.token)
        .map(card =>
          this.formBuilder.group({
            cardName: [card.name, Validators.required],
          })
        );
    } else {
      return [];
    }
  }
 */
  get paymentCards(): FormArray {
    return this.form.get('paymentCard') as FormArray;
  }

/*   get personalPaymentCards(): FormArray {
    return this.form.get('personalPaymentCard') as FormArray;
  } */

  openFfCardModal(type: string) {
    const modalRef = this.modalService.open(UserCardModalComponent, {
      windowClass: 'custom-modal-user-card',
      centered: true,
      backdrop: 'static',
      keyboard: false
    });
  
    if(type === 'ffCard'){
      modalRef.componentInstance.ffCards = this.form.get('ffCard').value;
      modalRef.componentInstance.cardType = 'ffCard';
    }else if(type === 'paymentCard'){
      modalRef.componentInstance.paymentCards = this.company?.cardRedsys || [];
      const userFormPaymentCards = this.form.get('paymentCard').value;
      const userPaymentCardsWithToken = userFormPaymentCards.map(userCard => {
        return this.company?.cardRedsys?.find(companyCard => companyCard.name === userCard.cardName);
      }).filter(card => card);
      modalRef.componentInstance.userPaymentCards = userPaymentCardsWithToken || [];
      modalRef.componentInstance.cardType = 'paymentCard';
    }/* else if(type === 'personalPaymentCard'){
      const filteredCards = (this.user?.personalPaymentCards || []).filter(card => card.token !== null);
      modalRef.componentInstance.personalPaymentCards = filteredCards || [];
      modalRef.componentInstance.cardType = 'personalPaymentCard';
    } */
    
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.externalUser = this.externalUser;
    modalRef.componentInstance.company = this.company;
  
    modalRef.result.then((result) => {
      if (result && result.type === 'ffCard') {
        let { ffCards } = result;

        ffCards = ffCards.filter(card => card !== undefined && card !== null);
  
        const ffCardArray = this.form.get('ffCard') as FormArray;

        ffCardArray.clear();
  
        ffCards.forEach(card => {
          ffCardArray.push(this.formBuilder.group({
            cardName: [card.cardName, Validators.required],
            cardNumber: [card.cardNumber, Validators.required]
          }));
        });
      }else if(result && result.type === 'paymentCard'){
        let { paymentCards, deletedPaymentCards  } = result;
        paymentCards = paymentCards.filter(card => card !== undefined && card !== null);
        if (deletedPaymentCards && deletedPaymentCards.length > 0) {
          this.company.cardRedsys = this.company.cardRedsys.filter(card => {
            return !deletedPaymentCards.some(deletedCard => deletedCard.token === card.token && deletedCard.name === card.name);
          });
        }
        const paymentCardArray = this.form.get('paymentCard') as FormArray;
        paymentCardArray.clear();
        paymentCards.forEach(card => {
          paymentCardArray.push(this.formBuilder.group({
            cardName: [card.name, Validators.required]
          }));
        });
      }/* else if(result && result.type === 'personalPaymentCard'){
        let { personalPaymentCards, deletedPersonalPaymentCards  } = result;
        personalPaymentCards = personalPaymentCards.filter(card => card !== undefined && card !== null);
        if (deletedPersonalPaymentCards && deletedPersonalPaymentCards.length > 0) {
          this.user.personalPaymentCards = this.user.personalPaymentCards.filter(card => {
            return !deletedPersonalPaymentCards.some(deletedCard => deletedCard.token === card.token && deletedCard.name === card.name);
          });
        }
        const personalPaymentCardArray = this.form.get('personalPaymentCard') as FormArray;
        personalPaymentCardArray.clear();
        personalPaymentCards.forEach(card => {
          personalPaymentCardArray.push(this.formBuilder.group({
            cardName: [card.cardName, Validators.required]
          }));
        });
      } */
    }).catch((error) => {});
  }

  formatGroup(group) {
    return group.name ? group.name : group;
  }

  getCardsInput(cards: any[]) {
    return cards.map(item => item.controls.cardName.value).join(', ')
  }

  changeManager(event) {
    this.isManager = !this.isManager;
    if(this.isManager) {
      // If isManager can't be group admin and receive group notifications
      this.f.isGroupAdmin.setValue(false);
      this.form.controls.isGroupAdmin.setValue(false);
      this.f.notifications.get(["group"]).setValue(false);
    }
  }

  changeGroupAdmin(event) {
    this.f.isGroupAdmin.setValue(!this.f.isGroupAdmin.value);
    this.form.controls.isGroupAdmin.setValue(!this.form.controls.isGroupAdmin.value);
    if(this.f.isGroupAdmin.value) {
      // If isGroupAdmin can't be company admin and receive company notifications
      this.isManager = false;
      this.f.notifications.get(["company"]).setValue(false);
    }
  }

  changeNotifications(event) {
    switch (event.source.id) {
      case "company":
        this.f.notifications.get(["company"]).setValue(event.checked);
        if(event.checked) {
          this.f.notifications.get(["group"]).setValue(false);
          this.f.notifications.get(["user"]).setValue(false);
        }
        break;
      case "group":
        this.f.notifications.get(["group"]).setValue(event.checked);
        if(event.checked) {
          this.f.notifications.get(["company"]).setValue(false);
          this.f.notifications.get(["user"]).setValue(false);
        }
        break;
      case "user":
      default:
        this.f.notifications.get(["user"]).setValue(event.checked);
        if(event.checked) {
          this.f.notifications.get(["company"]).setValue(false);
          this.f.notifications.get(["group"]).setValue(false);
        }
        break;
    }
  }

  searchGroup = (text$: Observable<string>) => {
    const searchPipe = new SearchPipe();

    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.clickGroup$.pipe(
      filter(() => !this.instanceGroup.isPopupOpen())
    );
    const inputFocus$ = this.focusGroup$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) => searchPipe.transform(this.groups, "name", term))
    );
  };

  onSubmit() {
    this.isInvalid = false;
    this.loading = true;
    const attributes = {};

    const image = this.profileImage;
    delete attributes["image"];
    let formData = new FormData();
    /*  let birthdate = this.parserFormDate(this.form.getRawValue().birthdate);
     delete this.form.value.birthdate;
     this.form.value.birthdate = birthdate;

     birthdate = this.parserFormDate(this.form.getRawValue().dniIssuanceDate);
     delete this.form.value.dniIssuanceDate;
     this.form.value.dniIssuanceDate = birthdate;

     birthdate = this.parserFormDate(this.form.getRawValue().dniExpirationDate);
     delete this.form.value.dniExpirationDate;
     this.form.value.dniExpirationDate = birthdate;

     if (this.form.value.passportIssuanceDate &&
       this.form.value.passportIssuanceDate.month != null &&
       !isNaN(this.form.value.passportIssuanceDate.month)) {
       birthdate = this.parserFormDate(
         this.form.getRawValue().passportIssuanceDate
       );
       delete this.form.value.passportIssuanceDate;
       this.form.value.passportIssuanceDate = birthdate;
     }
     if (
       this.form.value.passportExpires &&
       this.form.value.passportExpires.month != null &&
       !isNaN(this.form.value.passportExpires.month)
     ) {
       birthdate = this.parserFormDate(this.form.getRawValue().passportExpires);
       delete this.form.value.passportExpires;
       this.form.value.passportExpires = birthdate;
     } */

    let userPaymentCardsWithToken;
    if (this.f.paymentCard.value && this.company?.cardRedsys) {
      const userFormPaymentCards = this.f.paymentCard.value;
      userPaymentCardsWithToken = userFormPaymentCards
      .map(userCard => {
        const foundCard = this.company.cardRedsys.find(companyCard => companyCard.name === userCard.cardName);
        return foundCard ? foundCard : null;
      })
      .filter(card => card !== null);
    }

    /* let userPersonalPaymentCardsWithToken;
    if (this.f?.personalPaymentCard.value && this.user?.paymentCards) {
      const userFormPaymentCards = this.f?.personalPaymentCard.value;
      userPersonalPaymentCardsWithToken = userFormPaymentCards
      .map(userCard => {
        const foundCard = this.user.personalPaymentCards.find(card => card.name === userCard.cardName);
        return foundCard ? foundCard : null;
      })
      .filter(card => card !== null);
    } */

    const userInfo: any = {
      name: this.f.name.value,
      lastname: this.f.lastname.value,
      email: this.f.email.value,
      groupRestrictionUuid: !!this.f.group.value
        ? this.f.group.value
        : this.groups[0]
          ? this.groups[0].uuid
          : '',
      passport: this.f.passport.value,
      passportIssuanceDate:
        this.f.passportIssuanceDate.value &&
          this.f.passportIssuanceDate.value !== ""
          ? this.f.passportIssuanceDate.value.year +
          "-" +
          this.f.passportIssuanceDate.value.month +
          "-" +
          this.f.passportIssuanceDate.value.day
          : "",
      passportExpires:
        this.f.passportExpires.value && this.f.passportExpires.value !== ""
          ? this.f.passportExpires.value.year +
          "-" +
          this.f.passportExpires.value.month +
          "-" +
          this.f.passportExpires.value.day
          : "",
      phone: this.f.phone.value,
      gender: this.f.gender.value,
      resident: this.f.resident.value ? this.f.resident.value : "none",
      country: this.f.country.value,
      nationality: this.f.nationality.value ? this.f.nationality.value : "",
      currentCountry: this.f.currentCountry.value
        ? this.f.currentCountry.value
        : "",
      passportCountryEmitter: this.f.passportCountryEmitter.value
        ? this.f.passportCountryEmitter.value
        : "",
      birthdate:
        this.f.birthdate.value && this.f.birthdate.value !== ""
          ? this.f.birthdate.value.year +
          "-" +
          (this.f.birthdate.value.month.toString().length <= 1 ? "0" + this.f.birthdate.value.month.toString() : this.f.birthdate.value.month) +
          "-" +
          (this.f.birthdate.value.day.toString().length <= 1 ? "0" + this.f.birthdate.value.day.toString() : this.f.birthdate.value.day)
          : "",
      employeeNumber: this.f.employeeNumber.value
        ? this.f.employeeNumber.value.toString()
        : "",
      isGroupAdmin: this.f.isGroupAdmin.value
        ? this.f.isGroupAdmin.value.toString() === "true"
        : false,
      ffCard: this.f.ffCard.value || [],
      identificationType: this.f.identificationType.value
    };

    if(userPaymentCardsWithToken && userPaymentCardsWithToken.length > 0){
      userInfo.paymentCards = userPaymentCardsWithToken || [];
    }
/*     if(userPersonalPaymentCardsWithToken && userPersonalPaymentCardsWithToken.length > 0){
      userInfo.personalPaymentCards = userPersonalPaymentCardsWithToken || [];
    } */

    if (this.f.identificationType.value === 'DNI') {
      userInfo.dni = this.f.dni.value;
      userInfo.dniIssuanceDate =
        this.f.dniIssuanceDate.value && this.f.dniIssuanceDate.value !== ""
          ? this.f.dniIssuanceDate.value.year +
            "-" +
            this.f.dniIssuanceDate.value.month +
            "-" +
            this.f.dniIssuanceDate.value.day
          : "";
      userInfo.dniExpirationDate =
        this.f.dniExpirationDate.value && this.f.dniExpirationDate.value !== ""
          ? this.f.dniExpirationDate.value.year +
            "-" +
            this.f.dniExpirationDate.value.month +
            "-" +
            this.f.dniExpirationDate.value.day
          : "";
      delete userInfo.nie;
      delete userInfo.nieIssuanceDate;
      delete userInfo.nieExpirationDate;
    } else if (this.f.identificationType.value === 'NIE') {
      userInfo.nie = this.f.nie.value;
      userInfo.nieIssuanceDate =
        this.f.nieIssuanceDate.value && this.f.nieIssuanceDate.value !== ""
          ? this.f.nieIssuanceDate.value.year +
            "-" +
            this.f.nieIssuanceDate.value.month +
            "-" +
            this.f.nieIssuanceDate.value.day
          : "";
      userInfo.nieExpirationDate =
        this.f.nieExpirationDate.value && this.f.nieExpirationDate.value !== ""
          ? this.f.nieExpirationDate.value.year +
            "-" +
            this.f.nieExpirationDate.value.month +
            "-" +
            this.f.nieExpirationDate.value.day
          : "";
      delete userInfo.dni;
      delete userInfo.dniIssuanceDate;
      delete userInfo.dniExpirationDate;
    }

    _lodash.get(this.f, "city.value", "") === null
      ? (userInfo.city = "")
      : (userInfo.city = _lodash.get(this.f, "city.value"));
    _lodash.get(this.f, "province.value", "") === null
      ? (userInfo.province = "")
      : (userInfo.province = _lodash.get(this.f, "province.value"));
    _lodash.get(this.f, "city.postalCode", "") === null
      ? (userInfo.postalCode = "")
      : (userInfo.postalCode = _lodash.get(this.f, "postalCode.value"));
    _lodash.get(this.f, "address.value", "") === null
      ? (userInfo.address = "")
      : (userInfo.address = _lodash.get(this.f, "address.value"));
    _lodash.get(this.f, "postalCode.value", "") === null
      ? (userInfo.postalCode = "")
      : (userInfo.postalCode = _lodash.get(this.f, "postalCode.value"));
    _lodash.get(this.f, "renfeCard.value", "") === null
      ? (userInfo.renfeCard = "")
      : (userInfo.renfeCard = _lodash.get(this.f, "renfeCard.value"));
    if (this.fromCompanyDetails) {
      this.isManager
        ? // ? (userInfo["accessType"] !== "company")
        // : (userInfo["accessType"] !== "user") && (userInfo["accessType"] !== "company");

        (userInfo["accessType"] = "company")
        : (userInfo["accessType"] = "user");
    }
    if (this.company) {
      userInfo.companyUuid = !this.isCompany
        ? this.company.uuid
        : this.user.companyUuid;
    }
    if (this.agency) {
      userInfo.agencyUuid = this.agency;
      userInfo["accessType"] = "agency";
    }
    if (this.hub) {
      userInfo.hubUuid = this.hub;
      userInfo["accessType"] = "hub";
    }

    if (this.isAdmin) {
      userInfo.annotations = this.form.controls.annotations && this.form.controls.annotations.value && this.form.controls.annotations.value !== "" ? this.form.controls.annotations.value : ""
    }

    const notifications= {
      company: this.f.notifications.get("company").value,
      group: this.f.notifications.get("group").value,
      user: this.f.notifications.get("user").value,
    }
    formData.append('notifications', JSON.stringify(notifications))
    formData.append('ffCard', JSON.stringify(userInfo.ffCard));
    if(userInfo.paymentCards){
      formData.append('paymentCards', JSON.stringify(userInfo.paymentCards));
    }
    /* if(userInfo.personalPaymentCards){
      formData.append('personalPaymentCards', JSON.stringify(userInfo.personalPaymentCards));
    } */

    for (const property in userInfo) {
      if (userInfo[property] !== null && property !== 'ffCard' && property !== 'paymentCards' /* && property !== 'personalPaymentCards' */) {
        formData.append(`${property}`, `${userInfo[property]}`);
      }
    }


    formData = this.utilsService.toFormData(attributes, formData);
    if (image && image.name) {
      formData.append("file", image, image.name);
    }

    const action = this.isCreateUser
      ? this.userService.postUser(formData)
      : this.userService.putUser(this.user.uuid, formData);

    this.subcriptions.push(
      action.subscribe(
        (res) => {
          this.loading = false;
          this.utilsService.updateUser(res);
          this.translate
            .get(
              !this.isCreateUser
                ? "company.company-detail.user-edit"
                : "company.company-detail.created-user"
            )
            .subscribe((result) => {
              this.ngxToastrService.typeSuccess(null, result);
              if (
                this.externalUser &&
                (this.externalUser.type?.toUpperCase() === "EMPLOYEE" ||
                  (this.externalUser.user &&
                    (this.externalUser.user.accessType?.toLowerCase() === "agency" ||
                      this.externalUser.user.accessType?.toLowerCase() === "hub")))
              ) {
                this.router.navigate([
                  `/companies/${this.company.uuid || this.user.companyUuid}`,
                ]);
              } else if (this.fromCompanyHomepage) {
                this.router.navigate([`/agencies/${this.agency}/companies`]);
              } else if (!this.isUser && this.canUseTRM) {
                this.router.navigate(["/my-company/details"]);
              } else if (this.isAdmin) {
                if (res.accessType?.toLowerCase() === "company") {
                  this.router.navigate([
                    `/companies/${this.company.uuid || this.user.companyUuid}`,
                  ]);
                } else if (res.accessType?.toLowerCase() === "agency") {
                  this.router.navigate([`/companies/`]);
                } else {
                  this.router.navigate([`/agencies/`]);
                }
              } else {
                this.utilsService.userChanged();
              }
            });
          this.user = res;
        },
        (err) => {
          this.loading = false;
          this.translate
            .get(
              this.isCreateUser
                ? "company.company-detail.error-user-edit"
                : "company.company-detail.error-created-user"
            )
            .subscribe((result) => {
              this.ngxToastrService.typeInfo(result, err.error.message);
            });
        }
      )
    );
  }

  parserFormDate(date: any) {
    const birthdate = new Date(date.year, date.month, date.day);
    return birthdate.toISOString();
  }
  goBack() {
    this.location.back();
  }

  compareFn(a: GroupRestriction, b: GroupRestriction) {
    return a && b && a.uuid === b.uuid;
  }

  fillCountries() {
    this.countriesList = countryJson;
  }

  ngOnDestroy() {
    this.subcriptions.forEach((sub) => {
      sub.unsubscribe();
    });
    this.utilsService.removeItemFromLocalStorage("agency");
    this.utilsService.removeItemFromLocalStorage("hub");
    this.utilsService.removeItemFromLocalStorage("firstUser");
    this.utilsService.removeItemFromLocalStorage("signup");
    this.utilsService.removeItemFromLocalStorage("group");
    // this.utilsService.removeItemFromLocalStorage("company");
    this.utilsService.removeItemFromLocalStorage("loading");
    this.utilsService.removeItemFromLocalStorage("externalUser");
    this.utilsService.removeItemFromLocalStorage("fromCompanyDetails");
    this.utilsService.removeItemFromLocalStorage("companyData");
  }

  onImageChange(event) {
    this.imageError = null;

    const attributes = {};
    let formData = new FormData();
    // const image = this.profileImage;
    delete attributes["image"];

    if (event.target.files && event.target.files[0]) {
      // Size Filter Bytes
      const max_size = 2000000;
      const allowed_types = ["image/png", "image/jpeg"];
      const max_height = 15200;
      const max_width = 25600;
      if (event.target.files[0].size > max_size) {
        return this.subcriptions.push(
          this.translate
            .get(["user.form.tittle-file-error", "user.form.file-error-size"])
            .subscribe((result) => {
              this.ngxToastrService.typeInfo(
                result["user.form.tittle-file-error"],
                result["user.form.file-error-size"] + max_size / 1000000 + "Mb"
              );
            })
        );
      }
      if (!_lodash.includes(allowed_types, event.target.files[0].type)) {
        return this.subcriptions.push(
          this.translate
            .get(["user.form.tittle-file-error", "user.form.file-error-format"])
            .subscribe((result) => {
              this.ngxToastrService.typeInfo(
                result["user.form.tittle-file-error"],
                result["user.form.file-error-format"]
              );
            })
        );
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          const img_height = rs.currentTarget["height"];
          const img_width = rs.currentTarget["width"];

          if (img_height > max_height && img_width > max_width) {
            return this.subcriptions.push(
              this.translate
                .get([
                  "user.form.tittle-file-error",
                  "user.form.file-error-dimension",
                ])
                .subscribe((result) => {
                  this.ngxToastrService.typeInfo(
                    result["user.form.tittle-file-error"],
                    result["user.form.file-error-dimension"] +
                    max_width +
                    "*" +
                    max_height +
                    "px"
                  );
                })
            );
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;
            this.profileImage = event.target.files[0];
            this.previewImagePath = imgBase64Path;
          }
        };
      };
      reader.readAsDataURL(event.target.files[0]);

      formData = this.utilsService.toFormData(attributes, formData);
      if (event.target.files[0] && event.target.files[0].name) {
        formData.append(
          "file",
          event.target.files[0],
          event.target.files[0].name
        );
      }
      if (!this.isCreateUser) {
        const action = this.isCreateUser
          ? this.userService.postUser(formData)
          : this.userService.putUser(this.user.uuid, formData);
        this.subcriptions.push(
          action.subscribe(
            (res) => {
              this.loading = false;
              this.translate
                .get(
                  !this.isCreateUser
                    ? "company.company-detail.user-edit"
                    : "company.company-detail.created-user"
                )
                .subscribe((result) => {
                  this.ngxToastrService.typeSuccess(null, result);
                  if (this.isCreateUser) {
                    this.router.navigate(["/my-company/details"]);
                  }
                  if (this.externalUuid == null && !this.isCreateUser) {
                    this.utilsService.imageChanged(res.image);
                  }
                });
              this.user = res;
            },
            (err) => {
              this.loading = false;
              this.translate
                .get(
                  this.isCreateUser
                    ? "company.company-detail.error-user-edit"
                    : "company.company-detail.error-created-user"
                )
                .subscribe((result) => {
                  this.ngxToastrService.typeInfo(result, err.error.message);
                });
            }
          )
        );
      }
    }
  }

  removeImage() {
    this.cardImageBase64 = null;
    this.isImageSaved = false;
    this.profileImage = null;
    const formData = new FormData();
    formData.append("image", undefined);
    this.subcriptions.push(
      this.userService.putUser(this.user.uuid, formData).subscribe(
        (res) => {
          this.loading = false;
          this.translate
            .get(
              !this.isCreateUser
                ? "company.company-detail.user-edit"
                : "company.company-detail.created-user"
            )
            .subscribe((result) => {
              this.ngxToastrService.typeSuccess(null, result);
              if (this.isCreateUser) {
                this.router.navigate(["/my-company/details"]);
              }
              if (this.externalUuid == null && !this.isCreateUser) {
                this.utilsService.imageChanged(null);
              }
            });
          this.user = res;
        },
        (err) => {
          this.loading = false;
          this.translate
            .get(
              this.isCreateUser
                ? "company.company-detail.error-user-edit"
                : "company.company-detail.error-created-user"
            )
            .subscribe((result) => {
              this.ngxToastrService.typeInfo(result, err.error.message);
            });
        }
      )
    );
  }

  onIdentificationTypeChange(): void {
    const idType = this.form.get('identificationType').value;
    if (idType === 'DNI') {
      this.form.get('dni').setValidators([Validators.required]);
      this.form.get('nie').clearValidators();
    } else if (idType === 'NIE') {
      this.form.get('nie').setValidators([Validators.required]);
      this.form.get('dni').clearValidators();
    }
    this.form.get('dni').updateValueAndValidity();
    this.form.get('nie').updateValueAndValidity();
  }

}
