<form [formGroup]="form">
  <div class="column">
    <div class="row">
      <div>
        <input
          [disabled]="loadingData"
          (input)="emitPage()"
          formControlName="modelHotel"
          class="form-control form-control--squared form-control--small"
          [ngClass]="{ 'input-disabled': loadingData }"
          placeholder="{{ 'common.search-by' | translate }}"
        />
      </div>
      <div class="col-2 select-filter">
        <!-- <mat-select (change)="changeComponent($event.target.value)" id="changeComponent" class="form-control rounded-1">
          <mat-option *ngFor="let component of typeComponent; let i=index" value="{{i}}">
            {{component.text | translate}}
          </mat-option>
        </mat-select> -->
        <select
          (change)="changeComponent($event.target.value)"
          id="changeComponent"
          class="form-control form-control--squared form-control--small select-input"
          [disabled]="loadingData"
        >
          <option
            *ngFor="let component of typeComponent; let i = index"
            value="{{ i }}"
          >
            {{ component.text | translate }}
          </option>
        </select>
      </div>
      <div class="select-filter">
        <select
          (change)="changeStatusOption($event)"
          id="statusOptionType"
          class="form-control form-control--squared form-control--small select-input"
          [disabled]="loadingData"
        >
          <option
            *ngFor="let statusOption of statusOptions; let i = index"
            value="{{ i }}"
          >
            {{ statusOption.text | translate }}
          </option>
        </select>
      </div>
      <div>
        <input
          #bookedRange
          class="form-control form-control--squared form-control--small filter-dates"
          (dateSelect)="onDateSelection('booked', $event)"
          placeholder="{{ 'common.travel-date' | translate }}"
          #dp
          ngbDatepicker
          [dayTemplate]="t1"
          [autoClose]="true"
          #d1="ngbDatepicker"
          readonly
          (click)="d1.toggle()"
          [autoClose]="'outside'"
          [ngClass]="{
            'filter-dates-placeholder': !bookedRange.value,
            'disabled-date-input': loadingData
          }"
          [disabled]="loadingData"
        />
        <ng-template #t1 let-date let-focused="focused">
          <span
            class="custom-day"
            [ngClass]="{
              activeFrom: fromDate === date,
              activeTo: toDate === date
            }"
            [class.focused]="focused"
            [class.range]="isRange(date, 'booked')"
            [class.faded]="
              isHovered(date, 'booked') || isInside(date, 'booked')
            "
            (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null"
          >
            {{ date.day }}
          </span>
        </ng-template>
      </div>
      <div>
        <input
          #createdAtRange
          class="form-control form-control--squared form-control--small filter-dates date-input"
          (dateSelect)="onDateSelection('create', $event)"
          placeholder="{{ 'common.creation-date' | translate }}"
          #dp
          ngbDatepicker
          [dayTemplate]="t2"
          [autoClose]="true"
          [autoClose]="'outside'"
          #d2="ngbDatepicker"
          readonly
          (click)="d2.toggle()"
          [ngClass]="{
            'filter-dates-placeholder': !createdAtRange.value,
            'disabled-date-input': loadingData
          }"
          [disabled]="loadingData"
        />
        <ng-template #t2 let-date let-focused="focused">
          <span
            class="custom-day"
            [ngClass]="{
              activeFrom: createdFromDate === date,
              activeTo: createdToDate === date
            }"
            [class.focused]="focused"
            [class.range]="isRange(date, 'create')"
            [class.faded]="
              isHovered(date, 'create') || isInside(date, 'create')
            "
            (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null"
          >
            {{ date.day }}
          </span>
        </ng-template>
      </div>
      <div>
        <button
          [ngClass]="[
            canClean() ? 'btn-outline-danger' : '',
            loadingData ? 'btn-disabled' : ''
          ]"
          type="button"
          class="clear-btn"
          [disabled]="!canClean() || loadingData"
          (click)="cleanBookedFilters()"
        >
          {{ "common.clean" | translate }}
          <img
            src="{{
              !canClean()
                ? 'assets/img/svg/grey-cross.svg'
                : 'assets/img/svg/red-cross.svg'
            }}"
          />
        </button>
      </div>
    </div>
    <div>
      <mat-checkbox [disabled]="loadingData" (change)="nextBookings($event)">{{
        "my-bookeds.user.nextBookings" | translate
      }}</mat-checkbox>
    </div>
  </div>
</form>
