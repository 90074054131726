<!-- <agm-map
#AgmMap
  *ngIf="listHotels.length > 0"
  minZoom="12"
  (mapClick)="closeWindow()"
  style="height: 100%; width: 100%;"
  [zoom]="15"
  [fitBounds]="firstSearch"
  mapTypeId="roadmap"
  [latitude]="coordCentral.latitude"
  [longitude]="coordCentral.longitude"

  > -->
<!-- CÓDIGO PARA ZOOM OUT SEARCH -->
<!-- <div *ngIf="!listHotels || listHotels.length === 0" class="col-12">
  <app-loading [userType]="'user'" [map]="true"></app-loading>
</div> -->
<!-- <agm-map
  #AgmMap
  *ngIf="listHotels && listHotels.length > 0 && shouldLoadMark === false"
  (mapClick)="closeWindow()"
  style="height: 100%; width: 100%"
  [maxZoom]="18"
  [minZoom]="8"
  [zoom]="15"
  mapTypeId="roadmap"
  [latitude]="latitude"
  [longitude]="longitude"
>
<div>
  <agm-marker
  [agmFitBounds]="true"
  [latitude]="latitude"
  [longitude]="longitude"
  >
  </agm-marker>
</div>
  <div *ngFor="let hotel of listHotels;">
    <agm-marker
      [label]="showLabel(hotel) ? getPrice(hotel) + '€' : ''"
      [agmFitBounds]="true"
      [iconUrl]="setIcon(hotel)"
      (markerClick)="selectMarker(infoWindow)"
      style="width: 10px; height: 10px"
      [latitude]="hotel.coordinates.latitude"
      [longitude]="hotel.coordinates.longitude"
    >
      <agm-info-window [disableAutoPan]="true" #infoWindow>
        <div class="container-fluid">
          <div class="row">
            <div class="col col-6 noPadding carousel-container">
              <ngb-carousel
                class="card-img-top img-fluid"
                data-interval="false"
              >
                <ng-template
                  ngbSlide
                  *ngFor="let img of hotel.images; let i = index"
                >
                  <img
                    [src]="img | noimage"
                    alt="No hay imagen"
                    class="mr-auto ml-0 mb-0 imgcar"
                    style="height: 9.375rem !important"
                    (click)="getHotel(hotel)"
                  />
                </ng-template>
              </ngb-carousel>
            </div>
            <div
              class="col col-6 my-auto data-container"
              (click)="getHotel(hotel)"
            >
              <div>
                <h5
                  class="zoom-transition titlePopup cursor-pointer"
                  title="{{ 'search.card.see-rooms' | translate }}"
                >
                  {{ hotel.name }}
                </h5>
                <p class="m-0">
                  {{ hotel.address }}
                </p>
                <p class="m-0">
                  {{ hotel.distanceToDestination | number: "1.2-2" }}
                  {{ "search.card.distanceToDestination" | translate }}
                </p>
                <p class="m-0">
                  <i
                    *ngFor="let star of arrayOne(hotel.stars)"
                    class="fa fa-star yellow darken-2"
                  ></i>
                </p>
              </div>

              <h5 *ngIf="hotel.totalBooked" style="color: #464646; font-weight: bold">
                {{ hotel.totalBooked }} €
              </h5>

            </div>
          </div>
        </div>
      </agm-info-window>
    </agm-marker>
  </div>
</agm-map> -->

  <!-- <agm-circle *ngIf="listHotels.length > 0"
    [latitude]="coordCentral.latitude"
    [longitude]="coordCentral.longitude"
    [radius]="getRadius()"
    [fillColor]="'lightgrey'"
    [circleDraggable]="false"
    [editable]="false"
  >
  </agm-circle> -->

<!-- <agm-map #AgmMap *ngIf="shouldLoadMark"
  [latitude]="latitudeHotel"
  [longitude]="longitudeHotel"
  [maxZoom]="20"
  [minZoom]="8"
  [zoom]="15"
  [fitBounds]="firstSearch"
  mapTypeId="roadmap"
  style="height: 100%; width: 100%"
  [scrollwheel]="true"
  [mapTypeId]="'roadmap'"
  (mapClick)="closeWindow()"
>
  <div *ngFor="let hotel of listHotels;">

    <agm-marker
      *ngIf="shouldLoadMark == true"
      [agmFitBounds]="true"
      (markerClick)="selectMarker(infoWindow)"
      style="width: 10px; height: 10px"
      [latitude]="hotel.coordinates.latitude"
      [longitude]="hotel.coordinates.longitude"
    >
      <agm-info-window [disableAutoPan]="true" #infoWindow>
        <div class="container-fluid">
          <div class="row">
            <div class="col col-6 noPadding carousel-container">
              <ngb-carousel
                class="card-img-top img-fluid"
                data-interval="false"
              >
                <ng-template
                  ngbSlide
                  *ngFor="let img of hotel.images; let i = index"
                >
                  <img
                    [src]="img | noimage"
                    alt="No hay imagen"
                    class="mr-auto ml-0 mb-0 imgcar"
                    style="height: 9.375rem !important"
                    (click)="getHotel(hotel)"
                  />
                </ng-template>
              </ngb-carousel>
            </div>
            <div
              class="col col-6 my-auto data-container"
              (click)="getHotel(hotel)"
            >
              <div>
                <h5
                  class="zoom-transition titlePopup cursor-pointer"
                  title="{{ 'search.card.see-rooms' | translate }}"
                >
                  {{ hotel.name }}
                </h5>
                <p class="m-0">
                  {{ hotel.address }}
                </p>
                <p class="m-0">
                  {{ hotel.distanceToDestination | number: "1.2-2" }}
                  {{ "search.card.distanceToDestination" | translate }}
                </p>
                <p class="m-0">
                  <i
                    *ngFor="let star of arrayOne(hotel.stars)"
                    class="fa fa-star yellow darken-2"
                  ></i>
                </p>
              </div>

              <h5 *ngIf="hotel.totalBooked" style="color: #464646; font-weight: bold">
                {{ hotel.totalBooked }} €
              </h5>

            </div>
          </div>
        </div>
      </agm-info-window>
    </agm-marker>
  </div>
</agm-map> -->

  <map-view *ngIf="shouldLoadMark"
    [latitude]="latitudeHotel" [longitude]="longitudeHotel" 
    [zoom]="15" 
    [maxZoom]="25" 
    [minZoom]="8"
    [fitBounds]="firstSearch"
    style="height: 100%; width: 100%"
    [mapTypeId]="'roadmap'"
    [mapTypeControl]="false"
    (mapClick)="closeWindow()">

    <div *ngFor="let hotel of listHotels; let i = index; trackBy: utilsService.getUuidTracking">
    <map-marker
      *ngIf="shouldLoadMark == true"
      (markerClick)="selectMarker(infoWindow)"
      style="width: 10px; height: 10px"
      [latitude]="hotel.coordinates.latitude"
      [longitude]="hotel.coordinates.longitude">

      <map-info-window [disableAutoPan]="true" #infoWindow>
        <div class="info-window-content">
        <div class="container-fluid">
          <div class="row" style="padding-left: 10px !important;">
            <div class="col col-6 noPadding carousel-container" style="padding-right: 2rem !important;">
              <div id="hotelImagesMap{{ i }}" class="carousel slide w-100" style="height: 10rem !important; width: 15rem !important;" data-ride="carousel" wrap="true">
                <ol class="carousel-indicators">
                    <li *ngFor="let image of hotel.images; let j = index" 
                        [attr.data-target]="'#hotelImagesMap' + i" 
                        [attr.data-slide-to]="j" 
                        [class.active]="j === 0">
                    </li>
                </ol>
                <div class="carousel-inner">
                    <div *ngFor="let image of hotel.images; let j = index" 
                         class="carousel-item" 
                         [class.active]="j === 0">
                        <img [src]="image" class="d-block w-100 h-100" alt="{{ hotel.name }}">
                    </div>
                </div>
                <a class="carousel-control-prev" role="button" (click)="moveCarousel('prev', i)">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" role="button" (click)="moveCarousel('next', i)">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a>
            </div>
            </div>
            <div
              class="col col-6 my-auto data-container"
              (click)="getHotel(hotel)"
            >
              <div>
                <h5
                  class="zoom-transition titlePopup cursor-pointer"
                  title="{{ 'search.card.see-rooms' | translate }}"
                  style="font-size: medium !important; font-weight: 600 !important;"
                >
                  {{ hotel.name }}
                </h5>
                <p class="m-0">
                  {{ hotel.address }}
                </p>
                <p class="m-0">
                  {{ hotel.distanceToDestination | number: "1.2-2" }}
                  {{ "search.card.distanceToDestination" | translate }}
                </p>
                <p class="m-0">
                  <i
                    *ngFor="let star of arrayOne(hotel.stars)"
                    class="fa fa-star yellow darken-2"
                  ></i>
                </p>
              </div>

              <h5 *ngIf="hotel.totalBooked" style="color: #464646; font-weight: bold">
                {{ hotel.totalBooked }} €
              </h5>
            </div>
          </div>
        </div>
      </div>
      </map-info-window>
    </map-marker>
    </div>

  </map-view>

<!--////////////////////////////////////////////////////// -->

  <map-view
  *ngIf="listHotels && listHotels.length > 0 && shouldLoadMark === false"
  (mapClick)="closeWindow()"
  (zoomChange)="onZoomChange($event)"
  style="height: 100%; width: 100%"
  [maxZoom]="30"
  [minZoom]="8"
  [zoom]="15"
  mapTypeId="roadmap"
  [latitude]="latitude"
  [longitude]="longitude"
  [mapTypeControl]="false"
>
<div>
  <map-marker
  [latitude]="latitude"
  [longitude]="longitude"
  >
  </map-marker>
</div>
  <div *ngFor="let hotel of listHotels; let i = index; trackBy: utilsService.getUuidTracking">
    <map-marker
      [label]="showLabel(hotel) ? getPrice(hotel) + '€' : ''"
      [iconUrl]="setIcon(hotel)"
      (markerClick)="selectMarker(infoWindow)"
      style="width: 10px; height: 10px"
      [latitude]="hotel.coordinates.latitude"
      [longitude]="hotel.coordinates.longitude"
    >
      <map-info-window [disableAutoPan]="true" #infoWindow>
        <div class="info-window-content">
        <div class="container-fluid">
          <div class="row" style="padding-left: 10px !important;">
            <div class="col col-6 noPadding carousel-container">
              <div id="hotelImagesMap{{ i }}" class="carousel slide w-100" style="height: 10rem !important; width: 15rem !important;" data-ride="carousel" wrap="true">
                <ol class="carousel-indicators">
                    <li *ngFor="let image of hotel.images; let j = index" 
                        [attr.data-target]="'#hotelImagesMap' + i" 
                        [attr.data-slide-to]="j" 
                        [class.active]="j === 0">
                    </li>
                </ol>
                <div class="carousel-inner">
                    <div *ngFor="let image of hotel.images; let j = index" 
                         class="carousel-item" 
                         [class.active]="j === 0">
                        <img [src]="image" class="d-block w-100 h-100" alt="{{ hotel.name }}">
                    </div>
                </div>
                <a class="carousel-control-prev" role="button" (click)="moveCarousel('prev', i)">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" role="button" (click)="moveCarousel('next', i)">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a>
            </div>
            </div>
            <div
              class="col col-6 my-auto data-container"
              (click)="getHotel(hotel)"
            >
              <div>
                <h5
                  class="zoom-transition titlePopup cursor-pointer"
                  title="{{ 'search.card.see-rooms' | translate }}"
                  style="font-size: medium !important; font-weight: 600 !important;"
                >
                  {{ hotel.name }}
                </h5>
                <p class="m-0">
                  {{ hotel.address }}
                </p>
                <p class="m-0">
                  {{ hotel.distanceToDestination | number: "1.2-2" }}
                  {{ "search.card.distanceToDestination" | translate }}
                </p>
                <p class="m-0">
                  <i
                    *ngFor="let star of arrayOne(hotel.stars)"
                    class="fa fa-star yellow darken-2"
                  ></i>
                </p>
              </div>

              <h5 *ngIf="hotel.totalBooked" style="color: #464646; font-weight: bold">
                {{ hotel.totalBooked }} €
              </h5>

            </div>
          </div>
        </div>
      </div>
      </map-info-window>
    </map-marker>
  </div>
</map-view>