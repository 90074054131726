<div *ngIf="loading" style="display: block; margin: auto; z-index: 9999">
  <app-loading></app-loading>
</div>
<div class="summary-booked">
  <div class="icon-bcg">
    <img
      src="../../../../../assets/img/svg/calendar-black.svg"
      alt="confirmation-calendar"
    />
  </div>
  <div class="heading-5">
    <span>
      {{
        ((canRequest || isAdmin))
          ? ("search.search-booked.confirm-booked" | translate)
          : ("search.search-booked.confirm-request" | translate)
      }}
    </span>
  </div>
  <div class="information">
    <span
      ><b>{{ "common.hotel" | translate }}:</b> {{ hotel.name }}</span
    >
    <span>
      <b>{{ "search.search-booked.date-entry" | translate }}:</b>
      {{ formatDate(searchConfig.checkIn) }}
    </span>
    <span>
      <b>{{ "search.search-booked.date-departure" | translate }}:</b>
      {{ formatDate(searchConfig.checkOut) }}
    </span>
    <span>
      <b
        >{{ "csv.total" | translate }}
        {{ "agency.search.filters.adults" | translate }}:
      </b>
      x{{ totalAdults }}
    </span>
    <mat-accordion *ngIf="hotel.additionalDetails && (hotel.additionalDetails.checkIn || hotel.additionalDetails.checkOut || hotel.additionalDetails.fees || hotel.additionalDetails.policies)" class="additional-details">
      <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "hotel.additional-details" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content">
          <p *ngIf="hotel.additionalDetails.checkIn && hotel.additionalDetails.checkIn[0]">Checkin: {{ hotel.additionalDetails.checkIn[0].startTime }} - {{ hotel.additionalDetails.checkIn[0].endTime }}</p>
          <p *ngIf="hotel.additionalDetails.checkOut">Checkout: {{ hotel.additionalDetails.checkOut }}</p>
          <ng-container *ngIf="hotel.additionalDetails.checkIn && hotel.additionalDetails.checkIn[0] && hotel.additionalDetails.checkIn[0].instructions">
            <p>{{"common.instructions" | translate }}</p>
            <div class="instructions" [innerHTML]="hotel.additionalDetails.checkIn[0] && hotel.additionalDetails.checkIn[0].instructions">
            </div>
          </ng-container>
          <ng-container *ngIf="hotel.additionalDetails.checkIn && hotel.additionalDetails.checkIn[0] && hotel.additionalDetails.checkIn[0].specialInstructions">
            <p>{{"common.special-instructions" | translate }}</p>
            <div class="instructions" [innerHTML]="hotel.additionalDetails.checkIn[0] && hotel.additionalDetails.checkIn[0].specialInstructions">
            </div>
          </ng-container>
          <ng-container *ngIf="hotel.additionalDetails.fees && hotel.additionalDetails.fees.length > 0">
            <p>{{ "flights.form.fees" | translate }}</p>
            <div class="instructions">
              <div *ngFor="let fee of hotel.additionalDetails.fees" [innerHTML]="fee.content"></div>
            </div>
          </ng-container>
          <ng-container *ngIf="hotel.additionalDetails.policies && hotel.additionalDetails.policies.length > 0">
            <p>{{ "flight.multifamily.policies" | translate }}</p>
            <div class="instructions">
              <div *ngFor="let policie of hotel.additionalDetails.policies" [innerHTML]="policie.content"></div>
            </div>
          </ng-container>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <form [formGroup]="form" class="form">
      <h6 style="font-weight: bold !important">
        {{ "common.optional-field" | translate }}
      </h6>
      <div class="form-input">
        <input
          id="custom-code-input"
          class="form-control"
          formControlName="customCode"
          placeholder="{{ 'common.custom-code' | translate }}"
        />

        <input
          id="cost-center-input"
          class="form-control"
          formControlName="costCenter"
          placeholder="{{ 'common.cost-center' | translate }}"
        />

        <textarea
          class="form-control text-area"
          name=""
          id="{{
            ((canRequest || isAdmin))
              ? 'admin-hotel-comment'
              : 'request-comment'
          }}"
          formControlName="{{
            ((canRequest || isAdmin))
              ? 'adminHotelComment'
              : 'requestComment'
          }}"
          placeholder="{{
            (((canRequest || isAdmin))
              ? 'search.search-booked.comments'
              : 'search.search-booked.admin-comments'
            ) | translate
          }}"
          cols="30"
          rows="3"
        ></textarea>
      </div>
    </form>
    <div class="summary-order-container">
      <h1>
        {{ "search.search-booked.price-details" | translate }}
      </h1>
      <div *ngIf="selectedRooms">
        <div *ngFor="let room of selectedRooms; let j = index" class="room">
          <div>
            <p class="room-type m-0">
              {{ room.name | uppercase }}
            </p>
            <div matTooltip="{{ 'search.search-booked.select-bed' | translate }}">
              {{room.rates[0].bed}}
              <i class="fa fa-question-circle color-grey"></i>
            </div>
            <span
              class="price-policy"
              [ngClass]="
                isSameOrAfterToday(room.rates[0].cancellationPolicies[0].from)
                  ? 'color-info'
                  : 'color-grey'
              "
              >{{
                (isSameOrAfterToday(room.rates[0].cancellationPolicies[0].from)
                  ? "search.search-booked.free-cancel"
                  : "search.search-booked.no-cancel"
                )
                  | translate
                  | uppercase
              }}
              <p *ngFor="
                let policie of room.rates[0]
                  .cancellationPolicies;
                let i = index
              " class="badge" style="color: black !important;">
                {{
                policie.text
                | translator
                }}
              </p>
            </span>
          </div>
          <div class="row" style="justify-content: end;">
            <div class="price-breakdown" *ngIf="room.rates[0].price && code.charAt(0) !== 'E'">
              <p class="col-auto">{{ "hotel.base-rate" | translate }} {{ room.rates[0].price.totalPrice.total | price }}€</p>
              <p class="col-auto" *ngIf="room.rates[0].price.fees && room.rates[0].price.fees.amount > 0" style="color: #f46f69" matTooltip="{{ 'hotel.due-at-property-details' |translate }}">{{ "hotel.due-at-property" | translate }} <i class="fa fa-question-circle"></i> <b> {{ room.rates[0].price.fees.amount | price }}€</b></p>
            </div>
          </div>
          <div class="row" style="justify-content: end;">
            <div class="price-breakdown" *ngIf="room.rates[0].price && code.charAt(0) === 'E'">
              <p class="col-auto" *ngIf="room.rates[0].price.baseRate && room.rates[0].price.baseRate.amount > 0">{{ "hotel.base-rate" | translate }} <b>{{ room.rates[0].price.baseRate.amount | price }}€</b></p>
              <p class="col-auto" *ngIf="room.rates[0].price.taxAndServiceFee && room.rates[0].price.taxAndServiceFee.amount > 0" matTooltip="{{ 'hotel.taxes-and-fees-details' |translate }}">{{ "hotel.taxes-and-fees" | translate }} <i class="fa fa-question-circle"></i> <b> {{ room.rates[0].price.taxAndServiceFee.amount | price }}€</b></p>
              <p class="col-auto" *ngIf="room.rates[0].price.salesTax && room.rates[0].price.salesTax.amount > 0">{{ "hotel.sales-tax" | translate }} <b>{{ room.rates[0].price.salesTax.amount | price }}€</b></p>
              <p class="col-auto" *ngIf="room.rates[0].price.fees && room.rates[0].price.fees.amount > 0" style="color: #f46f69" matTooltip="{{ 'hotel.due-at-property-details' |translate }}">{{ "hotel.due-at-property" | translate }} <i class="fa fa-question-circle"></i> <b> {{ room.rates[0].price.fees.amount | price }}€</b></p>
            </div>
          </div>
          <hr class="my-1" />
          <div class="row" *ngIf="room.rates[0].price && room.rates[0].price.totalPrice.total">
            <p class="col total-price total-price--title">
              {{ "search.search-booked.total-price-by-room" | translate }}
            </p>
            <p class="col total-price">{{ room.rates[0].price.totalPrice.total | price }}€</p>
          </div>
        </div>
      </div>
      <hr class="my-1" />
      <div class="row">
        <p class="col total-price total-price--title">
          {{ "search.search-booked.total-price" | translate }}
        </p>
        <p class="col total-price">{{ getTotalPrice() | price }}€</p>
      </div>
      <p class="payment-info mb-0">
        {{ "search.search-booked.payment-info" | translate }}
      </p>
    </div>
  </div>
  <div class="policies-container">
    <div class="policies-content" *ngIf="code.startsWith('E')">
      <p class="mb-0" style="font-size: 10px; font-weight: 300">
        {{ "search.search-booked.policy-expedia.clic" | translate }}
        <a [href]="getTermsOfUseLink(false)" target="_blank">
          {{ "common.policies.terms-of-use" | translate }} </a
        >{{ "search.search-booked.policy-expedia.of" | translate }}
      </p>
      <p *ngIf="isEmployee" class="mb-0" style="font-size: 10px; font-weight: 300">
        {{ "search.search-booked.policy-expedia.clic" | translate }}
        <a [href]="getTermsOfUseLink(isEmployee)" target="_blank">
          {{ "common.policies.terms-of-use-agent" | translate }} </a
        >{{ "search.search-booked.policy-expedia.of" | translate }}
      </p>
    </div>
    <div class="policies-content">
      <p style="font-size: 10px; font-weight: 300; margin-top: 8px">
        {{ "common.policies.clic" | translate
        }}<a href="https://www.vyootrip.com/aviso-legal/" target="_blank">{{
          "common.policies.terms-of-use" | translate
        }}</a>
        {{ "common.policies.confirm" | translate }}
        <a
          href="https://www.vyootrip.com/politica-de-cookies/"
          target="_blank"
          >{{ "common.policies.cookie-policy" | translate }}</a
        >
        {{ "common.policies.and" | translate }}
        <a
          href="https://www.vyootrip.com/politica-de-privacidad/"
          target="_blank"
          >{{ "common.policies.privacy-policy" | translate }}</a
        >
      </p>
    </div>
  </div>
  <div class="button-container">
    <button (click)="closeModal()" class="custom-btn custom-btn--close">{{ "common.cancel" | translate }}</button>
    <button
      [ngClass]="{ 'custom-btn confirm-disabled': loading || disabled, 'custom-btn': !(loading || disabled) }"
      [disabled]="loading || disabled"
      (click)="onSubmit()"
    >
      {{ "common.confirm" | translate }}
    </button>
  </div>
</div>
