<div id="global-container">
  <div class="row">
    <div class="col-12">
      <p class="heading-5">
        {{ "my-bookeds.view.request-details" | translate }}
      </p>
      <div class="data-row">
        <div class="icon-bcg icon-bcg--small">
          <img src="assets/img/svg/sidebar/profile.svg" alt="employee-icon" />
        </div>
        <span class="bold"
          >{{ "authorization.labels.employee" | translate }}:
        </span>
        <span>{{ request.user ? request.user.name + " " + request.user.lastname : '' }}</span>
      </div>
      <div class="data-row" *ngIf="request.comments && request.comments !== ''">
        <div class="icon-bcg icon-bcg--small">
          <img src="assets/img/svg/pen-black.svg" alt="pencil-icon" />
        </div>
        <span class="bold">{{ "authorization.comments" | translate }}: </span>
        <span>{{ request.comments }}</span>
      </div>
    </div>
  </div>

  <div class="row" id="second-row">
    <div class="col-12">
      <div class="d-flex" *ngIf="request.type?.toLowerCase() !== 'airbnb'; else airbnbLayout">
        <div class="data-row w-50">
          <div class="icon-bcg icon-bcg--small">
            <img [src]="icon" alt="icon" />
          </div>
          <span class="bold">{{ request.hotelName || request.from }}</span>
        </div>
        <div class="data-row w-50">
          <div class="icon-bcg icon-bcg--small">
            <img [src]="icon" alt="icon" />
          </div>
          <span class="bold">{{ request.city || request.to }}</span>
        </div>
      </div>

      <ng-template #airbnbLayout>
        <div class="data-row" >
          <div class="icon-bcg icon-bcg--small">
            <img [src]="icon" alt="icon" />
          </div>
            <a 
            *ngIf="isValidUrl(request.hotelName)" 
            [href]="request.hotelName" 
            target="_blank" 
            rel="noopener noreferrer"
            class="bold airbnb-text"
          >
            {{ request.hotelName || request.from }}
          </a>
          <span 
            *ngIf="!isValidUrl(request.hotelName)" 
            class="bold airbnb-text"
          >
            {{ request.hotelName || request.from }}
          </span>
        </div>
        <div class="data-row">
          <div class="icon-bcg icon-bcg--small">
            <img src="assets/img/svg/location-black.svg" alt="icon" />
          </div>
          <span class="bold">{{ request.city || request.to }}</span>
        </div>
      </ng-template>
      
      <ng-container *ngIf="request.type?.toLowerCase() === 'hotel' || request.type?.toLowerCase() === 'airbnb'; else elseBlock">
        <div class="d-flex">
          <div *ngIf="request.type?.toLowerCase() === 'hotel'" class="data-row w-50" >
            <div class="icon-bcg icon-bcg--small">
              <img [src]="icon" alt="icon" />
            </div>
            <div class="data-text">
              <span class="bold">{{ "hotel.custom.rooms" | translate }}:</span>
              <span>{{ request.rooms }}</span>
            </div>
          </div>
          <div class="data-row w-50" *ngIf="request.people">
            <div class="icon-bcg icon-bcg--small">
              <img src="assets/img/svg/sidebar/hotel.svg" alt="icon" />
            </div>
            <div class="data-text">
              <span class="bold">{{ "hotel.custom.people" | translate }}:</span>
              <span>{{ request.people }}</span>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="data-row w-50">
            <div class="icon-bcg icon-bcg--small">
              <img
                src="assets/img/svg/calendar-black.svg"
                alt="calendar-icon-green"
              />
            </div>
            <div class="data-text">
              <span class="bold">{{ "hotel.custom.check-in" | translate }}:</span>
              <span>{{ request.initDate | date: "dd/MM/yyyy" }}</span>
            </div>
          </div>
          <div class="data-row w-50">
            <div class="icon-bcg icon-bcg--small">
              <img
                src="assets/img/svg/calendar-black.svg"
                alt="calendar-icon-green"
              />
            </div>
            <div class="data-text">
              <span class="bold">{{ "hotel.custom.check-out" | translate }}:</span>
              <span>{{ request.endDate ? (request.endDate | date: "dd/MM/yyyy") : "" }}</span>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #elseBlock>
        <div class="data-row">
          <div class="icon-bcg icon-bcg--small">
            <img src="assets/img/svg/calendar-black.svg" alt="icon" />
          </div>
          <div class="data-text">
            <span class="bold"
              >{{ "csv.flight.departDate" | translate }}:</span>
            <span>{{ request.initDate && isValidDate(request.initDate) ? (request.initDate | date: "dd/MM/yyyy") : "" }}</span>
          </div>
        </div>
        <div class="data-row">
          <div class="icon-bcg icon-bcg--small">
            <img src="assets/img/svg/clock-black.svg" alt="icon" />
          </div>
          <div class="data-text">
            <span class="bold">{{ "flight.form-flight.departureTime" | translate }}:</span>
            <span>{{ request.initDate && isValidDate(request.initDate) ? (request.initDate | date: "HH:mm") : "" }}</span>
          </div>  
        </div>
        <div class="data-row" *ngIf="request.endDate">
          <div class="icon-bcg icon-bcg--small">
            <img src="assets/img/svg/calendar-black.svg" alt="icon" />
          </div>
          <div class="data-text">
            <span class="bold">{{ "csv.flight.returnDate" | translate }}:</span>
            <span>{{ request.endDate && isValidDate(request.endDate) ? (request.endDate | date: "dd/MM/yyyy") : "" }}</span>
          </div>
        </div>
        <div class="data-row" *ngIf="request.endDate">
          <div class="icon-bcg icon-bcg--small">
            <img src="assets/img/svg/clock-black.svg" alt="icon" />
          </div>
          <div class="data-text">
          <span class="bold">{{ "flight.form-flight.departureTime" | translate }}:</span>
          <span>{{ request.endDate && isValidDate(request.endDate) ? (request.endDate | date: "HH:mm") : "" }}</span>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="data-row">
        <span class="bold"
          >{{ "company.company-booked.booking-type" | translate }}:</span
        >
        <span>
          {{
            (request.type?.toLowerCase() === "hotel"
              ? "common.hotel"
              : request.type?.toLowerCase() === "flight"
              ? "common.flight"
              : request.type?.toLowerCase() === "train"
              ? "common.train"
              : request.type?.toLowerCase() === "car"
              ? "common.car"
              : request.type?.toLowerCase() === "airbnb"
              ? "Airbnb"
              : ""
            ) | translate
          }}</span
        >
      </div>
      <div class="data-row">
        <span class="bold"
          >{{ "company.company-booked.status" | translate }}:</span
        >
        <span *ngIf="request.status === 'completed'" class="green-font">
          {{ "common.completed" | translate }}
        </span>
        <span *ngIf="request.status === 'active'" class="yellow-font">
          {{ "common.pending" | translate }}
        </span>
      </div>
      <div class="data-row">
        <span class="bold">{{ "common.creation-date" | translate }} : </span>
        <span>{{
          request.createdAt | dateMoment: "YYYY-MM-DD":"DD/MM/YYYY"
        }}</span>
      </div>
    </div>
    <div
    class="w-100 d-flex justify-content-center mt-3"
      *ngIf="hasPrivileges && request.status === 'active'"
    >
      <button class="custom-btn" (click)="addBook()">
        {{ "common.create-booking" | translate }}
      </button>
    </div>
  </div>
</div>
