<app-user-detail
  [userUuid]="userToBook"
  *ngIf="userType !== 'USER'"
  (navigateEvent)="goBack()"
></app-user-detail>
<div class="container">
  <span class="component-info heading-5">{{ "custom-service.title" | translate }}</span>

  <div class="bg-card">
    <span class="title heading-5">{{ "custom-service.request" | translate }}</span>

    <div class="service-card-container">
      <div class="row mb-3">
        <div
          class="col-lg-4 col-sm-12"
          (click)="navigate('hotel')"
          *ngIf="canBookHotel || userType !== 'USER'"
        >
          <div class="service-card">
            <div class="icon-bcg">
              <img
                src="../../../../assets/img/svg/sidebar/hotel.svg"
                alt="Custom hotel option icon"
              />
            </div>
            <div class="title-card">
              <span>{{"common.request" | translate}}</span>
              <span style="color: rgb(248, 95, 31)">{{ "hotel.custom.request.option" | translate }}</span>
            </div>
          </div>
        </div>

        <div
          class="col-lg-4 col-sm-12"
          (click)="navigate('flight')"
          *ngIf="canRequestCustomFlight || userType !== 'USER'"
        >
          <div class="service-card">
            <div class="icon-bcg">
              <img
                src="../../../../assets/img/svg/sidebar/flight.svg"
                alt="Custom flight option icon"
              />
            </div>
            <div class="title-card">
              <span>{{"common.request" | translate}}</span>
              <span style="color: rgb(248, 95, 31)">{{ "flight.custom.request.option" | translate }}</span>
            </div>
          </div>
        </div>

        <div
          class="col-lg-4 col-sm-12"
          (click)="navigate('train')"
          *ngIf="canRequestCustomTrain || userType !== 'USER'"
        >
          <div class="service-card">
            <div class="icon-bcg">
              <img
                src="../../../../assets/img/svg/sidebar/train.svg"
                alt="Custom train option icon"
              />
            </div>
            <div class="title-card">
              <span>{{"common.request" | translate}}</span>
              <span style="color: rgb(248, 95, 31)">{{ "train.form-train.request-train" | translate }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="col-lg-4 col-sm-12"
          (click)="navigate('car')"
          *ngIf="canRequestCustomCar || userType !== 'USER'"
        >
          <div class="service-card">
            <div class="icon-bcg">
              <img
                src="../../../../assets/img/svg/car-black.svg"
                alt="Custom car option icon"
              />
            </div>
            <div class="title-card">
              <span>{{"common.request" | translate}}</span>
              <span style="color: rgb(248, 95, 31)">{{ "car.custom.request.option" | translate }}</span>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 col-sm-12"
          (click)="navigate('airbnb')"
          *ngIf="canRequestAirbnb || userType !== 'USER'"
        >
          <div class="service-card">
            <div class="icon-bcg">
              <img
                src="../../../../assets/img/svg/airbnb.svg"
                alt="Custom airbnb option icon"
              />
            </div> 
            <div class="title-card">
              <span>{{"common.request" | translate}}</span>
              <span style="color: rgb(248, 95, 31)">{{ "airbnb.custom.request.option" | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
