<div class="modal-header">
  <h4 class="heading-5">
    {{
      (type + ".create-" + type + ".create-" + type
      ) | translate
    }}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <!-- FORMULARIO -->
      <form
        [formGroup]="form"
        [ngClass]="{ 'loading-background': loading }"
        (ngSubmit)="form.valid && !loading ? onSubmit() : (isInvalid = true)"
      >
        <div class="row" *ngIf="isModal && !userUuid">
          <div class="col-12">
            <div class="form-group">
              <mat-label>{{
                type + ".create-" + type + ".choose-employee" | translate
              }}</mat-label>
              <select
                class="form-control rounded-1 w-25"
                formControlName="userUuid"
              >
                <option
                  *ngFor="
                    let user of users;
                    trackBy: utilsService.getUuidTracking
                  "
                  [ngValue]="user"
                >
                  {{ user.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="userType === 'EMPLOYEE'">
          <div class="col-6">
            <div class="form-group">
              <label>{{ "flight" + ".create-" + "flight" + ".booked-file" | translate }}*</label>
              <div>
                <input
                  formControlName="file"
                  type="file"
                  id="file"
                  class="inputFile"
                  (change)="onFileChange($event)"
                  accept="application/pdf"
                  multiple
                />
                <div class="file-container">
                
                <label for="file" class="labelFile" [class.disabled]="userType !== 'EMPLOYEE'">
                  {{ "common.choose-file" | translate }}
                </label>
                <div class="file-tags" *ngIf="(files?.length > 0 || existingFiles?.length > 0); else showFileBack">
                  <span *ngFor="let f of existingFiles; let i = index" class="file-tag">
                    {{ f.name }}
                    <button type="button" class="remove-btn" (click)="removeFile(i)">x</button>
                  </span>
                  <span *ngFor="let f of files; let i = index" class="file-tag">
                    {{ f.name }}
                    <button type="button" class="remove-btn" (click)="removeFile(i)">x</button>
                  </span>
                </div>
                <ng-template #showFileBack>
                  <label class="pl-3" *ngIf="fileBack">
                    {{ fileBack.name }}
                  </label>
                </ng-template>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="userType === 'EMPLOYEE'">
          <div class="col-6">
            <div class="form-group">
              <input
                formControlName="city"
                type="text"
                id="city"
                name="city"
                class="form-control"
                matInput
                required
                [matAutocomplete]="fromM"
                (input)="getCities($event.target.value)"
                placeholder="{{ 'airbnb.create-airbnb.ubication' | translate }}"
                [ngClass]="{
                  'input-error': !form.controls.city.valid && isInvalid
                }"
                />
              <mat-autocomplete
                #fromM="matAutocomplete"
                panelWidth="20%"
                (optionSelected)="cleanCitiesList($event.option.value)"
              >
                <mat-option *ngFor="let option of citiesList" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
            <input
              formControlName="price"
              type="text"
              id="price"
              class="form-control"
              name="price"
              placeholder="{{ 'airbnb.create-airbnb.price' | translate }}"
              [ngClass]="{
                'input-error': !form.controls.price.valid && isInvalid
              }"
            />
            </div>
          </div>
        </div>
        <div class="row" *ngIf="userType === 'EMPLOYEE'">
          <div class="col-12">
            <div class="form-group">
            <input
              formControlName="link"
              type="text"
              id="link"
              class="form-control"
              name="link"
              placeholder="{{ 'airbnb.custom.request.link' | translate }}"
            />
            </div>
          </div>
        </div>
        <div class="row" *ngIf="userType === 'EMPLOYEE'">
            <div class="col-4">
              <div class="form-group">
                <input
                  class="form-control icon-rtl"
                  readonly
                  placeholder="{{ 'hotel.custom.select-date' | translate }}"
                  name="checkIn"
                  id="checkIn"
                  formControlName="checkIn"
                  ngbDatepicker
                  #dDeparture="ngbDatepicker"
                  (click)="dDeparture.toggle()"
                  [minDate]="minDateNgStruct"
                  [maxDate]="maxDateNgStruct"
                  [ngClass]="{
                    'input-error': !form.controls.checkOut.valid && isInvalid
                  }"
                />
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <input
                  readonly
                  [disabled]="f.checkOut.hasError('required')"
                  [ngClass]="{ dates: !f.checkOut.hasError('required') }"
                  class="form-control icon-rtl"
                  placeholder="{{ 'hotel.custom.select-date' | translate }}"
                  name="checkOut"
                  id="checkOut"
                  formControlName="checkOut"
                  ngbDatepicker
                  #dReturn="ngbDatepicker"
                  (click)="dReturn.toggle()"
                  [minDate]="getOutDate()"
                  [maxDate]="maxDateNgStruct"
                  [ngClass]="{
                    'input-error': !form.controls.checkIn.valid && isInvalid && !form.controls.checkIn.disabled
                  }"
                />
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
              <input
                formControlName="travelers"
                type="number"
                id="travelers"
                class="form-control icon-person"
                name="travelers"
                [ngClass]="{
                  'input-error': !form.controls.travelers.valid && isInvalid
                }"
              />
              </div>
            </div>
        </div>
        <div class="row" *ngIf="userType === 'EMPLOYEE'">
          <div class="col-6">
            <div class="form-group">
              <input
                formControlName="customCode"
                type="text"
                id="customCode"
                class="form-control rounded-1"
                name="customCode"
                placeholder="{{ 'common.custom-code' | translate }}"
                [ngClass]="{
                  'input-error': !form.controls.customCode.valid && isInvalid
                }"
              />
              </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <input
                formControlName="costCenter"
                type="text"
                id="costCenter"
                class="form-control rounded-1"
                name="costCenter"
                placeholder="{{ 'common.cost-center' | translate }}"
                [ngClass]="{
                  'input-error': !form.controls.costCenter.valid && isInvalid
                }"
              />
              </div>
          </div>
        </div>
        <div class="row" *ngIf="userType === 'EMPLOYEE'">
          <div class="col-12">
            <div class="form-group">
              <textarea
              matInput
              class="textarea form-control"
              formControlName="comments"
              placeholder="{{
                'hotel.custom.annotations-placeholder' | translate
              }}"
              [ngClass]="{
                'input-error': !form.controls.comments.valid && isInvalid
              }"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="row d-flex justify-content-end">
          <button
            class="custom-btn mb-0"
            ngbAutofocus
            mat-button
            [ngStyle]="{
              cursor: !form.valid || loading ? 'not-allowed' : 'pointer'
            }"
            [ngClass]="{
              'disabled-button': !form.valid || loading
            }"
          >
            {{ "common.create-booking" | translate }}
          </button>
        </div>
      </form>
      <div class="row" *ngIf="loading">
        <app-loading [userType]="'employee'"></app-loading>
      </div>
</div>

