import { Component, Input, OnInit } from "@angular/core";
import { Company, User } from "@core/models";
import { CompanyService, UserService } from "@core/services";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Observable, forkJoin } from "rxjs";

@Component({
  selector: "app-booked-delivery-note",
  templateUrl: "./booked-delivery-note.component.html",
  styleUrls: ["./booked-delivery-note.component.scss"],
})
export class BookedDeliveryNoteComponent implements OnInit {
  @Input() booked: any;
  @Input() fileName: string;
  @Input() type: string;
  blobUrl: any;
  company: Company;
  users: User[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    public companyService: CompanyService,
    public userService: UserService
  ) {}

  ngOnInit() {
    this.getBookedType();
    this.companyService
      .getCompany(this.booked.companyUuid)
      .subscribe((company: Company) => {
        this.company = company;
      });

    const userUuids = Array.isArray(this.booked.userUuid) ? this.booked.userUuid : [this.booked.userUuid];

    const validUserUuids = userUuids.filter(uuid => uuid);

    if (validUserUuids.length > 0) {
      const userRequests: Observable<User>[] = validUserUuids.map(uuid => this.userService.getUserByUuid(uuid));

      forkJoin(userRequests).subscribe((users: User[]) => {
        this.users = users;
      });
    }
  }

  downloadPDFBooked() {
    let DATA: any = document.getElementById('delivery-note');
  
    let hiddenContainer = document.createElement('div');
    hiddenContainer.style.width = '570px';
    hiddenContainer.style.height = 'auto';
    hiddenContainer.style.position = 'absolute';
    hiddenContainer.style.top = '-9999px';
    hiddenContainer.appendChild(DATA.cloneNode(true));
  
    document.body.appendChild(hiddenContainer);
  
    const h = hiddenContainer.scrollHeight;
    const w = hiddenContainer.offsetWidth;
  
    html2canvas(hiddenContainer, { scale: 2 }).then((canvas) => {
      const FILEURI = canvas.toDataURL('image/png');
      let PDF = new jsPDF('p', 'px', 'a4');
      let fileWidth = PDF.internal.pageSize.getWidth();
      let fileHeight = PDF.internal.pageSize.getHeight();
      let aspectRatio = w / h;
  
      let imgWidth, imgHeight;

      imgHeight = h > 500 ? fileHeight * 0.6 : h > 420 && h < 480 ? fileHeight * 0.45 : fileHeight * 0.5;
      imgWidth = imgHeight * aspectRatio;
  
      let x = (fileWidth - imgWidth) / 2;
  
      PDF.addImage(FILEURI, 'PNG', x, 20, imgWidth, imgHeight);
      PDF.save(this.booked.deliveryNote + '-Vyootrip.pdf');
  
      document.body.removeChild(hiddenContainer);
    });
  }
  closeModal() {
    this.activeModal.dismiss("Cross click");
  }

  getBookedType() {
    switch (this.type?.toLowerCase()) {
      case "bookingApiReference":
      case "custom-booking":
      case "hotel":
        this.type = 'common.hotel'
        break;
      case "flight":
        this.type = 'common.flight'
        break;
      case "train":
        this.type = 'common.train'
        break;
      case "car":
        this.type = 'common.car'
        break;
      case "expense":
      default:
        this.type = 'common.other'
        break;
    }
  }
}
