import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Hotel } from "@models";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

@Component({
  selector: "app-search-card",
  templateUrl: "./search-card.component.html",
  styleUrls: ["./search-card.component.scss"],
})
export class SearchCardComponent implements OnInit, OnDestroy {
  @Input() hotel: Hotel;
  @Input() canRequest: boolean;
  @Input() hotelsMapCopy: Hotel[];
  @Input() hotelsListCopy: Hotel[];
  @Input() firstSearch: boolean;
  @Input() mapHasBeenDragged: boolean;
  @Input() index: number;
  countStars: any[] = [];
  subscription: Subscription;
  translateSubscription: Subscription;
  isRestel = false;
  lang = "";
  @Input() searchConfig: any;
  @Input() filters: any;
  @Input() facilities: any;
  @Input() options: any;
  @Input() selectedUsers: any;
  @Input() selectedTravelers: any;
  @Input() projectData: any;
  @Input() originalSearchConfig: any;
  currency: "€" | "$";
  expandableFilters = false;
  mapView = false;

  constructor(
    private router: Router,
    private translate: TranslateService
  ) {
    this.currency = "€";
  }

  ngOnInit() {
    if(this.originalSearchConfig){
      this.searchConfig = {...this.originalSearchConfig}
    }
    this.lang = this.translate.currentLang;
    try {
      this.countStars = new Array(Math.floor(Number(this.hotel.stars)));
    } catch (e) {
      this.countStars = [];
    }
    if (this.hotel.code.toString().startsWith("R")) {
      this.isRestel = true;
    }
    this.hotel.images.length === 0
      ? this.hotel.images.push("assets/img/gallery/image_not_available.png")
      : null;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.translateSubscription) {
      this.translateSubscription.unsubscribe();
    }
  }

  changeSource(event, i) {
    this.hotel.images.splice(i, 1);
    this.hotel.images.length === 0
      ? (event.target.src = "assets/img/gallery/image_not_available.png")
      : null;
  }

  toggleFilter() {
    this.expandableFilters = !this.expandableFilters;
  }

  getHotel() {
    this.router.navigate([`${this.router.url}/hotels/`, this.hotel.code], {
      queryParams: {
        location: this.searchConfig.locationText,
        lat: this.searchConfig.latitude,
        lon: this.searchConfig.longitude,
        hotelUuid: this.hotel.uuid,
        checkin: `${this.searchConfig.checkIn.day}/${this.searchConfig.checkIn.month}/${this.searchConfig.checkIn.year}`,
        checkout: `${this.searchConfig.checkOut.day}/${this.searchConfig.checkOut.month}/${this.searchConfig.checkOut.year}`,
        canRequest: this.canRequest,
      },
      state: {
        searchConfig: this.searchConfig,
        hotelsMapCopy: this.hotelsMapCopy,
        hotelsListCopy: this.hotelsListCopy,
        filters: this.filters,
        facilities: this.facilities,
        selectedUsers: this.selectedUsers,
        selectedTravelers: this.selectedTravelers,
        projectData: this.projectData
      },
    });
  }
}
