import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Workbook } from "exceljs";
import * as FileSaver from "file-saver";
import * as fs from "file-saver";
import moment from "moment";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import * as XLSX from "xlsx";
import { NgxToastrService } from "./ngx.toastr.service";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Injectable({
  providedIn: "root",
})
export class ExcelService {
  types = [
    {
      name: "excel.hotel",
      value: "hotel",
    },
    {
      name: "excel.flight",
      value: "flight",
    },
    {
      name: "excel.train",
      value: "train",
    },
    {
      name: "excel.car",
      value: "car",
    },
    {
      name: "excel.transfer",
      value: "transfer",
    },
    {
      name: "excel.taxi",
      value: "taxi",
    },
    {
      name: "excel.other",
      value: "other",
    },
    {
      name: "excel.expense",
      value: "expense",
    },
    {
      name: "excel.user",
      value: "user",
    },
  ];
  subscriptions: Subscription[] = [];
  type: string;
  constructor(private translateService: TranslateService,
              private ngxToastrService: NgxToastrService) { }

  public exportAsExcelFile(
    json: any[],
    excelFileName: string,
    isTransactions?: boolean
  ): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    if (isTransactions) {
      worksheet["!cols"] = [
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 40 },
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 20 },
        { width: 35 },
        { width: 10 },
        { width: 30 },
        { width: 15 },
        { width: 28 },
        { width: 25 },
        { width: 8 },
      ];
    } else {
      worksheet["!cols"] = [
        { width: 6 },
        { width: 15 },
        { width: 15 },
        { width: 12 },
        { width: 8 },
        { width: 13 },
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 20 },
        { width: 35 },
        { width: 10 },
        { width: 30 },
        { width: 15 },
        { width: 28 },
        { width: 25 },
        { width: 8 },
      ];
    }

    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
      cellStyles: true,
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  async exportStyledExcel(excelData) {
    const title = excelData.title;
    const header = excelData.headers;
    const data = excelData.data;

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Data");

    // Title
    worksheet.mergeCells("A1", "D4");
    const titleRow = worksheet.getCell("A1");
    titleRow.value = title;
    titleRow.font = {
      name: "Calibri",
      size: 16,
      bold: true,
      color: { argb: "69C9C1" },
    };
    titleRow.alignment = { vertical: "middle", horizontal: "center" };

    // Date
    worksheet.mergeCells("F1:G4");
    const d = new Date();
    const month = d.getMonth() + 1;
    const date = d.getDate() + "-" + month + "-" + d.getFullYear();
    const dateCell = worksheet.getCell("F1");
    dateCell.value = date;
    dateCell.font = {
      name: "Calibri",
      size: 12,
      bold: true,
      color: { argb: "205954" },
    };
    dateCell.alignment = { vertical: "middle", horizontal: "center" };

    // Blank Row
    worksheet.addRow([]);

    // Adding Header Row
    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "69C9C1" },
        bgColor: { argb: "" },
      };
      cell.font = {
        bold: true,
        color: { argb: "FFFFFF" },
        size: 14,
      };
    });

    // Adding data
    data.forEach((e) => {
      worksheet.addRow(e);
    });

    worksheet.columns.forEach(function (column, i) {
      let maxLength = 0;
      column["eachCell"]({ includeEmpty: true }, function (cell) {
        const columnLength = cell.value
          ? cell.value.toString().trim().length
          : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 10 ? 10 : 28;
    });

    const lastRowIndex = data.length + 6;
    const lastRow = worksheet.getRow(lastRowIndex);
    worksheet.mergeCells(`L${lastRowIndex}:O${lastRowIndex}`);
    const lastCell = lastRow.getCell(12);
    lastCell.alignment = { wrapText: true, vertical: "middle" };
    
    worksheet.getRow(data.length + 6).eachCell({ includeEmpty: true }, (cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "69C9C1" },
        bgColor: { argb: "" },
      };
      cell.font = {
        bold: true,
        color: { argb: "FFFFFF" },
        size: 14,
      };
    });

    lastCell.font = {
      bold: true,
      size: 10,
      italic: true,
      color: { argb: "FFFFFF" },
    };

    worksheet.getRows(7, data.length - 1).forEach((row) => {
      row.alignment = { wrapText: true, vertical: "middle" };
    });

    // Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, title + EXCEL_EXTENSION);
    });
  }

  public exportAsStyledExcelFile(json: any[], excelFileName: string): void {
    const dataForExcel = [];

    json.sort((objA, objB) => {
      const dateA = moment(objA["BOOKING DATE"] ? objA["BOOKING DATE"] : objA["FECHA DE RESERVA"], "DD/MM/YYYY HH:mm");
      const dateB = moment(objB["BOOKING DATE"] ? objB["BOOKING DATE"] : objB["FECHA DE RESERVA"], "DD/MM/YYYY HH:mm");
      return dateB.diff(dateA);
    });

    json.forEach((row) => {
      dataForExcel.push(Object.values(row));
    });

    const reportData = {
      title: excelFileName,
      data: dataForExcel,
      headers: Object.keys(json[0]),
    };
    this.exportStyledExcel(reportData);
  }

  getStyledExcelData(header, resp, data) {
    console.log("data: ", data)
    const totalArray = [];
    let total = 0.0;
    let cancelledCount = 0;
    if (data.bookingFromModule?.length > 0) {
      const hotelsArray = data.bookingFromModule
                          .filter((hotel) => !hotel.deleted)
                          .map((hotel) => {
        total += parseFloat(hotel["price"]);
        if((hotel.status && hotel.status.toLowerCase() === "cancelled") || (hotel.refund && hotel.refund === true)){
          total -= parseFloat(hotel["price"]);
          cancelledCount++;
        }
        let rawPrice = parseFloat(hotel["price"]) / 1.1;
        let iva = rawPrice * 0.1;
        if(hotel["type"] && hotel["type"] === 'airbnb' || (hotel["type"] !== 'airbnb' && hotel["freeTaxes"] && hotel["freeTaxes"] === true)){
          iva = 0
          rawPrice = parseFloat(hotel["price"])
        }
        return {
          [header[0]]:
            hotel["company"] && hotel["user"]
              ? hotel["user"]["fullname"]?.toUpperCase() +
              " (" +
              hotel["company"]["companyName"]?.toUpperCase() +
              ")"
              : hotel["user"]
                ? hotel["user"]["fullname"]?.toUpperCase()
                : hotel["holder"]
                  ? hotel["holder"]["name"]?.toUpperCase() + " " + hotel["holder"]["surname"]?.toUpperCase()
                  : hotel["author"]
                    ? hotel["author"]
                    : (data.users && data.users.find((u) => hotel["userUuid"] === u.uuid) ? data.users.find((u) => hotel["userUuid"] === u.uuid).name + " " + data.users.find((u) => hotel["userUuid"] === u.uuid).lastname : "-"),
          [header[1]]: hotel["employeeNumber"] ? hotel["employeeNumber"] : "",
          [header[2]]: hotel["groupRestrictionName"]
            ? hotel["groupRestrictionName"]
            : "",
          [header[3]]: moment(hotel["createdAt"]).format("DD/MM/YYYY  HH:mm"),
          [header[4]]: resp["excel.hotel"],
          [header[5]]: hotel["hotel"] && hotel["hotel"]["destinationName"]
            ? hotel["hotel"]["destinationName"]
            : hotel["destinationName"]
              ? hotel["destinationName"]
              : hotel["hotel"] && hotel["hotel"]["address"]
                ? hotel["hotel"]["address"]
                : "-",
          [header[6]]: hotel["checkIn"] ? moment(hotel["checkIn"]).format("DD/MM/YYYY  HH:mm") : "-",
          [header[7]]: hotel["checkOut"] ? moment(hotel["checkOut"]).format("DD/MM/YYYY  HH:mm") : "-",
          [header[8]]: (hotel.status && hotel.status.toLowerCase() === "cancelled") || (hotel.refund && hotel.refund === true) ? "0,00€" : (iva.toFixed(2) + "€").replace(".", ","),
          [header[9]]: (hotel.status && hotel.status.toLowerCase() === "cancelled") || (hotel.refund && hotel.refund === true) ? "0,00€" : (rawPrice.toFixed(2) + "€").replace(".", ","),
          [header[10]]: (hotel.status && hotel.status.toLowerCase() === "cancelled") || (hotel.refund && hotel.refund === true) ? "0,00€" : (parseFloat(hotel["price"]).toFixed(2) + "€").replace(".", ","),
          [header[11]]: hotel["customCode"] ? hotel["customCode"] : "",
          [header[12]]: hotel["costCenter"] ? hotel["costCenter"] : "",
          [header[13]]: hotel["travelTitle"] ? hotel["travelTitle"] : (data["title"] ? data["title"] : ""),
          [header[14]]: hotel["paymentCard"] && hotel["paymentCard"]["name"] ? hotel["paymentCard"]["name"] : "",
          [header[15]]: hotel.status && hotel.status.toLowerCase() === "active"
            ? this.translateService.instant('agency.transaction.filters.status-confirmed')
            : hotel.status && hotel.status.toLowerCase() === 'cancelled'
            ? this.translateService.instant('agency.transaction.filters.status-cancelled') : "",
          [header[16]]: (
            hotel.payment
              ? this.translateService.instant('agency.transaction.filters.status-payment')
              : (hotel.refund
                ? this.translateService.instant('agency.transaction.filters.status-refund')
                : this.translateService.instant('agency.transaction.filters.type-pending')
              )
          )
        };
      });
      totalArray.push(...hotelsArray);
    } else if (data.bookings?.length > 0) {
      const hotelsArray = data.bookings
                          .filter((hotel) => !hotel.deleted)
                          .map((hotel) => {
        total += parseFloat(hotel["price"]);
        if((hotel.status && hotel.status.toLowerCase() === "cancelled") || (hotel.refund && hotel.refund === true)){
          total -= parseFloat(hotel["price"]);
          cancelledCount++;
        }
        let rawPrice = parseFloat(hotel["price"]) / 1.1;
        let iva = rawPrice * 0.1;
        if(hotel["type"] && hotel["type"] === 'airbnb' || (hotel["type"] !== 'airbnb' && hotel["freeTaxes"] && hotel["freeTaxes"] === true)){
          iva = 0
          rawPrice = parseFloat(hotel["price"])
        }
        return {
          [header[0]]:
            hotel["company"] && hotel["user"]
              ? hotel["user"]["fullname"]?.toUpperCase() +
              " (" +
              hotel["company"]["companyName"]?.toUpperCase() +
              ")"
              : hotel["user"]
                ? hotel["user"]["fullname"]?.toUpperCase()
                : hotel["holder"]
                  ? hotel["holder"]["name"]?.toUpperCase() + " " + hotel["holder"]["surname"]?.toUpperCase()
                  : hotel["author"]
                    ? hotel["author"]
                    : (data.users && data.users.find((u) => hotel["userUuid"] === u.uuid) ? data.users.find((u) => hotel["userUuid"] === u.uuid).name + " " + data.users.find((u) => hotel["userUuid"] === u.uuid).lastname : "-"),
          [header[1]]: hotel["employeeNumber"] ? hotel["employeeNumber"] : "",
          [header[2]]: hotel["groupRestrictionName"]
            ? hotel["groupRestrictionName"]
            : "",
          [header[3]]: moment(hotel["createdAt"]).format("DD/MM/YYYY  HH:mm"),
          [header[4]]: resp["excel.hotel"],
          [header[5]]: hotel["hotel"] && hotel["hotel"]["destinationName"]
            ? hotel["hotel"]["destinationName"]
            : hotel["destinationName"]
              ? hotel["destinationName"]
              : hotel["hotel"] && hotel["hotel"]["address"]
                ? hotel["hotel"]["address"]
                : "-",
          [header[6]]: hotel["checkIn"] ? moment(hotel["checkIn"]).format("DD/MM/YYYY  HH:mm") : "-",
          [header[7]]: hotel["checkOut"] ? moment(hotel["checkOut"]).format("DD/MM/YYYY  HH:mm") : "-",
          [header[8]]: (hotel.status && hotel.status.toLowerCase() === "cancelled") || (hotel.refund && hotel.refund === true) ? "0,00€" : (iva.toFixed(2) + "€").replace(".", ","),
          [header[9]]: (hotel.status && hotel.status.toLowerCase() === "cancelled") || (hotel.refund && hotel.refund === true) ? "0,00€" : (rawPrice.toFixed(2) + "€").replace(".", ","),
          [header[10]]: (hotel.status && hotel.status.toLowerCase() === "cancelled") || (hotel.refund && hotel.refund === true) ? "0,00€" : (parseFloat(hotel["price"]).toFixed(2) + "€").replace(".", ","),
          [header[11]]: hotel["customCode"] ? hotel["customCode"] : "",
          [header[12]]: hotel["costCenter"] ? hotel["costCenter"] : "",
          [header[13]]: hotel["travelTitle"] ? hotel["travelTitle"] : (data["title"] ? data["title"] : ""),
          [header[14]]: hotel["paymentCard"] && hotel["paymentCard"]["name"] ? hotel["paymentCard"]["name"] : "",
          [header[15]]: hotel.status && hotel.status.toLowerCase() === "active"
            ? this.translateService.instant('agency.transaction.filters.status-confirmed')
            : hotel.status && hotel.status.toLowerCase() === 'cancelled'
            ? this.translateService.instant('agency.transaction.filters.status-cancelled') : "",
          [header[16]]: (
            hotel.payment
              ? this.translateService.instant('agency.transaction.filters.status-payment')
              : (hotel.refund
                ? this.translateService.instant('agency.transaction.filters.status-refund')
                : this.translateService.instant('agency.transaction.filters.type-pending')
              )
          )
        };
      });
      totalArray.push(...hotelsArray);
    }

    if (data.flights?.length > 0) {
      const flightArray = data.flights
                        .filter((flight) => !flight.deleted)
                        .map((flight) => {
        total += parseFloat(flight["price"]);
        if((flight.status && flight.status.toLowerCase() === "cancelled") || (flight.refund && flight.refund === true)){
          total -= parseFloat(flight["price"]);
          cancelledCount++;
        }
        let rawPrice = parseFloat(flight["price"]) / 1.1
        let iva = rawPrice * 0.1;

        if(flight["freeTaxes"] === true){
          iva = 0
          rawPrice = parseFloat(flight["price"])
        }
        return {
          [header[0]]:
            flight["company"] && flight["user"]
              ? flight.user.name
                ? `${flight.user.name} ${flight.user.lastname}`?.toUpperCase()
                : flight.user.fullname?.toUpperCase() +
                " (" +
                flight["company"]["companyName"]?.toUpperCase() +
                ")"
              : flight["user"]
                ? flight.user.name
                  ? `${flight.user.name} ${flight.user.lastname}`?.toUpperCase()
                  : flight.user.fullname?.toUpperCase()
                : flight["holder"]
                  ? flight["holder"]["name"]?.toUpperCase() + ' ' + flight["holder"]["surname"]?.toUpperCase()
                  : flight["extraInfo"]
                  ? flight["extraInfo"][0]?.passengerName
                  : (data.users && data.users.find((u) => flight["userUuid"] === u.uuid) ? data.users.find((u) => flight["userUuid"] === u.uuid).name + " " + data.users.find((u) => flight["userUuid"] === u.uuid).lastname : "-"),
          [header[1]]: flight["employeeNumber"] ? flight["employeeNumber"] : "",
          [header[2]]: flight["groupRestrictionName"]
            ? flight["groupRestrictionName"]
            : "",
          [header[3]]: moment(flight["createdAt"]).format("DD/MM/YYYY  HH:mm"),
          [header[4]]: resp["excel.flight"],
          [header[5]]: flight["from"] + " - " + flight["to"],
          [header[6]]: moment(flight["departDate"]).format("DD/MM/YYYY  HH:mm"),
          [header[7]]:
            flight["oneWay"] === true
              ? resp["excel.one-way"]
              : moment(flight["returnDate"]).format("DD/MM/YYYY  HH:mm"),
          [header[8]]: (flight.status && flight.status.toLowerCase() === "cancelled") || (flight.refund && flight.refund === true) ? "0,00€" : (iva.toFixed(2) + "€").replace(".", ","),
          [header[9]]: (flight.status && flight.status.toLowerCase() === "cancelled") || (flight.refund && flight.refund === true) ? "0,00€" : (rawPrice.toFixed(2) + "€").replace(".", ","),
          [header[10]]: (flight.status && flight.status.toLowerCase() === "cancelled") || (flight.refund && flight.refund === true) ? "0,00€" : (parseFloat(flight["price"]).toFixed(2) + "€").replace(".", ","),
          [header[11]]: flight["customCode"] ? flight["customCode"] : "",
          [header[12]]: flight["costCenter"] ? flight["costCenter"] : "",
          [header[13]]: flight["travelTitle"] ? flight["travelTitle"] : (data["title"] ? data["title"] : ""),
          [header[14]]: flight["paymentCard"] && flight["paymentCard"]["name"] ? flight["paymentCard"]["name"] : "",
          [header[15]]: flight.status === "active" ? this.translateService.instant('agency.transaction.filters.status-confirmed') : this.translateService.instant('agency.transaction.filters.status-cancelled'),
          [header[16]]: (
            flight.payment
              ? this.translateService.instant('agency.transaction.filters.status-payment')
              : (flight.refund
                ? this.translateService.instant('agency.transaction.filters.status-refund')
                : this.translateService.instant('agency.transaction.filters.type-pending')
              )
          )
        };
      });
      totalArray.push(...flightArray);
    }

    if (data.trains?.length > 0) {
      const trainArray = data.trains
                          .filter((train) => !train.deleted)
                          .map((train) => {
        total += parseFloat(train["price"]);
        if((train.status && train.status.toLowerCase() === "cancelled") || (train.refund && train.refund === true)){
          total -= parseFloat(train["price"]);
          cancelledCount++;
        }
        let rawPrice = parseFloat(train["price"]) / 1.1
        let iva = rawPrice * 0.1;

        if(train["freeTaxes"] === true){
          iva = 0
          rawPrice = parseFloat(train["price"])
        }
        return {
          [header[0]]:
            train["company"] && train["user"]
              ? train.user.name
                ? `${train.user.name} ${train.user.lastname}`?.toUpperCase()
                : train.user.fullname?.toUpperCase() +
                " (" +
                train["company"]["companyName"]?.toUpperCase() +
                ")"
              : train["user"]
                ? train.user.name
                  ? `${train.user.name} ${train.user.lastname}`?.toUpperCase()
                  : train.user.fullname?.toUpperCase()
                : train["holder"]
                  ? train["holder"]["name"]?.toUpperCase() + ' ' + train["holder"]["surname"]?.toUpperCase()
                  : train["extraInfo"]
                    ? train["extraInfo"][0]?.passengerFullName
                    : (data.users && data.users.find((u) => train["userUuid"] === u.uuid) ? data.users.find((u) => train["userUuid"] === u.uuid).name + " " + data.users.find((u) => train["userUuid"] === u.uuid).lastname : "-"),
          [header[1]]: train["employeeNumber"] ? train["employeeNumber"] : "",
          [header[2]]: train["groupRestrictionName"]
            ? train["groupRestrictionName"]
            : "",
          [header[3]]: moment(train["createdAt"]).format("DD/MM/YYYY  HH:mm"),
          [header[4]]: resp["excel.train"],
          [header[5]]: train["from"] + " - " + train["to"],
          [header[6]]: moment(train["departDate"]).format("DD/MM/YYYY  HH:mm"),
          [header[7]]:
            train["oneWay"] === true
              ? resp["excel.one-way"]
              : moment(train["returnDate"]).format("DD/MM/YYYY  HH:mm"),
          [header[8]]: (train.status && train.status.toLowerCase() === "cancelled") || (train.refund && train.refund === true) ? "0,00€" : (iva.toFixed(2) + "€").replace(".", ","),
          [header[9]]: (train.status && train.status.toLowerCase() === "cancelled") || (train.refund && train.refund === true) ? "0,00€" : (rawPrice.toFixed(2) + "€").replace(".", ","),
          [header[10]]: (train.status && train.status.toLowerCase() === "cancelled") || (train.refund && train.refund === true) ? "0,00€" : (parseFloat(train["price"]).toFixed(2) + "€").replace(".", ","),
          [header[11]]: train["customCode"] ? train["customCode"] : "",
          [header[12]]: train["costCenter"] ? train["costCenter"] : "",
          [header[13]]: train["travelTitle"] ? train["travelTitle"] : (data["title"] ? data["title"] : ""),
          [header[14]]: train["paymentCard"] && train["paymentCard"]["name"] ? train["paymentCard"]["name"] : "",
          [header[15]]: train.status === "active" ? this.translateService.instant('agency.transaction.filters.status-confirmed') : this.translateService.instant('agency.transaction.filters.status-cancelled'),
          [header[16]]: (
            train.payment
              ? this.translateService.instant('agency.transaction.filters.status-payment')
              : (train.refund
                ? this.translateService.instant('agency.transaction.filters.status-refund')
                : this.translateService.instant('agency.transaction.filters.type-pending')
              )
          )
        };
      });
      totalArray.push(...trainArray);
    }

    if (data.expenses?.length > 0) {
      const expensesArray = data.expenses
                              .filter((element) => !element.deleted)
                              .map((element) => {
        total += parseFloat(element["price"]);
        if((element.status && element.status.toLowerCase() === "cancelled") || (element.refund && element.refund === true)){
          total -= parseFloat(element["price"]);
          cancelledCount++;
        }
        let rawPrice = parseFloat(element["price"]) / 1.1
        let iva = rawPrice * 0.1;

        if(element["freeTaxes"] === true){
          iva = 0
          rawPrice = parseFloat(element["price"])
        }
        return {
          [header[0]]:
            element["company"] && element["user"]
              ? element.user.name
                ? `${element.user.name} ${element.user.lastname}`?.toUpperCase()
                : element.user.fullname?.toUpperCase() +
                " (" +
                element["company"]["companyName"]?.toUpperCase() +
                ")"
              : element["user"]
                ? element.user.name
                  ? `${element.user.name} ${element.user.lastname}`?.toUpperCase()
                  : element.user.fullname?.toUpperCase()
                  : element["holder"]
                  ? element["holder"]["name"]?.toUpperCase() + ' ' + element["holder"]["surname"]?.toUpperCase()
                : (data.users && data.users.find((u) => element["userUuid"] === u.uuid) ? data.users.find((u) => element["userUuid"] === u.uuid).name + " " + data.users.find((u) => element["userUuid"] === u.uuid).lastname : "-"),
          [header[1]]: element["employeeNumber"]
            ? element["employeeNumber"]
            : "",
          [header[2]]: element["groupRestrictionName"]
            ? element["groupRestrictionName"]
            : "",
          [header[3]]: moment(element["createdAt"]).format("DD/MM/YYYY HH:mm"),
          [header[4]]: element["type"]
            ? this.transformTextType(element["type"], resp)
            : resp["excel.car"],
          [header[5]]: "-",
          [header[6]]: moment(element["beginDate"]).format("DD/MM/YYYY  HH:mm"),
          [header[7]]: moment(element["endDate"]).format("DD/MM/YYYY  HH:mm"),
          [header[8]]: (element.status && element.status.toLowerCase() === "cancelled") || (element.refund && element.refund === true) ? "0,00€" : (iva.toFixed(2) + "€").replace(".", ","),
          [header[9]]: (element.status && element.status.toLowerCase() === "cancelled") || (element.refund && element.refund === true) ? "0,00€" : (rawPrice.toFixed(2) + "€").replace(".", ","),
          [header[10]]: (element.status && element.status.toLowerCase() === "cancelled") || (element.refund && element.refund === true) ? "0,00€" : (parseFloat(element["price"]).toFixed(2) + "€").replace(".", ","),
          [header[11]]: element["customCode"] ? element["customCode"] : "",
          [header[12]]: element["costCenter"] ? element["costCenter"] : "",
          [header[13]]: element["travelTitle"] ? element["travelTitle"] : (data["title"] ? data["title"] : ""),
          [header[14]]: element["paymentCard"] && element["paymentCard"]["name"] ? element["paymentCard"]["name"] : "",
          [header[15]]: element.status ? (element.status === "active" ? this.translateService.instant('agency.transaction.filters.status-confirmed') : this.translateService.instant('agency.transaction.filters.status-cancelled')) : '',
          [header[16]]: (
            element.payment
              ? this.translateService.instant('agency.transaction.filters.status-payment')
              : (element.refund
                ? this.translateService.instant('agency.transaction.filters.status-refund')
                : this.translateService.instant('agency.transaction.filters.type-pending')
              )
          )
        };
      });
      totalArray.push(...expensesArray);
    }

    if (data.cars?.length > 0) {
      const carsArray = data.cars
                        .filter((car) => !car.deleted)
                        .map((car) => {
        total += parseFloat(car["price"]);
        if((car.status && car.status.toLowerCase() === "cancelled") || (car.refund && car.refund === true)){
          total -= parseFloat(car["price"]);
          cancelledCount++;
        }
        let rawPrice = parseFloat(car["price"]) / 1.1
        let iva = rawPrice * 0.1;

        if(car["freeTaxes"] === true){
          iva = 0
          rawPrice = parseFloat(car["price"])
        }
        return {
          [header[0]]:
            car["company"] && car["user"]
              ? car.user.name
                ? `${car.user.name} ${car.user.lastname}`?.toUpperCase()
                : car.user.fullname?.toUpperCase() +
                " (" +
                car["company"]["companyName"]?.toUpperCase() +
                ")"
              : car["traveller"] && car["traveller"].length >= 1
              ? car["traveller"][0]["name"] + " " + car["traveller"][0]["surname"]
              : car["holder"]
              ? car["holder"]["name"] + " " + car["holder"]["surname"]
              : car["user"]
                ? car.user.name
                  ? `${car.user.name} ${car.user.lastname}`?.toUpperCase()
                  : car.user.fullname?.toUpperCase()
                : (data.users && data.users.find((u) => car["userUuid"] === u.uuid) ? data.users.find((u) => car["userUuid"] === u.uuid).name + " " + data.users.find((u) => car["userUuid"] === u.uuid).lastname : "-"),
          [header[1]]: car["employeeNumber"] ? car["employeeNumber"] : "",
          [header[2]]: car["groupRestrictionName"]
            ? car["groupRestrictionName"]
            : "",
          [header[3]]: moment(car["createdAt"]).format("DD/MM/YYYY HH:mm"),
          [header[4]]: resp["excel.car"],
          [header[5]]:
            car["from"] +
            " (" +
            car["pickupPlaceFrom"] +
            " ) - " +
            car["to"] +
            " (" +
            car["pickupPlaceTo"] +
            " )",
          [header[6]]: moment(car["departDate"]).format("DD/MM/YYYY  HH:mm"),
          [header[7]]: moment(car["returnDate"]).format("DD/MM/YYYY  HH:mm"),
          [header[8]]: (car.status && car.status.toLowerCase() === "cancelled") || (car.refund && car.refund === true) ? "0,00€" : (iva.toFixed(2) + "€").replace(".", ","),
          [header[9]]: (car.status && car.status.toLowerCase() === "cancelled") || (car.refund && car.refund === true) ? "0,00€" : (rawPrice.toFixed(2) + "€").replace(".", ","),
          [header[10]]: (car.status && car.status.toLowerCase() === "cancelled") || (car.refund && car.refund === true) ? "0,00€" : (parseFloat(car["price"]).toFixed(2) + "€").replace(".", ","),
          [header[11]]: car["customCode"] ? car["customCode"] : "",
          [header[12]]: car["costCenter"] ? car["costCenter"] : "",
          [header[13]]: car["travelTitle"] ? car["travelTitle"] : (data["title"] ? data["title"] : ""),
          [header[14]]: car["paymentCard"] && car["paymentCard"]["name"] ? car["paymentCard"]["name"] : "",
          [header[15]]: car.status ? (car.status === "active" ? this.translateService.instant('agency.transaction.filters.status-confirmed') : this.translateService.instant('agency.transaction.filters.status-cancelled')) : '',
          [header[16]]: (
            car.payment
              ? this.translateService.instant('agency.transaction.filters.status-payment')
              : (car.refund
                ? this.translateService.instant('agency.transaction.filters.status-refund')
                : this.translateService.instant('agency.transaction.filters.type-pending')
              )
          )
        };
      });
      totalArray.push(...carsArray);
    }

    if (data.customBookings?.length > 0) {
      const customArray = data.customBookings
                          .filter((custom) => !custom.deleted)
                          .map((custom) => {
        total += parseFloat(custom["price"]);
        if((custom.status && custom.status.toLowerCase() === "cancelled") || (custom.refund && custom.refund === true) ){
          total -= parseFloat(custom["price"]);
          cancelledCount++;
        }
        let rawPrice = parseFloat(custom["price"]) / 1.1;
        let iva = rawPrice * 0.1;
        if(custom["type"] === 'airbnb' || (custom["type"] !== 'airbnb' && custom["freeTaxes"] && custom["freeTaxes"] === true)){
          iva = 0
          rawPrice = parseFloat(custom["price"])
        }
        return {
          [header[0]]:
            custom["company"] && custom["user"]
              ? custom["user"]["fullname"]?.toUpperCase() +
              " (" +
              custom["company"]["companyName"]?.toUpperCase() +
              ")"
              : custom["user"]
                ? custom["user"]["fullname"]?.toUpperCase()
                : custom["holder"]
                  ? custom["holder"]["name"]?.toUpperCase() + ' ' + custom["holder"]["surname"]?.toUpperCase()
                  : custom["author"]
                    ? custom["author"]
                    : (data.users && data.users.find((u) => custom["userUuid"] === u.uuid) ? data.users.find((u) => custom["userUuid"] === u.uuid).name + " " + data.users.find((u) => custom["userUuid"] === u.uuid).lastname : "-"),
          [header[1]]: custom["employeeNumber"] ? custom["employeeNumber"] : "",
          [header[2]]: custom["groupRestrictionName"]
            ? custom["groupRestrictionName"]
            : "",
          [header[3]]: moment(custom["createdAt"]).format("DD/MM/YYYY  HH:mm"),
          [header[4]]: resp["excel.hotel"],
          [header[5]]: custom["hotel"]
            ? custom["hotel"]["destinationName"]
            : custom["destinationName"]
              ? custom["destinationName"]
              : "-",
          [header[6]]: moment(custom["checkIn"]).format("DD/MM/YYYY  HH:mm"),
          [header[7]]: moment(custom["checkOut"]).format("DD/MM/YYYY  HH:mm"),
          [header[8]]: (custom.status && custom.status.toLowerCase() === "cancelled") || (custom.refund && custom.refund === true)  ? "0,00€" : (iva.toFixed(2) + "€").replace(".", ","),
          [header[9]]: (custom.status && custom.status.toLowerCase() === "cancelled") || (custom.refund && custom.refund === true)  ? "0,00€" : (rawPrice.toFixed(2) + "€").replace(".", ","),
          [header[10]]: (custom.status && custom.status.toLowerCase() === "cancelled") || (custom.refund && custom.refund === true)  ? "0,00€" : (parseFloat(custom["price"]).toFixed(2) + "€").replace(".", ","),
          [header[11]]: custom["customCode"] ? custom["customCode"] : "",
          [header[12]]: custom["costCenter"] ? custom["costCenter"] : "",
          [header[13]]: custom["travelTitle"] ? custom["travelTitle"] : (data["title"] ? data["title"] : ""),
          [header[14]]: custom["paymentCard"] && custom["paymentCard"]["name"] ? custom["paymentCard"]["name"] : "",
          [header[15]]: custom.status ? (custom.status === "active" ? this.translateService.instant('agency.transaction.filters.status-confirmed') : this.translateService.instant('agency.transaction.filters.status-cancelled')) : '',
          [header[16]]: (
            custom.payment
              ? this.translateService.instant('agency.transaction.filters.status-payment')
              : (custom.refund
                ? this.translateService.instant('agency.transaction.filters.status-refund')
                : this.translateService.instant('agency.transaction.filters.type-pending')
              )
          )
        }
      });
      totalArray.push(...customArray);
    }
    totalArray.push({
      [header[0]]: "",
      [header[1]]: "",
      [header[2]]: "",
      [header[3]]: "",
      [header[4]]: "",
      [header[5]]: "",
      [header[6]]: "",
      [header[7]]: "",
      [header[8]]: "",
      [header[9]]: "",
      [header[10]]: (total.toFixed(2) + "€").replace(".", ","),
      [header[11]]: cancelledCount > 0 ? this.translateService.instant("excel.cancelled-bookeds"): "",
      [header[12]]: "",
      [header[13]]: "",
      [header[14]]: "",
    });

    return totalArray;
  }

  getTravelContent(travel, resp) {
    let finalText = "";
    travel["users"].map((user) => {
      finalText += "\r\n" + user["name"] + " " + user["lastname"] + "\r\n";

      let hotels = "";
      travel["bookings"]
        .filter(
          (hotel) =>
            user["uuid"] === hotel["userUuid"]
        )
        .map((hotel) => {
          hotels +=
            " \t " +
            " \t " +
            (hotel.hotel ? hotel.hotel["name"] : hotel["hotelName"]) +
            " (" +
            moment(hotel["checkIn"]).format("DD/MM/YYYY") +
            " - " +
            moment(hotel["checkOut"]).format("DD/MM/YYYY") +
            ")\r\n" +
            "\r\n";
        });

      travel["customBookings"]
        .filter(
          (hotel) => user["uuid"] === hotel["userUuid"])
        .map((hotel) => {
          hotels +=
            " \t " +
            " \t " +
            (hotel.hotel ? hotel.hotel["name"] : hotel["hotelName"]) +
            " (" +
            moment(hotel["checkIn"]).format("DD/MM/YYYY") +
            " - " +
            moment(hotel["checkOut"]).format("DD/MM/YYYY") +
            ")\r\n" +
            "\r\n";
        });

      let flights = "";
      travel["flights"]
        .filter((flight) => user["uuid"] === flight["userUuid"])
        .map((flight) => {
          flights +=
            " \t " +
            " \t " +
            flight["from"] +
            " - " +
            flight["to"] +
            " (" +
            moment(flight["departDate"]).format("DD/MM/YYYY  HH:mm") +
            (flight["returnDate"]
              ? " - " + moment(flight["returnDate"]).format("DD/MM/YYYY  HH:mm")
              : "") +
            ")\r\n" +
            "\r\n";
        });

      let trains = "";
      travel["trains"]
        .filter((train) => user["uuid"] === train["userUuid"])
        .map((train) => {
          trains +=
            " \t " +
            " \t " +
            train["from"] +
            " - " +
            train["to"] +
            " (" +
            moment(train["departDate"]).format("DD/MM/YYYY  HH:mm") +
            (train["returnDate"]
              ? " - " + moment(train["returnDate"]).format("DD/MM/YYYY  HH:mm")
              : "") +
            ")\r\n" +
            "\r\n";
        });

      let cars = "";
      travel["cars"]
        .filter((car) => user["uuid"] === car["userUuid"])
        .map((car) => {
          cars +=
            " \t " +
            " \t " +
            car["carType"] +
            " (" +
            moment(car["departDate"]).format("DD/MM/YYYY  HH:mm") +
            " - " +
            moment(car["returnDate"]).format("DD/MM/YYYY  HH:mm") +
            ")\r\n" +
            "\r\n";
        });

      let expenses = "";
      travel["expenses"]
        .filter((expense) => user["uuid"] === expense["userUuid"])
        .map((expense) => {
          expenses +=
            " \t " +
            " \t " +
            expense["type"] +
            " (" +
            moment(expense["beginDate"]).format("DD/MM/YYYY") +
            " - " +
            moment(expense["endDate"]).format("DD/MM/YYYY") +
            ")\r\n" +
            "\r\n";
        });
      finalText +=
        "\r\n" +
        (hotels !== ""
          ? " \t " +
          this.transformTextType("hotel", resp) +
          "\r\n \r\n" +
          hotels +
          " \r\n"
          : "") +
        (flights !== ""
          ? " \t " +
          this.transformTextType("flight", resp) +
          "\r\n" +
          " \r\n" +
          flights +
          " \r\n"
          : "") +
        (trains !== ""
          ? " \t " +
          this.transformTextType("train", resp) +
          "\r\n" +
          " \r\n" +
          trains +
          " \r\n"
          : "") +
        (cars !== ""
          ? " \t " +
          this.transformTextType("car", resp) +
          "\r\n" +
          " \r\n" +
          cars +
          " \r\n"
          : "") +
        (expenses !== ""
          ? " \t " +
          this.transformTextType("expense", resp) +
          "\r\n" +
          " \r\n" +
          expenses +
          " \r\n"
          : "");
    });
    if (
      travel["customBookings"].length === 0 &&
      travel["bookings"].length === 0 &&
      travel["flights"].length === 0 &&
      travel["trains"].length === 0 &&
      travel["cars"].length === 0 &&
      travel["expenses"].length === 0
    ) {
      finalText += resp["excel.no-bookeds"] + "\r\n";
    }
    return finalText;
  }

  transformTextType(type: string, resp) {
    let ty = type;
    this.types.forEach((t) => {
      if (t.value === type) {
        ty = resp[t.name];
      }
    });
    return ty;
  }

  createBookingsExcel(title, data) {
    this.type = "bookings";
    let header = [];
    this.subscriptions.push(
      this.translateService
        .get([
          "excel.name",
          "excel.employee-number",
          "excel.employee-dpt",
          "excel.booking-date",
          "excel.service",
          "excel.destination",
          "excel.initDate",
          "excel.endDate",
          "excel.total",
          "excel.hotel",
          "excel.flight",
          "excel.train",
          "excel.car",
          "excel.transfer",
          "excel.taxi",
          "excel.other",
          "excel.one-way",
          "excel.iva",
          "excel.price-not-iva",
          "common.custom-code",
          "common.cost-center",
          "excel.related-project",
          "company.transaction.filters.payment-card",
          "common.status",
          "company.bills.bills",
        ])
        .pipe(take(1))
        .subscribe((resp) => {
          header = [
            resp["excel.name"],
            resp["excel.employee-number"],
            resp["excel.employee-dpt"],
            resp["excel.booking-date"],
            resp["excel.service"],
            resp["excel.destination"],
            resp["excel.initDate"],
            resp["excel.endDate"],
            resp["excel.iva"],
            resp["excel.price-not-iva"],
            resp["excel.total"],
            resp["common.custom-code"],
            resp["common.cost-center"],
            resp["excel.related-project"],
            resp["company.transaction.filters.payment-card"],
            resp["common.status"],
            resp["company.bills.bills"],
          ];
          const arrayExcel = this.getStyledExcelData(header, resp, data);
          this.exportAsStyledExcelFile(arrayExcel, "Vyoo-Report " + title);
        })
    );
  }

  createProjectsExcel(title, data, isAdmin, userUuid) {
    if (!isAdmin) {
      for (let travel of data) {
        const filterByUser = (t) =>
          Array.isArray(t.userUuid) ? t.userUuid.includes(userUuid) : t.userUuid === userUuid;
  
        travel.bookings = travel.bookings.filter(filterByUser);
        travel.bookingFromModule = travel.bookingFromModule.filter(filterByUser);
        travel.customBookings = travel.customBookings.filter(filterByUser);
        travel.cars = travel.cars.filter(filterByUser);
        travel.flights = travel.flights.filter(filterByUser);
        travel.trains = travel.trains.filter(filterByUser);
        travel.expenses = travel.expenses.filter(filterByUser);
        travel.users = travel.users.filter((t) => t.uuid === userUuid);
      }
    }
    this.type = "projects";
    this.translateService
      .get([
        "excel.name",
        "excel.employee-number",
        "excel.employee-dpt",
        "excel.booking-date",
        "excel.service",
        "excel.destination",
        "excel.initDate",
        "excel.endDate",
        "excel.total",
        "excel.hotel",
        "excel.flight",
        "excel.train",
        "excel.car",
        "excel.transfer",
        "excel.taxi",
        "excel.other",
        "excel.one-way",
        "excel.iva",
        "excel.price-not-iva",
        "common.custom-code",
        "common.cost-center",
        "excel.related-project",
        "company.transaction.filters.payment-card",
        "common.status",
        "excel.download-date",
        "company.bills.bills"
      ])
      .pipe(take(1))
      .subscribe((resp) => {
        let header = [];
        header = [
          resp["excel.name"],
          resp["excel.employee-number"],
          resp["excel.employee-dpt"],
          resp["excel.booking-date"],
          resp["excel.service"],
          resp["excel.destination"],
          resp["excel.initDate"],
          resp["excel.endDate"],
          resp["excel.iva"],
          resp["excel.price-not-iva"],
          resp["excel.total"],
          resp["common.custom-code"],
          resp["common.cost-center"],
          resp["excel.related-project"],
          resp["company.transaction.filters.payment-card"],
          resp["common.status"],
          resp["company.bills.bills"],
        ];
        const downloadDate = resp["excel.download-date"];
        const arrayExcel = [];
        for (let travel of data) {
          arrayExcel.push(this.getStyledExcelData(header, resp, travel));
        }
        const dataForExcel = [];

        arrayExcel.map((a) =>
          a.sort((objA, objB) => {
            const dateA = moment(
              objA["BOOKING DATE"]
                ? objA["BOOKING DATE"]
                : objA["FECHA DE RESERVA"],
              "DD/MM/YYYY HH:mm"
            );
            const dateB = moment(
              objB["BOOKING DATE"]
                ? objB["BOOKING DATE"]
                : objB["FECHA DE RESERVA"],
              "DD/MM/YYYY HH:mm"
            );
            return dateB.diff(dateA);
          })
        );

        for (let i = 0; i < arrayExcel.length; i++) {
          dataForExcel.push([]);
          for (let j = 0; j < arrayExcel[i].length; j++) {
            dataForExcel[i].push(Object.values(arrayExcel[i][j]));
          }
        }

        const reportData = {
          data: dataForExcel,
          headers: Object.keys(arrayExcel[0][0]),
        };

        const workbook = new Workbook();
        for (let i = 0; i < data.length; i++) {
          try{
          data[i].title = data[i].title.replace(/[*?:\\/\[\]]/g, '_');
          const worksheet = workbook.addWorksheet((i + 1) + " - " + data[i].title);

          // Title
          worksheet.mergeCells("A1", "D4");
          const titleRow = worksheet.getCell("A1");
          titleRow.value = title;
          titleRow.font = {
            name: "Calibri",
            size: 16,
            bold: true,
            color: { argb: "69C9C1" },
          };
          titleRow.alignment = { vertical: "middle", horizontal: "center" };

          // Date
          worksheet.mergeCells("F1:G4");
          const d = new Date();
          const month = d.getMonth() + 1;
          const date = d.getDate() + "-" + month + "-" + d.getFullYear();
          const dateCell = worksheet.getCell("F1");
          dateCell.value = downloadDate + ": " + date;
          dateCell.font = {
            name: "Calibri",
            size: 12,
            bold: true,
            color: { argb: "205954" },
          };
          dateCell.alignment = { vertical: "middle", horizontal: "center" };

          // Blank Row
          worksheet.addRow([]);

          // Adding Header Row
          const headerRow = worksheet.addRow(reportData.headers);
          headerRow.eachCell((cell, number) => {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "69C9C1" },
              bgColor: { argb: "" },
            };
            cell.font = {
              bold: true,
              color: { argb: "FFFFFF" },
              size: 14,
            };
          });

          // Adding data
          reportData.data[i].forEach((e) => {
            worksheet.addRow(e);
          });

          worksheet.columns.forEach(function (column, i) {
            let maxLength = 0;
            column["eachCell"]({ includeEmpty: true }, function (cell) {
              const columnLength = cell.value
                ? cell.value.toString().trim().length
                : 10;
              if (columnLength > maxLength) {
                maxLength = columnLength;
              }
            });
            column.width = maxLength < 10 ? 10 : 28;
          });

          const lastRow = worksheet.lastRow;
          let lastCell;
          if (lastRow) {
            const lastRowIndex = lastRow.number;
            worksheet.mergeCells(`L${lastRowIndex}:O${lastRowIndex}`);
            lastCell = lastRow.getCell(12);
            lastCell.alignment = { wrapText: true, vertical: "middle" };
          }

          worksheet
            .getRow(reportData.data[i].length + 6)
            .eachCell({ includeEmpty: true }, (cell, number) => {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "69C9C1" },
                bgColor: { argb: "" },
              };
              cell.font = {
                bold: true,
                color: { argb: "FFFFFF" },
                size: 14,
              };
            });

          lastCell.font = {
            bold: true,
            size: 10,
            italic: true,
            color: { argb: "FFFFFF" },
          };

          if (worksheet.getRows(7, reportData.data[i].length - 1))
            worksheet
              .getRows(7, reportData.data[i].length - 1)
              .forEach((row) => {
                row.alignment = { wrapText: true, vertical: "middle" };
              });

          if (i === data.length - 1) {
            // Generate & Save Excel File
            workbook.xlsx.writeBuffer().then((data) => {
              const blob = new Blob([data], { type: EXCEL_TYPE });
              fs.saveAs(blob, title + EXCEL_EXTENSION);
            });
          }
        } catch(error){
          if (error instanceof Error && error.message.includes("Worksheet name")) {
            this.translateService
              .get(["project.export-excel-error", "project.excel-title-error"])
              .pipe(take(1))
              .subscribe((result) => {
                  this.ngxToastrService.typeInfo(result["project.export-excel-error"], result["project.excel-title-error"]);
              });
          } else {
            this.translateService
              .get(["project.export-excel-error", "project.excel-standar-error"])
              .pipe(take(1))
              .subscribe((result) => {
                this.ngxToastrService.typeInfo(result["project.export-excel-error"], result["project.excel-standar-error"]);
              });
          }}
        }
      }, (error => {
          this.translateService
              .get(["project.export-excel-error", "project.excel-standar-error"])
              .pipe(take(1))
              .subscribe((result) => {
                this.ngxToastrService.typeInfo(result["project.export-excel-error"], result["project.excel-standar-error"]);
              });
      }));
  }
}
