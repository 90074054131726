<div *ngIf="(isLoading | async)" style="display: block; margin: auto; z-index: 999999999">
  <app-loading></app-loading>
</div>
<app-loading
  [userType]="'user'"
  *ngIf="loading"
  style="z-index: 1000000"
></app-loading>
<ng-container *ngIf="utilsService.allLoaded">
  <app-booked-search
    [isEmployee]="isEmployee"
    [form]="form"
    [place]="place"
    (nextBookingsEmit)="nextBookings($event)"
    (fromDateEmit)="fromDate = $event"
    [service]="service"
    (changeComponentEmit)="changeComponent($event)"
    (changeTypeEmit)="changeType($event)"
    (toDateEmit)="toDate = $event"
    (fromCreationDateEmit)="createdFromDate = $event"
    (toCreationDateEmit)="createdToDate = $event"
    (pageEmit)="setPage()"
    [isUser]="isUser"
    [bookeds]="bookeds"
    [statusOptions]="statusOptions"
    [nextTravelsChecked]="nextTravelsChecked"
    [loadingData]="loadingData"
    (selectChange)="onSelectChange($event)"
    *ngIf="bookeds.length > 0 && utilsService.allLoaded"
  >
  </app-booked-search>
</ng-container>

<!-- EMPTY STATE -->
<div class="text-center" *ngIf="bookeds.length <= 0 && utilsService.allLoaded">
  <img
    class="not_found_image"
    src="assets/img/gallery/flights-not-found.svg"
    alt="No hay archivos"
  />
  <h3 class="mt-4">
    <b>{{ "my-bookeds.user.no-bookeds" | translate }}</b>
  </h3>
  <span>{{ "my-bookeds.user.do-reserve" | translate }}</span>
</div>

<!-- TABLA DE MIS RESERVAS -->
<div *ngIf="bookeds.length > 0 && utilsService.allLoaded">
  <div class="container-customize">
    <div class="row" *ngIf="!loading">
      <div
        class="col-12 p-1"
        *ngFor="
          let booked of bookeds
            | filterBooked : nameFilters : getFilters() : typeFilters
            | slice : lowValue : highValue;
            let i = index
        "
      >
        <app-book-card
          (loadType)="reloadType($event)"
          [groupRestrictionUuid]="groupRestrictionUuid"
          [booked]="booked"
          [companyUuid]="companyUuid"
          [uuid]="booked.uuid"
          [isUser]="isUser"
          [type]="service"
          [requestCancelBooked]="requestCancelBooked"
          [requestCancelFlight]="requestCancelFlight"
          [userUuid]="userUuid"
          [isEmployee]="isEmployee"
          (reloadType)="reloadType()"
          [nextTravelsChecked]="nextTravelsChecked"
          [index]="i"
        ></app-book-card>
      </div>
    </div>
    <div
      class="col-12 my-5 d-flex justify-content-center"
      *ngIf="
        (bookeds | filterBooked : nameFilters : getFilters() : typeFilters)
          .length === 0
      "
    >
      <div class="icon-bcg icon-bcg--large">
        <img
          src="assets/img/svg/empty-search.svg"
          alt="emptySearch"
          class="empty-search"
        />
      </div>
      <h2 class="ml-3 my-auto">{{ "search.not-data" | translate }}</h2>
    </div>
  </div>
</div>
<div class="d-flex justify-content-center mt-3" *ngIf="bookeds.length > 0 && utilsService.allLoaded">
  <mat-paginator
    #paginator
    [length]="
      (bookeds | filterBooked : nameFilters : getFilters() : typeFilters).length
    "
    [pageSize]="4"
    (page)="getPaginatorData($event)"
    showFirstLastButtons
    [hidePageSize]="true"
    style="border-radius: 15px"
    [hidden]="
      (bookeds | filterBooked : nameFilters : getFilters() : typeFilters)
        .length <= 0 || !utilsService.allLoaded
    "
  >
  </mat-paginator>
</div>
