import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Booked, Flight, Train } from "@models";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {
  AuthService,
  BookedService,
  BookService,
  CarService,
  CompanyService,
  ExcelService,
  ExpensesService,
  FlightService,
  MediaService,
  NgxToastrService,
  RequestService,
  TrainService,
  TravelService,
  UserService,
  UtilsService,
} from "@services";
import { DialogAssignProjectComponent } from "@shared/component/dialog-assign-project/dialog-assign-project.component";
import { PaginationConfig } from "@shared/interfaces";
import { MyBookedsState } from "app/store/reducers/my-bookeds.reducer";
import moment from "moment";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { AppState } from "../my-bookeds.reducer";
import { Location, PlatformLocation } from "@angular/common";

@Component({
  selector: "app-user-my-bookeds",
  templateUrl: "./user-my-bookeds.component.html",
  styleUrls: ["./user-my-bookeds.component.scss"],
})
export class UserMyBookedsComponent implements OnInit, OnDestroy {
  paginationConf: PaginationConfig = {
    page: 1,
    pageSize: 9,
    collectionSize: 120,
  };
  companyImage = "";
  // FILTROS
  form: UntypedFormGroup;
  formFlight: UntypedFormGroup;
  formTrain: UntypedFormGroup;
  formExpenses: UntypedFormGroup;
  formTravel: UntypedFormGroup;
  formCars: UntypedFormGroup;
  statusOptions = [];
  bookeds: Booked[];
  flights: Flight[];
  trains: Train[];
  cars: any[];
  expenses;
  travels = [];
  isAdmin = false;
  user: any;
  requestBooked: boolean;
  request: boolean;
  canBook: boolean;
  userUuid = "";
  travelPagination = {};
  isLoadingTravel : boolean = false;
  expensesSubscription: Subscription;
  bookedSubscription: Subscription;
  travelSubscription: Subscription;
  companySubcription: Subscription;
  subcriptions: Subscription[] = [];
  loading = false;
  navActive = 1;
  module = "";
  isEmployee: boolean;
  companyUuid: string;
  isCompanyBookeds: boolean;
  all: any[] = [];
  params: any = {};
  authorizations: any[] = [];
  requests: any[] = [];
  isDownloading: boolean = false;
  travelsLoaded: boolean = false;

  showBookeds = true;
  showAuths = false;
  showRequests = false;
  groupRestrictionUuid: string;
  nextTravelsChecked;
  specialAction: string;
  routeSubscription: Subscription;
  anyKeyHasData: boolean = false;
  fromReservation: boolean = false;
  allUuids: any = {
    bookingUuids: [],
    customBookingUuids: [],
    flightUuids: [],
    trainUuids: [],
    carsUuids: [],
    expenseUuids: []
  };
  travelTitlesLoaded: boolean = false;
  allCompanyUsers: any[];

  constructor(
    private store: Store<AppState>,
    private bookedService: BookedService,
    private flightService: FlightService,
    private trainService: TrainService,
    private ngxToastrService: NgxToastrService,
    private formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    private expensesService: ExpensesService,
    private userService: UserService,
    private travelService: TravelService,
    private route: ActivatedRoute,
    private mediaService: MediaService,
    private companyService: CompanyService,
    private carService: CarService,
    private router: Router,
    private excelService: ExcelService,
    private bookService: BookService,
    public utilsService: UtilsService,
    private dialog: MatDialog,
    private requestService: RequestService,
    private authService: AuthService,
    private _location: Location,
    private platformLocation: PlatformLocation
  ) {
    this.utilsService.allLoaded = false;
    this.params["type"] = "all";
    this.params["status"] = "all";
    this.paginationConf = {
      page: 1,
      pageSize: 9,
      collectionSize: 120,
    };
    this.isCompanyBookeds =
      this.route.snapshot.routeConfig.path === "bookings" ||
      this.route.snapshot.routeConfig.path === ":id/bookings";

    this.paginationConf.collectionSize = this.all.length;
    this.store
      .select("auth")
      .pipe(take(1))
      .subscribe((auth) => {
        this.isAdmin = auth.user
          ? auth.user?.type?.toUpperCase() !== "USER"
            ? true
            : false
          : this.authService.isAdmin();
        this.isEmployee = auth.user
          ? auth.user?.type?.toUpperCase() === "EMPLOYEE"
            ? true
            : false
          : this.authService.isEmployee();

        this.userUuid =
          (this.isAdmin || this.isEmployee) &&
          this.route.snapshot.paramMap.get("uuid")
            ? this.route.snapshot.paramMap.get("uuid")
            : auth.user?.uuid;
        if (!this.userUuid) {
          this.utilsService.getLocalStorageItem("userUuid");
        }
        this.companyUuid = this.route.snapshot.paramMap.has("id")
          ? this.route.snapshot.paramMap.get("id")
          : auth.user
          ? auth.user["companyUuid"]
          : null;
        this.utilsService.storeParamInLocalStorage({
          title: "userUuid",
          value: this.userUuid,
        });
        this.user = auth.user;
        if (!this.isEmployee && !this.isAdmin) {
          this.companyUuid = this.user?.companyUuid;
          this.userService
            .getGroupUser(this.userUuid)
            .pipe(take(1))
            .subscribe((group) => {
              this.request = true;
              this.canBook =
                group.filters.requestBooked ||
                group.filters.requestTrain ||
                group.filters.requestFlight;
              this.groupRestrictionUuid = group.uuid;
              this.loadData();
            });
        } else {
          if (this.userUuid != null && !this.isCompanyBookeds) {
            this.subcriptions.push(
              this.userService.getUser(this.userUuid).subscribe((user) => {
                this.companyUuid = user.companyUuid;
                this.groupRestrictionUuid = user.groupRestrictionUuid;
                this.loadData();
              })
            );
          } else {
              const cachedBookeds = this.bookService.getAllData();
              if(cachedBookeds && Object.keys(cachedBookeds).length > 0){
                this.bookeds = this.bookService.getData('bookeds') || [];
                this.flights = this.bookService.getData('flights') || [];
                this.cars =  this.bookService.getData('cars') || [];
                this.expenses = this.bookService.getData('expenses') || [];
                this.trains = this.bookService.getData('trains') || [];
                this.companyImage = this.bookService.getData('companyImg');
                this.travels = this.bookService.getData('travels') || [];
                this.all = [];
                (this.bookeds && this.bookeds.length > 0) ?? this.all.push(...this.bookeds);
                (this.flights && this.flights.length > 0) ?? this.all.push(...this.flights);
                (this.trains && this.trains.length > 0) ?? this.all.push(...this.trains);
                (this.cars && this.cars.length > 0) ?? this.all.push(...this.cars);
                (this.expenses && this.expenses.length > 0) ?? this.all.push(...this.expenses);
                this.all = this.utilsService.sortArrayByDate(this.all);
                this.utilsService.allLoaded = true
              }else{
                this.loadData();
              }
          }
        }
      });

    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras.state) {
      this.fromReservation = navigation.extras.state.fromReservation || false;
    }
    this.module =
      this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation().extras &&
      this.router.getCurrentNavigation().extras.state
        ? this.router.getCurrentNavigation().extras.state.module
        : this.utilsService.getLocalStorageItem("module")
        ? this.utilsService.getLocalStorageItem("module").value
        : "Vyoo-All-Bookings";
    this.utilsService.storeParamInLocalStorage({
      title: "module",
      value: this.module,
    });
    if (this.module === "travels") {
      this.module = this.isAdmin ? "travels-admin" : "travels-user";
    }
    if(this.user.accessType.toLowerCase() === "user"){
      this.bookService.clearCache();
    }
  }

  ngOnInit() {
    if(this.isCompanyBookeds){
      this.companyService.getCompanyUsers(this.companyUuid).subscribe(resp => {
        this.allCompanyUsers = resp.map(user => user.uuid);
      }, err => {})
    }
    this.routeSubscription = this.route.queryParams.subscribe(params => {
      if (params['specialAction']) {
        this.specialAction = params['specialAction'];
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { },
          queryParamsHandling: 'merge',
        });
      }})
      if (this.fromReservation) {
        this.platformLocation.onPopState(() => {
          this.utilsService.setBackNavigation(true);
          this.router.navigate(['/flight']);
        });
      }
    const cachedData = this.bookService.getAllData();
    if (cachedData) {
      this.anyKeyHasData = Object.keys(cachedData).some(key => {
        const data = cachedData[key];
        return data && Object.keys(data).length > 0;
      });
    }
    
    if (!this.anyKeyHasData) {
      this.all = [];
    } 
    
    this.currentTab();
    this.statusOptions = [
      { value: "active", text: "common.confirmed" },
      { value: "cancelled", text: "common.canceled" },
    ];
    // this.loadRequest();

    this.store
      .select("myBookeds")
      .subscribe((myBookedsState: MyBookedsState) => {
        this.paginationConf.page = myBookedsState.paginationConfig.page;
      });

    this.form = this.formBuilder.group({
      modelHotel: "",
      modelDestiny: "",
      statusOptions: "active",
    });

    this.formTravel = this.formBuilder.group({
      title: "",
      total: 0,
    });

    this.bookService.reload.subscribe(() => {
      this.utilsService.allLoaded = false;
      switch (this.navActive) {
        case 1:
          this.allUuids = {
            bookingUuids: [],
            customBookingUuids: [],
            flightUuids: [],
            trainUuids: [],
            carsUuids: [],
            expenseUuids: [],
          };
          this.bookeds = undefined;
          this.flights = undefined;
          this.trains = undefined;
          this.cars = undefined;
          this.expenses = undefined;
          this.onloadAllBookings();
          break;
        case 2:
          this.allUuids.bookingUuids = [];
          this.allUuids.customBookingUuids = [];
          this.bookeds = undefined;
          this.loadBookeds();
          this.loadTravels();
          break;
        case 3:
          this.allUuids.flightUuids = [];
          this.flights = undefined;
          this.loadFlights();
          this.loadTravels();
          break;
        case 4:
          this.allUuids.trainUuids = [];
          this.trains = undefined;
          this.loadTrains();
          this.loadTravels();
          break;
        case 5:
          this.allUuids.carsUuids = [];
          this.cars = undefined;
          this.loadCars();
          this.loadTravels();
          break;
        case 6:
          this.allUuids.expenseUuids = [];
          this.expenses = undefined;
          this.loadExpenses();
          this.loadTravels();
          break;
        case 7:
        case 8:
          this.loadTravels();
          break;
        default:
          break;
      }
    });
  }

  loadData() {
    this.loading = true;
    this.loadBookeds();
    this.loadFlights();
    this.loadTrains();
    this.loadExpenses();
    this.loadTravels();
    this.loadCompanyImg();
    this.loadCars();
    if (this.isEmployee || this.isAdmin) {
      // this.loadAuthorizations();
      this.loadRequests();
    }
  }

  loadAuthorizations() {
    if (this.isEmployee || this.isAdmin) {
      delete this.params["companyUuid"];
      if (this.companyUuid !== null) {
        this.params["companyUuid"] = this.companyUuid;
      }
    } else {
      if (this.companyUuid) {
        this.params["companyUuid"] = this.companyUuid;
      } else {
        this.params["companyUuid"] = this.user.companyUuid;
      }
    }
    if (this.route.snapshot.paramMap.has("agencyUuid")) {
      delete this.params["companyUuid"];
      this.params["agencyUuid"] =
        this.route.snapshot.paramMap.get("agencyUuid");
    }
    if (this.params["status"] === "all") {
      delete this.params["status"];
    }
    if (this.params["type"] === "all") {
      delete this.params["type"];
    }
    if (this.userUuid != null && this.isAdmin === false) {
      this.params["userUuid"] = this.userUuid;
    }
    this.subcriptions.push(
      this.bookedService.getAuthorization(this.params).subscribe((result) => {
        this.authorizations = result;
        this.loading = false;
        // if (!this.authorizations.some(authorization => authorization.status === "request")) {
        //   this.utilsService.haveRequest = false;
        // }
      })
    );
  }

  loadTravels() {
    this.isLoadingTravel = true;
    const params = {};
    if (!this.isCompanyBookeds) {
      params["userUuid"] = this.userUuid;
      params["companyUuid"] = this.companyUuid;
    } else {
      params["companyUuid"] = this.companyUuid;
    }
    this.showBookeds = false;
    this.travelSubscription = this.travelService
      .getTravelsWithParams(params)
      .subscribe(
        (travels: any) => {
          this.travels = travels.docs;
          this.travelsLoaded = true;
          this.travelPagination = travels;
          if (this.anyKeyHasData) {
            this.updateCachedData('travels', this.travels);
          }
          delete this.travelPagination["docs"];
          if(this.travelTitlesLoaded){
            this.loading = false;
          }
          this.showBookeds = true;
          this.isLoadingTravel = false;
        },
        (err) => {
          this.loading = false;
          this.showBookeds = true;
          this.isLoadingTravel = false;
          this.travelsLoaded = false;
          this.translate
            .get("my-bookeds.user.error-get-booked")
            .subscribe((result) => {
              this.ngxToastrService.typeInfo(result, err.error.error);
            });
        }
      );
  }

  loadRequests() {
    const param = {};
    if (this.isAdmin || this.isEmployee) {
      param["companyUuid"] = this.companyUuid;
    }
    if (this.userUuid && this.userUuid !== null && !this.isCompanyBookeds) {
      param["userUuid"] = this.userUuid;
    }
    this.subcriptions.push(
      this.requestService.getRequests(param).subscribe(async (result: any) => {
        this.requests = await result;
        if(this.travelTitlesLoaded){
          this.loading = false;
        }
        // this.totalRequests = this.requests.filter((doc) => doc.status == "active").length;
      })
    );
  }

  loadExpenses() {
    this.showBookeds = false;
    this.loading = true;
    this.nextTravelsChecked = false
    const getExpenses = this.isCompanyBookeds
      ? this.expensesService.getCompanyExpenses(this.companyUuid)
      : this.expensesService.getUserExpenses(this.userUuid);
    this.expensesSubscription = getExpenses.subscribe(
      (expenses: any[]) => {
        this.showBookeds = true;
        const expensesList = Array.isArray(expenses) ? expenses : expenses["docs"];
        this.expenses = expenses["docs"]
          ? expenses["docs"].reverse()
          : expenses.reverse();
        expensesList.forEach(expense => {
          this.allUuids.expenseUuids.push(expense.uuid);
        });
        this.utilsService.allLoaded =
          this.bookeds !== undefined &&
          this.flights !== undefined &&
          this.trains !== undefined &&
          this.cars !== undefined &&
          this.expenses !== undefined;
            
        if (this.utilsService.allLoaded) {
          this.all = [];
          this.all.push(...this.bookeds);
          this.all.push(...this.flights);
          this.all.push(...this.trains);
          this.all.push(...this.cars);
          this.all.push(...this.expenses);
          this.all = this.utilsService.sortArrayByDate(this.all);
          this.loadTravelTitles();
        }
        if (this.anyKeyHasData) {
          this.updateCachedData('expenses', this.expenses);
        }

      },
      (err) => {
        this.showBookeds = true;
        this.loading = false;
        this.translate
          .get("my-bookeds.user.error-get-booked")
          .subscribe((result) => {
            this.ngxToastrService.typeInfo(result, err.error.error);
          });
      }
    );
  }

  loadBookeds() {
    this.showBookeds = false;
    this.loading = true;
    this.nextTravelsChecked = false
    const getBookeds = this.isCompanyBookeds
      ? this.bookedService.getBookedsByCompany(this.companyUuid)
      : this.isAdmin
      ? this.bookedService.getBookedsByUser(this.userUuid)
      : this.bookedService.getMyBookeds();
    this.bookedSubscription = getBookeds.subscribe(
      (bookeds: Booked[]) => {
        this.showBookeds = true;
        if (bookeds) {
          this.bookeds = bookeds.reverse();
          bookeds.forEach(booking => {
            if (booking.hotel) {
              this.allUuids.bookingUuids.push(booking.uuid);
            } else {
              this.allUuids.customBookingUuids.push(booking.uuid);
            }
          });
          this.utilsService.allLoaded =
            this.bookeds !== undefined &&
            this.flights !== undefined &&
            this.trains !== undefined &&
            this.cars !== undefined &&
            this.expenses !== undefined;
                
          if (this.utilsService.allLoaded) {
            this.all = [];
            this.all.push(...this.bookeds);
            this.all.push(...this.flights);
            this.all.push(...this.trains);
            this.all.push(...this.cars);
            this.all.push(...this.expenses);
            this.all = this.utilsService.sortArrayByDate(this.all);
            this.loadTravelTitles();
          }
          if (this.anyKeyHasData) {
            this.updateCachedData('bookeds', this.bookeds);
          }
        }
      },
      (err) => {
        this.showBookeds = true;
        this.loading = false;
        this.translate
          .get("my-bookeds.user.error-get-booked")
          .subscribe((result) => {
            this.ngxToastrService.typeInfo(result, err.error.error);
          });
        this.loading = false;
      }
    );
  }

  loadFlights() {
    this.showBookeds = false;
    this.loading = true
    this.nextTravelsChecked = false
    const getFlights = this.isCompanyBookeds
      ? this.companyService.getFlights(this.companyUuid)
      : this.isAdmin
      ? this.userService.getFlights(this.userUuid)
      : this.flightService.getUserFlight();
    getFlights.subscribe(
      (flights: Flight[]) => {
        this.showBookeds = true;
        this.flights = flights.reverse();
        flights.forEach(flight => {
          this.allUuids.flightUuids.push(flight.uuid);
        });
        this.utilsService.allLoaded =
          this.bookeds !== undefined &&
          this.flights !== undefined &&
          this.trains !== undefined &&
          this.cars !== undefined &&
          this.expenses !== undefined;

        
        if (this.utilsService.allLoaded) {
          this.all = [];
          this.all.push(...this.bookeds);
          this.all.push(...this.flights);
          this.all.push(...this.trains);
          this.all.push(...this.cars);
          this.all.push(...this.expenses);
          this.all = this.utilsService.sortArrayByDate(this.all);
          this.loadTravelTitles();
        }
        if (this.anyKeyHasData) {
          this.updateCachedData('flights', this.flights);
        }

      },
      (err) => {
        this.translate
          .get("my-bookeds.user.error-get-flights")
          .subscribe((result) => {
            this.loading = false;
            this.showBookeds = true;
            this.ngxToastrService.typeInfo(result, err.error.error);
          });
      }
    );
  }

  loadTrains() {
    this.showBookeds = false;
    this.loading = true
    this.nextTravelsChecked = false
    const getTrains = this.isCompanyBookeds
      ? this.companyService.getTrains(this.companyUuid)
      : this.isAdmin
      ? this.userService.getTrains(this.userUuid)
      : this.trainService.getUserTrain();
    getTrains.subscribe(
      (trains: Train[]) => {
        this.showBookeds = true;
        this.trains = trains.reverse();
        trains.forEach(train => {
          this.allUuids.trainUuids.push(train.uuid);
        });
        this.utilsService.allLoaded =
          this.bookeds !== undefined &&
          this.flights !== undefined &&
          this.trains !== undefined &&
          this.cars !== undefined &&
          this.expenses !== undefined;
          
        if (this.utilsService.allLoaded) {
          this.all = [];
          this.all.push(...this.bookeds);
          this.all.push(...this.flights);
          this.all.push(...this.trains);
          this.all.push(...this.cars);
          this.all.push(...this.expenses);
          this.all = this.utilsService.sortArrayByDate(this.all);
          this.loadTravelTitles();
        }
        if (this.anyKeyHasData) {
          this.updateCachedData('trains', this.trains);
        }
      },
      (err) => {
        this.showBookeds = true;
        this.loading = false;
        this.translate
          .get("my-bookeds.user.error-get-trains")
          .subscribe((result) => {
            this.ngxToastrService.typeInfo(result, err.error.error);
          });
      }
    );
  }

  loadCars() {
    this.showBookeds = false;
    this.loading = true
    this.nextTravelsChecked = false
    const getCars = this.isCompanyBookeds
      ? this.carService.getCompanyCars(this.companyUuid)
      : this.carService.getUserCars(this.userUuid);
    this.subcriptions.push(
      getCars.subscribe(
        (cars) => {
          this.showBookeds = true;
          const carsList = Array.isArray(cars) ? cars : cars["docs"];
          this.cars = cars["docs"] ? cars["docs"].reverse() : cars.reverse();
          carsList.forEach(car => {
            this.allUuids.carsUuids.push(car.uuid);
          });
          this.utilsService.allLoaded =
            this.bookeds !== undefined &&
            this.flights !== undefined &&
            this.trains !== undefined &&
            this.cars !== undefined &&
            this.expenses !== undefined;
            
    
          if (this.utilsService.allLoaded) {
            this.all = [];
            this.all.push(...this.bookeds);
            this.all.push(...this.flights);
            this.all.push(...this.trains);
            this.all.push(...this.cars);
            this.all.push(...this.expenses);
            this.all = this.utilsService.sortArrayByDate(this.all);
            this.loadTravelTitles();
          }
          if (this.anyKeyHasData) {
            this.updateCachedData('cars', this.cars);
          }

        },
        (err) => {
          this.showBookeds = true;
          this.loading = false;
          this.translate
            .get("my-bookeds.user.error-get-cars")
            .subscribe((result) => {
              this.ngxToastrService.typeInfo(result, err.error.error);
            });
        }
      )
    );
  }

  loadCompanyImg() {
    this.subcriptions.push(
      this.store.select("auth").subscribe((u) => {
        this.companySubcription = this.companyService
          .getCompanyImage(this.companyUuid, this.userUuid)
          .subscribe((img: string) => {
            this.mediaService.getMedia(img).subscribe((s) => {
              const reader = new FileReader();
              reader.readAsDataURL(s);
              let base64data;
              reader.onloadend = () => {
                base64data = reader.result;
                this.companyImage = base64data;
                if (this.anyKeyHasData) {
                  this.updateCachedData('companyImg', this.companyImage);
                }
              };
            });
          });
      })
    );
  }

  addTravel() {
    const modalRef = this.dialog.open(DialogAssignProjectComponent, {
      data: {
        create: true,
        isOnlyCreate: true,
        userUuid: this.userUuid,
        companyUuid: this.companyUuid,
      },
      panelClass: "new-dialog",
    });
    modalRef.afterClosed().subscribe((result) => {
      if (result != null) {
        if (this.userUuid !== null) {
          result.travelData["userUuid"] = this.userUuid;
        }

        if (this.companyUuid !== null) {
          result.travelData["companyUuid"] = this.companyUuid;
        }
        if (
          result.travelData["endDate"] === "" &&
          result.travelData["initDate"] === ""
        ) {
          delete result.travelData["endDate"];
          delete result.travelData["initDate"];
        }
        this.subcriptions.push(
          this.travelService.postTravel(result.travelData).subscribe(
            () => {
              this.subcriptions.push(
                this.translate
                  .get(`project.my-projects.created`)
                  .subscribe((resp) => {
                    this.ngxToastrService.typeSuccess(null, resp);
                    setTimeout(() => {
                      if (this.nextTravelsChecked === false) {
                        this.loadTravels();
                      }
                    }, 3000);
                  })
              );
            },
            (err) => {
              this.subcriptions.push(
                this.translate.get("common.error").subscribe((resp) => {
                  this.ngxToastrService.typeInfo(resp, err.error.message);
                })
              );
            }
          )
        );
      }
    });
  }

  currentTab() {
    switch (this.module) {
      case "all":
        this.navActive = 1;
        break;
      case "hotels":
        this.navActive = 2;
        break;
      case "flights":
        this.navActive = 3;
        break;
      case "trains":
        this.navActive = 4;
        break;
      case "cars":
        this.navActive = 5;
        break;
      case "expenses":
        this.navActive = 6;
        break;
      case "travels-user":
        this.navActive = 7;
        break;
      case "travels-admin":
        this.navActive = 8;
        break;
      default:
        this.utilsService.bookeds = this.utilsService.sortArrayByDate(this.all);
        break;
    }
  }

  exportExcel(service: string) {
    this.isDownloading = true;
    let bookingsExcel = this.bookeds;
    let flightsExcel = this.flights;
    let trainsExcel = this.trains;
    let carsExcel = this.cars;
    let expensesExcel = this.expenses;
    let travelsExcel = this.travels;
    const fromDate = this.utilsService.userBookingsFilters.fromDate;
    const toDate = this.utilsService.userBookingsFilters.toDate;
    const createdFromDate =
      this.utilsService.userBookingsFilters.createdFromDate;
    const createdToDate = this.utilsService.userBookingsFilters.createdToDate;
    if (fromDate && toDate) {
      bookingsExcel = bookingsExcel.filter((element) => {
        const checkInDate = moment(element.checkIn);
        const checkOutDate = moment(element.checkOut);
        const fromDateParsed = moment(fromDate);
        const toDateParsed = moment(toDate);

        return (
          checkInDate.isSameOrAfter(fromDateParsed) &&
          checkOutDate.isSameOrBefore(toDateParsed)
        );
      });
      flightsExcel = flightsExcel.filter((element) => {
        const departDate = moment(element.departDate);
        const returnDate = element.returnDate ? moment(element.returnDate) : null;
        const fromDateParsed = moment(fromDate);
        const toDateParsed = moment(toDate);
      
        if (returnDate) {
          return (
            departDate.isSameOrAfter(fromDateParsed) &&
            returnDate.isSameOrBefore(toDateParsed)
          );
        } else {
          return departDate.isSameOrAfter(fromDateParsed);
        }
      });
      
      trainsExcel = trainsExcel.filter((element) => {
        const departDate = moment(element.departDate);
        const returnDate = element.returnDate ? moment(element.returnDate) : null;
        const fromDateParsed = moment(fromDate);
        const toDateParsed = moment(toDate);
      
        if (returnDate) {
          return (
            departDate.isSameOrAfter(fromDateParsed) &&
            returnDate.isSameOrBefore(toDateParsed)
          );
        } else {
          return departDate.isSameOrAfter(fromDateParsed);
        }
      });
      
      carsExcel = carsExcel.filter((element) => {
        const departDate = moment(element.departDate);
        const returnDate = element.returnDate ? moment(element.returnDate) : null;
        const fromDateParsed = moment(fromDate);
        const toDateParsed = moment(toDate);
      
        if (returnDate) {
          return (
            departDate.isSameOrAfter(fromDateParsed) &&
            returnDate.isSameOrBefore(toDateParsed)
          );
        } else {
          return departDate.isSameOrAfter(fromDateParsed);
        }
      });
      
      expensesExcel = expensesExcel.filter((element) => {
        const beginDate = moment(element.beginDate);
        const endDate = element.endDate ? moment(element.endDate) : null;
        const fromDateParsed = moment(fromDate);
        const toDateParsed = moment(toDate);
      
        if (endDate) {
          return (
            beginDate.isSameOrAfter(fromDateParsed) &&
            endDate.isSameOrBefore(toDateParsed)
          );
        } else if (beginDate) {
          return beginDate.isSameOrAfter(fromDateParsed);
        } else {
          return true;
        }
      });
      
      travelsExcel = travelsExcel.filter((element) => {
        const initDate = moment(element.initDate);
        const endDate = element.endDate ? moment(element.endDate) : null;
        const fromDateParsed = moment(fromDate);
        const toDateParsed = moment(toDate);
      
        if (endDate) {
          return (
            initDate.isSameOrAfter(fromDateParsed) &&
            endDate.isSameOrBefore(toDateParsed)
          );
        } else {
          return initDate.isSameOrAfter(fromDateParsed);
        }
      });
    }

    if (createdFromDate && createdToDate) {
      const filterByDate = (element) =>
        moment(new Date(element.createdAt)).isBetween(
          moment(new Date(createdFromDate)),
          moment(new Date(createdToDate)),
          "day",
          "[]"
        );
    
      bookingsExcel = bookingsExcel.filter(filterByDate);
      flightsExcel = flightsExcel.filter(filterByDate);
      trainsExcel = trainsExcel.filter(filterByDate);
      carsExcel = carsExcel.filter(filterByDate);
      expensesExcel = expensesExcel.filter(filterByDate);
      travelsExcel = travelsExcel.filter(filterByDate);
    }
    switch (service) {
      case "all":
        const totalBookings = {
          bookings: bookingsExcel,
          flights: flightsExcel,
          trains: trainsExcel,
          expenses: expensesExcel,
        };
        const getFullReservations = this.isCompanyBookeds
          ? this.companyService.getCompanyBookeds(this.companyUuid)
          : this.userService.getUserBookeds(this.userUuid);
        this.subcriptions.push(
          getFullReservations.subscribe((res) => {
            if (createdFromDate && createdToDate) {
              const filterByDate = (element) =>
                moment(new Date(element.createdAt)).isBetween(
                  moment(new Date(createdFromDate)),
                  moment(new Date(createdToDate)),
                  "day",
                  "[]"
                );
        
              Object.keys(res).forEach((key) => {
                res[key] = res[key].filter(filterByDate);
              });
            }

            if (fromDate && toDate) {
              const filterByDateRange = (element, fromDateProp, toDateProp) => {
                const fromDateValue = moment(element[fromDateProp]).startOf('day');
                const toDateValue = toDateProp ? moment(element[toDateProp]).startOf('day') : null;
                const fromDateParsed = moment(fromDate).startOf('day');
                const toDateParsed = moment(toDate).startOf('day');
              
                if (!toDateValue || toDateValue == null) {
                  return fromDateValue.isBetween(fromDateParsed, toDateParsed, null, '[]');
                }
              
                return (
                  fromDateValue.isSameOrAfter(fromDateParsed) &&
                  toDateValue.isSameOrBefore(toDateParsed)
                );
              };

            Object.keys(res).forEach((key) => {
              res[key] = res[key].filter((element) => {
                if (
                  ["checkIn", "checkOut", "departDate", "returnDate", "initDate", "beginDate"].some(
                    (prop) => element.hasOwnProperty(prop)
                  )
                ) {
                  let fromDateProp, toDateProp;

                  if (element.hasOwnProperty("checkIn")) {
                    fromDateProp = "checkIn";
                    toDateProp = "checkOut";
                  } else if (element.hasOwnProperty("departDate")) {
                    fromDateProp = "departDate";

                    if (element.hasOwnProperty("returnDate") && element["returnDate"] !== null){
                      toDateProp = "returnDate";
                    }else{
                      toDateProp = null;
                    }
                  } else if (element.hasOwnProperty("initDate")) {
                    fromDateProp = "initDate";
                    toDateProp = "endDate";
                  } else if (element.hasOwnProperty("beginDate")) {
                    fromDateProp = "beginDate";
                    toDateProp = "endDate";
                  } else {
                    return true;
                  }

                  return filterByDateRange(element, fromDateProp, toDateProp);
                } else {
                  return true;
                }
              });
            });
          }

            Object.keys(res).forEach((key) => {
              res[key].map((e, i) => {
                if (
                  !res[key][i]["hotel"] &&
                  !res[key][i]["destinationName"] &&
                  key !== "cars"
                ) {
                  const object = totalBookings[key].find(
                    (book) =>
                      book.apiReference === res[key][i].bookedApiReference
                  );
                  if (object) {
                    res[key][i]["destinationName"] = object.hotel
                      ? object.hotel.destinationName
                      : object.destinationName;
                  } else {
                    res[key][i]["destinationName"] = "-";
                  }
                }
              });
            });

            Object.keys(res).forEach((key) => {
              res[key] = this.associateTravelsWithBookings(res[key], travelsExcel, key);
            });
      
            if (
              this.module === "travels-admin" ||
              this.module === "travels-user"
            ) {
              this.module = "travels";
            }
            this.excelService.createBookingsExcel(
              this.module ? this.module : "Vyoo-Bookings",
              res
            );
            this.isDownloading = false;
          })
        );
        break;
      case "hotel":
        bookingsExcel = this.associateTravelsWithBookings(bookingsExcel, travelsExcel, 'hotel');
        this.excelService.createBookingsExcel("Vyoo-Hotel Report", {
          bookings: bookingsExcel,
        });
        this.isDownloading = false;
        break;
      case "flight":
        flightsExcel = this.associateTravelsWithBookings(flightsExcel, travelsExcel, 'flight');
        this.excelService.createBookingsExcel("Vyoo-Flight Report", {
          flights: flightsExcel,
        });
        this.isDownloading = false;
        break;
      case "train":
        trainsExcel = this.associateTravelsWithBookings(trainsExcel, travelsExcel, 'train');
        this.excelService.createBookingsExcel("Vyoo-Train Report", {
          trains: trainsExcel,
        });
        this.isDownloading = false;
        break;
      case "car":
        carsExcel = this.associateTravelsWithBookings(carsExcel, travelsExcel, 'car');
        this.excelService.createBookingsExcel("Vyoo-Car Report", {
          cars: carsExcel,
        });
        this.isDownloading = false;
        break;
      case "expense":
        expensesExcel = this.associateTravelsWithBookings(expensesExcel, travelsExcel, 'expense');
        this.excelService.createBookingsExcel("Vyoo-Expense Report", {
          expenses: expensesExcel,
        });
        this.isDownloading = false;
        break;
      case "travel":
        this.excelService.createProjectsExcel("Vyoo-Project Report", this.travels, this.isAdmin && this.isCompanyBookeds, this.userUuid);
        this.isDownloading = false;
        break;
      default:
        this.isDownloading = false;
        break;
    }
  }

  associateTravelsWithBookings(bookingsExcel, travelsExcel, type): any[] {
  
    const travelUuidsMap = {
      'hotel': ['bookingUuids', 'bookingFromModule', 'customBookingUuids'],
      'bookings': ['bookingUuids', 'bookingFromModule', 'customBookingUuids'],
      'flight': ['flightUuids'],
      'flights': ['flightUuids'],
      'train': ['trainUuids'],
      'trains': ['trainUuids'],
      'car': ['carUuids'],
      'cars': ['carUuids'],
      'expense': ['expenseUuids'],
      'expenses': ['expenseUuids']
    };
  
    const uuidsToCheck = travelUuidsMap[type] || [];
    const findTravelTitle = (booked, travel) => {
      for (let uuidType of uuidsToCheck) {
        if (travel[uuidType] && travel[uuidType].some(uuid => uuid.uuid ? uuid.uuid === booked.uuid : uuid === booked.uuid)) {
          booked.travelTitle = travel.title;
          return true;
        }
      }
      return false;
    };
  
    bookingsExcel.forEach(booked => {
      travelsExcel.some(travel => findTravelTitle(booked, travel));
    });
    return bookingsExcel;
  }

  changeType(event) {
    switch (event.tabIndex) {
      case 1:
        this.utilsService.bookeds = this.utilsService.sortArrayByDate(this.all);
        break;
      case 2:
        this.utilsService.bookeds = this.utilsService.sortArrayByDate(
          this.bookeds
        );
        break;
      case 3:
        this.utilsService.bookeds = this.utilsService.sortArrayByDate(
          this.flights
        );
        break;
      case 4:
        this.utilsService.bookeds = this.utilsService.sortArrayByDate(
          this.trains
        );
        break;
      case 5:
        this.utilsService.bookeds = this.utilsService.sortArrayByDate(
          this.cars
        );
        break;
      case 6:
        this.utilsService.bookeds = this.utilsService.sortArrayByDate(
          this.expenses
        );
        break;
      case 7:
        this.loadTravels();
        break;
      default:
        this.utilsService.bookeds = this.utilsService.sortArrayByDate(this.all);
        break;
    }
  }

  changeComponent(event) {
    switch (event.value) {
      case "authorization":
        this.showBookeds = false;
        this.showAuths = true;
        this.showRequests = false;

        break;

      case "booking":
        this.showBookeds = true;
        this.showAuths = false;
        this.showRequests = false;
        break;

      case "request":
        this.showBookeds = false;
        this.showAuths = false;
        this.showRequests = true;
        break;

      default:
        this.showBookeds = true;
        this.showAuths = false;
        this.showRequests = false;
        break;
    }
  }

  reloadType(event) {
    switch (event) {
      case "flight":
        this.loadFlights();
        break;
      case "train":
        this.loadTrains();
        break;
      case "car":
        this.loadCars();
        break;
      case "expense":
        this.loadExpenses();
        break;
      case "hotel":
      case "custom-booking":
      case "bookingApiReference":
        this.loadBookeds();
        break;
      default:
        break;
    }
  }

  onloadAllBookings() {
    this.loadBookeds();
    this.loadFlights();
    this.loadTrains();
    this.loadExpenses();
    this.loadCars();
    this.loadTravels();
  }

  loadTravelTitles() {
    const params = {
      companyUuid: this.companyUuid,
      userUuids: this.allCompanyUsers ? this.allCompanyUsers : [this.userUuid]
    }

    this.travelService.getTravelsByListBooking(params).subscribe(
      (response: any) => {
        this.assignTitlesToReservations(response);
        if(this.travelsLoaded){
          this.loading = false;
        }
        this.travelTitlesLoaded = true;
      },
      (err) => {
        this.loading = false;
        this.travelTitlesLoaded = true;
      }
    );
  }

  assignTitlesToReservations(projectTitleByUuidWrapper: any) {
    const projectTitleByUuid = projectTitleByUuidWrapper.projectTitleByUuid;
    this.bookeds.forEach(booking => {
      const travelTitle = projectTitleByUuid[booking.uuid];
      if (travelTitle) {
        booking.travelTitle = travelTitle;
      }
    });
  
    this.flights.forEach(flight => {
      const travelTitle = projectTitleByUuid[flight.uuid];
      if (travelTitle) {
        flight.travelTitle = travelTitle;
      }
    });
  
    this.cars.forEach(car => {
      const travelTitle = projectTitleByUuid[car.uuid];
      if (travelTitle) {
        car.travelTitle = travelTitle;
      }
    });
  
    this.expenses.forEach(expense => {
      const travelTitle = projectTitleByUuid[expense.uuid];
      if (travelTitle) {
        expense.travelTitle = travelTitle;
      }
    });
  
    this.trains.forEach(train => {
      const travelTitle = projectTitleByUuid[train.uuid];
      if (travelTitle) {
        train.travelTitle = travelTitle;
      }
    });
  }

  updateCachedData(key: string, data: any) {
    this.bookService.setData(key, data);
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.bookedSubscription) {
      this.bookedSubscription.unsubscribe();
    }
    if (this.expensesSubscription) {
      this.expensesSubscription.unsubscribe();
    }

    if (this.travelSubscription) {
      this.travelSubscription.unsubscribe();
    }
    if (this.subcriptions) {
      this.subcriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.utilsService.removeItemFromLocalStorage("module");
    this.utilsService.userBookingsFilters.fromDate = null;
    this.utilsService.userBookingsFilters.toDate = null;
    this.utilsService.userBookingsFilters.createdFromDate = null;
    this.utilsService.userBookingsFilters.createdToDate = null;
    this.bookService.clearCache();
  }

  onSelectChangeToGrandparent(selectedValue) {
    this.nextTravelsChecked = selectedValue.checked;
  }

  goBack() {
    this._location.back();
  }
}
