<div class="row mt-2">
  <div class="col-12">
    <!-- TITULO -->
    <div class="row">
      <div class="col-12">
        <h3 class="heading-5">
          {{ "train.form-train.request-train-title" | translate }}
        </h3>
      </div>
    </div>
    <app-user-detail
      *ngIf="(isAdmin && canUseTRM) || isEmployee"
      [userUuid]="userToBook"
      (navigateEvent)="goBack()"
    ></app-user-detail>

    <!-- FORMULARIO -->

    <!--Buscador de trenes-->
    <app-train-search
      [searchHasFailed]="searchHasFailed"
      (emitSelectedTravelersTrain)="handleSelectedTravelers($event)"
      (searchSubmitEmitter)="handleSearchSubmit($event)"
    ></app-train-search>
    <!-- Resultados
    <div id="resultList">
      <div
        id="listHeader"
        *ngIf="trainsGoingUI.length > 0 || trainsReturningUI.length > 0"
      >
        <h5 class="bold">{{ "train.class.premium" | translate }}</h5>
        <h5 class="bold">{{ "train.class.choose" | translate }}</h5>
        <h5 class="bold">{{ "train.class.basic" | translate }}</h5>
      </div>
      <ng-container *ngIf="!return && trainsGoingUI.length > 0">
        <h4 class="bold">{{ "train.user-form.wayGo" | translate }}</h4>
        <ul id="list" *ngFor="let train of trainsGoingUI; let i = index">
          <div class="card">
            <li>
              <div>
                <img src="../../../../assets/img/trains/renfe.png" alt="" />
                <div id="departureTime">
                  <span>{{ train.departureTime }}</span>
                  <span>{{ train.travelDurationString }}</span>
                </div>
                <div class="icon">
                  <img src="assets/img/gallery/arrow-right.png" alt="" />
                </div>
                <div id="arrivalTime">
                  <span>{{ train.arrivalTime }}</span>
                  <span>{{ train.trainTypeDescription }}</span>
                </div>
              </div>
              <div>
                <div id="tourist" (click)="openTrainDialog(i, 'T')">
                  <span *ngIf="train.priceTourist.length > 0">{{
                    "train.prices.from" | translate
                  }}</span>
                  <span *ngIf="train.priceTourist.length > 0"
                    >{{ train.priceTourist[0] }} €</span
                  >
                  <span *ngIf="train.priceTourist.length === 0">{{
                    "train.prices.noAvailable" | translate
                  }}</span>
                </div>
                <div id="touristPlus" (click)="openTrainDialog(i, 'P')">
                  <span *ngIf="train.priceTouristPlus.length > 0">{{
                    "train.prices.from" | translate
                  }}</span>
                  <span *ngIf="train.priceTouristPlus.length > 0"
                    >{{ train.priceTouristPlus[0] }} €</span
                  >
                  <span *ngIf="train.priceTouristPlus.length === 0">{{
                    "train.prices.noAvailable" | translate
                  }}</span>
                </div>
                <div id="business" (click)="openTrainDialog(i, 'B')">
                  <span *ngIf="train.priceBusiness.length > 0">{{
                    "train.prices.from" | translate
                  }}</span>
                  <span *ngIf="train.priceBusiness.length > 0"
                    >{{ train.priceBusiness[0] }} €</span
                  >
                  <span *ngIf="train.priceBusiness.length === 0">{{
                    "train.prices.noAvailable" | translate
                  }}</span>
                </div>
              </div>
            </li>
          </div>
        </ul>
      </ng-container>
      <ng-container *ngIf="return && trainsReturningUI.length > 0">
        <h4 class="bold">{{ "train.user-form.wayRet" | translate }}</h4>
        <ul id="list" *ngFor="let train of trainsReturningUI; let i = index">
          <div class="card">
            <li>
              <div>
                <img src="../../../../assets/img/trains/renfe.png" alt="" />
                <div id="departureTime">
                  <span>{{ train.departureTime }}</span>
                  <span>{{ train.travelDurationString }}</span>
                </div>
                <div class="icon">
                  <img src="assets/img/gallery/arrow-right.png" alt="" />
                </div>
                <div id="arrivalTime">
                  <span>{{ train.arrivalTime }}</span>
                  <span>{{ train.trainTypeDescription }}</span>
                </div>
              </div>
              <div>
                <div id="tourist" (click)="openTrainDialog(i, 'T')">
                  <span *ngIf="train.priceTourist.length > 0">{{
                    "train.prices.from" | translate
                  }}</span>
                  <span *ngIf="train.priceTourist.length === 0">{{
                    "train.prices.noAvailable" | translate
                  }}</span>
                  <span *ngIf="train.priceTourist.length > 0"
                    >{{ train.priceTourist[0] }} €</span
                  >
                </div>
                <div id="touristPlus" (click)="openTrainDialog(i, 'P')">
                  <span *ngIf="train.priceTouristPlus.length > 0">{{
                    "train.prices.from" | translate
                  }}</span>
                  <span *ngIf="train.priceTouristPlus.length === 0">{{
                    "train.prices.noAvailable" | translate
                  }}</span>
                  <span *ngIf="train.priceTouristPlus.length > 0"
                    >{{ train.priceTouristPlus[0] }} €</span
                  >
                </div>
                <div id="business" (click)="openTrainDialog(i, 'B')">
                  <span *ngIf="train.priceBusiness.length > 0">{{
                    "train.prices.from" | translate
                  }}</span>
                  <span *ngIf="train.priceBusiness.length === 0">{{
                    "train.prices.noAvailable" | translate
                  }}</span>
                  <span *ngIf="train.priceBusiness.length > 0"
                    >{{ train.priceBusiness[0] }} €</span
                  >
                </div>
              </div>
            </li>
          </div>
        </ul>
      </ng-container>
    </div> -->
  </div>
</div>
