import { Component, OnDestroy, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { BookService, FlightService, NgxToastrService, UtilsService } from "@services";
import { FlightPending } from "@shared/interfaces/amadeus/pnr/flight-pending";
import { FlightPendingList } from "@shared/interfaces/amadeus/pnr/flight-pending-list";
import * as moment from "moment";
import { Subscription, forkJoin, switchMap } from "rxjs";
import swal from "sweetalert2";
import { PendingFlightModalComponent } from "./pending-flight-modal/pending-flight-modal.component";

@Component({
  selector: "app-flight-pending",
  templateUrl: "./flight-pending.component.html",
  styleUrls: ["./flight-pending.component.scss"],
})
export class FlightPendingComponent implements OnInit, OnDestroy {
  flightPendingList: FlightPendingList = {};
  pastFlightDontHaveChekinList: FlightPendingList = {};
  flightsToView: FlightPending[] = [];
  pastFlightsToView: FlightPending[] = [];
  subcriptions: Subscription[] = [];
  typeBooked = "Flight";
  loading = true;
  lowValue = 0;
  highValue = 10;
  currentDate: any;
  nextCurrentDate: any;
  nextFlight: boolean = false;
  filterSearch: String;
  bookStatus: String;
  constructor(
    private flightService: FlightService,
    private translate: TranslateService,
    private ngxToastrService: NgxToastrService,
    private router: Router,
    private modalService: NgbModal,
    public utilsService: UtilsService,
    private bookService: BookService
  ) {
    this.loading = true;
  }

  ngOnInit() {
    // this.subcriptions.push(
    //   this.flightService.getPastFlightDontHaveChekin().subscribe((list) => {
    //     console.log(list);

    //     this.pastFlightDontHaveChekinList = list;
    //     this.pastFlightDontHaveChekinList.departFlights.docs.forEach((d) => {
    //       if (d.status === 'active') {
    //         d['pastFlight'] = true;
    //         this.flightsToView.push(d);
    //       }
    //     });
    //     this.pastFlightDontHaveChekinList.endFlights.docs.forEach((d) => {
    //       if (d.status === 'active') {
    //         d['pastFlight'] = true;

    //         this.flightsToView.push(d);
    //       }
    //     });
    //     this.loading = false;
    //     console.log('pending', this.flightsToView);

    //   }),
    //   this.flightService.getFlightPendingList().subscribe((list) => {

    //     this.flightPendingList = list;
    //     this.flightPendingList.departFlights.docs.forEach((d) => {
    //       if (d.status === 'active') {
    //         d['pastFlight'] = false;
    //         this.flightsToView.push(d);
    //       }
    //     });
    //     this.flightPendingList.endFlights.docs.forEach((d) => {
    //       if (d.status === 'active') {
    //         d['pastFlight'] = false;
    //         this.flightsToView.push(d);
    //       }
    //     });
    //     console.log('all', this.flightsToView);

    //     this.loading = false;
    //   }),
    // );

    this.subcriptions.push(
      forkJoin([
        this.flightService.getPastFlightDontHaveChekin(),
        this.flightService.getFlightPendingList()
      ]).pipe(
        switchMap(([pastFlights, pendingFlights]) => {
          this.pastFlightDontHaveChekinList = pastFlights;
          this.flightPendingList = pendingFlights;
          this.loading = false;

          const flightsFromPast = [
            ...pastFlights.departFlights.docs.map(flight => ({ ...flight, pastFlight: true })),
            ...pastFlights.endFlights.docs.map(flight => ({ ...flight, pastFlight: true }))
          ];

          const flightsFromPending = [
            ...pendingFlights.departFlights.docs.map(flight => ({ ...flight, pastFlight: false })),
            ...pendingFlights.endFlights.docs.map(flight => ({ ...flight, pastFlight: false }))
          ];

          this.flightsToView = [...flightsFromPast, ...flightsFromPending];
          this.flightsToView = this.flightsToView.filter(d => d.status === 'active');
          this.flightsToView.sort((a, b) => (a.pastFlight === b.pastFlight) ? 0 : a.pastFlight ? -1 : 1);

          return this.flightsToView;
        })
      ).subscribe(result => {
      })
    );


    this.currentDate = new Date();
    this.currentDate.setDate(this.currentDate.getDate() + 1);
    this.currentDate = moment(this.currentDate).format('DD/MM/YYYY');

    this.nextCurrentDate = new Date();
    this.nextCurrentDate.setDate(this.nextCurrentDate.getDate() + 1);
    this.nextCurrentDate = moment(this.nextCurrentDate).format('DD/MM/YYYY');
    this.filterSearch = "";
    this.bookStatus = "";
  }

  hasPdf(booked: any) {
    if (
      booked.voucherFileUrl &&
      booked.voucherFileUrl !== "" &&
      booked.voucherFileUrl !== "/media/flight/undefined"
    ) {
      return true;
    } else {
      return false;
    }
  }

  onGetPdf(booked) {
    this.loading = true;
    if (this.hasPdf(booked)) {
      this.loading = false;
      this.bookService.onGetPDF(booked, 'flight');
    } else {
      this.loading = false;
      this.translate
        .get([
          "flight-train.no-bono",
        ])
        .subscribe((resp) => {
          this.ngxToastrService.typeError(
            resp["flight-train.no-bono"],
            resp[""]
          );
        });
    }

  }

  clearFilters() {
    this.filterSearch = "";
    this.bookStatus = "";
    this.flightPendingList.departFlights.docs.forEach((d) => {
      if (!this.flightsToView.includes(d) && d.status === 'active')
        this.flightsToView.push(d);
    });
    this.flightPendingList.endFlights.docs.forEach((d) => {
      if (!this.flightsToView.includes(d) && d.status === 'active')
        this.flightsToView.push(d);
    });
  }

  filterFlights() {
    this.flightsToView = [];

    if (!(this.filterSearch == "")) {
      this.flightPendingList.departFlights.docs.forEach((f) => {
        if (
          (f["pnr"]?.toLowerCase().includes(this.filterSearch?.toLowerCase()) ||
            f["from"]?.toLowerCase().includes(this.filterSearch?.toLowerCase()) ||
            f["to"]?.toLowerCase().includes(this.filterSearch?.toLowerCase()) ||
            f.company["name"]?.toLowerCase().includes(this.filterSearch?.toLowerCase()) ||
            f.user["name"]?.toLowerCase().includes(this.filterSearch?.toLowerCase()) ||
            f.user["lastname"]?.toLowerCase().includes(this.filterSearch?.toLowerCase())) && f['status'] === 'active'
          && !this.flightsToView.includes(f)
        ) {
          this.flightsToView.push(f);
        }
      });
      this.flightPendingList.endFlights.docs.forEach((f) => {
        if (
          (f["pnr"]?.toLowerCase().includes(this.filterSearch?.toLowerCase()) ||
            f["from"]?.toLowerCase().includes(this.filterSearch?.toLowerCase()) ||
            f["to"]?.toLowerCase().includes(this.filterSearch?.toLowerCase()) ||
            f.company["name"]?.toLowerCase().includes(this.filterSearch?.toLowerCase()) ||
            f.user["name"]?.toLowerCase().includes(this.filterSearch?.toLowerCase()) ||
            f.user["lastname"]?.toLowerCase().includes(this.filterSearch?.toLowerCase())) && f['status'] === 'active'
          && !this.flightsToView.includes(f)
        ) {
          this.flightsToView.push(f);
        }
      });
    } else if (
      this.filterSearch === "" ||
      this.filterSearch === "<empty string>"
    ) {
      this.flightPendingList.departFlights.docs.forEach((d) => {
        if (!this.flightsToView.includes(d) && d.status === 'active')
          this.flightsToView.push(d);
      });
      this.flightPendingList.endFlights.docs.forEach((d) => {
        if (!this.flightsToView.includes(d) && d.status === 'active')
          this.flightsToView.push(d);
      });
    }
  }

  public getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

  goBack() {
    if (this.flightPendingList.docs.length === 0) {
      this.router.navigateByUrl("/");
    } else {
      this.flightsToView = this.flightPendingList.docs;
    }
  }

  goCompanies() {
    this.router.navigate(["/companies"]);
  }

  checkDateCancel(dateCancel) {
    return moment(dateCancel).isAfter(moment());
  }

  onView(booking: any) {
    const modalRef = this.modalService.open(PendingFlightModalComponent, {
      size: "lg",
      centered: true,
    });
    modalRef.componentInstance.booking = booking;
    modalRef.componentInstance.type = this.typeBooked?.toLowerCase();
    modalRef.componentInstance.isUser = true;
    modalRef.componentInstance.flightUuid = booking.uuid ?? '';
    modalRef.componentInstance.sendInfo.subscribe(r => {
      if (r) {
        this.flightsToView = [];
        this.loading = true;
        this.nextFlight = false;
        this.ngOnInit();

      }
    })
  }

  onCancel(booked: any) {
    this.subcriptions.push(
      this.translate
        .get([
          `user.details.cancel-${this.typeBooked?.toLowerCase()}`,
          "user.details.action-no-undo",
          "common.confirm",
          "common.cancel",
        ])
        .subscribe((result) => {
          swal
            .fire({
              title:
                result[`user.details.cancel-${this.typeBooked?.toLowerCase()}`],
              text: result["user.details.action-no-undo"],
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#0CC27E",
              cancelButtonColor: "#FF586B",
              confirmButtonText: result["common.confirm"],
              cancelButtonText: result["common.cancel"],
              customClass: {
                confirmButton: "btn btn-success btn-raised",
                cancelButton: "btn btn-danger btn-raised mr-5",
              },
              buttonsStyling: false,
            })
            .then((action) => {
              if (action.value) {
                const cancelBooked =
                  this.typeBooked === "Flight"
                    ? this.flightService.cancelFlight(booked.uuid)
                    : null;
                cancelBooked.subscribe(
                  () => {
                    this.translate
                      .get(
                        `user.details.${this.typeBooked?.toLowerCase()}-canceled`
                      )
                      .subscribe((resp) => {
                        this.ngxToastrService.typeSuccess(null, resp);
                        this.flightsToView = [];
                        this.loading = true;
                        this.ngOnInit();
                      });
                  },
                  (err) => {
                    this.translate
                      .get(
                        `user.details.error-cancel-${this.typeBooked?.toLowerCase()}`
                      )
                      .subscribe((resp) => {
                        this.ngxToastrService.typeError(
                          resp,
                          err.error.message
                        );
                      });
                  }
                );
              }
            });
        })
    );
  }

  formatDate(value) {
    return moment(value).format("DD/MM/YYYY");
  }

  getNextCheckinFlights(isNextFlight) {
    this.loading = true
    this.nextFlight = !isNextFlight;
    this.flightService.getNextCheckinFlights().subscribe((list) => {
      if ((list?.departFlights?.docs?.length > 0 || list?.returnFlights?.docs?.length > 0) && this.nextFlight) {
        this.flightsToView = [
          ...list?.departFlights?.docs.map(flight => ({ ...flight, nextFlight: true })),
          ...list?.returnFlights?.docs.map(flight => ({ ...flight, nextFlight: true }))];

        this.flightsToView = this.flightsToView.filter(d => d.status === 'active');
        this.getPaginatorData({'pageIndex': 0, "pageSize":10, 'length': this.flightsToView.length})
        this.loading = false;
      } else if (!this.nextFlight) {
        this.ngOnInit();
        this.loading = false;
      } else {
        this.nextFlight = false;
        this.translate
          .get([
            "flight-train.no-next-flight-list",
          ])
          .subscribe((resp) => {
            this.ngxToastrService.typeError(
              resp["flight-train.no-next-flight-list"],
              resp[""]
            );
          });
        this.loading = false;
        this.ngOnInit();
      }
    })
  }
  ngOnDestroy() {
    this.subcriptions.forEach((s) => s.unsubscribe());
  }
}
