import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { TransactionService } from "@core/services/transaction.service";
import { PaginationConfig } from "@interfaces";
import {
  NgbDate,
  NgbDateStruct,
  NgbInputDatepicker,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { BookService, CompanyService, ExcelService, NgxToastrService, UserService } from "@services";
import { AppState } from "app/app.reducer";
import * as moment from "moment";
import { Subscription, take } from "rxjs";
import swal from "sweetalert2";
import { BookedsViewComponent } from "../bookeds-view/bookeds-view.component";
import { Company, User } from "@core/models";
import { UserCardModalComponent } from "@shared/component/user-card-modal/user-card-modal.component";

@Component({
  selector: "app-transaction",
  templateUrl: "./transaction.component.html",
  styleUrls: ["./transaction.component.scss"],
})
export class TransactionComponent implements OnInit, OnDestroy {
  @Input() uuidBusinessEntity;
  @Input() businessEntityType;
  @Input() loading: boolean = false;
  @Input() transactions: any[];
  form: UntypedFormGroup;
  subscription: Subscription[] = [];
  paginationConf: PaginationConfig = {
    page: 1,
    pageSize: 8,
    collectionSize: 120,
  };
  lowValue = 0;
  highValue = 5;
  fromDate: any;
  toDate: any;
  hoveredDate: NgbDateStruct;
  editableArr: boolean[] = [];
  minToDate: NgbDate | null = null;
  user: User;
  company: Company;

  @ViewChild("d") input: NgbInputDatepicker;
  @ViewChild("rangeInput") rangeInput: ElementRef;
  @Output() fromDateEmit = new EventEmitter();
  @Output() toDateEmit = new EventEmitter();
  isEmployee = false;

  @ViewChild("dateRet") dateRet: Input;
  @ViewChild("dateDep") dateDep: Input;

  @ViewChild("paginator") paginator: MatPaginator;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private companyService: CompanyService,
    private translateService: TranslateService,
    private excelService: ExcelService,
    public translate: TranslateService,
    private store: Store<AppState>,
    private ngxToastrService: NgxToastrService,
    private transactionService: TransactionService,
    private bookService: BookService,
    private modalService: NgbModal,
    private userService: UserService
  ) {
    this.subscription.push(
      this.store.select("auth").subscribe((s: any) => {
        if (s.user.type?.toUpperCase() === "EMPLOYEE") {
          this.isEmployee = true;
        }
      })
    );
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      user: [""],
      department: [""],
      type: [""],
      fromDate: [null],
      toDate: [null],
    });
    this.transactions = [];

    const cachedBookeds = this.bookService.getData('transactions');
    if(cachedBookeds && Object.keys(cachedBookeds).length > 0){
      this.transactions = cachedBookeds;
    }
  }

  getDate(date) {
    return moment(date).format("DD/MM/YYYY");
  }

  loadTransactions() {
    this.loading = true;
    this.companyService
      .getTransactions(this.uuidBusinessEntity)
      .subscribe((transactions) => {
        this.transactions = transactions;
        this.transactions.reverse();
        this.transactions.forEach((transaction) => {
          if (transaction.type?.toLowerCase() === "pending") {
            this.editableArr.push(true);
          } else {
            this.editableArr.push(false);
          }
        });
        const cachedBookeds = this.bookService.getData('transactions');
        if(cachedBookeds && Object.keys(cachedBookeds).length > 0){
          this.bookService.setData('transactions', transactions);
        }
        this.loading = false
      });
  }
  get f() {
    return this.form.controls;
  }

  onDateSelection(date: NgbDate, inputType: 'fromDate' | 'toDate') {
    const fromDate = this.form.get('fromDate').value;
    const toDate = this.form.get('toDate').value;
  
    if (inputType === 'fromDate') {
      this.form.get('fromDate')?.setValue(date);
      this.minToDate = date;
  
      if (toDate && date.after(toDate)) {
        this.form.get('toDate')?.setValue(null);
      }
    } else if (inputType === 'toDate') {
      if (!fromDate || date.after(fromDate) || date.equals(fromDate)) {
        this.form.get('toDate')?.setValue(date);
      }
    }
    this.setPage();
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    this.editableArr = [];
    this.transactions
      .slice(this.lowValue, this.highValue)
      .forEach((transaction) => {
        if (transaction.type?.toLowerCase() === "pending") {
          this.editableArr.push(true);
        } else {
          this.editableArr.push(false);
        }
      });
    return event;
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      date.equals(this.toDate) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  cleanFilters() {
    this.form.reset({
      user: '',
      department: '',
      type: '',
      fromDate: null,
      toDate: null,
    });
  
    if (this.dateDep && this.dateDep['nativeElement']) {
      this.dateDep['nativeElement'].value = '';
    }
    if (this.dateRet && this.dateRet['nativeElement']) {
      this.dateRet['nativeElement'].value = '';
    }
  
    this.fromDate = null;
    this.toDate = null;
  
    this.setPage();
  }

  canClean() {
    return (
      this.f.user.value !== "" ||
      this.f.department.value !== "" ||
      this.f.type.value !== "" ||
      this.fromDate ||
      this.toDate
    );
  }

  exportData() {
    let headers = [];
    this.subscription.push(
      this.translateService
        .get([
          "company.transaction.filters.user",
          "company.transaction.filters.department",
          "company.transaction.filters.type",
          "company.transaction.filters.booked",
          "company.transaction.filters.auth_code",
          "company.transaction.filters.created",
          "company.transaction.filters.amount",
          "company.transaction.filters.type-payment",
          "company.transaction.filters.type-refund",
          "expenses.title",
          "company.transaction.flight",
          "company.transaction.train",
          "company.transaction.filters.no-booked-create",
          "csv.iva",
        ])
        .subscribe((resp) => {
          headers = [
            resp["company.transaction.filters.department"],
            resp["company.transaction.filters.user"],
            resp["company.transaction.filters.type"],
            resp["company.transaction.filters.booked"],
            resp["company.transaction.filters.auth_code"],
            resp["company.transaction.filters.created"],
            resp["company.transaction.filters.amount"],
            resp["csv.iva"],
          ];
          const filterUser = this.f.user.value;
          const filterCompany = this.f.department.value;
          const filterBooking = this.f.booked.value;

          const arrayTransactions = this.transactions
            .filter((transaction) => {
              if (
                filterUser !== "" &&
                !(
                  transaction["user"]
                    ?.toLowerCase()
                    .indexOf(filterUser?.toLowerCase()) > -1
                )
              ) {
                return false;
              }
              if (
                filterCompany !== "" &&
                !(
                  transaction["department"]
                    ?.toLowerCase()
                    .indexOf(filterCompany?.toLowerCase()) > -1
                )
              ) {
                return false;
              }
              if (
                filterBooking !== "" &&
                !(
                  transaction["booked"]
                    ?.toLowerCase()
                    .indexOf(filterBooking?.toLowerCase()) > -1
                )
              ) {
                return false;
              }
              return true;
            })
            .map((element) => {
              const price = element.amount / 100;

              return {
                [headers[0]]: element.company,
                [headers[1]]: element.user,
                [headers[2]]:
                  element.type?.toLowerCase() === "payment"
                    ? resp["company.transaction.filters.type-payment"]
                    : resp["company.transaction.filters.type-refund"],
                [headers[3]]:
                  element.booked === ""
                    ? resp["company.transaction.filters.no-booked-create"]
                    : element.booked === "flight"
                    ? resp["company.transaction.flight"]
                    : element.booked === "train"
                    ? resp["company.transaction.train"]
                    : element.booked === "expense"
                    ? resp["expenses.title"]
                    : element.booked,
                [headers[4]]: element.code,
                [headers[5]]: moment(element.createdAt).format("DD/MM/YYYY"),
                [headers[6]]: price,
                [headers[7]]: (price - price / 1.1).toFixed(2).toString(),
              };
            });
          this.excelService.exportAsExcelFile(
            arrayTransactions,
            "Vyoo-Report-Transactions",
            true
          );
        })
    );
  }

  changeTransactionType(transaction, event, index) {
    this.subscription.push(
      this.companyService
        .changeTransactionType(this.uuidBusinessEntity, transaction.uuid, event)
        .subscribe(
          (res) => {
            this.loadTransactions();
            this.editableArr[index] = !this.editableArr[index];
          },
          (err) => {
            this.subscription.push(
              this.translateService
                .get("agency.agency-detail.error-removed-group")
                .subscribe((result) => {
                  this.ngxToastrService.typeInfo(
                    result["agency.agency-detail.error-removed-group"],
                    err.error.message
                  );
                })
            );
          }
        )
    );
  }

  doTransactionAction(transaction, action: string) {
    let amount = transaction.amount;
    const userUuidRaw = transaction?.bookedObject?.userUuid;
    const userUuid = Array.isArray(userUuidRaw) ? userUuidRaw[0] : userUuidRaw;
    let body = {
      userUuid: userUuid,
      companyUuid: this.uuidBusinessEntity,
      transactionUuid: transaction.uuid,
      amount,
      action: action,
    };
    this.userService.getUser(userUuid).subscribe(
      (user) => {
        this.user = user;
        if (this.user?.companyUuid) {
          this.companyService.getCompany(this.user.companyUuid).subscribe(
            (company: Company) => {
              this.company = company;

              if (action === 'PAYMENT') {
                this.showPaymentCardSelector().then((selectedCard) => {
                  if (!selectedCard && !this.company.bookedWithoutCard) {
                    return;
                  }
        
                  if (selectedCard) {
                    body["paymentCard"] = {
                      name: selectedCard.name,
                      token: selectedCard.token,
                    };
                  }
        
                  this.completeTransactionAction(transaction, action, amount, body);
                });
              } else {
                this.completeTransactionAction(transaction, action, amount, body);
              }
            },
            (error) => {
              this.subscription.push(
                this.translateService
                  .get("common.error")
                  .subscribe((resp) => {
                    this.ngxToastrService.typeInfo(
                      resp,
                      error.error.message || error.message
                    );
                  })
              );
            }
          );
        }
      },
      (err) => {
        this.subscription.push(
          this.translateService
            .get("common.error")
            .subscribe((resp) => {
              this.ngxToastrService.typeInfo(
                resp,
                err.error.message || err.message
              );
            })
        );
      }
    );
  }
  

  showPaymentCardSelector(): Promise<any> {
    if (this.company.bookedWithoutCard && (!this.user.paymentCards || this.user?.paymentCards.length <= 0)) {
      return Promise.resolve(null);
    }

    if (!Array.isArray(this.user.paymentCards)) {
      this.user.paymentCards = this.user.paymentCards ? [this.user.paymentCards] : [];
    }

    /* if (!Array.isArray(this.user.personalPaymentCards)) {
      this.user.personalPaymentCards = this.user.personalPaymentCards ? [this.user.personalPaymentCards] : [];
    } */

    if ((!this.user.paymentCards || this.user.paymentCards.length === 0) /* && (!this.user.personalPaymentCards || this.user.personalPaymentCards.length === 0) */) {
      this.translate
        .get(["common.no-card-user-error"])
        .pipe(take(1))
        .subscribe((value) =>
          this.ngxToastrService.typeInfo(
            null,
            value["common.no-card-user-error"]
          )
        );
      return Promise.resolve(null);
    }

    return new Promise((resolve) => {
      const modalRef = this.modalService.open(UserCardModalComponent, {
        windowClass: 'custom-modal-user-card',
        centered: true,
        backdrop: 'static',
        keyboard: false
      });
      modalRef.componentInstance.bookCards = this.user.paymentCards;
      /* modalRef.componentInstance.bookPersonalCards = this.user.personalPaymentCards; */
      modalRef.componentInstance.fromBookSelect = true;
      modalRef.componentInstance.cardSelected.subscribe((selectedCard) => {
        resolve(selectedCard);
      });
      modalRef.result.catch(() => {
        resolve(null);
      });
    });
  }

  completeTransactionAction(transaction, action: string, amount: any, body: any) {
    this.translate
      .get([
        "company.transaction.filters.action-title",
        "common.confirm",
        "common.cancel",
        "company.transaction.filters.amount",
        "company.transaction.filters.type-payment",
        "company.transaction.filters.type-refund",
      ])
      .subscribe((translateRes) => {
        swal
          .fire({
            title: translateRes["company.transaction.filters.action-title"],
            html: `<div><h3 class="heading-5">${
              action === "PAYMENT"
                ? translateRes["company.transaction.filters.type-payment"]
                : translateRes["company.transaction.filters.type-refund"]
            }</h3></div><input type="number" id="amount-input" max="${
              action === "REFUND" ? amount : "999999999999999"
            }" min="0" class="form-control" style="display: block: width: 100%;" placeholder="${
              translateRes["company.transaction.filters.amount"]
            }"/>`,
            showCancelButton: true,
            confirmButtonColor: "#10B981",
            cancelButtonColor: "#ED5A46",
            confirmButtonText: translateRes["common.confirm"],
            cancelButtonText: translateRes["common.cancel"],
            customClass: {
              confirmButton: "custom-btn btn-raised",
              cancelButton: "custom-btn btn-raised mr-5",
            },
            buttonsStyling: false,
            preConfirm: (): any => {
              amount = (<HTMLInputElement>(
                document.getElementById("amount-input")
              )).value;
              amount = parseFloat(amount);
              if (action === "REFUND" && amount > transaction.amount) {
                this.subscription.push(
                  this.translateService
                    .get([
                      "common.error",
                      "company.transaction.filters.action-incorrect-amount",
                    ])
                    .subscribe((resp) => {
                      this.ngxToastrService.typeInfo(
                        resp["common.error"],
                        `${resp["company.transaction.filters.action-incorrect-amount"]}${transaction.amount}`
                      );
                    })
                );
                return false;
              } else {
                return undefined;
              }
            },
          })
          .then((action) => {
            if (action.value) {
              body.amount = amount;
              this.subscription.push(
                this.transactionService.doPayment(body).subscribe(
                  (res) => {
                    this.loadTransactions();
                    this.subscription.push(
                      this.translateService
                        .get([
                          "common.congrat",
                          "company.transaction.filters.action-success",
                        ])
                        .subscribe((resp) => {
                          this.ngxToastrService.typeInfo(
                            resp["common.congrat"],
                            resp["company.transaction.filters.action-success"]
                          );
                        })
                    );
                  },
                  (err) => {
                    this.subscription.push(
                      this.translateService
                        .get("common.error")
                        .subscribe((resp) => {
                          this.ngxToastrService.typeInfo(
                            resp,
                            err.error.message || err.message
                          );
                        })
                    );
                  }
                )
              );
            }
          });
      });
  }

  onView(transaction: any) {
    const onView = this.modalService.open(BookedsViewComponent, {
      size: "lg",
      centered: true,
    });

    if(transaction.order){
      transaction.bookedObject.order = transaction.order;
    }

    if(transaction.user){
      transaction.bookedObject.userName = transaction.user;
    }

    onView.componentInstance.booking = transaction.bookedObject;
    onView.componentInstance.fromTransactions = true;

    if (transaction.service === 'room' && transaction.bookedObject.apiReference || transaction.bookedObject.hotel) {
      onView.componentInstance.type = "bookingApiReference";
    } else if (transaction.service === 'room' && !transaction.bookedObject.apiReference || transaction.bookedObject.hotel) {
      onView.componentInstance.type = 'custom-booking';
    } else {
      onView.componentInstance.type = transaction.service;
    }
    
    onView.componentInstance.isEmployee = this.isEmployee;
  }

  setPage() {
    if (this.paginator) {
      this.paginator.firstPage();
    }
  }

  ngOnDestroy() {
    this.subscription.forEach((s) => s.unsubscribe());
  }
}
