import { Options } from "@angular-slider/ngx-slider";
import {  Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { environment } from "@env/environment";
import {
  Association,
  AvailabilityPriceRequest,
  CabinType,
  FlightAvailReq,
  FlightAvailRes,
  FlightDto,
  FlightRouteDto,
  Journey,
  JourneyRequest,
  JourneysReferences,
  PaxesDistribution,
  Rule,
  SearchCriteria,
  Solution,
  TransportRequest,
  User,
} from "@models";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {
  AuthService,
  FlightLleegoService,
  GroupService,
  NgxToastrService,
  StateService,
  TravelUtilsService,
  UserService,
  UtilsService,
} from "@services";
import { PaginationConfig } from "@shared/interfaces";
import { Flight } from "@shared/interfaces/amadeus/search/flight";
import { AppState } from "app/app.reducer";
import { CreateTravelModalComponent } from "app/modules/components/travels/create-travel-modal/create-travel-modal.component";
import newAirports from "assets/utils/airports.json";
import airports from "assets/utils/amadeus/airports.json";
import cities from "assets/utils/cities.json";
import * as moment from "moment";
import { forkJoin, Subscription } from "rxjs";
import { FlightFormComponent } from "../flight-form/flight-form.component";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-form-flight-train",
  templateUrl: "./form-flight-train.component.html",
  styleUrls: ["./form-flight-train.component.scss"],
})
export class FormFlightTrainComponent implements OnInit, OnDestroy {
  userUuid?: string;
  companyUuid?: string;

  isManualFlight = false;
  private subcriptions: Subscription[] = [];
  isAmadeusEnabled = environment.AMADEUS_ENABLED === 0 ? false : true;
  lowValue = 0;
  highValue = 10;
  requestId = "";
  form: UntypedFormGroup;
  flightDto: FlightDto;
  flightRouteDtoOrigin: FlightRouteDto;
  flightRouteDtoDestination: FlightRouteDto;
  formSearchFlight: UntypedFormGroup;
  flightListResult;
  flightsToView: Flight[] = [];
  loading = false;
  prefOptions = [];
  date = new Date();
  minDateNgStruct = {
    year: this.date.getFullYear(),
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  maxDateNgStruct = {
    year: this.date.getFullYear() + 20,
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  checkedOneWay = false;
  checkedReturn = true;
  users = [];
  airportList: any[] = [];
  airportSelected = "";
  checked = false;
  disabled = true;
  groupFilters: any = {};
  seats = [
    {
      value: "Y",
      viewValue: "Economy",
    },
    {
      value: "W",
      viewValue: "Economic Premium",
    },
    {
      value: "M",
      viewValue: "Economic Standard",
    },
    {
      value: "C",
      viewValue: "Business",
    },
    {
      value: "F",
      viewValue: "First",
    },
  ];
  isOnlyDirectChecked = false;
  isAllowBaggageChecked = false;
  isPermitCancellationChecked = false;
  isArrivalSameDayChecked = false;
  loadingFlights = false;
  paginationConfig: PaginationConfig = {
    page: 1,
    pageSize: 10,
    collectionSize: 0,
  };
  state: any;
  isUser = true;
  allowAllFlights = false;
  airportsJson: any = {};
  bodyTravelModal: any = {};
  citiesList = [];
  userAge: number;
  travelersAge: number[] = [];
  flightAvailReq: FlightAvailReq;
  minTimeDeparture = 0;
  maxTimeDeparture = 24;
  optionsTimeDeparture: Options = {
    floor: 0,
    ceil: 24,
    step: 1,
    translate: (value: number): string => {
      return value + "h";
    },
  };
  user: User;
  minTimeReturnDeparture = 0;
  maxTimeReturnDeparture = 24;
  optionsTimeArrival: Options = {
    floor: 0,
    ceil: 24,
    step: 1,
    translate: (value: number): string => {
      return value + "h";
    },
  };
  distanceDeparture = 0;
  // kmValues: string[] = this.initKmValues();
  value = 0; // this.distanceToIndex('0 km');
  optionsDistanceDeparture: Options = {
    floor: 0,
    ceil: 1000,
    step: 50,
    translate: (value: number): string => {
      return value + " Km";
    },
  };
  distanceArrival = 0;
  optionsDistanceArrival: Options = {
    floor: 0,
    ceil: 1000,
    step: 50,
    translate: (value: number): string => {
      return value + " Km";
    },
  };
  expandableFilters = false;
  originRadius: number;
  destinationRadius: number;
  departureSchedules: {
    minTimeDeparture: string;
    maxTimeDeparture: string;
  };
  arrivalSchedules: {
    minTimeDeparture: string;
    maxTimeDeparture: string;
  };
  flightAvailRes: FlightAvailRes;
  outwardList: JourneysReferences[] = [];
  returnList: JourneysReferences[] = [];
  transportRequest: TransportRequest;
  selectedUsers: any;

  isAdmin = false;
  isEmployee = false;
  canUseTRM: boolean;
  userToBook: string;
  cheaper = true;
  oneWay: boolean;
  projectData: any;
  airlines: any;
  selectedAirlines: string[] = [];
  filteredFlights = [];
  flights = [];
  isAirlinesAccordionOpen = false;
  
  @ViewChild("flightResult") flightResult: FlightFormComponent;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    private ngxToastrService: NgxToastrService,
    private groupService: GroupService,
    public utilsService: UtilsService,
    public activeModal: NgbActiveModal,
    public stateService: StateService,
    private store: Store<AppState>,
    private userService: UserService,
    private travelUtilsService: TravelUtilsService,
    private modalService: NgbModal,
    private dialog: MatDialog,
    private flightLleegoService: FlightLleegoService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      const didSearch = params['didSearch'];
      if(didSearch){
        this.stateService.didFirstSearch = true;
      }else{
        this.stateService.didFirstSearch = false;
      }
    });

    this.subcriptions.push(
      this.store.select("auth").subscribe((s: any) => {
        if (s.user && s.user.type?.toUpperCase() !== "USER") {
          this.isAdmin = true;
          if (s.user.type?.toUpperCase() === "EMPLOYEE") {
            this.isEmployee = true;
          }
          this.userToBook = localStorage.getItem("userToBook")
            ? this.utilsService.decrypt(localStorage.getItem("userToBook"))
            : null;
          if (this.userToBook != null) {
            this.userUuid = this.userToBook;
          }
          this.canUseTRM = s.user.type
            ? s.user.plan && s.user.plan.permissions.canUseTRM
            : s.user.user.plan && s.user.user.plan?.permissions.canUseTRM;
          if(!this.canUseTRM){
            this.canUseTRM = this.authService.userCompanyPlan?.permissions.canUseTRM;
          }
        } else {
          this.companyUuid = s.user.companyUuid;
        }
      })
    );

    if (window.history.state.dataFromCompany) {
      const dataFromCompany = window.history.state.dataFromCompany;
      this.userUuid = dataFromCompany.userUuid;
      this.companyUuid = dataFromCompany.companyUuid;
      this.isUser = false;
      this.stateService.setDataFromCompany(dataFromCompany);
    } else {
      const dataFromState = this.stateService.dataFromCompany$.getValue();
      if (dataFromState && dataFromState.userUuid) {
        this.userUuid = dataFromState.userUuid;
        this.companyUuid = dataFromState.companyUuid;
        this.isUser = false;
      }
      if (!this.userUuid || this.userUuid === "" || this.userUuid === null) {
        this.subcriptions.push(
          this.store.select("auth").subscribe((s) => {
            if (
              s.user &&
              (s.user.type?.toUpperCase() === "USER" || s.user.type?.toUpperCase() === "COMPANY")
            ) {
              const user: any = s.user;
              this.userAge = moment().diff(user.birthdate, "years");
              this.userUuid = s.user.uuid;
              this.travelersAge = [this.userAge];
            }
          })
        );
      }
    }
  }

  ngOnInit() {
    if(this.stateService.didFirstSearch == false){
      this.stateService.flightSearchForm = null;
      this.stateService.flightSelectedState = [];
      this.stateService.transportRequest = null;
    }
    this.state = this.stateService.state$.getValue() || {};
    if (
      window.history.state &&
      window.history.state.isOutdated != null &&
      window.history.state.isOutdated === true
    ) {
      this.stateService.flightSelectedState = null;
      this.stateService.didFirstSearch = false;
    }

    if (this.state.flights) {
      this.flightListResult = this.state.flights;
      this.requestId = this.state.flights.requestId;
    }
    this.airlines = this.stateService.getAirlines();
    this.stateService.state$.next({});
    this.subcriptions.push(
      this.store.select("auth").subscribe((s: any) => {
        if (s.user && s.user.type?.toUpperCase() === "USER") {
          this.isUser = true;
          this.user = s.user;
          this.subcriptions.push(
            this.userService.getGroupUser(s.user.uuid).subscribe((g) => {
              this.groupFilters = g.filters;
              this.groupFilters.allFlightsAllowed
                ? (this.allowAllFlights = true)
                : (this.allowAllFlights = false);
              this.seats = [];
              if (!g.filters.sitType) {
                this.pushAllSeatTypes();
              }
              this.pushSeatType(g.filters.sitType);
            })
          );
        } else {
          this.isUser = false;
          this.subcriptions.push(
            this.userService.getGroupUser(this.userUuid).subscribe((g) => {
              this.groupFilters = g.filters;
              this.groupFilters.allFlightsAllowed
                ? (this.allowAllFlights = true)
                : (this.allowAllFlights = false);
              this.seats = [];
              if (!g.filters.sitType) {
                this.pushAllSeatTypes();
              }
              this.pushSeatType(g.filters.sitType);
            }),
            this.userService.getUser(this.userUuid).subscribe((u) => {
              this.user = u;
              if (this.user) {
                this.userAge = moment().diff(this.user.birthdate, "years");
                // this.userUuid = s.user.uuid;
                this.travelersAge = [this.userAge];
              }
            })
          );
        }
      })
    );
    this.airportsJson = airports;
    this.translate
      .get([
        "train.form-train.anyone",
        "train.form-train.morning",
        "train.form-train.afternoon",
        "train.form-train.night",
      ])
      .subscribe((result) => {
        this.prefOptions = [
          { value: "anyone", text: result["train.form-train.anyone"] },
          { value: "morning", text: result["train.form-train.morning"] },
          { value: "evening", text: result["train.form-train.afternoon"] },
          { value: "noon", text: result["train.form-train.night"] },
        ];
      });
    this.expandableFilters = this.stateService.searchOptionsToggled
      ? this.stateService.searchOptionsToggled
      : false;
    if (this.stateService.flightSelectedState != null) {
      this.flightListResult = this.stateService.flightSelectedState;
    }

    if(this.stateService.selectedUsers){
      this.selectedUsers = this.stateService.selectedUsers;
    }

    if(this.stateService.projectData){
      this.projectData = this.stateService.projectData;
    }

    if (this.stateService.transportRequest != null) {
      this.transportRequest = this.stateService.transportRequest;
    }

    if (this.stateService.flightSearchForm != null) {
      this.formSearchFlight = this.stateService.flightSearchForm;
      this.stateService.oneWay =
        !this.stateService.flightSearchForm.get("roundtrip").value;
    } else {
      this.formSearchFlight = this.formBuilder.group({
        origin: [null, [Validators.required]],
        /*originNearAirports: [false],*/
       // originNearAirports: [this.nearbyDeparture? true:false],
        originNearAirports:[this.utilsService.nearbyDeparture == null ? false:this.utilsService.nearbyDeparture],
        dateIn: [null, [Validators.required]],
        dateOut: [null],
        destination: [null, [Validators.required]],
        /*        destinationNearAirports: [false],*/
        //destinationNearAirports: [this.nearbyArrival? true:false],
        destinationNearAirports: [this.utilsService.nearbyArrival ==null ? false:this.utilsService.nearbyArrival],
        onlyDirect: [false],
        roundtrip: ["false", [Validators.required]],
        type: ["Y", [Validators.required]],
        travelers: [1, [Validators.required]],
        sources: [1, [Validators.required]],
        allowBaggage: [false],
        allowCancelation: [false],
        arrivalSameDay: [false],
        companies: [[]],
      });
    }
    // Cargo los empleados de la compañia
    this.flightDto = {};
    this.flightRouteDtoOrigin = {};
    this.flightRouteDtoDestination = {};
    this.flightListResult = {};

    this.route.queryParams.subscribe((params) => {
      if (params['didSearch'] === 'true') {
        this.utilsService.reloadingFlights = true;
        this.getFlightList();
      }
    });
  }

  public getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

  getSeatSelected() {
    const seat = this.seats.find(
      (e) => e.value === this.formSearchFlight.controls["type"].value
    );
    return seat.viewValue;
  }

  selectSeat(seat: { viewValue: string; value: string }) {
    this.formSearchFlight.controls["type"].setValue(seat.value);
  }

  getSliderValue(sliderOf: number) {
    switch (sliderOf) {
      case 0:
        this.originRadius = this.stateService.distanceDeparture;
        if (this.originRadius > 0) {
          this.formSearchFlight.patchValue({ originNearAirports: true });
        } else {
          this.formSearchFlight.patchValue({ originNearAirports: false });
        }
        break;
      case 1:
        this.destinationRadius = this.stateService.distanceArrival;
        if (this.destinationRadius > 0) {
          this.formSearchFlight.patchValue({ destinationNearAirports: true });
        } else {
          this.formSearchFlight.patchValue({ destinationNearAirports: false });
        }
        break;
      case 2:
        this.departureSchedules = {
          minTimeDeparture:
            this.stateService.minTimeDeparture.toString() + ":00",
          maxTimeDeparture:
            this.stateService.maxTimeDeparture.toString() + ":00",
        };
        break;
      case 3:
        this.arrivalSchedules = {
          minTimeDeparture:
            this.stateService.minTimeDeparture.toString() + ":00",
          maxTimeDeparture:
            this.stateService.maxTimeDeparture.toString() + ":00",
        };
        break;
      default:
        break;
    }
  }

  orderByPrice() {
    this.cheaper = true;
    this.stateService.flightSelectedState.solutions =
      this.stateService.orderByLleego;
  }

  orderByTime() {
    this.stateService.orderByLleego = Array.from(
      this.stateService.flightSelectedState.solutions
    );
    this.cheaper = false;
    const originalArray = this.stateService.flightSelectedState.solutions;

    originalArray.sort((solution1, solution2) => {
      return (
        this.getTotalTime(solution1.data.associations) -
        this.getTotalTime(solution2.data.associations)
      );
    });
    this.stateService.flightSelectedState.solutions = originalArray;
  }

  getTotalTime(associations: Association[]) {
    let totalTime = 0;
    Array.from(associations).forEach((association: Association) => {
      association.journey_references.forEach((journeyId: string) => {
        const journeyObj: Journey =
          this.stateService.flightSelectedState.journeys.find(
            (journey) => journey.id === journeyId
          );
        totalTime += journeyObj.object.duration;
      });
    });
    return totalTime;
  }

  openSearchDialog() {
    const dialogRef = this.dialog.open(FlightFormComponent, {
      height: "auto",
      width: "90%",
      panelClass: "search-flight-dialog",
      data: { modal: true },
    });
    if(this.selectedUsers){
      dialogRef.componentInstance.travelersSelected= this.selectedUsers;
    }
    if(this.projectData){
      dialogRef.componentInstance.projectDataSaved= this.projectData;
    }
    dialogRef.afterClosed().subscribe((result) => {
      this.stateService.oneWay =
        !this.stateService.flightSearchForm.get("roundtrip").value;
        this.selectedAirlines = [];
        this.flights = this.stateService.flightSelectedState.solutions;
        this.airlines = this.extractAvailableAirlines(this.stateService.flightSelectedState);
        
        this.filteredFlights = [...this.flights];
        this.stateService.setAirlines(this.airlines);

      if (result) {
        if (this.cheaper) {
          this.stateService.flightSelectedState = result.result;
        } else {
          this.orderByTime();
        }
      }
    });

    dialogRef.componentInstance.emitSelectedTravelers.subscribe((selectedUsers: any) => {
      this.handleSelectedUsers(selectedUsers);
    });
    dialogRef.componentInstance.emitProject.subscribe((projectData: any) => {
      this.handleSelectedProjects(projectData);
    });
    dialogRef.componentInstance.emitCabin.subscribe((cabinData: any) => {
      this.handleSelectedCabin(cabinData);
    });
  }

  loadGroups(uuid) {
    this.loading = true;
    this.groupService.getGroups(uuid).subscribe(
      (result: any[]) => {
        this.loading = false;
        result
          .filter((group) => group.filters.requestFlight)
          .forEach((group) => (this.users = this.users.concat(group.users)));
      },
      (err) => {
        this.loading = false;
        this.translate
          .get(`flight.create-flight.error-load-employees`)
          .subscribe((result) => {
            this.ngxToastrService.typeInfo(result, err.error.error);
          });
      }
    );
  }

  get f() {
    return this.form.controls;
  }

  get fS() {
    return this.formSearchFlight.controls;
  }

  didFirstSearch() {
    if (
      this.stateService.flightSelectedState &&
      this.stateService.flightSelectedState.solutions.length > 0
    ) {
      this.stateService.didFirstSearch = true;
    } else {
      this.stateService.didFirstSearch = false;
    }
    this.selectedAirlines = [];
    this.flights = this.stateService.flightSelectedState.solutions;
    this.airlines = this.extractAvailableAirlines(this.stateService.flightSelectedState);
    
    this.filteredFlights = [...this.flights];
    this.stateService.setAirlines(this.airlines);
  }

  handleSelectedUsers(selectedUserUuids: any) {
    const userRequests = selectedUserUuids.map(uuid => this.userService.getUserByUuid(uuid));

    forkJoin(userRequests).subscribe(
      users => {
        this.selectedUsers = users;
        this.stateService.selectedUsers = this.selectedUsers;
      },
      error => {}
    );
  }

  handleSelectedProjects(projectData: any){
    this.projectData = projectData;
    this.stateService.projectData = this.projectData;
  }

  handleSelectedCabin(cabin: any){
    this.formSearchFlight.controls["type"].setValue(cabin);
  }

  getOutMinDate() {
    return this.utilsService.getOutDateSame(
      this.minDateNgStruct,
      this.formSearchFlight.controls["dateIn"].value
    );
  }
  getOutMinDateManual() {
    return this.utilsService.getOutDateSame(
      this.minDateNgStruct,
      this.form.controls["dateDeparture"].value
    );
  }

  // Buscador de vuelos
  /*   getAirports(value: string) {
      this.airportList = [];
      if (value !== "" && value.length > 2) {
        airports.Table1.forEach((e) => {
          if (
            e.AirportName.includes(value) ||
            e.AirportName.toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(
                value
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
              ) ||
            e.IATA.includes(value) ||
            e.IATA.toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(
                value
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
              ) ||
            e.Country.includes(value) ||
            e.Country.toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(
                value
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
              )
          ) {
            this.airportList.push({
              title: e.AirportName + " " + "(" + e.IATA + ")",
              coordinates: {
                latitude: e.Latitude,
                longitude: e.Longitude,
              },
            });
          }
        });
      } else {
        this.airportList = [];
      }
    } */

  getAirports(value: string) {
    this.airportList = [];
    if (value !== "" && value.length > 2) {
      newAirports["Table1"].forEach((e) => {
        if (
          (e.name &&
            (e.name.includes(value) ||
              e.name
                ?.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  value
                    ?.toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                ))) ||
          (e.IATA &&
            (e.IATA.includes(value) ||
              e.IATA?.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  value
                    ?.toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                ))) ||
          (e.country &&
            (e.country.includes(value) ||
              e.country
                ?.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  value
                    ?.toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                ))) ||
          (e.city &&
            (e.city.includes(value) ||
              e.city
                ?.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  value
                    ?.toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )))
        ) {
          this.airportList.push({
            title:
              e.city.trim() +
              "/" +
              e.name.trim() +
              " " +
              "(" +
              e.IATA.trim() +
              ")",
            coordinates: {
              latitude: e.lat.trim(),
              longitude: e.lon.trim(),
            },
          });
        }
      });
      airports.Table1.forEach((e) => {
        if (
          e.AirportName.includes(value) ||
          e.AirportName?.toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              value
                ?.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            ) ||
          e.IATA.includes(value) ||
          e.IATA?.toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              value
                ?.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            ) ||
          e.Country.includes(value) ||
          e.Country?.toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              value
                ?.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            )
        ) {
          // comprobamos que el aeropuerto no se repita en la lista primigenia
          if (
            !this.airportList.some((f) =>
              f.title?.toLowerCase().includes(`${value?.toLowerCase()}`)
            )
          ) {
            this.airportList.push({
              title: e.AirportName + " " + "(" + e.IATA + ")",
              coordinates: {
                latitude: e.Latitude,
                longitude: e.Longitude,
              },
            });
          }
        }
      });
    } else {
      this.airportList = [];
    }
  }
  // Buscador de ciudades
  getCities(value: string) {
    this.citiesList = [];
    if (value !== "" && value.length > 2) {
      cities.Table1.forEach((v) => {
        if (
          v["name"]?.toLowerCase().includes(value?.toLowerCase()) &&
          !this.citiesList.includes(v["name"])
        ) {
          this.citiesList.push(v["name"]);
        }
      });
    } else {
      this.citiesList = [];
    }
  }

  getFlightListFilter() {
    if (
      this.formSearchFlight.valid &&
      this.stateService.flightSelectedState != null
    ) {
      this.getFlightList();
    }
  }

  showSlider(sliderOf: number) {
    if (sliderOf === 0) {
      this.utilsService.nearbyDeparture = !this.utilsService.nearbyDeparture;
    } else {
      this.utilsService.nearbyArrival = !this.utilsService.nearbyArrival;
    }
  }

  getFlightList() {
    this.travelUtilsService.removeTravelLocalStorage();
    this.flightAvailRes = null;
    this.outwardList = [];
    this.returnList = [];
    this.stateService.flightsGoing = this.outwardList;
    this.stateService.flightsReturn = this.returnList;
    this.stateService.flightSelectedState = null;
    this.loadingFlights = true;
    this.utilsService.loadingFlights = true;
    this.utilsService.reloadingFlights = true;
    const journeys: JourneyRequest[] = [];
    const paxes_distribution: PaxesDistribution = {
      passengers_ages:
        this.stateService.transportRequest.paxes_distribution.passengers_ages ||
        this.travelersAge,
    };
    this.stateService.flightSearchForm.controls["onlyDirect"].setValue(
      this.formSearchFlight.controls["onlyDirect"].value
    );
    this.stateService.flightSearchForm.controls["allowBaggage"].setValue(
      this.formSearchFlight.controls["allowBaggage"].value
    );
    this.stateService.flightSearchForm.controls["allowCancelation"].setValue(
      this.formSearchFlight.controls["allowCancelation"].value
    );
    this.stateService.flightSearchForm.controls["arrivalSameDay"].setValue(
      this.formSearchFlight.controls["arrivalSameDay"].value
    );
    this.stateService.flightSearchForm.controls["type"].setValue(
      this.formSearchFlight.controls["type"].value
    );
    this.formSearchFlight = this.stateService.flightSearchForm;
    const formResult = this.formSearchFlight.getRawValue();
    const departDateMonth =
      formResult.dateIn.month < 10
        ? `0${formResult.dateIn.month}`
        : formResult.dateIn.month;
    const departDateDay =
      formResult.dateIn.day < 10
        ? `0${formResult.dateIn.day}`
        : formResult.dateIn.day;
    const departDate = `${formResult.dateIn.year}-${departDateMonth}-${departDateDay}`;
    this.stateService.setFlightSearch(this.formSearchFlight);
    const dateDepartureAux = departDate;
    try {
      const origin = this.fS["origin"].value.split("(")[1].split(")")[0];
      const destination = this.fS["destination"].value
        .split("(")[1]
        .split(")")[0];
      let goSchedule = [];
      if (
        this.stateService.minTimeDeparture !== 0 ||
        this.stateService.maxTimeDeparture !== 24
      ) {
        goSchedule = [
          this.stateService.minTimeDeparture.toString() + ":00",
          this.stateService.maxTimeDeparture.toString() + ":00",
        ];
        if (this.stateService.maxTimeDeparture === 24) {
          goSchedule[1] = "23:59";
        }
      }
      const goFourney: JourneyRequest = {
        max_layover_count: this.fS["onlyDirect"].value ? 0 : null,
        date: dateDepartureAux,
        origin: origin,
        destination: destination,
        schedules: goSchedule,
        arrival_same_day: this.fS["arrivalSameDay"].value,
      };
      if (this.formSearchFlight.value.originNearAirports === true) {
      //if (this.originRadius>0) {
              goFourney.origin_radius = this.originRadius;
      }
      if (this.formSearchFlight.value.destinationNearAirports === true) {
      //  if (this.destinationRadius>0) {
        goFourney.destination_radius = this.destinationRadius;
      }
      journeys.push(goFourney);
      if (this.fS["roundtrip"].value === true && this.fS["dateOut"].value) {
        const returnDateMonth =
          formResult.dateOut.month < 10
            ? `0${formResult.dateOut.month}`
            : formResult.dateOut.month;
        const returnDateDay =
          formResult.dateOut.day < 10
            ? `0${formResult.dateOut.day}`
            : formResult.dateOut.day; 
        const returnDate = `${formResult.dateOut.year}-${returnDateMonth}-${returnDateDay}`;
        const dateReturnAux = returnDate;
        let returnSchedule = [];
        if (
          this.stateService.minTimeReturnDeparture !== 0 ||
          this.stateService.maxTimeReturnDeparture !== 24
        ) {
          returnSchedule = [
            this.stateService.minTimeReturnDeparture.toString() + ":00",
            this.stateService.maxTimeReturnDeparture.toString() + ":00",
          ];
          if (this.stateService.maxTimeReturnDeparture === 24) {
            returnSchedule[1] = "23:59";
          }
        }
        const retJourney: JourneyRequest = {
          max_layover_count: this.fS["onlyDirect"].value ? 0 : null,
          date: dateReturnAux,
          origin: destination,
          destination: origin,
          schedules: returnSchedule,
          arrival_same_day: this.fS["arrivalSameDay"].value,
        };
        if (this.formSearchFlight.value.originNearAirports === true) {
        //  if (this.originRadius>0) {
        retJourney.origin_radius = this.originRadius;
        }
        if (this.formSearchFlight.value.destinationNearAirports === true) {
        //  if (this.destinationRadius>0) {
        retJourney.destination_radius = this.destinationRadius;
        }
        journeys.push(retJourney);
      }

      const cabinType: CabinType = this.fS["type"].value;

      this.transportRequest = {
        //exclude_fares: ["TTOO", "CUPO"],
        include_bus: false,
        include_gds: true,
        include_ndc: true,
        include_train: false,
        journeys: journeys,
        low_cost: true,
        only_flight: true,
        paxes_distribution: paxes_distribution,
        cabin: cabinType,
        refundable: this.fS["allowCancelation"].value,
        baggage: this.fS["allowBaggage"].value,
      };
      this.stateService.transportRequest = this.transportRequest;

      const rule: Rule = {
        combined: true,
        duplicated: true,
        only_partial: false,
        show_data: true,
        show_partial: false,
      };
      const searchCriteria: SearchCriteria = {
        travel: this.stateService.transportRequest,
        rule: rule,
        // connectors: ["BA"],
      };
      const availabilityPriceRequest: AvailabilityPriceRequest = {
        criterias: [searchCriteria],
      };
      if (this.userUuid) {
        this.flightAvailReq = {
          query: availabilityPriceRequest,
          userUuid: this.userUuid,
        };
      } else {
        this.flightAvailReq = { query: availabilityPriceRequest };
      }

      this.subcriptions.push(
        this.flightLleegoService.postFlightAvail(this.flightAvailReq).subscribe(
          (res) => {
            this.flightAvailRes = res;
            this.stateService.flightSelectedState = this.flightAvailRes;
            this.selectedAirlines = [];
            this.flights = this.stateService.flightSelectedState.solutions;
            this.airlines = this.extractAvailableAirlines(this.stateService.flightSelectedState);
            
            this.filteredFlights = [...this.flights];
            this.stateService.setAirlines(this.airlines);

            if (
              this.flightAvailRes &&
              this.flightAvailRes.solutions.length > 0 &&
              res !== null
            ) {
              this.flightAvailRes.solutions.forEach((solution: Solution) => {
                this.getFlights(solution, 0);
              });
              if (journeys.length === 2) {
                this.flightAvailRes.solutions.forEach((solution: Solution) => {
                  this.getFlights(solution, 1);
                });
              }
              this.loadingFlights = false;
              this.utilsService.loadingFlights = false;
              this.utilsService.reloadingFlights = false;
            } else {
              this.subcriptions.push(
                this.translate
                  .get(["flight.form-flight.not-found"])
                  .subscribe((result) => {
                    this.presentToast(
                      "Error",
                      result["flight.form-flight.not-found"]
                    );
                    this.loadingFlights = false;
                    this.utilsService.loadingFlights = false;
                    this.utilsService.reloadingFlights = false;
                  })
              );
            }
            if (!this.cheaper) {
              this.orderByTime();
            }
          },
          (err) => {
            this.loadingFlights = false;
            this.utilsService.loadingFlights = false;
            this.utilsService.reloadingFlights = false;
            this.subcriptions.push(
              this.translate
                .get(["flight.form-flight.internal-error"])
                .subscribe((result) => {
                  this.presentToast(
                    "Error",
                    result["flight.form-flight.internal-error"]
                  );
                  this.loadingFlights = false;
                  this.utilsService.loadingFlights = false;
                  this.utilsService.reloadingFlights = false;
                })
            );
            this.loadingFlights = false;
            this.utilsService.loadingFlights = false;
            this.utilsService.reloadingFlights = false;
          }
        )
      );
    } catch (error) {
      this.subcriptions.push(
        this.translate
          .get(["flight.form-flight.search-criteria-error"])
          .subscribe((result) => {
            this.presentToast(
              "Error",
              result["flight.form-flight.search-criteria-error"]
            );
            this.loadingFlights = false;
            this.utilsService.loadingFlights = false;
            this.utilsService.reloadingFlights = false;
          })
      );
      this.loadingFlights = false;
      this.utilsService.loadingFlights = false;
      this.utilsService.reloadingFlights = false;
    }
  }

  openTravelModal() {
    if (
      !localStorage.getItem("travel") ||
      localStorage.getItem("travel") === null
    ) {
      const modalRef = this.modalService.open(CreateTravelModalComponent, {
        size: "lg",
        centered: true,
      });
      modalRef.componentInstance.loading = false;
      modalRef.componentInstance.previousBooking = false;
      if (this.companyUuid) {
        modalRef.componentInstance.companyUuid = this.companyUuid;
      }
      if (this.userUuid) {
        modalRef.componentInstance.userUuid = this.userUuid;
      }
      this.subcriptions.push(
        modalRef.componentInstance.sendInfo.subscribe((dataTravel) => {
          this.bodyTravelModal = dataTravel;
          this.travelUtilsService.setTravelLocalStorage(this.bodyTravelModal);
        })
      );
    }
  }

  getSegmentRef(journeyId: string) {
    const segmentsRef: string[] = [];
    if (journeyId.includes("@")) {
      const segments = journeyId.split("@");
      segments.forEach((segment: string) => {
        segmentsRef.push(segment);
      });
    } else {
      segmentsRef.push(journeyId);
    }
    return segmentsRef;
  }

  getFlights(solution: Solution, index: number) {
    let segmentsRef: string[] = [];
    const solutionId = solution.id;

    solution.data.associations[index].journey_references.forEach(
      (journeyId: string) => {
        segmentsRef = this.getSegmentRef(journeyId);
        if (index === 0) {
          this.stateService.flightsGoing.push({
            solution_id: solutionId,
            segments_ref: segmentsRef,
            price: solution.total_price.total,
          });
        } else if (
          index === 1
          // &&
          // this.fS["roundtrip"].value === true
        ) {
          this.stateService.flightsReturn.push({
            solution_id: solution.id,
            segments_ref: segmentsRef,
            price: solution.total_price.total,
          });
        }
        segmentsRef = [];
      }
    );
  }

  private pushAllSeatTypes() {
    this.seats.push(
      {
        value: "Y",
        viewValue: "Economy",
      },
      {
        value: "W",
        viewValue: "Economic Premium",
      },
      {
        value: "M",
        viewValue: "Economic Standard",
      },
      {
        value: "C",
        viewValue: "Business",
      },
      {
        value: "F",
        viewValue: "First",
      }
    );
  }

  private pushSeatType(type) {
    switch (type) {
      case "ECONOMY":
        this.seats.push({
          value: "Y",
          viewValue: "Economy",
        });
        break;
      case "PREMIUM_ECONOMY":
        this.seats.push(
          {
            value: "Y",
            viewValue: "Economy",
          },
          {
            value: "M",
            viewValue: "Economic Standard",
          },
          {
            value: "W",
            viewValue: "Economic Premium",
          }
        );
        break;
      case "BUSINESS":
        this.seats.push(
          {
            value: "Y",
            viewValue: "Economy",
          },
          {
            value: "M",
            viewValue: "Economic Standard",
          },
          {
            value: "W",
            viewValue: "Economic Premium",
          },
          {
            value: "C",
            viewValue: "Business",
          }
        );
        break;
      case "FIRST":
        this.seats.push(
          {
            value: "Y",
            viewValue: "Economy",
          },
          {
            value: "W",
            viewValue: "Economic Premium",
          },
          {
            value: "M",
            viewValue: "Economic Standard",
          },
          {
            value: "C",
            viewValue: "Business",
          },
          {
            value: "F",
            viewValue: "First",
          }
        );
        break;
      default:
        this.seats.push(
          {
            value: "Y",
            viewValue: "Economy",
          },
          {
            value: "W",
            viewValue: "Economic Premium",
          },
          {
            value: "M",
            viewValue: "Economic Standard",
          },
          {
            value: "C",
            viewValue: "Business",
          },
          {
            value: "F",
            viewValue: "First",
          }
        );
        break;
    }
  }
  async presentToast(header: string, msg: string) {
    this.ngxToastrService.typeInfo(header, msg);
  }

  toggleAirlinesAccordion() {
    this.isAirlinesAccordionOpen = !this.isAirlinesAccordionOpen;
  }

  toggleAirlineSelection(iata: string, isChecked: boolean) {
    if (isChecked) {
      if (!this.selectedAirlines.includes(iata)) {
        this.selectedAirlines.push(iata);
      }
    } else {
      this.selectedAirlines = this.selectedAirlines.filter((selected) => selected !== iata);
    }
    this.filterFlights();
  }

  filterFlights() {
    if (this.selectedAirlines.length === 0) {
      this.filteredFlights = [...this.flights];
    } else {
      this.filteredFlights = this.flights.filter((flight) =>
        flight.data.associations.some((association) =>
          this.selectedAirlines.includes(association.validating_company)
        )
      );
    }
    this.stateService.flightSelectedState.solutions = this.filteredFlights;
  }

  isAirlineSelected(iata: string): boolean {
    return this.selectedAirlines.includes(iata);
  }

  private extractAvailableAirlines(flightState: any): { name: string; iata: string }[] {
    if (!flightState?.solutions || flightState.solutions.length === 0) {
      return [];
    }
  
    const availableAirlines = new Set(
      flightState.segments.map(segment => segment.id.substring(0, 2))
    );
  
    return Object.entries(flightState.data.company as any)
      .filter(([iata, details]) => availableAirlines.has(iata))
      .map(([iata, details]: [string, any]) => ({ name: details.name, iata }));
  }
  
  goBack() {
    if (!this.stateService.didFirstSearch) {
      this.stateService.didFirstSearch = false;
      this.router.navigate([`/companies/${this.user.companyUuid}`])
    } else {
      this.stateService.didFirstSearch = false;
    }
  }

  ngOnDestroy() {
    this.subcriptions.forEach((s) => s.unsubscribe());
    this.stateService.didFirstSearch = false;
  }
}
