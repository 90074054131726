<div class="container">
  <div class="titles-container">
    <div class="fare-title"></div>
    <div class="services-container">
      <div class="service">{{ 'flight.multifamily.seats' | translate }}</div>
      <div class="service">{{ 'flight.multifamily.meal' | translate }}</div>
      <div class="service">{{ 'flight.multifamily.changes' | translate }}</div>
      <div class="service">{{ 'flight.multifamily.refund' | translate }}</div>
      <div class="service">{{ 'flight.multifamily.hand-baggage' | translate }}</div>
      <div class="service">{{ 'flight.multifamily.baggage' | translate }}</div>
    </div>
    <div class="button-container" style="background: none;"></div>
  </div>
  <ng-container *ngFor="let multi of multiFamily.fares; let i = index">
    <div class="fare-container" (click)="touchAndUntouch(multi)"
      [ngClass]="{ touched: multi.touched, '': !multi.touched }">
      <div class="fare-title">
        <span>
          <img src="assets/img/svg/arrow-right.svg" />
          {{ multi.families[0]}}
        </span>
        <span *ngIf="multi.families[1]">
          <img src="assets/img/svg/arrow-right.svg" style="transform: rotate(180deg);" />
          {{ multi.families[1]}}
        </span>
      </div>
      <div class="services-container">
            <div class="indicator" [matTooltip]="seeIndicatorInfo(multi, 'SeatMap')">
              <img [src]="getMultifamilyServiceIcon(multi.services, 'SeatMap')" />
            </div>
            <div class="indicator" [matTooltip]="seeIndicatorInfo(multi, 'Meal')">
              <img [src]="getMultifamilyServiceIcon(multi.services, 'Meal')" />
            </div>
            <div class="indicator" [matTooltip]="seeIndicatorInfo(multi, 'Changes')">
              <img [src]="getMultifamilyServiceIcon(multi.services, 'Changes')" />
            </div>
            <div class="indicator" [matTooltip]="seeIndicatorInfo(multi, 'Refund')">
              <img [src]="getMultifamilyServiceIcon(multi.services, 'Refund')" />
            </div>
            <div class="indicator" [matTooltip]="seeIndicatorInfo(multi, 'HandBaggage')">
              <img [src]="getMultifamilyServiceIcon(multi.services, 'HandBaggage')" />
            </div>
            <div class="indicator" [matTooltip]="seeIndicatorInfo(multi, 'Baggage')">
              <img [src]="getMultifamilyServiceIcon(multi.services, 'Baggage')" />
            </div>
      </div>
      <div class="button-container" (click)="touchAndUntouch(multi)">
        <div *ngIf="!checkMultiPrize(multi) || groupFilters.onlyFlightAuthorization" style="display: flex; justify-content: center;">
          <span class="admin-permission-msg">{{ "authorization.permissions-required" | translate }}</span>
        </div>
        <button class="continue-button" (click)="
              emitNextStep(
                multi,
                multi.family
              )
            ">
          <span>{{ "flight.detail-flight.continue-by" | translate }}</span>
          <span>{{ multi.total | price }} €</span>
        </button>
        <button [ngClass]="{'price-with-auth': !checkMultiPrize(multi)}" class="buttons" (click)="openPolicies(multi)">
          {{ "flight.result-flight.see-politics" | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</div>