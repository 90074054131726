<div class="modal-header">
  <h4 *ngIf="agency" class="modal-title heading-5" id="modal-basic-title">
    {{ "agency.agency-form.edit-agency" | translate }}
  </h4>
  <h4 *ngIf="!agency" class="modal-title heading-5" id="modal-basic-title">
    {{ "agency.agency-form.create-agency" | translate }}
  </h4>
</div>
<div class="content">
  <div *ngIf="isLoading" style="display: block; margin: auto; z-index: 9999">
    <app-loading [userType]="'employee'"></app-loading>
  </div>
  <mat-horizontal-stepper
    #stepper
    [ngClass]="{ 'header-desactivado': this.agency == null }"
  >
    <ng-template matStepperIcon="edit" let-index="index">
      {{ index + 1 }}
    </ng-template>
    <mat-step label="{{ 'common.general-data' | translate }}">
      <form [formGroup]="formGeneral" class="step-form">
        <!-- Upload photo -->
        <div class="image-row">
          <p>{{ imageError }}</p>
          <div
            class="img-space"
            [ngClass]="{
              'invalid-image  ':
                formGeneral.controls['image'].hasError('required')
            }"
          >
            <input
              type="file"
              (change)="onImageChange($event)"
              id="upload-photo"
              class="fileInputProfile"
            />
            <ng-container *ngIf="isImageSaved; else elseTemplate">
              <img [src]="cardImageBase64" class="upload-photo" />
            </ng-container>
            <ng-template #elseTemplate>
              <img src="../../../../../assets/img/svg/upload-photo.svg" />
            </ng-template>
            <span class="span-name" *ngIf="agency != null">{{
              agency.name
            }}</span>
          </div>
          <div class="modify-image-wrapper" (click)="modifySelect.open()">
            <div class="modify-image">
              <label>{{
                "company.company-form.modify-image" | translate
              }}</label>
              <mat-select class="modify-image-select" #modifySelect>
                <mat-option (click)="changePhoto()">{{
                  "company.company-form.change-image" | translate
                }}</mat-option>
                <mat-option (click)="removeImage()">{{
                  "company.company-form.remove-image" | translate
                }}</mat-option>
              </mat-select>
            </div>
          </div>
        </div>
        <div class="form-row mb-3">
          <div class="col-4">
            <label for="name"
              >{{ "common.name" | translate }}*</label
            >
            <input
              [readOnly]="loading"
              formControlName="name"
              type="text"
              id="name"
              class="text-input form-control form-control--no-border"
            />
          </div>
          <div class="col-4">
            <label for="email"
              >{{ "common.email" | translate }}*</label
            >
            <input
              [readOnly]="loading"
              formControlName="email"
              type="text"
              id="email"
              class="text-input form-control form-control--no-border"
            />
          </div>
          <div class="col-4">
            <label for="cif"
              >{{ "common.cif" | translate }}*</label
            >
            <input
              [readOnly]="loading"
              formControlName="cif"
              type="cif"
              id="cif"
              class="text-input form-control form-control--no-border"
            />
          </div>
        </div>
        <div class="form-row mb-3">
          <div class="col-4">
            <label for="cian"
              >{{ "common.cian" | translate }}*</label
            >
            <input
              [readOnly]="loading"
              formControlName="cian"
              type="text"
              id="cian"
              class="text-input form-control form-control--no-border"
            />
          </div>
          <div class="col-4">
            <label for="address"
              >{{ "common.address" | translate }}*</label
            >
            <input
              [readOnly]="loading"
              formControlName="address"
              type="text"
              id="address"
              class="text-input form-control form-control--no-border"
            />
          </div>
          <div class="col-4">
            <label for="city"
              >{{ "common.city" | translate }}*</label
            >
            <input
              [readOnly]="loading"
              formControlName="city"
              type="text"
              id="city"
              class="text-input form-control form-control--no-border"
            />
          </div>
        </div>
        <div class="form-row mb-3">
          <div class="col-4">
            <label for="city"
              >{{ "common.province" | translate }}*</label
            >
            <input
              [readOnly]="loading"
              formControlName="province"
              type="text"
              id="province"
              class="text-input form-control form-control--no-border"
            />
          </div>
          <div class="col-4">
            <label for="postalCode"
              >{{ "common.postal-code" | translate }}*</label
            >
            <input
              [readOnly]="loading"
              formControlName="postalCode"
              type="text"
              id="postalCode"
              class="text-input form-control form-control--no-border"
            />
          </div>
          <div class="col-4">
            <label for="country"
              >{{ "common.country" | translate }}*</label
            >
            <mat-select
              formControlName="country"
              type="text"
              id="country"
              class="form-control form-control--no-border"
            >
              <mat-option *ngFor="let c of countriesList" value="{{ c.name }}">
                {{ c.name }}
              </mat-option>
            </mat-select>
          </div>
        </div>
        <div class="form-row mb-3">
          <div class="col-4">
            <label for="phone"
              >{{ "common.phone" | translate }}*</label
            >
            <input
              [readOnly]="loading"
              formControlName="phone"
              type="text"
              id="phone"
              required
              class="text-input form-control form-control--no-border"
            />
          </div>
          <ng-container *ngIf="isAdmin">
            <div class="col-4">
              <label for="hotelComission">{{
                "common.hotelComission" | translate
              }}</label>
              <input
                [readOnly]="loading"
                formControlName="hotelComission"
                type="number"
                step="0.01"
                min="0"
                max="maxHotelDiscount"
                id="hotelComission"
                class="text-input form-control form-control--no-border"
              />
            </div>
            <div class="col-4">
              <label for="discount"
                >{{ "common.flightDiscount" | translate }}
                <img
                  src="assets/img/svg/info.svg"
                  alt=""
                  width="16px"
                  style="margin-left: 1em"
                  [matTooltip]="'agency.agency-form.flight-fee-info' | translate"
              /></label>
              <input
                [readOnly]="loading"
                formControlName="flightDiscount"
                type="number"
                id="flightDiscount"
                min="0"
                max="maxFlightDiscount"
                class="text-input form-control form-control--no-border"
              />
            </div>
          </ng-container>
        </div>
        <div class="form-row mb-3" *ngIf="isAdmin">
          <div class="col-4">
            <label for="trainComission">{{
              "common.trainComission" | translate
            }}</label>
            <input
              [readOnly]="loading"
              formControlName="trainComission"
              type="number"
              step="0.01"
              min="0"
              max="maxHotelDiscount"
              id="trainComission"
              class="text-input form-control form-control--no-border"
            />
          </div>
          <div
            class="col-4"
            *ngIf="
              isAdmin &&
              !route.snapshot.paramMap.has('hubUuid') &&
              !hubUuid
            "
          >
            <label for="hubUuid"
              >{{ "common.hub" | translate }}*</label
            >
            <mat-select
              type="text"
              id="hubUuid"
              formControlName="hubUuid"
              (selectionChange)="setHubUuid($event)"
              class="form-control form-control--no-border"
            >
              <mat-option class="option" value="">
                {{ "hub.hub-detail.select-hub" | translate }}
              </mat-option>
              <mat-option *ngFor="let hub of hubsList" value="{{ hub.uuid }}">
                {{ hub.name }}
              </mat-option>
            </mat-select>
          </div>
        </div>
      </form>
      <div class="button-row">
        <button
          mat-button
          class="custom-btn"
          (click)="goNextStepper()"
          [disabled]="!formGeneral.valid"
        >
          {{ "company.company-form.next" | translate }}
        </button>
        <button
          mat-button
          class="custom-btn custom-btn--close"
          (click)="goBackStepper()"
          [disabled]="loading"
        >
          {{ "common.back" | translate }}
        </button>
      </div>
    </mat-step>
    <mat-step label="{{ 'common.billing-title' | translate }}">
      <form [formGroup]="formBilling" id="billing-form">
        <div class="form-row mb-3">
          <div class="col-4">
            <label for="identificator"
              >{{ "common.identificator" | translate }}*</label
            >
            <input
              [readOnly]="loading"
              formControlName="identificator"
              type="text"
              id="identificator"
              class="text-input form-control form-control--no-border"
            />
          </div>
          <div class="col-4">
            <label for="businessName"
              >{{ "common.business" | translate }}*</label
            >
            <input
              [readOnly]="loading"
              formControlName="businessName"
              type="text"
              id="businessName"
              class="text-input form-control form-control--no-border"
            />
          </div>
          <div class="col-4">
            <label for="addressBilling"
              >{{ "common.address" | translate }}*</label
            >
            <input
              [readOnly]="loading"
              formControlName="address"
              type="text"
              id="addressBilling"
              class="text-input form-control form-control--no-border"
            />
          </div>
        </div>
        <div class="form-row mb-3">
          <div class="col-4">
            <label for="cityBilling"
              >{{ "common.city" | translate }}*</label
            >
            <input
              [readOnly]="loading"
              formControlName="town"
              type="text"
              id="cityBilling"
              class="text-input form-control form-control--no-border"
            />
          </div>
          <div class="col-4">
            <label for="provinceBilling"
              >{{ "common.province" | translate }}*</label
            >
            <input
              [readOnly]="loading"
              formControlName="province"
              type="text"
              id="provinceBilling"
              class="text-input form-control form-control--no-border"
            />
          </div>
          <div class="col-4">
            <label for="postalCodeBilling"
              >{{ "common.postal-code" | translate }}*</label
            >
            <input
              [readOnly]="loading"
              formControlName="postalCode"
              type="text"
              id="postalCodeBilling"
              class="text-input form-control form-control--no-border"
            />
          </div>
        </div>
        <div class="form-row mb-3">
          <div class="col-4">
            <label for="countryBilling"
              >{{ "common.country" | translate }}*</label
            >
            <mat-select
              formControlName="country"
              type="text"
              id="countryBilling"
              class="form-control form-control--no-border"
            >
              <mat-option *ngFor="let c of countriesList" value="{{ c.name }}">
                {{ c.name }}
              </mat-option>
            </mat-select>
          </div>

          <div class="col-4" id="admin-wrapper">
            <form
              id="admin-form"
              *ngIf="isAdmin"
              [formGroup]="formGeneral"
              class="mb-4"
            >
              <div id="admin-div">
                <label>
                  {{ "company.company-form.payment-method" | translate }}
                </label>
                <div id="checkbox-container">
                  <div>
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="filtersHotel"
                      formControlName="bookedWithoutCard"
                      id="bookedWithoutCard"
                    />
                    <label
                      class="custom-control-label labelFilterSearch"
                      for="bookedWithoutCard"
                    >
                      {{ "common.flexible-payment" | translate }}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="nofiltersHotel"
                      id="bookedWithCard"
                    />
                    <label
                      class="custom-control-label labelFilterSearch"
                      for="bookedWithCard"
                    >
                      {{ "common.card-payment" | translate }}
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </form>

      <div class="button-row">
        <button
          mat-button
          class="custom-btn"
          (click)="agency ? onEdit() : onSubmit()"
          [disabled]="!formBilling.valid"
        >
          {{ "common.save" | translate }}
        </button>
        <button
          mat-button
          class="custom-btn custom-btn--close"
          (click)="goBackStepper()"
          [disabled]="loading"
        >
          {{ "common.back" | translate }}
        </button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
