import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
  UserService,
} from "@services";
import * as moment from "moment";
import { Subscription } from "rxjs";
@Component({
  selector: "app-bookeds-view",
  templateUrl: "./bookeds-view.component.html",
  styleUrls: ["./bookeds-view.component.scss"],
})
export class BookedsViewComponent implements OnInit, OnDestroy {
  @Input() booking: any;
  @Input() isUser: any;
  @Input() type: string;
  @Input() pnr?: string;
  @Input() isEmployee: boolean;
  @Input() fromTransactions: boolean;
  subscriptions: Subscription[] = [];
  checkDate = false;
  user;
  file: File;
  loading = false;
  totalAdults = 0;
  totalChildren = 0;
  rooms: any[] = [];
  travellersData: any[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private userService: UserService,
  ) {}

  ngOnInit() {  
    this.checkPriceDate();
    this.loadTravellersData();
    if (this.booking.hotel) {
      this.rooms = this.booking.hotel.rooms || this.booking.hotel.room;
      this.rooms.forEach((r) => {
        this.totalAdults += r.rates[0].adults;
        this.totalChildren += r.rates[0].children;
      });
    } else if (this.booking.rooms) {
      this.rooms = this.booking.rooms;
    }
  }

  loadTravellersData() {
    let userUuids: string[] = [];
  
    if (Array.isArray(this.booking.userUuid)) {
      userUuids = [...this.booking.userUuid];
    } else if (typeof this.booking.userUuid === "string") {
      userUuids = [this.booking.userUuid];
    }
    const requests = userUuids.map(uuid =>
      this.userService.getUserByUuid(uuid).toPromise()
    );
  
    Promise.all(requests)
      .then(users => {
        this.travellersData = users;
      })
      .catch(error => {});
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
  }
  closeModal() {
      this.activeModal.dismiss("Cross click");
  }

  checkPriceDate() {
    this.checkDate = moment(this.booking.createdAt).isBefore(
      moment(new Date("2022-02-01"))
    );
  }

  createGMapsLink(location: string) {
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      location
    )}`;
  }

  hasRenfeCardOrFlight() {
    if (this.booking.extraInfo) {
      return this.booking.extraInfo[0]?.renfeCard
        ? 1
        : this.booking.extraInfo[0]?.ffCard
        ? 0
        : -1;
    }
    return -1;
  }

  getLogo(value: string) {
    return `assets/img/airlines/small/${value}.png`;
  }

  getDate(date) {
    return moment(date).format('DD/MM/YYYY HH:mm')
  }

  isValidUrl(value: string): boolean {
    if (!value || value.toLowerCase() === 'airbnb') {
      return false;
    }
    const urlPattern = /^(https?:\/\/)?([\w\-]+\.)+[\w]{2,}(\/\S*)?$/;
    return urlPattern.test(value);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
