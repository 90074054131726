import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormArray, FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { CompanyService, UserService } from "@core/services";
import { NgxToastrService } from "@core/services/ngx.toastr.service";
import { TravelUtilsService } from "@core/services/travel-utils.service";
import { UtilsService } from "@core/services/utils.service";
import { Company, User } from "@models";
import {
  NgbActiveModal,
  NgbDateParserFormatter,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { UserCardModalComponent } from "@shared/component/user-card-modal/user-card-modal.component";
import { AppState } from "app/app.reducer";
import cities from "assets/utils/cities.json";
import hotels from "assets/utils/hotels.json";
import * as moment from "moment";
import { Subscription, take } from "rxjs";
@Component({
  selector: "app-create-custom-hotel",
  templateUrl: "./create-custom-hotel.component.html",
  styleUrls: ["./create-custom-hotel.component.scss"],
})
export class CreateCustomHotelComponent implements OnInit {
  @Input() isAdmin;
  @Input() loading;
  @Input() companyUuid: string;
  @Input() userId: string;
  @Output() sendInfo = new EventEmitter<any>();
  @Input() data;
  editing = false;
  isModal = true;
  hotelsList = [];
  citiesList = [];
  city = "";
  form: UntypedFormGroup;
  prefOptions = [];
  users: User[] = [];
  user: User;
  file: File;
  date = new Date();
  minDateNgStruct = {
    year: this.date.getFullYear() - 1,
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  maxDateNgStruct = {
    year: this.date.getFullYear() + 20,
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  bodyTravel: any = {};
  subscriptions: Subscription[] = [];
  request: any;
  isInvalid: boolean = false
  userType;
  companyUsers: { name: string; lastname: string; uuid: string }[] = [];
  company: Company;
  @ViewChild("departure") departure: ElementRef;

  @Input() blobUrl;
  @Input() fileBack;
  files: File[] = [];
  filesToRemove: any[] = [];
  existingFiles: any[] = [];
  isDropdownOpen = false;
  selectedTravelers = [];

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    private ngbDateParser: NgbDateParserFormatter,
    private travelUtilsService: TravelUtilsService,
    private utilsService: UtilsService,
    private modalService: NgbModal,
    private ngxToastrService: NgxToastrService,
    private userService: UserService,
    private store: Store<AppState>,
    private companyService: CompanyService
  ) { }

  ngOnInit(): void {
    if (this.fileBack) {
      this.existingFiles = Array.isArray(this.fileBack) ? this.fileBack : [this.fileBack];
    }
    if (Array.isArray(this.files[0])) {
      this.files = this.files.reduce((acc, val) => acc.concat(val), []);
    }

    this.data && this.data !== null
      ? (this.editing = true)
      : (this.editing = false);
    if (this.userId) {
      this.userService
        .getUser(this.userId)
        .pipe(take(1))
        .subscribe((user: User) => {
          this.user = user;
          if (this.user?.companyUuid) {
            this.companyService.getCompany(this.user.companyUuid).subscribe(
              (company: Company) => {
                this.company = company;
              },
              (error) => {}
            );
          }
        });
    }
    this.store
      .select("auth")
      .pipe(take(1))
      .subscribe((res) => {
        if (res.isAuthenticated) {
          this.userType = res.user.type;
        }
      });
    this.form = this.formBuilder.group({
      file: [
        this.editing ? this.data.file : '',
        this.fileBack && this.blobUrl ? [] : [],
      ],
      hotel: [
        { value: this.editing
          ? this.data.hotel
            ? this.data.hotel.name
            : this.data.hotelName
          : this.request
            ? this.request.hotelName
            : '', disabled: (this.editing && this.data.codePetition) ||this.userType != "EMPLOYEE" },
        [Validators.required],
      ],
      customCode: [
        this.editing
          ? this.data.customCode
          : this.request
            ? this.request.customCode
            : "",
      ],
      costCenter: [
        this.editing
          ? this.data.costCenter
          : this.request
            ? this.request.costCenter
            : "",
      ],
      city: [
        { value: this.editing
          ? this.data.hotel
            ? this.data.hotel.destinationName
            : this.data.destinationName
          : this.request
            ? this.request.city
            : '', disabled: (this.editing && this.data.codePetition) || this.userType != "EMPLOYEE" },
        [Validators.required],
      ],
      checkIn: [
        this.editing
          ? this.ngbDateParser.parse(
            moment(this.data.checkIn).toString()
          )
          : '',
        [Validators.required],
      ],
      checkOut: [
        this.editing
          ? this.ngbDateParser.parse(
            moment(this.data.checkOut).toString()
          )
          : '',
        [Validators.required],
      ],
      comments: [{ value: this.editing ? this.data.comments : "", disabled: this.userType != "EMPLOYEE" }],
      price: [
        { value: this.editing ? this.data.price.toFixed(2) : '', disabled: this.data?.payment || this.data?.refund || this.userType != "EMPLOYEE" },
        [Validators.required]
      ],
      freeTaxes: [
        { value: this.editing && this.data !== null  && this.data.freeTaxes 
          ? this.data.freeTaxes 
          : false, disabled: this.userType !== "EMPLOYEE" },
          this.editing && this.data.codePetition ? null : Validators.required
      ],
      userUuid: this.userId,
    });

    if (this.editing) {
      if (this.data?.hotel) {
        this.form.addControl(
          "traveler",
          new FormArray(
            Array.isArray(this.data?.userUuid) 
              ? this.data.userUuid.map(uuid => new FormControl(uuid)) 
              : [new FormControl(this.data?.userUuid)]
          )
        );
      } else {
        this.form.addControl(
          "traveler",
          new FormControl(this.data?.userUuid || "", Validators.required)
        );
      }
    } else {
      this.form.addControl(
        "traveler",
        new FormControl(this.userId ? this.userId : "", Validators.required)
      );
    }
    
    if (this.data?.hotel) {
      this.form.addControl('address', new FormControl({ value: this.data ? this.data?.hotel?.address : '', disabled: this.userType != "EMPLOYEE" },
        [Validators.required]
      ));
    }
  
    if (this.form.value.checkIn) {
      this.form.patchValue({ checkIn: { day: this.form.value.checkIn.day, month: this.form.value.checkIn.month + 1, year: this.form.value.checkIn.year  } })
    }
    if (this.form.value.checkOut) {
      this.form.patchValue({ checkOut: { day: this.form.value.checkOut.day, month: this.form.value.checkOut.month + 1, year: this.form.value.checkOut.year } })
    }
    if (this.request) {
      this.request.initDate = moment(this.request.initDate).format(
        "DD/MM/YYYY"
      );
      const valueInit = {
        year: this.request.initDate.toString().split("/")[2] * 1,
        month: this.request.initDate.split("/")[1] * 1,
        day: this.request.initDate.split("/")[0] * 1,
      };
      this.form.patchValue({ checkIn: valueInit });
      this.request.endDate = moment(this.request.endDate).format("DD/MM/YYYY");
      const valueEnd = {
        year: this.request.endDate.toString().split("/")[2] * 1,
        month: this.request.endDate.split("/")[1] * 1,
        day: this.request.endDate.split("/")[0] * 1,
      };
      this.form.patchValue({ checkOut: valueEnd });
    }
  
    if(this.editing && this.utilsService.isAdmin(this.userType?.toLowerCase())){
      this.getCompanyUsers();
    }
  }

  // Buscador de ciudades
  getCities(value: string) {
    this.citiesList = [];
    if (value !== "" && value.length > 2) {
      cities.Table1.forEach((v) => {
        if (
          v["name"]?.toLowerCase().includes(value?.toLowerCase()) &&
          !this.citiesList.includes(v["name"])
        ) {
          this.citiesList.push(v["name"]);
        }
      });
    } else {
      this.citiesList = [];
    }
  }

  get f() {
    return this.form.controls;
  }

  getHotels(value: string) {
    if (value !== "" && this.city !== "") {
      hotels.hotels.forEach((h) => {
        {
          if (
            h["city"]?.toLowerCase().includes(this.city?.toLowerCase()) &&
            !this.hotelsList.includes(h.name)
          ) {
            this.hotelsList.push(h.name);
          }
        }
      });
    } else {
      this.hotelsList = [];
    }
  }

  cleanCitiesList(option) {
    this.city = option;
    this.citiesList = [];
  }

  cleanHotelsList() {
    this.hotelsList = [];
  }

  onSubmit() {
    if (!this.editing) {
      this.showPaymentCardSelector().then((selectedCard) => {
        if (!selectedCard && !this.company.bookedWithoutCard) {
          this.loading = false;
          return;
        }
  
        this.processBookingForm(selectedCard);
      });
    } else {
      this.processBookingForm();
    }
  }
  
  processBookingForm(selectedCard?: any) {
    this.isInvalid = false;
    this.loading = true;
    const travel = this.travelUtilsService.getTravelLocalStorage();
    const body = this.form.getRawValue();
    if (this.data?.hotel) {
      body.traveler = Array.isArray(body.traveler) ? body.traveler : body.traveler ? [body.traveler] : [];
    } else {
      body.traveler = Array.isArray(body.traveler) ? body.traveler[0] || "" : body.traveler || "";
    }
    body["checkIn"] = moment(this.ngbDateParser.format(body["checkIn"]), "DD/MM/YYYY").format("YYYY-MM-DD");
    body["checkOut"] = moment(this.ngbDateParser.format(body["checkOut"]), "DD/MM/YYYY").format("YYYY-MM-DD");
    body["price"] = parseFloat(body["price"].toString().replace(",", ".")).toFixed(2);
    if (this.editing && this.data.codePetition) {
      delete body['freeTaxes'];
    }

    if (this.request || this.user) {
      body['holder'] = this.request ? this.request.holder : this.user ? { name: this.user.name, surname: this.user.lastname } : null;
      body['people'] = this.request?.people ?? null;
      body['rooms'] = this.request?.rooms ?? null;
    }

    if (this.userId) {
      body["userUuid"] = this.userId;
    }
    if (travel && travel.title) {
      body["travelName"] = travel.title;
    }
    if (selectedCard) {
      body["paymentCard"] = {
        name: selectedCard.name,
        token: selectedCard.token,
      };
    }
    body["file"] = this.files.length > 0 ? this.files : [];
    body.file = body.file.flat();
    body["filesToRemove"] = this.filesToRemove;
      this.sendInfo.emit(body);
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0 && this.userType === 'EMPLOYEE') {
      for (let i = 0; i < event.target.files.length; i++) {
        this.files.push(event.target.files[i]);
      }
    }
  }

  removeFile(index: number) {
    const fileToRemove = this.existingFiles[index] || this.files[index];
    if (fileToRemove.url) {
      this.filesToRemove.push(fileToRemove.url);
      this.existingFiles.splice(index, 1);
    } else {
      this.files.splice(index, 1);
    }
  }

  getOutMinDateManual() {
    return this.utilsService.getOutDate(
      this.minDateNgStruct,
      this.form.controls["checkIn"].value
    );
  }

  handleTravel(dataTravel: any) {
    this.bodyTravel = dataTravel;
    this.travelUtilsService.setTravelLocalStorage(this.bodyTravel);
    this.translate.get(["project.form.saved"]).subscribe((resp) => {
      this.ngxToastrService.typeSuccess(resp["project.form.saved"], "");
    });
  }

  closeModalCross() {
    this.modalService.dismissAll();
  }

  getCompanyUsers() {
    this.companyService.getCompanyUsers(this.companyUuid).subscribe(
      (res) => {
        this.companyUsers = res.map(user => ({
          name: user.name,
          lastname: user.lastname,
          uuid: user.uuid
        }));
  
        if (this.data?.hotel) {
          this.selectedTravelers = this.companyUsers.filter(user =>
            (this.data.userUuid || []).includes(user.uuid)
          );
        }
        if (this.data) {
          this.form.patchValue({
            traveler: this.data.userUuid
          });
        }
      },
      (err) => {
        console.error('Error fetching users:', err);
      }
    );
  }

  showPaymentCardSelector(): Promise<any> {
    if (this.company.bookedWithoutCard) {
      return Promise.resolve(null);
    }

    if (!Array.isArray(this.user.paymentCards)) {
      this.user.paymentCards = this.user.paymentCards ? [this.user.paymentCards] : [];
    }

    /* if (!Array.isArray(this.user.personalPaymentCards)) {
      this.user.personalPaymentCards = this.user.personalPaymentCards ? [this.user.personalPaymentCards] : [];
    } */

    if ((!this.user.paymentCards || this.user.paymentCards.length === 0) /* && (!this.user.personalPaymentCards || this.user.personalPaymentCards.length === 0) */) {
      this.translate
        .get(["common.no-card-user-error"])
        .pipe(take(1))
        .subscribe((value) =>
          this.ngxToastrService.typeInfo(
            null,
            value["common.no-card-user-error"]
          )
        );
      return Promise.resolve(null);
    }

    return new Promise((resolve) => {
      const modalRef = this.modalService.open(UserCardModalComponent, {
        windowClass: 'custom-modal-user-card',
        centered: true,
        backdrop: 'static',
        keyboard: false
      });
      modalRef.componentInstance.bookCards = this.user.paymentCards;
      /* modalRef.componentInstance.bookPersonalCards = this.user.personalPaymentCards; */
      modalRef.componentInstance.fromBookSelect = true;
      modalRef.componentInstance.cardSelected.subscribe((selectedCard) => {
        resolve(selectedCard);
      });
      modalRef.result.catch(() => {
        resolve(null);
      });
    });
  }

  isSelected(uuid: string): boolean {
    return this.selectedTravelers.some(user => user.uuid === uuid);
  }
  
  toggleTraveler(user) {
    const travelersArray = this.form.get("traveler") as FormArray;
    const maxSelection = Array.isArray(this.data?.userUuid) ? this.data.userUuid.length : 1;
  
    if (!this.isSelected(user.uuid) && this.selectedTravelers.length >= maxSelection) {
      return;
    }
  
    const index = travelersArray.controls.findIndex(control => control.value === user.uuid);
  
    if (index === -1) {
      travelersArray.push(new FormControl(user.uuid));
      this.selectedTravelers.push(user);
    } else {
      travelersArray.removeAt(index);
      this.selectedTravelers = this.selectedTravelers.filter(u => u.uuid !== user.uuid);
    }
  }
  
  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  
  get selectedTravelersNames(): string {
    return this.selectedTravelers.map(user => `${user.name} ${user.lastname}`).join(", ");
  }

}
