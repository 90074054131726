<app-user-detail
  *ngIf="isAdmin || isEmployee"
  [userUuid]="userToBook"
  (navigateEvent)="goBack()"
></app-user-detail>
<div class="container">
  <!-- TITULO -->
  <span class="component-info heading-5">{{
    "airbnb.custom.request.title-request" | translate
  }}</span>

  <!-- FORMULARIO -->
  <div class="bg-card">
    <div class="service-card-container">
      <div class="selector">
        <span
          class="selector-item"
          *ngIf="canBookHotel || isAdmin"
          (click)="navigate('hotel')"
          >{{ "common.hotels" | translate }}</span
        >
        <span
          class="selector-item"
          *ngIf="canRequestCustomFlight || isAdmin"
          (click)="navigate('flight')"
          >{{ "common.flights" | translate }}</span
        >
        <span
          class="selector-item"
          *ngIf="canRequestCustomTrain || isAdmin"
          (click)="navigate('train')"
          >{{ "common.trains" | translate }}</span
        >
        <span
          class="selector-item"
          *ngIf="canRequestCustomCar || isAdmin"
          (click)="navigate('car')"
          >{{ "common.cars" | translate }}</span
        >
        <span
          class="selector-item-selected"
          *ngIf="canRequestAirbnb || isAdmin"
          >{{ "airbnb.custom.request.option" | translate }}</span
        >
      </div>

      <form
        *ngIf="form"
        [formGroup]="form"
        [ngClass]="{ 'loading-background': loading }"
        (ngSubmit)="form.valid && !loading ? onSubmit() : (isInvalid = true)"
      >

      <div *ngIf="loading" class="row">
        <app-loading [userType]="'user'"></app-loading>
      </div>

        <div class="row" *ngIf="isModal && !userUuid">
          <div class="col-12">
            <div class="form-group">
              <mat-label>{{
                type + ".create-" + type + ".choose-employee" | translate
              }}</mat-label>
              <select
                class="form-control rounded-1 w-25"
                formControlName="userUuid"
              >
                <option
                  *ngFor="
                    let user of users;
                    trackBy: utilsService.getUuidTracking
                  "
                  [ngValue]="user"
                >
                  {{ user.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="car-field">
            <input
              formControlName="city"
              type="text"
              id="city"
              name="city"
              class="custom-input city form-control form-control--no-border"
              matInput
              [ngClass]="{
                'input-error': !form.controls.city.valid && isInvalid
              }"
              [matAutocomplete]="fromM"
              (input)="getCities($event.target.value)"
              placeholder="{{ 'car.custom.request.ubication' | translate }}"
            />
            <mat-autocomplete
              #fromM="matAutocomplete"
              panelWidth="20%"
              (optionSelected)="cleanCitiesList($event.option.value)"
            >
              <mat-option *ngFor="let option of citiesList" [value]="option">
                {{ option }}
              </mat-option>
            </mat-autocomplete>
          </div>
          <div class="car-field">
            <input
              formControlName="link"
              type="text"
              id="link"
              class="custom-input link form-control form-control--no-border"
              matInput
              name="link"
              [ngClass]="{ error: form.controls['link'].invalid }"
              placeholder="{{ 'airbnb.custom.request.link' | translate }}"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="travel-date-field" [formGroup]="form">
            <input
              #bookedRange
              readonly
              class="date-input form-control form-control--no-border"
              (dateSelect)="onDateSelection($event)"
              placeholder="{{ 'hotel.custom.select-date' | translate }}"
              #dp
              ngbDatepicker
              [dayTemplate]="t1"
              [autoClose]="'outside'"
              [displayMonths]="1"
              #d1="ngbDatepicker"
              (click)="d1.toggle()"
              (closed)="onDatepickerClosed()"
              [minDate]="minDateNgStruct"
              [maxDate]="maxDateNgStruct"
              [ngClass]="{
                'input-error':
                  (!form.controls.checkIn.valid ||
                    !form.controls.checkOut.valid) &&
                  isInvalid
              }"
            />
            <ng-template #t1 let-date let-focused="focused">
              <span
                class="custom-day"
                [ngClass]="{
                  activeFrom: form.controls['checkIn'].value === date,
                  activeTo: form.controls['checkOut'].value === date
                }"
                [class.focused]="focused"
                [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)"
                (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null"
              >
                {{ date.day }}
              </span>
            </ng-template>
          </div>
          <div class="traveler-select-container">
            <div class="form-control form-control--no-border" (click)="openTravelerSelector()">
              <label class="input-icon cursor-pointer">
                <span>
                  {{
                    searchConfig.travelers != null
                      ? searchConfig.travelers.length
                      : "0"
                  }}
                  {{ "airbnb.custom.request.guests" | translate | lowercase }}
                </span>
                <img src="../../../../../../assets/img/svg/add.svg" />
              </label>
            </div>
          </div>
          <div class="car-field image-upload-container">
            <!-- TODO: Uncomment image upload when backend ready -->
            <!-- <input
              formControlName="images"
              type="text"
              id="images"
              class="custom-input images"
              matInput
              [matAutocomplete]="toM"
              name="images"
              [ngClass]="{ error: form.controls['images'].invalid }"
              (input)="getCities($event.target.value)"
              placeholder="{{ 'airbnb.custom.request.images' | translate }}"
            /> -->
          </div>
        </div>
        <div class="form-row">
          <div class="annotations">
            <mat-label class="annotations-label">{{
              "hotel.custom.annotations" | translate
            }}</mat-label>
            <textarea
              matInput
              class="annotation-textarea form-control form-control--no-border"
              formControlName="comments"
              placeholder="{{
                'hotel.custom.annotations-placeholder' | translate
              }}"
            ></textarea>
          </div>
        </div>
        <div class="form-row" *ngIf="isAdmin">
          <div class="car-field">
            <input
              formControlName="customCode"
              type="text"
              id="customCode"
              class="form-control form-control--no-border"
              matInput
              name="customCode"
              placeholder="{{ 'common.custom-code' | translate }}"
            />
          </div>
          <div class="car-field">
            <input
              formControlName="costCenter"
              type="text"
              id="costCenter"
              class="form-control form-control--no-border"
              matInput
              name="costCenter"
              placeholder="{{ 'common.cost-center' | translate }}"
            />
          </div>
        </div>
        <div class="button-row">
          <button
            [ngStyle]="{
              cursor: !form.valid || loading ? 'not-allowed' : 'pointer'
            }"
            [ngClass]="{
              'custom-btn disabled-button': !form.valid || loading, 'custom-btn': form.valid || !loading
            }"
          >
            {{ "common.send-request" | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
