import { MapsAPILoader } from "@ng-maps/core";
import { Injectable } from "@angular/core";
import { Hotel } from "@core/models";
import { BehaviorSubject, from, Observable, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
@Injectable({
  providedIn: "root",
})
export class GoogleMaps {
  latitude;
  longitude;
  hotels: Hotel[];
  private geocoder: any;
  private apiLoaded$ = new BehaviorSubject<boolean>(false);

  constructor(private mapLoader: MapsAPILoader) {
    this.loadAPI();
  }

  private loadAPI() {
    this.mapLoader.load().then(() => {
      this.initGeocoder();
      this.apiLoaded$.next(true);
    });
  }
  
  private initGeocoder() {
    this.geocoder = new google.maps.Geocoder();
  }
  private waitForMapsToLoad(): Observable<boolean> {
    if (!this.geocoder) {
      return from(this.mapLoader.load()).pipe(
        tap(() => this.initGeocoder()),
        map(() => true)
      );
    }
    return of(true);
  }
  geocodeAddress(location: string): Observable<any> {
    return this.waitForMapsToLoad().pipe(
      // filter(loaded => loaded),
      switchMap(() => {
        return new Observable((observer) => {
          this.geocoder.geocode({ address: location }, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
              observer.next({
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
              });
            } else {
              observer.next({ lat: 0, lng: 0 });
            }
            observer.complete();
          });
        });
      })
    );
  }

  geocodeLatLng(lat: number, lng: number): Observable<any> {
    return this.waitForMapsToLoad().pipe(
      switchMap(() => {
        return new Observable((observer) => {
          const latlng = { lat, lng };
          this.geocoder.geocode({ location: latlng }, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK && results[0]) {
              const addressComponents = results[0].address_components;
              let city = '';
              let country = '';
              let street = '';
              let adminArea1 = '';
              let adminArea2 = '';
  
              addressComponents.forEach(component => {
                if (component.types.includes('locality')) {
                  city = component.long_name;
                } else if (component.types.includes('administrative_area_level_2')) {
                  adminArea2 = component.long_name;
                } else if (component.types.includes('administrative_area_level_1')) {
                  adminArea1 = component.long_name;
                } else if (component.types.includes('country')) {
                  country = component.long_name;
                } else if (component.types.includes('route')) {
                  street = component.long_name;
                }
              });

              if (!city) {
                city = adminArea2 || adminArea1 || country || '';
              }
  
              observer.next({
                lat: lat,
                lng: lng,
                city: city,
                country: country,
                street: street,
                fullAddress: results[0].formatted_address
              });
            } else {
              observer.next(null);
            }
            observer.complete();
          });
        });
      })
    );
  }

  setCoordinates(lat, lon) {
    this.latitude = lat;
    this.longitude = lon;
  }

  getCoordinates() {
    return { latitude: this.latitude, longitude: this.longitude };
  }

  setListHotels(hotels: Hotel[]) {
    this.hotels = hotels;
  }

  getListHotels() {
    return this.hotels;
  }
}
