import { Location } from "@angular/common";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NavigationExtras, Router } from "@angular/router";
import { Company, User } from "@core/models";
import {
  CompanyService,
  NgxToastrService,
  TravelUtilsService,
  UserService,
} from "@core/services";
import {
  NgbActiveModal,
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { UserCardModalComponent } from "@shared/component/user-card-modal/user-card-modal.component";
import { AppState } from "app/app.reducer";
import cities from "assets/utils/cities.json";
import moment from "moment";
import { Subscription, take } from "rxjs";

@Component({
  selector: "app-create-airbnb",
  templateUrl: "./create-airbnb.component.html",
  styleUrls: ["./create-airbnb.component.scss"],
})
export class CreateAirbnbComponent implements OnInit {
  public get ngbDateParser(): NgbDateParserFormatter {
    return this._ngbDateParser;
  }
  public set ngbDateParser(value: NgbDateParserFormatter) {
    this._ngbDateParser = value;
  }
  @Input() isModal?;
  @Input() userUuid?;
  @Input() companyUuid?;
  @Input() userId: string;
  type = "airbnb";
  subscriptions: Subscription[] = [];
  canBookHotel: boolean;
  canRequestCustomFlight: boolean;
  canRequestCustomTrain: boolean;
  canRequestCustomCar: boolean;
  canRequestAirbnb: boolean;
  user: User;
  authUserUuid = "";
  userType: string;
  form: FormGroup;
  date = new Date();
  hoveredDate: NgbDateStruct;
  minDateNgStruct = {
    year: this.date.getFullYear(),
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  maxDateNgStruct = {
    year: this.date.getFullYear() + 20,
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  city = "";
  citiesList = [];
  userImageBase64: string;
  isEmployee = false;
  canUseTRM: boolean;
  userToBook: string;
  bookedUserAccessType: string;
  isInvalid: boolean = false
  @ViewChild("bookedRange") bookedRange: ElementRef;
  @ViewChild("createdAtRange") createdAtRange: ElementRef;
  @Output() checkOutEmit = new EventEmitter();
  @Output() checkInEmit = new EventEmitter();
  @Input() isAdmin;
  @Input() loading: Boolean;
  @Output() sendInfo = new EventEmitter<any>();
  request: any;
  company: Company;
  @Input() blobUrl;
  @Input() fileBack;
  files: File[] = [];
  filesToRemove: any[] = [];
  existingFiles: any[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    private _ngbDateParser: NgbDateParserFormatter,
    private router: Router,
    private store: Store<AppState>,
    private travelUtilsService: TravelUtilsService,
    private userService: UserService,
    private location: Location,
    private companyService: CompanyService,
    private ngxToastrService: NgxToastrService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    if (this.fileBack) {
      this.existingFiles = Array.isArray(this.fileBack) ? this.fileBack : [this.fileBack];
    }
    if (this.userId) {
      this.userService
        .getUser(this.userId)
        .pipe(take(1))
        .subscribe((user: User) => {
          this.user = user;
          if (this.user?.companyUuid) {
            this.companyService.getCompany(this.user.companyUuid).subscribe(
              (company: Company) => {
                this.company = company;
              },
              (error) => {}
            );
          }
        });
    }
    this.store
    .select("auth")
    .pipe(take(1))
    .subscribe((res) => {
      if (res.isAuthenticated) {
        this.userType = res.user.type;
      }
    });
    this.form = this.formBuilder.group({
      checkIn: [null, [Validators.required]],
      checkOut: [ null, [Validators.required]],
      link: [ this.request ? this.request.hotelName : null ],
      city: [this.request ? this.request.city : ''],
      travelers: [ this.request ? this.request.people : 1, [Validators.required] ],
      price: [ this.request ? this.request.price : null, [Validators.required] ],
      comments: [this.request ? this.request.comments : ""],
      userUuid: this.userUuid,
      companyUuid: this.companyUuid,
      customCode: [this.request ? this.request.customCode : ''],
      costCenter: [this.request ? this.request.costCenter : ''],
      file: [
        this.fileBack ? this.fileBack : "", 
        this.fileBack ? [] : []
      ],
    });
   
    if (this.request) {
      const formattedInitDate = moment(this.request.initDate).format("DD/MM/YYYY");
      const formattedEndDate = moment(this.request.endDate).format("DD/MM/YYYY");
    
      const valueInit = {
        year: moment(formattedInitDate, "DD/MM/YYYY").year(),
        month: moment(formattedInitDate, "DD/MM/YYYY").month() + 1,
        day: moment(formattedInitDate, "DD/MM/YYYY").date(),
      };
      this.form.patchValue({ checkIn: valueInit });
    
      const valueEnd = {
        year: moment(formattedEndDate, "DD/MM/YYYY").year(),
        month: moment(formattedEndDate, "DD/MM/YYYY").month() + 1,
        day: moment(formattedEndDate, "DD/MM/YYYY").date(),
      };
      this.form.patchValue({ checkOut: valueEnd });
    }
  }


  get f() {
    return this.form.controls;
  }

  closeModal() {
    this.activeModal.dismiss("Cross click");
  }

  // Buscador de ciudades
  getCities(value: string) {
    this.citiesList = [];
    if (value !== "" && value.length > 2) {
      cities.Table1.forEach((v) => {
        if (
          v["name"]?.toLowerCase().includes(value?.toLowerCase()) &&
          !this.citiesList.includes(v["name"])
        ) {
          this.citiesList.push(v["name"]);
        }
      });
    } else {
      this.citiesList = [];
    }
  }

  goBack() {
    this.location.back();
  }

  navigate(route: string) {
    const navigationExtra: NavigationExtras = {
      state: {
        canBookHotel: this.canBookHotel,
        canRequestCustomFlight: this.canRequestCustomFlight,
        canRequestCustomTrain: this.canRequestCustomTrain,
        canRequestCustomCar: this.canRequestCustomCar,
        canRequestAirbnb: this.canRequestAirbnb,
        userUuid: this.userToBook ? this.userToBook : this.authUserUuid,
        companyUuid: this.companyUuid,
        userImageBase64: this.userImageBase64,
      },
    };

    this.router.navigate(["custom-services/" + route], navigationExtra);
  }

  cleanCitiesList(option) {
    this.city = option;
    this.citiesList = [];
  }

  getOutDate() {
    return this.f.checkIn ? this.f.checkIn.value : this.minDateNgStruct;
  }

  onSubmit() {
      this.showPaymentCardSelector().then((selectedCard) => {
        if (!selectedCard && !this.company.bookedWithoutCard) {
          this.loading = false;
          return;
        }

        this.processBookingForm(selectedCard);
      });
  }

  processBookingForm(selectedCard?: any) {
    this.isInvalid = false;
    this.loading = true;
    const body = this.form.getRawValue();
    body["file"] = this.files.length > 0 ? this.files : [];
    body["filesToRemove"] = this.filesToRemove;
    const travel = this.travelUtilsService.getTravelLocalStorage();
    body["checkIn"] = moment(
      this.ngbDateParser.format(body["checkIn"]),
      "DD/MM/YYYY"
    ).format("YYYY-MM-DD");
    body["checkOut"] = moment(
      this.ngbDateParser.format(body["checkOut"]),
      "DD/MM/YYYY"
    ).format("YYYY-MM-DD");
    if (travel && travel.title) {
      body["travelName"] = travel.title;
    }
    body["userUuid"] = this.userUuid;

    if (selectedCard) {
      body["paymentCard"] = {
        name: selectedCard.name,
        token: selectedCard.token,
      };
    }
    this.sendInfo.emit(body);

  }

  showPaymentCardSelector(): Promise<any> {
    if (this.company.bookedWithoutCard) {
      return Promise.resolve(null);
    }
  
    if (!Array.isArray(this.user.paymentCards)) {
      this.user.paymentCards = this.user.paymentCards ? [this.user.paymentCards] : [];
    }

    /* if (!Array.isArray(this.user.personalPaymentCards)) {
      this.user.personalPaymentCards = this.user.personalPaymentCards ? [this.user.personalPaymentCards] : [];
    } */
  
    if ((!this.user.paymentCards || this.user.paymentCards.length === 0) /* && (!this.user.personalPaymentCards || this.user.personalPaymentCards.length === 0) */) {
      this.translate
        .get(["common.no-card-user-error"])
        .pipe(take(1))
        .subscribe((value) =>
          this.ngxToastrService.typeInfo(
            null,
            value["common.no-card-user-error"]
          )
        );
      return Promise.resolve(null);
    }
  
    return new Promise((resolve) => {
      const modalRef = this.modalService.open(UserCardModalComponent, {
        windowClass: 'custom-modal-user-card',
        centered: true,
        backdrop: 'static',
        keyboard: false
      });
      modalRef.componentInstance.bookCards = this.user.paymentCards;
      /* modalRef.componentInstance.bookPersonalCards = this.user.personalPaymentCards; */
      modalRef.componentInstance.fromBookSelect = true;
      modalRef.componentInstance.cardSelected.subscribe((selectedCard) => {
        resolve(selectedCard);
      });
      modalRef.result.catch(() => {
        resolve(null);
      });
    });
  }

  onFileChange(event) {
    for (let i = 0; i < event.target.files.length; i++) {
            this.files.push(event.target.files[i]);
    }
  }

  removeFile(index: number) {
    const fileToRemove = this.existingFiles[index] || this.files[index];
    if (fileToRemove.url) {
      this.filesToRemove.push(fileToRemove.url);
      this.existingFiles.splice(index, 1);
    } else {
      this.files.splice(index, 1);
    }
  }

  emitDates() {
    this.checkInEmit.emit(this.form.controls["checkIn"].value);
    this.checkOutEmit.emit(this.form.controls["checkOut"].value);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
