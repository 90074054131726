<section id="user-profile">
  <div class="row">
    <div class="col-12">
      <div
        class="card"
        [ngStyle]="{ background: isBookeds ? '#FCFEE8' : '#FFFFFF' }"
      >
        <div class="media profil-cover-details row">
          <div class="col-12 text-center">
            <div class="back-arrow">
              <img
                class="cursor-pointer"
                src="../../../../../assets/img/svg/arrow-back.svg"
                width="24px"
                alt="back-arrow"
                (click)="navigateEvent.emit()"
              />
            </div>
            <div class="img-space">
              <ng-container *ngIf="image; else elseTemplate">
                <div class="user-image">
                  <img [src]="image" />
                </div>
              </ng-container>
              <ng-template #elseTemplate>
                <div class="user-image">
                  <div class="profile-initials text-white">
                    {{ user?.name[0] }}{{ user?.lastname[0] }}
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <div
          class="oneOrder col-lg-4 col-md-4 col-xs-12 text-center mb-2 user-data"
        >
          <p>{{ user ? user.name : "" }} {{ user ? user.lastname : "" }}</p>
          <p>{{ department ? department.name : "" }}</p>
        </div>
      </div>
    </div>
  </div>
</section>
