<div class="row mt-2">
  <div class="col-12">
    <div class="row">
      <div class="col-12">
        <h3 class="heading-5" *ngIf="firstSearch">
          {{ "common.searcher" | translate }}
        </h3>
        <h3 class="heading-5" *ngIf="!firstSearch">
          {{ "common.result" | translate }}
        </h3>
      </div>
    </div>
    <app-user-detail
      *ngIf="(isAdmin && canUseTRM) || isEmployee"
      [userUuid]="userToBook"
      (navigateEvent)="goBack()"
    ></app-user-detail>
    <div class="row">
      <!-- BUSCADOR -->
      <app-search-form
        style="width: 100%"
        [ngStyle]="{ cursor: !firstSearch ? 'pointer' : '' }"
        (click)="!firstSearch ? openSearchModal() : ''"
        (emitData)="newSearch($event)"
        (emitSelectedUsers)="handleSelectedUsers($event)"
        (emitSelectedTravelers)="handleSelectedTravelers($event)"
        (emitProject)="handleSelectedProjects($event)"
        [firstSearch]="firstSearch"
        [searchConfig]="searchConfig"
        [companyUuid]="companyUuid"
        [userToBook]="userToBook"
        [uuidUser]="uuidUser"
        [isAdmin]="isAdmin"
        [groupFilters]="groupFilters"
      ></app-search-form>
    </div>
    <div class="row mt-4">
      <!-- FILTROS-->
      <div
        class="col-md-3 col-sm-12 card-filter"
        *ngIf="!firstSearch || utilsService.loadingHotels"
      >
        <div class="col-12">
          <h5 class="mt-2 ml-3">
            {{ "search.search-homepage.order-by" | translate }}
          </h5>
        </div>
        <div class="col-12">
          <h6 class="mt-2 ml-3">{{ "search.filters.stars" | translate }}</h6>
          <div class="form-group ml-2 my-auto col-12" id="rowStars">
            <div class="stars-row">
              <span class="stars-style">
                <div *ngFor="let i of [0,1,2,3,4]">
                  <i *ngIf="groupFilters && groupFilters.stars > i && STARS_FILTERS[i]" (click)="starsFilter(i)" class="fa fa-star yellow darken-2"></i>
                  <i *ngIf="groupFilters && groupFilters.stars > i && !STARS_FILTERS[i]" (click)="starsFilter(i)" class="fa fa-star-o yellow darken-2"></i>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div class="col-12">
          <h6 class="mt-2 ml-2 col-12">
            {{ "search.filters.title" | translate }}
          </h6>
          <div class="form-group ml-2 my-auto col-12" id="rowFilters">
            <div class="row">
              <div class="col-12" *ngFor="let filter of filters">
                <div class="px-sm-3 px-xs-5 custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    name="filtersHotel"
                    [checked]="isChecked(filter.code)"
                    [id]="filter.code"
                    (change)="applyFilters($event)"
                  />
                  <label
                    class="custom-control-label labelFilterSearch"
                    [for]="filter.code"
                    >{{ filter.name }}</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <h6 class="mt-2 ml-2 col-12">
            {{ "agency.search.filters.price" | translate }}
          </h6>
          <div class="col-12">
            <div class="custom-slider">
              <ngx-slider
                (userChangeEnd)="priceFilter($event)"
                [(value)]="value"
                [(highValue)]="highValue"
                [options]="options"
              ></ngx-slider>
            </div>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-center">
          <button
            id="filterSearchBtn"
            class="custom-btn my-auto"
            (click)="applyFiltersAction()"
          >
            {{ "common.search" | translate }}
          </button>
        </div>
      </div>
      <!-- FIN FILTROS -->
      <div
        class="col-md-9 col-sm-12 empty-results"
        *ngIf="
          !firstSearch && (!hotelsList || hotelsList.length === 0) && !utilsService.loadingHotels
        "
      >
        <img src="assets/img/svg/empty-results.svg" alt="Empty results" />
        <p class="no-results">
          Ups... {{ "agency.search.search-homepage.no-results" | translate }}
        </p>
        <p>{{ "common.change-search" | translate }}</p>
      </div>
      <div *ngIf="utilsService.loadingHotels && firstSearch" class="skeleton">
        <div class="ph-item">
          <div class="ph-col-12">
            <div class="ph-row">
              <div class="ph-col-2 big"></div>
            </div>
          </div>
        </div>
        <div class="ph-item">
          <div class="ph-col-6">
            <div class="ph-gap ph-row-picture">
              <span class="ph-picture"></span>
              <div class="ph-col-8 empty">
                <div class="ph-row ph-gap-small ph-no-margin">
                  <div class="ph-col-4 ph-first"></div>
                  <div class="ph-col-2 empty"></div>
                  <div class="ph-col-6"></div>
                  <div class="ph-col-2 empty"></div>
                  <div class="ph-col-6"></div>
                  <div class="ph-col-4 empty"></div>
                  <div class="ph-col-2 big"></div>
                  <div class="ph-col-6 empty"></div>
                  <div class="ph-col-2 big"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="ph-col-6">
            <div class="ph-picture"></div>
          </div>
        </div>
      </div>
      <div
        class="col-md-9 col-sm-12"
        *ngIf="hotelsList && hotelsList.length > 0"
      >
        <div class="nav-container">
          <div class="nav">
            <ul ngbNav #nav="ngbNav" [(activeId)]="navActive" class="nav-tabs">
              <!-- TAB ALL -->
              <li [ngbNavItem]="1" (click)="showHotels = true">
                <a ngbNavLink>
                  <img
                    src="{{
                      navActive === 1
                        ? 'assets/img/svg/list-white.svg'
                        : 'assets/img/svg/list.svg'
                    }}"
                  />
                  {{ "common.list" | translate }}</a
                >
                <ng-template ngbNavContent>
                  <!-- HOTELES -->
                  <div class="row" id="listMap" *ngIf="!utilsService.loadingHotels">
                    <div
                      id="scrollableList"
                      class="col-md-7 col-sm-12"
                      *ngIf="showHotels"
                      style="min-width: 100%"
                    >
                      <div class="col-12 nopadding">
                        <div class="contentListHotels">
                          <app-search-card
                            *ngFor="
                              let hotel of hotelsList;
                              let last = last;
                              index as i
                            "
                            [mapHasBeenDragged]="mapHasBeenDragged"
                            [firstSearch]="firstSearch"
                            [hotelsMapCopy]="hotelMapsCopy"
                            [hotelsListCopy]="hotelListCopy"
                            [hotel]="hotel"
                            [canRequest]="canRequest"
                            [searchConfig]="searchConfig"
                            [originalSearchConfig]="originalSearchConfig"
                            [selectedUsers]="selectedUsers"
                            [selectedTravelers]="selectedTravelers"
                            [projectData]="projectData"
                            [index]="i"
                            #item
                          >
                          </app-search-card>
                          <div
                            class="card horizontal"
                            id="endOfListCard"
                            *ngIf="isLastPage"
                          >
                            <div class="card-content">
                              <p>
                                {{
                                  "hotel.max-range-reached.title" | translate
                                }}
                              </p>
                              <button
                                class="custom-btn my-auto"
                                (click)="loadMoreHotelsPage()"
                              >
                                {{
                                  "hotel.max-range-reached.subtitle" | translate
                                }}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          *ngIf="loadingHotels"
                          class="ph-item"
                          id="loading-shadow"
                        >
                          <div class="card horizontal">
                            <div id="cardContent" class="row mx-0 card-content">
                              <div id="hotelImage" class="card-img">
                                <div id="hotelImages" class="carousel slide" data-ride="carousel" wrap="true">
                                  <div class="carousel-inner">
                                    <div class="carousel-item active" >
                                      <img alt="no-image">
                                    </div>
                                  </div>
                                  <a class="carousel-control-prev" href="#hotelImages" role="button" data-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="sr-only">Previous</span>
                                  </a>
                                  <a class="carousel-control-next" href="#hotelImages" role="button" data-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="sr-only">Next</span>
                                  </a>
                                </div>
                                  <ng-template ngbSlide>
                                    <img alt="no-image" />
                                  </ng-template>
                                </div>
                              <div id="hotelData" class="col-12 card-stacked">
                                <div class="row">
                                  <h4 class="card-title"></h4>
                                  <div class="text-left divStarts pl-1"></div>
                                </div>
                                <div class="row">
                                  <div class="row">
                                    <div>
                                      <p class="card-text col-12"></p>
                                      <p class="card-text col-7"></p>
                                    </div>
                                    <div class="col-5 facilities-container">
                                      <div class="facilities"></div>
                                    </div>
                                  </div>
                                  <div class="row price">
                                    <div class="my-auto py-1 px-2">
                                      <h5 class="text-bold-600 my-auto"></h5>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12">
                                      <span> </span>
                                      <span
                                        style="margin-top: 0; color: orange"
                                      >
                                        <span
                                          class="material-icons mr-0 px-0"
                                        ></span>
                                      </span>
                                      <!-- <span *ngIf="
                                          hotel.userBookingOption === 1
                                        " style="margin-top: 0; color: #52c9ad"><span class="material-icons mr-0 px-0"> checked </span>
                                            {{"search.card.allPreAuthorized" | translate }}</span> -->
                                    </div>
                                  </div>
                                </div>
                                <div class="row" id="colButtonGetHotel">
                                  <div class="dropdown">
                                    <a
                                      ><img
                                        src="assets/img/svg/black-map.svg" />
                                      <img
                                        src="assets/img/svg/ionic-ios-arrow-down.svg"
                                    /></a>
                                  </div>
                                  <div class="text-right"></div>
                                </div>
                                <div *ngIf="mapView" class="row map">
                                  <app-google-maps
                                    [listHotels]="hotelMaps"
                                    [firstSearch]="firstSearch"
                                    [mapHasBeenDragged]="mapHasBeenDragged"
                                    [searchConfig]="searchConfig"
                                    [fromCard] = "false"
                                    style="height: 100%; width: 100%"
                                  ></app-google-maps>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      *ngIf="length > paginationConf.pageSize"
                      class="d-flex justify-content-center mt-2 mb-2 col-12"
                    >
                      <mat-paginator
                        #paginator
                        [length]="length"
                        [pageSize]="paginationConf.pageSize"
                        (page)="onPageChange($event)"
                        showFirstLastButtons
                        [hidePageSize]="true"
                        style="border-radius: 15px"
                      >
                      </mat-paginator>
                    </div>
                  </div>
                </ng-template>
              </li>
              <li [ngbNavItem]="2" (click)="showHotels = false">
                <a ngbNavLink>
                  <img
                    src="{{
                      navActive === 2
                        ? 'assets/img/svg/map-white.svg'
                        : 'assets/img/svg/map.svg'
                    }}"
                  />
                  {{ "common.map" | translate }}</a
                >
                <ng-template ngbNavContent>
                  <app-google-maps
                    [listHotels]="hotelMaps"
                    [hotelsMapCopy]="hotelMapsCopy"
                    [hotelsListCopy]="hotelListCopy"
                    [singleSearch]="false"
                    [firstSearch]="firstSearch"
                    [mapHasBeenDragged]="mapHasBeenDragged"
                    [searchConfig]="searchConfig"
                    [canRequest]="canRequest"
                    [fromCard] = "false"
                    [selectedUsers]="selectedUsers"
                    [selectedTravelers]="selectedTravelers"
                    (zoomOutEvent)="loadMoreHotelsPage()"
                    style="height: 100%; width: 100%"
                  ></app-google-maps>
                  <div
                    class="card horizontal"
                    id="endOfListCard"
                    *ngIf="isLastPage || navActive === 2"
                  >
                    <div class="card-content">
                      <p>
                        {{ "hotel.max-range-reached.title" | translate }}
                      </p>
                      <button
                        class="custom-btn my-auto"
                        (click)="loadMoreHotelsPage()"
                      >
                        {{ "hotel.max-range-reached.subtitle" | translate }}
                      </button>
                    </div>
                  </div>
                </ng-template>
              </li>
            </ul>
            <ul class="nav-tabs nav" *ngIf="showHotels">
              <li (click)="setFilter('distance'); filter()" class="nav-item">
                <a
                  class="nav-link"
                  [ngClass]="this.order.order === 'distance' ? 'sorted' : ''"
                >
                  {{ "common.distance" | translate }}
                </a>
              </li>
              <li (click)="setFilter('price'); filter()">
                <a
                  class="nav-link"
                  [ngClass]="this.order.order === 'price' ? 'sorted' : ''"
                >
                  {{ "agency.search.search-homepage.price" | translate }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>
</div>
