<div *ngIf="isLoadingTravel" style="display: block; margin: auto; z-index: 999999999">
  <app-loading></app-loading>
</div>
<div *ngIf="isCompanyBookeds" id="content-title">
  <div id="goBackButtonContainer">
    <button mat-button mat-flat-button (click)="goBack()">
      <img src="assets/img/svg/arrow-back.svg" alt="" width="24px" />
    </button>
  </div>
  <div id="logo-container">
    <img src="{{ companyImage ? companyImage : 'assets/img/gallery/image_not_available.png' }}" class="company-logo" alt="Company logo" />
  </div>
</div>
<!-- EMPTY STATE -->
<div class="text-center" *ngIf="travels && travels.length <= 0 && !isLoading && !isLoadingTravel">
  <img
    class="not_found_image"
    src="assets/img/gallery/flights-not-found.svg"
    alt="No hay archivos"
  />
  <h3 class="mt-4">
    <b>{{ "project.no-projects" | translate }}</b>
  </h3>
  <span>{{ "project.do-projects" | translate }}</span>
</div>

<div class="col-12 d-flex justify-content-center mt-3" *ngIf="isCompanyBookeds && !isLoading && !isLoadingTravel && travels && travels.length <= 0">
  <li class="custom-btn custom-btn--yellow" [ngClass]="{'disabled': loading || !utilsService.allLoaded}">
    <a (click)="addTravel()">{{ "project.add" | translate }}</a>
  </li>
</div>

<div
  *ngIf="travels && travels.length > 0"
  [ngStyle]="{ 'padding-top': isAdmin ? '1rem' : '0' }"
>
  <div class="container-customize">
    <form [formGroup]="form">
      <!-- FILTER -->
      <div class="column">
        <div class="row">
          <div class="filters">
            <div>
              <!-- User First name filter -->
              <input
                (input)="setPage()"
                class="form-control form-control--squared form-control--small"
                [formControlName]="'title'"
                id="title-input"
                placeholder="{{ 'project.my-projects.title' | translate }}"
              />
            </div>
            <div>
              <input
                #createdAtRange
                class="form-control form-control--squared form-control--small filter-dates"
                (dateSelect)="onDateSelection($event)"
                placeholder="{{ 'common.creation-date' | translate }}"
                #dp
                ngbDatepicker
                [dayTemplate]="t2"
                [autoClose]="true"
                [autoClose]="'outside'"
                #d2="ngbDatepicker"
                readonly
                (click)="d2.toggle()"
                [ngClass]="!createdAtRange.value ? 'filter-dates-placeholder' : ''"
              />
              <ng-template #t2 let-date let-focused="focused">
                <span
                  class="custom-day"
                  [ngClass]="{
                    activeFrom: createdFromDate === date,
                    activeTo: createdToDate === date
                  }"
                  [class.focused]="focused"
                  [class.range]="isRange(date, 'create')"
                  [class.faded]="
                    isHovered(date) || isInside(date)
                  "
                  (mouseenter)="hoveredDate = date"
                  (mouseleave)="hoveredDate = null"
                >
                  {{ date.day }}
                </span>
              </ng-template>
            </div>
            <div>
              <button
                type="button"
                class="clear-btn"
                [ngClass]="
                  f.title.value || createdFromDate || createdToDate
                    ? 'btn-outline-danger btn-danger'
                    : ''
                "
                (click)="clearFilters()"
              >
                {{ "common.clean" | translate }}
                <img
                  src="{{
                    f.title.value || createdFromDate || createdToDate
                      ? 'assets/img/svg/red-cross.svg'
                      : 'assets/img/svg/grey-cross.svg'
                  }}"
                />
              </button>
            </div>
          </div>
          <li *ngIf="isCompanyBookeds" class="custom-btn custom-btn--yellow" [ngClass]="{'disabled': loading || !utilsService.allLoaded}">
            <a (click)="addTravel()">{{ "project.add" | translate }}</a>
          </li>
        </div>
      </div>
      <div class="table-container">
        <table class="custom-table">
          <thead>
            <th>
              {{ "project.my-projects.title" | translate }}
            </th>
            <th>
              {{ "project.my-projects.description" | translate }}
            </th>
            <th>
              {{ "common.creation-date" | translate }}
            </th>
            <th>
              {{ "project.my-projects.total" | translate }}
            </th>
            <th>
              {{ "project.my-projects.creator" | translate }}
            </th>
            <th>
              {{ "project.my-projects.initDate" | translate }}
            </th>
            <th>
              {{ "project.my-projects.endDate" | translate }}
            </th>
            <th></th>
          </thead>
          <tbody>
            <!-- DATA TABLE -->
            <tr
              *ngFor="
                let travel of travels
                  | filterBooked
                    : ['title', 'createdAt']
                    : [f.title.value, [this.createdFromDate, this.createdToDate]]
                    : ['string', 'date']
                  | slice: lowValue:highValue;
                trackBy: utilsService.getUuidTracking
              "
            >
              <td class="max-width-cell">
                <p>{{ travel.title }}</p>
              </td>
              <td class="max-width-cell">
                <p>
                  {{ travel.description }}
                </p>
              </td>
              <td class="max-width-cell">
                <p>{{ transformDate(travel.createdAt) }}</p>
              </td>
              <td class="max-width-cell">
                <p>{{ projectTotal(travel) | price }}</p>
              </td>
              <td class="max-width-cell">
                <p>
                  {{
                    travel.creator ? travel.creator.name : "VYOOTRIP SOLUTIONS"
                  }}
                  {{ travel.creator ? travel.creator.lastname : "S L" }}
                </p>
              </td>
              <td class="max-width-cell max-width-cell--date">
                <p *ngIf="travel.initDate">
                  {{ transformDate(travel.initDate) }}
                </p>
              </td>
              <td class="max-width-cell max-width-cell--date">
                <p *ngIf="travel.endDate">
                  {{ transformDate(travel.endDate) }}
                </p>
              </td>
              <td ngbDropdown>
                <div *ngIf="isAdmin || canExportBonus(travel)">
                  <img
                    ngbDropdownToggle
                    src="assets/img/svg/black-kebab.svg"
                    style="cursor: pointer"
                  />
                  <div
                    ngbDropdownMenu
                    class="dropdown-menu"
                    aria-labelledby="dropdownBasic1"
                  >
                    <p *ngIf="isAdmin" (click)="onEdit(travel)">
                      <img src="assets/img/svg/black-edit-2.svg" />
                      <span>{{ "project.edit" | translate }}</span>
                    </p>
                    <p
                      *ngIf="isAdmin"
                      (click)="onCancel(travel.uuid)"
                    >
                      <img src="assets/img/svg/black-trash.svg" />
                      <span>{{ "project.delete" | translate }}</span>
                    </p>
                    <p *ngIf="canExportBonus(travel)" (click)="exportExcel(travel)">
                      <img src="assets/img/svg/black-bonus.svg" />
                      <span>{{ "project.export-excel" | translate }}</span>
                    </p>
                    <p *ngIf="canExportBonus(travel)" (click)="exportBonus(travel)">
                      <img src="assets/img/svg/black-bonus.svg" />
                      <span>{{ "project.export-bonus" | translate }}</span>
                    </p>
                    <p *ngIf="canSeeBookings(travel)" (click)="seeBookings(travel)">
                      <img src="assets/img/svg/black-bonus.svg" />
                      <span>{{"common.see-booked" | translate}}</span>
                    </p>
                  </div>
              </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </form>
    <div
      class="col-12 d-flex justify-content-center my-5"
      *ngIf="
        (travels | filterBooked
        : ['title', 'createdAt']
        : [f.title.value, [this.createdFromDate, this.createdToDate]]
        : ['string', 'date']
    ).length === 0
      "
    >
      <div class="icon-bcg icon-bcg--large">
        <img
          src="assets/img/svg/empty-search.svg"
          alt="emptySearch"
          class="empty-search"
        />
      </div>
      <h2 class="ml-3 my-auto">{{ "search.not-data" | translate }}</h2>
    </div>
  </div>

  <div class="d-flex justify-content-center mt-3">
    <mat-paginator
      #paginator
      [length]="
        (
          travels
          | filterBooked
          : ['title', 'createdAt']
          : [f.title.value, [this.createdFromDate, this.createdToDate]]
          : ['string', 'date']
        ).length
      "
      [pageSize]="6"
      (page)="getPaginatorData($event)"
      showFirstLastButtons
      [hidePageSize]="true"
      style="border-radius: 15px"
    >
    </mat-paginator>
  </div>
</div>
