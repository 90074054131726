import { AbstractControl, ValidationErrors } from "@angular/forms";

export function ValidateAdult(control: AbstractControl): ValidationErrors | null {
  if (!control.value) return null;

  const birthdate = new Date(control.value.year, control.value.month - 1, control.value.day);
  const today = new Date();
  const age = today.getFullYear() - birthdate.getFullYear();

  if (
    age < 18 ||
    (age === 18 &&
      (today.getMonth() < birthdate.getMonth() ||
        (today.getMonth() === birthdate.getMonth() && today.getDate() < birthdate.getDate())))
  ) {
    return { underage: true };
  }

  return null;
}