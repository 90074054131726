import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompanyService, NgxToastrService, UserService } from '@core/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';
import swal from 'sweetalert2';

@Component({
  selector: 'app-user-card-modal',
  templateUrl: './user-card-modal.component.html',
  styleUrls: ['./user-card-modal.component.scss']
})
export class UserCardModalComponent implements OnInit {

  @Input() ffCards: any[] = [];
  @Input() paymentCards: any[] = [];
  /* @Input() personalPaymentCards: any[] = []; */
  @Input() userPaymentCards: any[] = [];
  @Input() user: any;
  @Input() externalUser: any;
  @Input() company: any;
  @Input() cardType: string;
  @Input() fromBookSelect: boolean = false;
  @Input() bookCards: any[] = [];
  @Input() bookPersonalCards: any[] = [];
  @Input() bookType: string;
  @Output() updatedFfCards = new EventEmitter<any[]>();
  @Output() updatedPaymentCards = new EventEmitter<any[]>();
  /* @Output() updatedPersonalPaymentCards = new EventEmitter<any[]>(); */

  ffCardForm: FormGroup;
  paymentCardForm: FormGroup;
  /* personalPaymentCardForm: FormGroup; */
  selectBookCardForm: FormGroup;
  /* selectedPersonalPaymentCard: any; */
  paymentCardsChanged: boolean = false;
  /* personalPaymentCardsChanged: boolean = false; */
  deletedPaymentCards: any[] = [];
  /* deletedPersonalPaymentCards: any[] = []; */
  @Output() cardSelected = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder,
              public activeModal: NgbActiveModal,
              public translate: TranslateService,
              private ngxToastrService: NgxToastrService,
              private userService: UserService,
              private companyService: CompanyService) {}

  ngOnInit(): void {
    if(this.cardType === 'ffCard'){
      this.ffCardForm = this.formBuilder.group({
        ffCards: this.formBuilder.array(this.initFfCards()),
        selectedFfCard: [
          this.ffCards.length > 0 ? 0 : null,
        ],
        newCardName: ['', Validators.required],
        newCardNumber: ['', Validators.required]
      });
    }else if(this.cardType === 'paymentCard'){

      const uniquePaymentCards = this.paymentCards.reduce((acc, currentCard) => {
        const existingCard = acc.find(card => card.name === currentCard.name);
        if (!existingCard && currentCard.token) {
          acc.push(currentCard);
        }
        return acc;
      }, []);
  
      this.paymentCards = uniquePaymentCards;

      const selectedCardIndices = this.userPaymentCards.map(userCard => {
        return this.paymentCards.findIndex(card => card.token === userCard.token && card.name === userCard.name);
      }).filter(index => index !== -1);

      this.paymentCardForm = this.formBuilder.group({
        paymentCards: this.formBuilder.array(this.initPaymentCards()),
        selectedPaymentCard: this.formBuilder.control(selectedCardIndices),
        newPaymentCardName: ['', Validators.required],
      });
    /* }else if(this.cardType === 'personalPaymentCard'){

      const uniquePaymentCards = this.personalPaymentCards.reduce((acc, currentCard) => {
        const existingCard = acc.find(card => card.name === currentCard.name);
        if (!existingCard && currentCard.token) {
          acc.push(currentCard);
        }
        return acc;
      }, []);
  
      this.personalPaymentCards = uniquePaymentCards;
      this.personalPaymentCardForm = this.formBuilder.group({
        personalPaymentCards: this.formBuilder.array(this.initPersonalPaymentCards()),
        newPersonalPaymentCardName: ['', Validators.required],
      });
      this.selectedPersonalPaymentCard = this.personalPaymentCards.length > 0 ? 0 : null; */
    }else if(this.fromBookSelect){
      this.bookCards = this.bookCards.filter(card => card.token);
      this.bookPersonalCards = this.bookPersonalCards.filter(card => card.token);
      if (this.fromBookSelect) {
        this.selectBookCardForm = this.formBuilder.group({
          selectedBookCard: [0],
          newPersonalPaymentCardName: ['', Validators.required],
        });
      } else {
        this.selectBookCardForm = this.formBuilder.group({
          selectedBookCard: [null]
        });
      }
    }
  }

  initFfCards(): FormGroup[] {
    return this.ffCards.map(card => this.formBuilder.group({
      cardName: [card.cardName, Validators.required],
      cardNumber: [card.cardNumber, Validators.required]
    }));
  }

  initPaymentCards(): FormGroup[] {
    return this.paymentCards.map(card => this.formBuilder.group({
      cardName: [card.name, Validators.required],
      cardToken: [card.token],
      cardBrand: [card.cardBrand],
      lastDigits: [card.lastDigits]
    }));
  }

/*   initPersonalPaymentCards(): FormGroup[] {
    return this.personalPaymentCards.map(card => this.formBuilder.group({
      cardName: [card.name, Validators.required],
      cardToken: [card.token]
    }));
  } */

  get ffCardsArray(): FormArray {
    return this.ffCardForm.get('ffCards') as FormArray;
  }

  get paymentCardsArray(): FormArray {
    return this.paymentCardForm.get('paymentCards') as FormArray;
  }

/*   get personalPaymentCardsArray(): FormArray {
    return this.personalPaymentCardForm.get('personalPaymentCards') as FormArray;
  } */

  addNewFfCard() {
    if (this.ffCardForm.invalid) {
      this.ffCardForm.markAllAsTouched();
      return;
    }

    const newCard = {
      cardName: this.ffCardForm.get('newCardName').value,
      cardNumber: this.ffCardForm.get('newCardNumber').value
    };
    this.translate
    .get([
      "my-bookeds.train-flight.add-card-success",
      "my-bookeds.train-flight.add-card-error"
    ])
    .subscribe((result) => {
      const userUuid = this.user?.uuid ? this.user.uuid : (typeof this.user === 'string' ? this.user : null);
      this.userService.newFFCard(newCard, userUuid).subscribe(
        (resp) => {
          this.ffCardsArray.push(
            this.formBuilder.group({
              cardName: [newCard.cardName, Validators.required],
              cardNumber: [newCard.cardNumber, Validators.required]
            })
          );

          const newCardIndex = this.ffCardsArray.length - 1;
          this.ffCardForm.patchValue({
            selectedFfCard: newCardIndex
          });

          this.ngxToastrService.typeSuccess(
            result["my-bookeds.train-flight.add-card-success"],
            null
          );

          this.ffCardForm.patchValue({
            newCardName: '',
            newCardNumber: ''
          });

          this.ffCardForm.get('newCardName').markAsUntouched();
          this.ffCardForm.get('newCardNumber').markAsUntouched();
        },
        (error) => {
          this.ngxToastrService.typeError(
            result["my-bookeds.train-flight.add-card-error"],
            error.error.message
          );
        }
      );
    });
  }

  setPaymentCards(){
    if (this.isSavePaymentCardsDisabled()) {
      this.paymentCardForm.markAllAsTouched();
      return;
    }
    const selectedIndices = this.paymentCardForm.get('selectedPaymentCard').value;
    const selectedCards = selectedIndices.map((index: number) => {
      return this.paymentCards[index];
    });

    this.paymentCardsChanged = true;
    const body = { paymentCards: selectedCards };

    this.translate
      .get([
        "my-bookeds.train-flight.add-card-success",
        "my-bookeds.train-flight.add-card-error"
      ])
      .subscribe((result) => {
        const userUuid = this.user?.uuid ? this.user.uuid : (typeof this.user === 'string' ? this.user : null);
        this.userService.setPaymentCards(body, userUuid).subscribe(
          (response) => {
            this.ngxToastrService.typeSuccess(
              result["my-bookeds.train-flight.add-card-success"],
              null
            );
          },
          (error) => {
            this.ngxToastrService.typeError(
              result["my-bookeds.train-flight.add-card-error"],
              error.error.message
            );
          }
        );
      });
  }

  removeFfCard(i: number) {
    const cardToDelete = this.ffCardsArray.at(i).value;
    this.translate
      .get([
        "my-bookeds.train-flight.ff-card-delete",
        "common.confirm",
        "common.cancel",
        "my-bookeds.train-flight.deleted-ff-card",
        "my-bookeds.train-flight.deleted-ff-card-error",
      ])
      .subscribe((result) => {
        const title = `${result["my-bookeds.train-flight.ff-card-delete"]} "${cardToDelete.cardName}"?`;
        swal
          .fire({
            title: title,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#0CC27E",
            cancelButtonColor: "#FF586B",
            confirmButtonText: result["common.confirm"],
            cancelButtonText: result["common.cancel"],
            customClass: {
              confirmButton: "btn btn-success btn-raised",
              cancelButton: "btn btn-danger btn-raised mr-5",
            },
            buttonsStyling: false,
          })
          .then((action) => {
            if (action.value) {
              const userUuid = this.user?.uuid ? this.user.uuid : (typeof this.user === 'string' ? this.user : null);
              this.userService.deleteFFCard(userUuid, cardToDelete).subscribe(
                () => {
                  this.ffCardsArray.removeAt(i);
                  this.ngxToastrService.typeSuccess(
                    null,
                    result["my-bookeds.train-flight.deleted-ff-card"]
                  );
                },
                (error) => {
                  this.ngxToastrService.typeInfo(
                    result["my-bookeds.train-flight.deleted-ff-card-error"],
                    error.error.message
                  );
                }
              );
            }
          });
      });
  }

  removePaymentCard(i: number) {
    const cardToDelete = this.paymentCardsArray.at(i).value;
    const cardToRemove = {
      name: cardToDelete.cardName,
      token: cardToDelete.cardToken
    };
    this.paymentCardsChanged = true;
    const selectedIndices = [...this.paymentCardForm.get('selectedPaymentCard').value];

    this.translate
      .get([
        "my-bookeds.train-flight.ff-card-delete",
        "common.confirm",
        "common.cancel",
        "my-bookeds.train-flight.deleted-ff-card",
        "my-bookeds.train-flight.deleted-ff-card-error",
      ])
      .subscribe((result) => {
        const title = `${result["my-bookeds.train-flight.ff-card-delete"]} "${cardToRemove.name}"?`;
        swal
          .fire({
            title: title,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#0CC27E",
            cancelButtonColor: "#FF586B",
            confirmButtonText: result["common.confirm"],
            cancelButtonText: result["common.cancel"],
            customClass: {
              confirmButton: "btn btn-success btn-raised",
              cancelButton: "btn btn-danger btn-raised mr-5",
            },
            buttonsStyling: false,
          })
          .then((action) => {
            if (action.value) {
              this.companyService.removeCardRedsys(this.company, cardToRemove).subscribe(
                () => {
                  this.deletedPaymentCards.push(cardToRemove);
                  this.paymentCardsArray.removeAt(i);
                  this.paymentCards.splice(i, 1);
                  const newSelectedIndices = selectedIndices
                  .filter(index => index !== i)
                  .map(index => (index > i ? index - 1 : index));

                  this.paymentCardForm.get('selectedPaymentCard').setValue(newSelectedIndices);

                  this.ngxToastrService.typeSuccess(
                    null,
                    result["my-bookeds.train-flight.deleted-ff-card"]
                  );
                },
                (error) => {
                  this.ngxToastrService.typeInfo(
                    result["my-bookeds.train-flight.deleted-ff-card-error"],
                    error.error.message
                  );
                }
              );
            }
          });
      });
  }

/*   removePersonalPaymentCard(i:number){
    const cardToDelete = this.personalPaymentCardsArray.at(i).value;
    const cardToRemove = {
      name: cardToDelete.cardName,
      token: cardToDelete.cardToken
    };
    this.personalPaymentCardsChanged = true;

    this.translate
      .get([
        "my-bookeds.train-flight.ff-card-delete",
        "common.confirm",
        "common.cancel",
        "my-bookeds.train-flight.deleted-ff-card",
        "my-bookeds.train-flight.deleted-ff-card-error",
      ])
      .subscribe((result) => {
        const title = `${result["my-bookeds.train-flight.ff-card-delete"]} "${cardToRemove.name}"?`;
        swal
          .fire({
            title: title,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#0CC27E",
            cancelButtonColor: "#FF586B",
            confirmButtonText: result["common.confirm"],
            cancelButtonText: result["common.cancel"],
            customClass: {
              confirmButton: "btn btn-success btn-raised",
              cancelButton: "btn btn-danger btn-raised mr-5",
            },
            buttonsStyling: false,
          })
          .then((action) => {
            if (action.value) {
              this.userService.removePersonalPaymentCard(this.user.uuid, cardToRemove).subscribe(
                () => {
                  this.deletedPersonalPaymentCards.push(cardToRemove);
                  this.personalPaymentCardsArray.removeAt(i);
                  this.personalPaymentCards.splice(i, 1);

                  this.ngxToastrService.typeSuccess(
                    null,
                    result["my-bookeds.train-flight.deleted-ff-card"]
                  );
                },
                (error) => {
                  this.ngxToastrService.typeInfo(
                    result["my-bookeds.train-flight.deleted-ff-card-error"],
                    error.error.message
                  );
                }
              );
            }
          });
      });
  } */

  save() {
    if (this.ffCardForm && this.ffCardForm.valid) {
      this.updatedFfCards.emit(this.ffCardForm.value.ffCards);
    }
    if (this.paymentCardForm && this.paymentCardForm.valid && this.paymentCardsChanged) {
      const selectedCards = this.paymentCardForm.get('selectedPaymentCard').value.map((index: number) => {
        return this.paymentCardsArray.at(index).value;
      });
      this.updatedPaymentCards.emit(selectedCards);
    }
    /* if (this.personalPaymentCardForm && this.personalPaymentCardForm.valid && this.personalPaymentCardsChanged) {
      const updatedCards = this.personalPaymentCardForm.get('personalPaymentCards').value;
      this.updatedPersonalPaymentCards.emit(updatedCards);
    } */
  }

  closeModal() {
    this.save();
    if (this.cardType === 'paymentCard' && !this.paymentCardsChanged) {
      this.activeModal.dismiss();
    /* }else if(this.cardType === 'personalPaymentCard' && !this.personalPaymentCardsChanged){
      this.activeModal.dismiss(); */
    }else if(this.cardType === 'ffCard'){
      this.activeModal.close({
        ffCards: this.ffCardForm.value.ffCards,
        selectedFfCard: this.ffCardForm.value.selectedFfCard,
        type: 'ffCard'
      });
    }else if (this.cardType === 'paymentCard' && this.paymentCardsChanged) {
      const selectedIndices = this.paymentCardForm.get('selectedPaymentCard').value;
      const selectedCards = selectedIndices.map((index: number) => {
        return this.paymentCards[index];
      });
      
      this.activeModal.close({
        paymentCards: selectedCards,
        deletedPaymentCards: this.deletedPaymentCards,
        type: 'paymentCard'
      });
    /* }else if(this.cardType === 'personalPaymentCard' && this.personalPaymentCardsChanged){
      const updatedPersonalCards = this.personalPaymentCardForm.get('personalPaymentCards').value;
      this.activeModal.close({
        personalPaymentCards: updatedPersonalCards,
        deletedPersonalPaymentCards: this.deletedPersonalPaymentCards,
        type: 'personalPaymentCard'
      }); */
    }else if(this.fromBookSelect){
      this.activeModal.close();
    }
  }

  getSelectedCard(): any {
    const selectedIndex = this.ffCardForm.get('selectedFfCard')?.value;
    return this.ffCardsArray.at(selectedIndex)?.value;
  }

  getSelectedPaymentCard(): any {
    const selectedIndex = this.paymentCardForm.get('selectedPaymentCard')?.value;
    return this.paymentCardsArray.at(selectedIndex)?.value;
  }

  getSelectedCardText(): string {
    const selectedIndex = this.ffCardForm.get('selectedFfCard').value;
    if (this.ffCardsArray && this.ffCardsArray.controls[selectedIndex]) {
      const card = this.ffCardsArray.controls[selectedIndex];
      return `${card.get('cardName').value} - ${card.get('cardNumber').value}`;
    }
    return '';
  }

  getSelectedPaymentCardText(): string {
    const selectedIndices = this.paymentCardForm.get('selectedPaymentCard').value;
    if (selectedIndices && selectedIndices.length > 0) {
      return selectedIndices.map((index: number) => {
        const card = this.paymentCardsArray.at(index);
        return card?.get('cardName').value;
      }).join(', ');
    }
    return '';
  }

  generateToken(type: string) {
    let cardData;
    let route;
    const isPaymentCard = type === 'paymentCard';
    /* const isPersonalPaymentCard = type === 'personalPaymentCard'; */
    if(isPaymentCard){
      cardData = this.paymentCardForm.get('newPaymentCardName')?.value;
      route = `/user/edit-user/${this.user.uuid}`
   /*  }else if(isPersonalPaymentCard){
      cardData = this.personalPaymentCardForm.get('newPersonalPaymentCardName')?.value;

      if(this.user && (this.user?.type?.toLowerCase() === 'user' || this.user?.accessType?.toLowerCase() === 'user') && !this.externalUser){
        route = `/user/user-profile?personal=true`
      }else{
        route = `/user/edit-user/${this.user.uuid}?personal=true`
      }
    }else{
      cardData = this.selectBookCardForm.get('newPersonalPaymentCardName')?.value;
      const path = window.location.pathname;
      const query = window.location.search;
      const urlParams = new URLSearchParams(query);
      const location = urlParams.get('location');
      
      if (location) {
        urlParams.set('location', encodeURIComponent(location));
      }
      const updatedQuery = `?${urlParams.toString()}`;
      route = `${path}${updatedQuery}`; */
    }

    if (cardData !== null && cardData !== "") {
      const serviceCall = isPaymentCard 
      ? this.companyService.postCompanyRedsys(this.company.uuid, { name: cardData, route })
      : this.userService.postUserRedsys(this.user.uuid, { name: cardData, route });

      serviceCall.subscribe(
          (result) => {
            localStorage.setItem('cardName', cardData);
            result = JSON.parse(result);
            this.translate
              .get(["card.redirect", "insurance.continue"])
              .pipe(take(1))
              .subscribe((transl) => {
                swal
                  .fire({
                    title: "",
                    html: `<html>
              <script></script>
              <div class="dialog-container">
                <h1>${transl["card.redirect"]}</h1>
                <form method="post" action=${result.url}>
                  <input type="text" name="Ds_SignatureVersion" value=${result.body.Ds_SignatureVersion} hidden />
                  <input type="text" name="Ds_MerchantParameters" value=${result.body.Ds_MerchantParameters} hidden />
                  <input type="text" name="Ds_Signature" value=${result.body.Ds_Signature} hidden />
                  <button type="submit" style="background-color: #0cc27e !important;
                  color: #fff !important;
                  border-color: #0cc27e;
                  font-weight: 400;
                  text-align: center;
                  border: 1px solid transparent;
                  padding: 0.375rem 0.75rem;
                  font-size: 1rem;
                  line-height: 1.5;
                  border-radius: 0.25rem;
                  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
                    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;">${transl["insurance.continue"]}</button>
                </form>
              </div>
              </html>`,
                    icon: "warning",
                    width: "650px",
                    customClass: {
                      htmlContainer: "card-swal",
                    },
                    showConfirmButton: false,
                  })
                  .then((action) => {
                    if (!action.dismiss) {
                    }
                  });
              });
          },
          (err) => {
            this.translate.get("company.card.error").subscribe((resp) => {
              this.ngxToastrService.typeInfo(null, resp);
            });
          }
        );
    }
  }

  isSavePaymentCardsDisabled(): boolean {
    return this.paymentCardForm.get('selectedPaymentCard').invalid;
  }

  isAddNewPaymentCardDisabled(): boolean {
    return this.paymentCardForm.get('newPaymentCardName').invalid;
  }

  /* isAddNewPersonalPaymentCardDisabled(): boolean {
    return this.personalPaymentCardForm.get('newPersonalPaymentCardName')?.invalid;
  }

  isBookPersonalPaymentCardDisabled(): boolean {
    return this.selectBookCardForm.get('newPersonalPaymentCardName')?.invalid;
  } */

  onCardSelect() {
    const selectedCardIndex = this.selectBookCardForm.get('selectedBookCard')?.value;
    if (selectedCardIndex !== null && selectedCardIndex !== undefined) {
      let selectedCard;
      if (selectedCardIndex < this.bookCards.length) {
        selectedCard = this.bookCards[selectedCardIndex];
      } else {
        const personalCardIndex = selectedCardIndex - this.bookCards.length;
        selectedCard = this.bookPersonalCards[personalCardIndex];
      }
      this.cardSelected.emit(selectedCard);
      this.activeModal.close();
    }
  }

  get paymentCardPlaceholder(): string {
    if (this.userPaymentCards?.length === 0) {
      return this.translate.instant('company.card.no-selected-card');
    } else {
      return this.translate.instant('company.card.go-select-cards');
    }
  }

  getCardLogo(cardBrand: number): string {
    const cardLogos: { [key: number]: string } = {
      1: 'logo_visa.PNG',
      2: 'logo_masterCard.PNG',
      6: 'logo_dinners.PNG',
      7: 'logo_private.PNG',
      8: 'logo_amex.PNG',
      9: 'logo_jcb.PNG',
      22: 'logo_upi.PNG'
    };
    return cardLogos[cardBrand] || '';
  }

}
