<div class="row mt-2 full-flight-content">
  <div class="col-12">
    <!-- TITULO -->
    <div>
      <div class="col-12" style="margin-bottom: 5vh">
        <h3 class="heading-5">
          {{
            (stateService.didFirstSearch
              ? "train.search-result.title"
              : "flight.custom.lets-search"
            ) | translate
          }}
        </h3>
        <!-- {{'flights.form-flight.search-title' | translate}} -->
      </div>
    </div>
    <app-user-detail
      *ngIf="(isAdmin && canUseTRM) || isEmployee"
      [userUuid]="userToBook"
    ></app-user-detail>
    <!-- FORMULARIO -->
    <div class="row" *ngIf="isAmadeusEnabled && !isManualFlight">
      <div class="col-12">
        <!-- Es un componente por como está hecho el diseño en la parte de resultados de vuelos para volver a hacer una busqueda se abre en modal -->
        <app-flight-form
          *ngIf="!stateService.didFirstSearch"
          (firstSearch)="didFirstSearch($event)"
          (emitSelectedTravelers)="handleSelectedUsers($event)"
          (emitProject)="handleSelectedProjects($event)"
          (emitCabin)="handleSelectedCabin($event)"
        ></app-flight-form>
      </div>
    </div>
    <!--Resultados-->
    <div class="row">
      <div class="col-12" *ngIf="stateService.didFirstSearch">
        <div>
          <div class="row">
            <div class="col-3 greenBack">
              <div id="filters-wrapper">
                <h4 class="heading-6">{{ "common.search-filters" | translate }}</h4>
                <form [formGroup]="formSearchFlight">
                  <div class="filter-group">
                    <p>{{ "flights.form.nearbyAirports" | translate }}</p>
                    <div class="checkbox-div">
                  <mat-checkbox
                        class="example-margin"
                        (click)="showSlider(0)"
                        formControlName="originNearAirports"
                        (ngModel)="this.utilsService.nearbyDeparture"
                      >
                      </mat-checkbox>
                      <label>{{
                        "flight.form-flight.origin" | translate
                      }}</label>
                    </div>
                    <div class="distanceSlider active"*ngIf="this.utilsService.nearbyDeparture">
                      <div class="custom-slider">
                        <ngx-slider
                          (userChangeEnd)="getSliderValue(0)"
                          [(value)]="stateService.distanceDeparture"
                          [options]="optionsDistanceDeparture"
                        >
                        </ngx-slider>
                      </div>
                    </div>
                    <ng-container *ngIf="!stateService.oneWay">
                      <div class="checkbox-div">
                        <mat-checkbox
                          class="example-margin"
                          (click)="showSlider(1)"
                          formControlName="destinationNearAirports"
                          (ngModel)="this.utilsService.nearbyArrival"
                        >
                        </mat-checkbox>
                        <label>{{
                          "flight.form-flight.destination" | translate
                        }}</label>
                      </div>
                      <div class="distanceSlider active" *ngIf="this.utilsService.nearbyArrival">
                        <div class="custom-slider">
                          <ngx-slider
                            (userChangeEnd)="getSliderValue(1, $event)"
                            [(value)]="stateService.distanceArrival"
                            [options]="optionsDistanceArrival"
                          >
                          </ngx-slider>
                        </div>
                      </div>
                    </ng-container>
                  </div>

                  <div class="filter-group">
                    <p>
                      {{ "flight.form-flight.characteristics" | translate }}
                    </p>

                    <div class="checkbox-div">
                      <mat-checkbox formControlName="onlyDirect"></mat-checkbox>
                      <label>{{
                        "flight.form-flight.onlyDirect" | translate
                      }}</label>
                    </div>

                    <div class="checkbox-div">
                      <mat-checkbox
                        formControlName="allowBaggage"
                      ></mat-checkbox>
                      <label>{{
                        "flights.form.allowBaggage" | translate
                      }}</label>
                    </div>

                    <div class="checkbox-div">
                      <mat-checkbox
                        formControlName="allowCancelation"
                      ></mat-checkbox>
                      <label>{{
                        "flights.form.allowCancelation" | translate
                      }}</label>
                    </div>

                    <div class="checkbox-div">
                      <mat-checkbox
                        formControlName="arrivalSameDay"
                      ></mat-checkbox>
                      <label>{{
                        "flights.form.arrivalSameDay" | translate
                      }}</label>
                    </div>
                  </div>

                  <div class="filter-group">
                    <div class="seat-select-container" ngbDropdown>
                      <p>
                        {{ "train.trenymas.class-type" | translate }}
                      </p>
                      <div
                        ngbDropdownToggle
                        class="form-control form-control--squared cursor-pointer"
                      >
                        {{
                          formSearchFlight.controls["type"].value != null
                            ? getSeatSelected()
                            : seats[seats.length - 1].viewValue
                        }}
                      </div>
                      <div
                        ngbDropdownMenu
                        class="dropdown-menu border-radius-20"
                        aria-labelledby="dropdownBasic1"
                      >
                        <p
                          ngbDropdownItem
                          *ngFor="let seat of seats; let i = index"
                          (click)="selectSeat(seat)"
                          [ngClass]="{
                            selected:
                              seat.value ===
                              formSearchFlight.controls['type'].value.value,
                            last: seats.length - 1 === i
                          }"
                        >
                          {{ seat.viewValue }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="filter-group">
                    <p>{{ "flights.form.time-selection" | translate }}</p>

                    <label>
                      {{ "flight.form-flight.arrival-time" | translate }}
                    </label>
                    <div class="custom-slider">
                      <ngx-slider
                        (userChangeEnd)="getSliderValue(2, $event)"
                        [(value)]="stateService.minTimeDeparture"
                        [(highValue)]="stateService.maxTimeDeparture"
                        [options]="optionsTimeDeparture"
                      ></ngx-slider>
                    </div>

                    <ng-container *ngIf="!stateService.oneWay">
                      <label>
                        {{ "flight.form-flight.departure-time" | translate }}
                      </label>

                      <div class="custom-slider">
                        <ngx-slider
                          (userChangeEnd)="getSliderValue(3, $event)"
                          [(value)]="stateService.minTimeReturnDeparture"
                          [(highValue)]="stateService.maxTimeReturnDeparture"
                          [options]="optionsTimeArrival"
                        >
                        </ngx-slider>
                      </div>
                    </ng-container>
                  </div>
                </form>
                <div id="form-button">
                  <button class="custom-btn" (click)="getFlightList()">
                    {{ "common.search" | translate }}
                  </button>
                </div>
              </div>
            </div>
            <div class="col-9 redBack" id="result-container">
              <div id="result-actions">
                <div id="buttons-container">
                  <div
                    (click)="orderByPrice()"
                    [ngClass]="{ checked: cheaper }"
                    *ngIf="
                      stateService.flightSelectedState &&
                      stateService.flightSelectedState.solutions.length > 0
                    "
                  >
                    {{ "flight.result-flight.cheaper" | translate }}
                  </div>
                  <!--TODO SSD ordenar por duracion del vuelo-->
                  <div
                    (click)="orderByTime()"
                    [ngClass]="{ checked: !cheaper }"
                    *ngIf="
                      stateService.flightSelectedState &&
                      stateService.flightSelectedState.solutions.length > 0
                    "
                  >
                    {{ "flight.result-flight.fastest" | translate }}
                  </div>
                </div>
                <div id="filters-actions">
                  <div id="text-container">
                    <span
                      >{{ stateService.transportRequest.journeys[0].origin }} -
                      {{
                        stateService.transportRequest.journeys[0].destination
                      }}
                      {{
                        stateService.transportRequest.journeys[1] ? "| " : ""
                      }}
                      {{
                        stateService.transportRequest.journeys[1] ? stateService.transportRequest.journeys[0].destination : ''
                      }}
                      {{
                        stateService.transportRequest.journeys[1] ? " - " : ""
                      }}
                      {{
                        stateService.transportRequest.journeys[1] ? stateService.transportRequest.journeys[0].origin : ''
                      }}</span
                    >
                    <span>
                      {{
                        stateService.transportRequest.journeys[0].date
                          | date: "dd/MM/yyyy"
                      }}
                      {{
                        stateService.transportRequest.journeys[1] ? "| " : ""
                      }}
                      {{
                        stateService.transportRequest.journeys[1]
                          ? (stateService.transportRequest.journeys[1].date
                            | date: "dd/MM/yyyy")
                          : ""
                      }}</span
                    >
                  </div>
                  <div>
                    <button (click)="openSearchDialog()">
                      {{ "flight.result-flight.modify-search" | translate }}
                    </button>
                  </div>
                </div>
              </div>
              <div
                id="result-content"
                *ngIf="
                  stateService.flightSelectedState &&
                  stateService.flightSelectedState.solutions.length > 0
                "
              >
                <app-flights-card-result
                  #flightResult
                  [userUuid]="userUuid"
                  [isUser]="isUser"
                  [groupFilters]="groupFilters"
                  [travelersAge]="travelersAge"
                  [selectedUsers]="selectedUsers"
                  [projectData]="projectData"
                >
                </app-flights-card-result>
              </div>
              <div
                class="empty-results"
                *ngIf="
                  (!stateService.flightSelectedState ||
                  stateService.flightSelectedState.solutions.length <= 0) &&
                  !utilsService.loadingFlights
                "
              >
                <img
                  src="assets/img/svg/empty-results.svg"
                  alt="Empty results"
                />
                <p class="no-results">
                  Ups...
                  {{ "agency.search.search-homepage.no-results" | translate }}
                </p>
                <p>{{ "common.change-search" | translate }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
