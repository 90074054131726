<div id="goBackButtonContainer" *ngIf="!isAdmin">
  <button mat-button mat-flat-button (click)="goBack()">
    <img src="assets/img/svg/arrow-back.svg" alt="" width="24px" />
  </button>
</div>
<app-user-detail *ngIf="(isAdmin && canUseTRM)" [userUuid]="userToBook" [didSearch]="didSearch"
  (navigateEvent)="goBack()"></app-user-detail>

<mat-horizontal-stepper linear [linear]="isLinear" #stepper>
  <ng-template matStepperIcon="edit" let-index="index">
    {{ index + 1 }}
  </ng-template>
  <mat-step label="{{ 'flight.select-fare' | translate }}" *ngIf="optimusMultiFamily?.fares.length > 0" #first_step>
    <ng-container *ngIf="stepper.selected == null || stepper.selected == first_step">
      <app-flight-fares-list (nextStep)="nextStepFromFares($event)" [multiFamily]="optimusMultiFamily"
        (openPolicy)="openPoliciesDialog($event)" [groupFilters]="groupFilters">
      </app-flight-fares-list>
    </ng-container>
  </mat-step>
  <mat-step label="{{ 'flight.passengers-data' | translate }}" #second_step>
    <ng-container *ngIf="stepper.selected == second_step">
      <app-flight-step-travelerform [totalPrice]="totalPrice" [travelersAge]="
          travelersAge.length > 0 ? travelersAge : stateService.flightTravelers
        " [isUser]="isUser" [userUuid]="userUuid" [needDocApis]="needDocApis" [needAddress]="needAddress"
        [stepper]="stepper" [selectedUsers]="selectedUsers"></app-flight-step-travelerform>
    </ng-container>
  </mat-step>
  <mat-step label="{{ 'flight.details' | translate }}" #third_step>
    <ng-container *ngIf="stepper.selected == third_step">
      <ng-container *ngIf="fareSelected !== null; else elseBlock">
        <app-flight-step-detail (baggageSelectedEvent)="updateBaggagePrice($event)" (nextStep)="goNextStep($event)"
          [multiFamily]="optimusMultiFamily" [fareName]="fareName" [totalPrice]="totalPrice"
          [fareSelected]="fareSelected" [selectedFlights]="selectedFlights" [durations]="durations"
          [requestData]="requestData" [groupFilters]="groupFilters">
        </app-flight-step-detail>
      </ng-container>
      <ng-template #elseBlock>
        <app-flight-step-detail (baggageSelectedEvent)="updateBaggagePrice($event)" (nextStep)="goNextStep($event)"
          [multiFamily]="optimusMultiFamily" [fareName]="fareName" [totalPrice]="totalPrice" [durations]="durations"
          [selectedFlights]="selectedFlights" [requestData]="requestData" [groupFilters]="groupFilters">
        </app-flight-step-detail>
      </ng-template>
    </ng-container>
  </mat-step>
  <mat-step label="{{ 'flight.pucharse-confirmation' | translate }}">
    <div style="display: flex;">
      <div id="confirm-container">
        <div id="confirm-text-container">
          <h6>{{ "flight.result-flight.almost-there" | translate }}</h6>
          <form id="extraInfo" [formGroup]="extraInfoForm" *ngIf="extraInfoForm != null">
            <div class="input-row">
              <div>
                <p class="semi-bold" style="padding-right: 1rem;">
                  {{ "common.custom-code" | translate }}
                </p>
                <label class="border-radius-20" style="width: 100%; padding-right: 1rem;">
                  <input id="customCode" type="text" formControlName="customCode" placeholder="(PPT...)"
                    class="form-control form-control--no-border" />
                </label>
              </div>
              <div>
                <p class="semi-bold" style="padding-left: 1rem;">
                  {{ "common.cost-center" | translate }}
                </p>
                <label class="border-radius-20" style="width: 100%; padding-left: 1rem;">
                  <input id="costCenter" type="text" formControlName="costCenter"
                    class="form-control form-control--no-border" />
                </label>
              </div>
            </div>
            <div class="input-full-row">
              <p class="semi-bold">
                {{ "flight.form-flight.annotations" | translate }}
              </p>
              <label class="border-radius-20">
                <textarea id="annotationsExtras" formControlName="annotationsExtras"
                  placeholder="{{ 'flight.form-flight.extras-annotations' | translate }}"
                  class="form-control form-control--no-border" rows="4"></textarea>
              </label>
            </div>
          </form>
          <div>
            <p style="font-size: 10px; font-weight: 300; margin-top: 15px">
              {{ "common.policies.clic" | translate
              }}<a href="https://www.vyootrip.com/aviso-legal/" target="_blank">{{
                "common.policies.terms-of-use" | translate
                }}</a>{{ "common.policies.confirm" | translate
              }}<a href="https://www.vyootrip.com/politica-de-cookies/" target="_blank">{{ "common.policies.cookie-policy"
                | translate }}</a>
            </p>
          </div>
          <h4>
            {{
            (((groupFilters.onlyFlightAuthorization && !isAdmin) || (!isFareWithinRange(totalPrice) && !isAdmin))
            ? "flight.result-flight.confirm-auth"
            : "flight.result-flight.confirm-book")
            | translate }}
          </h4>
        </div>
        <div id="confirm-button-container">
          <button id="cancel" matStepperPrevious>
            {{ "flight.detail-flight.cancel" | translate }}
          </button>
          <button id="confirm" (click)="
                  ((groupFilters.onlyFlightAuthorization && !isAdmin) || (!isFareWithinRange(totalPrice) && !isAdmin))
                    ? requestFlight()
                    : confirmFlight()
                ">
            {{ "flight.detail-flight.confirm" | translate }}
          </button>
        </div>
      </div>
      <div class="col-4" id="price-col">
        <div id="details-container">
          <span *ngIf="canUseResident" class="resident-applied">{{ 'flight.resident-applied' | translate }}</span>
          <div class="details-buy-text" [ngClass]="{ 'no-fare': fareSelected == null }">
            <ng-container *ngIf="fareSelected && farePricing; else elseBlock">
              <div class="buy-text">
                <div>
                  <div *ngIf="!isPriceWithinRangeMethod(getPriceStep) || groupFilters.onlyFlightAuthorization"
                    style="display: flex; justify-content: center;">
                    <span class="admin-permission-msg">{{ "authorization.permissions-required" | translate }}</span>
                  </div>
                </div>
                <div *ngIf="fareSelected">
                  <div class="total-price base-price">
                    <p>{{ "hotel.base-rate" | translate }}:</p>
                    <p>{{ fareSelected.total | price }} €</p>
                  </div>
                  <div class="total-price fee-emit">
                    <p>{{ "flight.result-flight.fee-emit" | translate }}:</p>
                    <p>{{ (fareSelected.total_price - fareSelected.total) | price }} €</p>
                  </div>
                  <div class="total-price baggage-price" *ngIf="selectBaggage?.length > 0">
                    <p>{{ "my-bookeds.flight.baggage" | translate }} ({{ selectedBaggages.length }}):</p>
                    <p>{{ getTotalBaggagePrice() | price }} €</p>
                  </div>
                  <div class="total-price total-price-label">
                    <p>{{ "flight.result-flight.total-price" | translate }}:</p>
                    <p>{{ totalPrice | price }} €</p>
                  </div>
                </div>
                <span class="change-fare" (click)="goToFirstStep()">
                  {{ "flight.result-flight.change-fare" | translate }}
                </span>
              </div>
            </ng-container>
            <ng-template #elseBlock>
              <p>{{ "flight.result-flight.total-price" | translate }}:</p>
              <p *ngIf="totalPrice > 0">{{ totalPrice | price }} €</p>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </mat-step>

  <button #nextStep matStepperNext style="display: none">Next</button>
</mat-horizontal-stepper>