<div class="modal-content">
    <div class="modal-header">
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="closeModal()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <h5 class="modal-title heading-6" style="margin-bottom: 1.5rem;" id="modal-basic-title">
        {{ fromBookSelect ? ("company.card.select-payment-card" | translate) : cardType === 'paymentCard' ? ("my-bookeds.train-flight.company-cards" | translate) : ("my-bookeds.train-flight.personal-cards" | translate)}}
      </h5>
      
      <form [formGroup]="ffCardForm" *ngIf="cardType === 'ffCard'">
        <label>{{ "my-bookeds.train-flight.delete-card" | translate }}</label>
        <div class="form-group">
          <mat-form-field appearance="outline" class="full-width">
            <mat-select *ngIf="ffCardsArray.controls.length > 0" formControlName="selectedFfCard">
              <mat-select-trigger>
                {{ getSelectedCardText() }}
              </mat-select-trigger>
              <mat-option *ngFor="let card of ffCardsArray.controls; let i = index" [value]="i">
                <div class="card-option">
                  <span>{{ card.get('cardName').value }} - {{ card.get('cardNumber').value }}</span>
                  <button mat-icon-button color="warn" class="delete-btn" (click)="removeFfCard(i); $event.stopPropagation();">
                    <mat-icon>{{ "common.delete" | translate }}</mat-icon>
                  </button>
                </div>
              </mat-option>
            </mat-select>
          
            <mat-select placeholder="{{ 'my-bookeds.train-flight.no-cards-available' | translate }}" *ngIf="ffCardsArray.controls.length === 0" disabled>
              <mat-option disabled>
                {{ "my-bookeds.train-flight.no-cards-available" | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          
        </div>
  
        <hr>
  
        <label>{{ "my-bookeds.train-flight.card-add" | translate }}</label>

        <div class="form-group">
          <input
            formControlName="newCardName"
            type="text"
            id="newCardName"
            class="form-control"
            style="margin-bottom: 0;"
            placeholder="{{ 'my-bookeds.train-flight.card-name' | translate }}"
          />
          <div *ngIf="ffCardForm.get('newCardName').invalid && ffCardForm.get('newCardName').touched" class="error-message">
            {{ "my-bookeds.train-flight.card-name-required" | translate }}
          </div>
        </div>

        <div class="form-group">
          <input
            formControlName="newCardNumber"
            type="text"
            id="newCardNumber"
            class="form-control"
            style="margin-bottom: 0;"
            placeholder="{{ 'my-bookeds.train-flight.card-number' | translate }}"
          />
          <div *ngIf="ffCardForm.get('newCardNumber').invalid && ffCardForm.get('newCardNumber').touched" class="error-message">
            {{ "my-bookeds.train-flight.card-number-required" | translate }}
          </div>
        </div>

        <button type="button" class="btn btn-primary" style="color: white !important;" (click)="addNewFfCard()" [disabled]="ffCardForm.invalid">
          {{ "my-bookeds.train-flight.card-add-custom" | translate }}
        </button>
      </form>

      <form [formGroup]="paymentCardForm" *ngIf="cardType === 'paymentCard'">
        <label>{{ "company.card.user-select-card" | translate }}</label>
        <div class="form-group">
          <mat-form-field appearance="outline" class="full-width">
            <mat-select 
              *ngIf="paymentCardsArray.controls.length > 0"
              formControlName="selectedPaymentCard"
              multiple
              placeholder="{{ paymentCardPlaceholder }}">
            
              <mat-select-trigger>
                {{ getSelectedPaymentCardText() }}
              </mat-select-trigger>
              <mat-option *ngFor="let card of paymentCardsArray.controls; let i = index" [value]="i">
                <span class="card-option-content">
                  <span *ngIf="card.get('cardBrand').value" class="card-logo" [ngStyle]="{ 'background-image': 'url(assets/img/credit-cards/' + getCardLogo(card.get('cardBrand').value) + ')' }">
                  </span>
                  <span class="card-name">
                    <span>{{ card.get('cardName').value }}</span>
                  </span>
                  <span class="last-digits" *ngIf="card.get('lastDigits').value">
                    **** {{ card.get('lastDigits').value }}
                  </span>
                </span>
              </mat-option>
            </mat-select>
      
            <mat-select *ngIf="paymentCardsArray.controls.length === 0" placeholder="{{ 'my-bookeds.train-flight.no-cards-available' | translate }}" disabled>
              <mat-option disabled>
                {{ "my-bookeds.train-flight.no-cards-available" | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <button *ngIf="paymentCardsArray.controls.length > 0" type="button" class="btn btn-primary" style="color: white !important;" (click)="setPaymentCards()" [disabled]="isSavePaymentCardsDisabled()">
          {{ "common.save-selection" | translate }}
        </button>
      </form>
      <!-- <form [formGroup]="personalPaymentCardForm" *ngIf="cardType === 'personalPaymentCard'">
        <div class="form-group">
          <mat-form-field appearance="outline" class="full-width">
            <mat-select 
              *ngIf="personalPaymentCardsArray.controls.length > 0"
              placeholder="{{ 'company.card.available-cards' | translate }}"
              [(value)]="selectedPersonalPaymentCard"
            >
              <mat-select-trigger>
                {{ personalPaymentCardsArray.controls[selectedPersonalPaymentCard]?.get('cardName').value }}
              </mat-select-trigger>
              <mat-option *ngFor="let card of personalPaymentCardsArray.controls; let i = index" class="no-select-option" [value]="i">
                <div class="card-option">
                  <span>{{ card.get('cardName').value }}</span>
                  <button mat-icon-button color="warn" class="delete-btn" (click)="removePersonalPaymentCard(i); $event.stopPropagation();">
                    <mat-icon>{{ "common.delete" | translate }}</mat-icon>
                  </button>
                </div>
              </mat-option>
            </mat-select>
          
            <mat-select *ngIf="personalPaymentCardsArray.controls.length === 0" placeholder="{{ 'my-bookeds.train-flight.no-cards-available' | translate }}" disabled>
              <mat-option disabled>
                {{ "my-bookeds.train-flight.no-cards-available" | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          
        </div>
        <hr>
        <label>{{ "my-bookeds.train-flight.card-add" | translate }}</label>

        <div class="form-group">
          <input
            formControlName="newPersonalPaymentCardName"
            type="text"
            id="newPersonalPaymentCardName"
            class="form-control"
            style="margin-bottom: 0;"
            placeholder="{{ 'my-bookeds.train-flight.card-name' | translate }}"
          />
          <div *ngIf="personalPaymentCardForm.get('newPersonalPaymentCardName').invalid && personalPaymentCardForm.get('newPersonalPaymentCardName').touched" class="error-message">
            {{ "my-bookeds.train-flight.card-name-required" | translate }}
          </div>
        </div>
        <button type="button" class="btn btn-primary" style="color: white !important;" (click)="generateToken('personalPaymentCard')" [disabled]="isAddNewPersonalPaymentCardDisabled()">
          {{ "company.card.add-credit-card" | translate }}
        </button>
      </form> -->
      <form [formGroup]="selectBookCardForm" *ngIf="fromBookSelect">
        <div class="form-group">
          <mat-form-field appearance="outline" class="full-width">
            <mat-select 
              formControlName="selectedBookCard" 
              placeholder="{{ 'company.card.select-one-card' | translate }}" 
              panelClass="custom-scroll">
              <mat-select-trigger *ngIf="selectBookCardForm.get('selectedBookCard')?.value !== null">
                <span class="card-option-content">
                  <span class="card-logo" 
                    *ngIf="bookCards[selectBookCardForm.get('selectedBookCard')?.value]?.cardBrand"
                    [ngStyle]="{ 'background-image': 'url(assets/img/credit-cards/' + getCardLogo(bookCards[selectBookCardForm.get('selectedBookCard')?.value]?.cardBrand) + ')' }">
                  </span>
                  <span class="card-name">
                    {{ bookCards[selectBookCardForm.get('selectedBookCard')?.value]?.name }}
                  </span>
                  <span class="last-digits" *ngIf="bookCards[selectBookCardForm.get('selectedBookCard')?.value]?.lastDigits">
                    **** {{ bookCards[selectBookCardForm.get('selectedBookCard')?.value]?.lastDigits }}
                  </span>
                </span>
              </mat-select-trigger>
              <ng-container *ngIf="(bookCards?.length > 0 || bookPersonalCards?.length > 0); else noCardsAvailable">
                <mat-optgroup *ngIf="bookCards?.length > 0" [label]="'my-bookeds.train-flight.company-cards' | translate">
                  <mat-option *ngFor="let card of bookCards; let i = index" [value]="i">
                    <span class="card-option-content">
                      <span *ngIf="card.cardBrand" class="card-logo" [ngStyle]="{ 'background-image': 'url(assets/img/credit-cards/' + getCardLogo(card.cardBrand) + ')' }">
                      </span>
                      <span class="card-name">
                        {{ card.name }}
                      </span>
                      <span class="last-digits" *ngIf="card.lastDigits">
                        **** {{ card.lastDigits }}
                      </span>
                    </span>
                  </mat-option>
                </mat-optgroup>
                <mat-optgroup *ngIf="bookPersonalCards?.length > 0" [label]="'my-bookeds.train-flight.personal-cards' | translate">
                  <mat-option *ngFor="let card of bookPersonalCards; let i = index" [value]="bookCards.length + i">
                    {{ card.name }}
                  </mat-option>
                </mat-optgroup>
              </ng-container>
              
              <ng-template #noCardsAvailable>
                <mat-option disabled>
                  {{ "my-bookeds.train-flight.no-cards-available" | translate }}
                </mat-option>
              </ng-template>
              
            </mat-select>
          </mat-form-field>
        </div>

        <!-- <hr *ngIf="bookType">
        <mat-expansion-panel *ngIf="bookType">
          <mat-expansion-panel-header>
            <mat-panel-title class="modal-title heading-6">
              {{ "my-bookeds.train-flight.card-insert" | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          
          <div class="form-group" style="margin-top: 1rem;">
            <input
              formControlName="newPersonalPaymentCardName"
              type="text"
              id="newPersonalPaymentCardName"
              class="form-control"
              style="margin-bottom: 0;"
              placeholder="{{ 'my-bookeds.train-flight.card-name' | translate }}"
            />
            <div *ngIf="selectBookCardForm?.get('newPersonalPaymentCardName')?.invalid && selectBookCardForm?.get('newPersonalPaymentCardName')?.touched" class="error-message">
              {{ "my-bookeds.train-flight.card-name-required" | translate }}
            </div>
          </div>
          
          <button type="button" class="btn btn-primary" style="color: white !important;" (click)="generateToken('onBook')" [disabled]="isBookPersonalPaymentCardDisabled()">
            {{ "company.card.add-credit-card" | translate }}
          </button>
        </mat-expansion-panel> -->

        <div class="button-container">
          <button
            style="color: white !important;"
            type="button"
            class="btn btn-primary"
            (click)="onCardSelect()"
            [disabled]="(bookCards?.length === 0 && bookPersonalCards?.length === 0) || selectBookCardForm?.get('selectedBookCard')?.value === null || selectBookCardForm?.get('selectedBookCard')?.value === undefined">
            {{ "project.accept" | translate }}
          </button>
        </div>
      </form>
  </div>
</div>