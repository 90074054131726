import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "@core/models";
import { MediaService, StateService, UserService } from "@core/services";

@Component({
  selector: "app-user-detail",
  templateUrl: "./user-detail.component.html",
  styleUrls: ["./user-detail.component.scss"],
})
export class UserDetailComponent implements OnInit{
  @Input() userUuid: string;
  @Input() didSearch: boolean;
  fullname: string;
  image: any;
  department: any;
  user: User;
  isBookeds = false;
  @Input() specialAction;
  @Input() module;
  @Output() navigateEvent = new EventEmitter<string>();

  constructor(
    private mediaService: MediaService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private stateService: StateService,
  ) {
    this.isBookeds = this.route.snapshot.routeConfig.path === "booked/:uuid";
  }

  ngOnInit() {

    this.userService.getUserByUuid(this.userUuid).subscribe((res) => {
      this.user = res;
      this.fullname = this.user.name + " " + this.user.lastname;
      if (this.user.image && this.user.image !== "") {
        this.mediaService.getMedia(this.user.image).subscribe((s) => {
          const reader = new FileReader();
          const reader1 = new FileReader();

          reader.readAsDataURL(s);
          let base64data;
          reader.onload = async () => {
            base64data = reader.result;
            this.image = await base64data;
          };
          reader1.readAsArrayBuffer(s);
        });
      }
      this.userService.getGroupUser(this.userUuid).subscribe((data) => {
        this.department = data;
      });
    });
  }

  goBack() {
    if (this.specialAction) {
      if(this.module === 'flights') {
        this.router.navigate([`/flight`])
      } else {
        this.stateService.didFirstSearch = false;
        this.router.navigate([`/search/${this.user.uuid}`])
      }
    } else if(this.didSearch){
      this.router.navigate(['/flight'], { queryParams: { didSearch: true } });
    } else {
      this.stateService.didFirstSearch = false;
      this.router.navigate([`/companies/${this.user.companyUuid}`])
    }
  }
}
