import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core'
import { Router } from '@angular/router'
import { Hotel } from '@core/models'
import { UtilsService } from '@core/services'
import { Subject, Subscription } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'

@Component({
  selector: 'app-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss']
})
export class GoogleMapsComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('map') mapElement: any
  @Output() zoomOutEvent = new EventEmitter<void>();
  subscription: Subscription
  @Input() searchConfig
  map: google.maps.Map
  @Input() listHotels
  @Input() mapHasBeenDragged
  @Input() firstSearch
  @Input() singleSearch: boolean
  @Input() canRequest: boolean
  @Input() fromCard: boolean
  @Input() fromDetails: boolean
  @Input() hotelsMapCopy: Hotel[];
  @Input() hotelsListCopy: Hotel[];
  @Input() filters: any;
  @Input() facilities: any;
  @Input() selectedTravelers: any;
  @Input() selectedUsers: any;
  @Input() projectData: any;
  bounds = new google.maps.LatLngBounds()
  coordCentral
  infoWindowOpened = null
  previousInfoWindow = null
  isDragging = false
  zoomChange = false
  currentZoom = 15
  private originalZoom: number = 15;
  oldZoom = 15
  latitude
  longitude
  shouldLoadMark = false
  latitudeHotel
  longitudeHotel
  subject: Subject<any> = new Subject()
  icon: any = {
    url: 'assets/img/svg/marker.svg'
  }
  iconHovered: any = {
    url: 'assets/img/svg/marker-selected.svg'
  }
  markers = []
  newCoordinates = {}

  @Output() emitCenterChanged = new EventEmitter<any>()
  constructor (private router: Router, public utilsService: UtilsService) {
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      const carousels = document.querySelectorAll('.carousel');
      carousels.forEach(carousel => {
        // @ts-ignore
        $(carousel).carousel();
      });
    }, 500);
    const styles = document.createElement('style');
    if (this.fromCard && !this.fromDetails) {
      styles.innerHTML = `
        .gm-style-iw-c {
          padding-bottom: 12rem !important;
        }
      `;
    } else {
      styles.innerHTML = `
        .gm-style-iw-c {
          padding-bottom: 1rem !important;
        }
      `;
    }
    document.head.appendChild(styles);
  }

  ngOnInit () {
    this.latitude = parseFloat(this.searchConfig.latitude)
    this.longitude = parseFloat(this.searchConfig.longitude)
    this.latitudeHotel = parseFloat(this.listHotels[0].coordinates.latitude)
    this.longitudeHotel = parseFloat(this.listHotels[0].coordinates.longitude)

    if (this.fromCard) {
      this.shouldLoadMark = true
    } else {
      this.shouldLoadMark = false
    }

  }
  ngOnChanges () {
    const coordsAux = []
    if (this.listHotels && this.listHotels.length > 0) {
      for (const data of this.listHotels) {
        coordsAux.push({
          latitude: data.coordinates.latitude,
          longitude: data.coordinates.longitude
        })
      }
      this.coordCentral = this.averageGeolocation(coordsAux)
      this.createMarker()
    }
  }

  setIcon (hotel) {
    let iconAux = this.icon

    iconAux.scaledSize = {
      width: 50,
      height: 20
    }
    iconAux.latitude = hotel.coordinates.latitude
    iconAux.longitude = hotel.coordinates.longitude

    return iconAux
  }

  createMarker () {
    for (const hotel of this.listHotels) {
      const marker = new google.maps.Marker({
        position: {
          lat: parseFloat(hotel.coordinates.latitude),
          lng: parseFloat(hotel.coordinates.longitude)
        },
        map: this.map
      })
      this.markers.push(marker)
      this.bounds.extend(marker.getPosition())
    }
  }

  averageGeolocation (coords) {
    if (coords.length === 1) {
      return {
        latitude: Number(coords[0].latitude),
        longitude: Number(coords[0].longitude)
      }
    }

    let x = 0.0
    let y = 0.0
    let z = 0.0

    for (const coord of coords) {
      const latitude = (coord.latitude * Math.PI) / 180
      const longitude = (coord.longitude * Math.PI) / 180
      x += Math.cos(latitude) * Math.cos(longitude)
      y += Math.cos(latitude) * Math.sin(longitude)
      z += Math.sin(latitude)
    }

    const total = coords.length

    x = x / total
    y = y / total
    z = z / total

    const centralLongitude = Math.atan2(y, x)
    const centralSquareRoot = Math.sqrt(x * x + y * y)
    const centralLatitude = Math.atan2(z, centralSquareRoot)
    return {
      latitude: (centralLatitude * 180) / Math.PI,
      longitude: (centralLongitude * 180) / Math.PI
    }
  }

  arrayOne (number) {
    const array = []
    for (let i = 1; i <= parseInt(number, 10); i++) {
      array.push(i)
    }
    return array
  }

  closeWindow () {
    if (this.previousInfoWindow) {
      this.previousInfoWindow.close()
    }
  }

  selectMarker (infoWindow) {
    if (this.previousInfoWindow == null) {
      this.previousInfoWindow = infoWindow
    } else {
      this.infoWindowOpened = infoWindow
      this.previousInfoWindow.close()
    }
    this.previousInfoWindow = infoWindow
  }

  onZoomChange(newZoom: number) {
    if (newZoom < this.currentZoom && newZoom < this.originalZoom) {
      this.handleZoomOut(newZoom);
    }
    if (newZoom < this.originalZoom) {
      this.originalZoom = newZoom;
    }
    this.currentZoom = newZoom;
  }

  handleZoomOut(newZoom: number) {
    this.zoomOutEvent.emit();
  }

  getHotel (hotel) {
    const currentRoute = this.router.url

    // Verifica si ya estás en la ruta deseada
    if (currentRoute.includes('/hotels/')) {
      // No hagas nada si ya estás en la ruta correcta
      return
    }

    this.router.navigate([`${this.router.url}/hotels/`, hotel.code], {
      queryParams: {
        location: this.searchConfig.locationText,
        lat: this.newCoordinates['latitude']
          ? this.newCoordinates['latitude']
          : this.searchConfig.latitude,
        lon: this.newCoordinates['longitude']
          ? this.newCoordinates['latitude']
          : this.searchConfig.longitude,
        hotelUuid: hotel.uuid,
        checkin: `${this.searchConfig.checkIn.day}/${this.searchConfig.checkIn.month}/${this.searchConfig.checkIn.year}`,
        checkout: `${this.searchConfig.checkOut.day}/${this.searchConfig.checkOut.month}/${this.searchConfig.checkOut.year}`,
        canRequest: this.canRequest
      },
      state: {
        searchConfig: this.searchConfig,
        hotelsMapCopy: this.hotelsMapCopy,
        hotelsListCopy: this.hotelsListCopy,
        filters: this.filters,
        facilities: this.facilities,
        selectedTravelers: this.selectedTravelers,
        selectedUsers: this.selectedUsers,
        projectData: this.projectData
      },
    })
  }

  mapReady (event) {
    event.addListener('dragend', () => {
      this.sendNewPosition()
    })
  }

  sendNewPosition () {
    this.emitCenterChanged.emit(this.newCoordinates)
  }
  setNewCoordinates (event) {
    this.newCoordinates = {
      latitude: event.lat || event.Ab.g,
      longitude: event.lng || event.Ra.g
    }
  }
  zoomIsChanging (event) {
    this.zoomChange = true
    this.oldZoom = this.currentZoom
    this.subject
      .pipe(debounceTime(800), distinctUntilChanged())
      .subscribe(() => {
        this.currentZoom = event
        console.table({ oldZoom: this.oldZoom, currentZoom: this.currentZoom })
      })
  }
  getPrice (hotel) {
    return Math.trunc(hotel.totalBooked)
  }

  showLabel (hotel): boolean {
    return hotel.totalBooked ? true : false
  }

  moveCarousel(direction: 'prev' | 'next', index: number): void {
    const carouselId = `#hotelImagesMap${index}`;
    const carousel = document.querySelector(carouselId);
    
    if (carousel) {
      if (direction === 'prev') {
        // @ts-ignore
        $(carousel).carousel('prev');
      } else {
        // @ts-ignore
        $(carousel).carousel('next');
      }
    }
  }
  
}
