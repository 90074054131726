export class FlightAvailReq {
  query: AvailabilityPriceRequest;
  delegation?: string;
  userUuid?: string;
}

export interface FlightAvailRes {
  token: string;
  num_solutions: number;
  segments: Segment[];
  journeys: Journey[];
  originDestinations?: OriginDestination[];
  fares: FareType[];
  solutions: Solution[];
  partial_solutions?: Context[];
  data?: any;
  sources: any;
}

export interface PricingRes {
  token: string;
  version: number;
  solutions: SolutionPricing[];
  price: any;
  available_payments?: any;
}

export interface PolicyRes {
  token: string;
  version: number;
  solutions: SolutionPolicy[];
}

export interface StandAloneCatalogueRes {
  token: string;
  version: number;
  solutions: SolutionStandAloneCatalogue[];
  error?: any;
}

export class BookingFlightReq {
  query: BookingQuery;
  emitOnBookingDetail: any;
  delegation?: string;
  userUuid?: string;
  insurance?: any;
  available_payments?: any;
}

export interface BookingFlightRes {
  success: boolean;
  token: string;
  version: number;
  booking: Booking;
  notes: {};
}

export class EmitFlighReq {
  query: EmitQuery;
  delegation: string;
}

export class AvailabilityPriceRequest {
  token?: string;
  target?: string;
  criterias: SearchCriteria[];
  delegation?: string;
}

export class SearchCriteria {
  connectors?: string[];
  travel: TransportRequest;
  max_responses?: number;
  rule: Rule;
  corporate_code?: CorporateCode;
}

// Amadeus = 1A, Travelport = 1G, Travelfusion = TF, NDC Iberia = IB,
// Ticketya = NV, Renfe = RN, NDC Vueling = VY, NDC KLM = KL,
// NDC AirFrance = AF, NDC British Airways = BA, NDC Pyton = PY
export enum ConnectorsCriteria {
  "1A" = "1A",
  "1G" = "1G",
  "TF" = "TF",
  "IB" = "IB",
  "NV" = "NV",
  "RN" = "RN",
  "VY" = "VY",
  "KL" = "KL",
  "AF" = "AF",
  "BA" = "BA",
  "PY" = "PY",
}

export class TransportRequest {
  product_type?: string;
  paxes_distribution: PaxesDistribution;
  journeys: JourneyRequest[];
  cabin?: CabinType;
  direct?: boolean;
  range_dates?: RangeDate;
  flexible_date?: boolean;
  refundable?: boolean;
  low_cost?: boolean;
  baggage?: boolean;
  companies?: any;
  send_fee_resi_gds_agency?: boolean;
  init_dif_fee_gds_agency?: number;
  is_iata_agency?: boolean;
  resident?: ResidentType;
  include_train?: boolean;
  include_ndc?: boolean;
  fare_type?: FareType;
  exclude_fares?: string[];
  disney?: boolean;
  cruise?: boolean;
  large_family_discount?: LargueFamilyDiscountType;
  airlines?: any;
  include_bus: boolean;
  include_gds: boolean;
  only_flight: boolean;
}

export class Rule {
  combined: boolean;
  duplicated: boolean;
  show_data: boolean;
  show_partial: boolean;
  show_origin_destination?: boolean;
  show_public_fares?: boolean;
  only_partial: boolean;
}

export class CorporateCode {
  code_acount: string;
}

export class PaxesDistribution {
  passengers_ages: number[];
}

export class JourneyRequest {
  max_layover_count?: number;
  date: string;
  origin: string;
  destination: string;
  connections?: string[];
  schedules?: any[];
  schedules_arrival?: any[];
  origin_radius?: number;
  destination_radius?: number;
  arrival_same_day?: boolean;
  range_dates?: RangeDate;

  constructor(
    date,
    origin,
    destination,
    conections?,
    schedules?,
    schedules_arrival?,
    origin_radius?,
    destination_radius?,
    arrival_same_day?,
    range_dates?
  ) {
    this.date = date;
    this.origin = origin;
    this.destination = destination;
    this.connections = conections;
    this.schedules = schedules;
    this.schedules_arrival = schedules_arrival;
    this.origin_radius = origin_radius;
    this.destination_radius = destination_radius;
    this.arrival_same_day = arrival_same_day;
    this.range_dates = range_dates;
  }
}

// Economic = Y, Economic Standard = M, Economic Premium = W, Business = C, First = F
export enum CabinType {
  "Y" = "Y",
  "M" = "M",
  "W" = "W",
  "C" = "C",
  "F" = "F",
}

// El maximo rango permitido entre prev y post es de 3
export class RangeDate {
  prev: number;
  post: number;
}

// Resident code (Balear = BL, Canary = CN, Ceuta = CE, Melilla = ML)
// Note: See resident from pricing for new resident legislation.
export enum ResidentType {
  "none" = "none",
  "BL" = "BL",
  "CN" = "CN",
  "CE" = "CE",
  "ML" = "ML",
}

// PUBLIC = Public fares, PRIVATE = Private fares
export enum FareTypeEnum {
  "PUBLIC" = "PUBLIC",
  "PRIVATE" = "PRIVATE",
}

// General = F1, Special = F2
export enum LargueFamilyDiscountType {
  "F1" = "F1",
  "F2" = "F2",
}

export class Segment {
  airline?: any;
  id?: string;
  object: {
    departure: string;
    departure_terminal?: string;
    departure_date: string;
    arrival: string;
    arrival_terminal?: string;
    arrival_date: string;
    marketing_company: string;
    operating_company: string;
    transport_number: string;
    technical_stops?: TechnicalStop[];
    transport_model?: string;
    transport_type?: string;
  };
}

export class TechnicalStop {
  port: string;
  departure_date_time: string;
  arrival_date_time: string;
  duration: string;
}

export class Journey {
  id?: string;
  object: {
    duration: number;
    lowcost: boolean;
    layovers: number;
    context: number;
    segments: string[];
  }; // Formado por el marketing_company + transport_number+departure_time sin segundos (formato americano)
}

export class OriginDestination {
  origin: string;
  destination: string;
  journeys: string[];
}

export class Solution {
  id: string;
  source_id?: string;
  time_limits: TimeLimits;
  total_price: TotalPrice;
  data: SolutionData;
  resident_candidates?: ResidentType[];
  providers?: Provider[];
  partial_solutions?: [];
  sources?: any;
  multiFamilyData?: any;
}

export class TimeLimits {
  last_ticket_date: string;
  last_date: string;
  last_ticket_date_data: LastTicketDateData | [];
}
export interface LastTicketDateData {
  title: string;
  tag: string;
  locale: string;
}

export class TotalPrice {
  currency: string;
  base: number;
  total_taxes: number;
  taxes?: [{}];
  fee: number;
  emission_fee: number;
  total: any;
}

export class SolutionData {
  fare_list: Fare[];
  associations?: Association[];
  allow_multi_family: boolean;
}

export class Fare {
  context: number;
  farebasis: string[];
  passenger_type: string;
  currency: string;
  base: number;
  total_taxes: number;
  fee: number;
  emission_fee: number;
  amount: number;
  total: number;
  refundable?: boolean;
  opaque?: number;
  family?: string;
  quantity: number;
  passenger_refs?: string[];
}

export class Association {
  provider: string;
  origin_destination_references: string[];
  journey_references: string[];
  segment_references: SolutionSegment[];
  source: string;
}

export class SolutionSegment {
  id?: string;
  object: {
    baggage: Baggage;
    cabin: Cabin;
    class_of_service: string;
    fare_basis_code: string;
    seats: number | string;
    fare_type?: any;
  };
}

export class Baggage {
  quantity: number;
  unit: string;
}

export class Cabin {
  id: string;
  long_name: string;
  short_name: string;
  source_i_d?: string;
  type?: string;
}

export class Context {
  provider: Solution[];
}

export class SolutionPricing {
  transport: TransportSlim;
  flight_usa: boolean;
  need_doc_apis: boolean;
  price: PriceDetail;
  need_address: boolean;
}

export class TransportSlim {
  last_ticket_date: string;
  id: string;
  solution_id: string;
  origin_destinations: OriginDestinationSlim[];
  catalogue: boolean;
  emit_on_booking_detail: any;
}

export class OriginDestinationSlim {
  journey?: JourneySlim[];
  journeys?: any;
  fare_id: string;
  fare_type: string;
  validating_company_iata: string;
}

export class JourneySlim {
  duration: string;
  segments: SegmentSlim[];
  lowcost: boolean;
  departure_date: string;
}

export class SegmentSlim {
  departure_date: string;
  departure_time: string;
  arrival_date: string;
  arrival_time: string;
  arrival_date_time: string;
  origin_iata: string;
  origin_short_name: string;
  origin_city_name: string;
  destination_iata: string;
  destination_short_name: string;
  destination_city_name: string;
  company_name: string;
  company_iata: string;
  op_company_iata: string;
  baggage: number;
  baggage_weight: string;
  baggage_unit: string;
  baggage_detail: any; // Array<key,array>
  tro: string;
  trd: string;
  nv: string;
  tf: string;
  class: string;
  fare_basis_code: string;
  equipment: string;
  flight_usa: boolean;
  cabin: string;
  marketing_name: string;
  type: TransportType;
  fare_type: FareTypeEnum;
}

export enum TransportType {
  "Flight" = "Flight",
  "Train" = "Train",
  "Bus" = "Bus",
}

export class PriceDetail {
  currency: string;
  base: number;
  total_taxes: number;
  taxes: [{}];
  fee: number;
  emission_fee: number;
  total: number;
  pax: number;
  fares: TravelFare[];
  amount: number;
}

export class TravelFare {
  id?: string;
  object: {
    short_name: string;
    long_name: string;
    source_i_d: string;
    context: number;
    passenger_type: string;
    quantity: number;
    currency: string;
    base: number;
    taxes: [{}];
    fee: number;
    emission_fee: number;
    markup: number;
    opaque: number;
    amount: number;
    total: number;
    penalties: Penalty[];
    validation_company: LlegoCompany;
    last_ticket_date: string;
    last_ticket_type: string;
    public: boolean;
  };
}

export class Penalty {
  id: string;
  short_name: string;
  long_name: string;
  type: PenaltyType;
  extensions: {};
  segment_ref: string;
  amount: number;
  currency: string;
  message: string;
  refund: boolean;
}

export enum PenaltyType {
  "Change" = "Change",
  "Cancel" = "Cancel",
  "Refund" = "Refund",
  "Complete" = "Complete",
}

export class LlegoCompany {
  id: string;
  short_name: string;
  long_name: string;
  extensions: {};
}

export class SolutionPolicy {
  penalties: Penalty[];
}

export class SolutionStandAloneCatalogue {
  selections: string[];
  distributions: string[];
  paxes_types: string[];
  services: TransportSupplement[];
}

export class TransportSupplement {
  id: string;
  short_name: string;
  long_name: string;
  source_i_d: string;
  extensions: [{}];
  base: number;
  fee: number;
  price: number;
  total: number;
  included: boolean;
  mandatory: boolean;
  selected: boolean;
  direct_payment: boolean;
  category: string;
  pattern: string;
  quantity: number;
  units: number;
  unit_qualifier: string;
  status: string;
  conector_i_d: string;
  refundable: boolean;
  distribution: string;
  value: string;
  pax_references: string[];
  seg_references: string[];
  journey_references: string;
  selection: string;
}

export class BookingQuery {
  token: string;
  solutions: SolutionQuery[];
  holder: any;
  travellers: Traveller[];
  fees?: FeeQuery[];
  ancillaries?: TransportSupplement[];
  seat_preference?: TransportSeat;
  emergency_contact?: Traveller;
  special_services?: SpecialServiceQuery[];
  largue_family_discount?: LargueFamilyDiscountQuery;
  notes?: Note[];
  dynamic_parameters?: DynamicParameterQuery[];
  target?: TargetType;
  payment?: Payment;
  expedient_number?: string;
  quote_number?: string;
  crm?: string;
  confirm?: boolean;
  cancel_if_not_docs?: boolean;
  vyooPrice?: number;
}

export class SolutionQuery {
  id: string;
  journeys: string[];
  element_no?: number;
}

export class Traveller {
  uuid?: string;
  code?: string;
  type?: string;
  title: TitleType;
  name: string;
  surnames: string[];
  birthDate?: string;
  birth_date?: string;
  contact?: Contact;
  documents?: Document[];
  responsibles?: PersonInCharge[];
}

export enum GenderType {
  "Male" = "Male",
  "Female" = "Female",
}

export enum TitleType {
  "Mr" = "Mr",
  "Mrs" = "Mrs",
  "Ms" = "Ms",
  "Miss" = "Miss",
}

export class Contact {
  type?: string;
  mails?: string[];
  phones?: Telephone[];
  address?: string;
  city?: string;
  postal_code?: string;
  country?: string;
}
export class Telephone {
  country_pref: string;
  number: string;
}
export class Document {
  type: DocumentType;
  number: string;
  city?: string;
  country?: string;
  nationality?: string;
  issue_date?: string;
  expiry_date?: string;
  validated?: boolean;
}

export enum DocumentType {
  PP = "PP",
  NI = "NI",
  TR = "TR",
  FF = "FF",
  CE = "CE",
  DL = "DL",
  NIE = "TR",
}

export class PersonInCharge {
  location: string;
  name: string;
  address: string;
  phone: string;
  origin_destination_ref: number;
}

export class FeeQuery {
  pax_type: string;
  amount: number;
  amount_markup?: number;
}

export class TransportSeat {
  id: string;
  short_name: string;
  long_name: string;
  extensions: [{}];
  situation: string;
  direction: string;
}

export class SpecialServiceQuery {
  pax_reference: string;
  category: string;
  more_info: string;
}

export class LargueFamilyDiscountQuery {
  type: LargueFamilyDiscountType;
  document: string;
  location: LargueFamilyDiscountType;
}

export class LargueFamilyLocation {
  "410917"?: "ANDALUCIA";
  "471868"?: "CASTILLA LEON";
  "330447"?: "ASTURIAS";
  "060830"?: "EXTRAMADURA";
  "502973"?: "ARAGON";
  "451685"?: "CASTILLA LA MANCHA";
  "150780"?: "GALICIA";
  "462508"?: "VALENCIA";
  "390759"?: "CANTABRIA";
  "300308"?: "MURCIA";
  "010590"?: "PAIS VASCO";
  "260892"?: "LA RIOJA";
  "312016"?: "NAVARRA";
  "070407"?: "ISLAS BALEARES";
  "080193"?: "CATALUNA";
  "380380"?: "CANARIAS";
  "280796"?: "MADRID";
  "510013"?: "CEUTA";
  "520018"?: "MELILLA";
}

export class Note {
  id: string;
  short_name: string;
  description: string;
  type: string;
  extensions: [{}];
  mode: string;
}

export class DynamicParameterQuery {
  key: string;
  value: string;
}

export enum TargetType {
  "test",
  "prod",
}

export enum TravellerType {
  ADT = "ADT",
  CHD = "CHD",
  INF = "INF",
}

// Type number: 1: credit, 2: transfer, 3: deposit, 4: uatp / card, 5: cash, 8: picard, 11: voucher
export class Payment {
  type: number;
  card_apply: string;
  cash: boolean;
  card: Card;
  voucher: Voucher;
  url: string;
}
// Credit card type code Please, check the card types returned by pricing method.
// If no types are returned, you can user ["VI" | "CA" | "AX" | "DC"]
export class Card {
  card_type: string;
  category: string;
  number: string;
  holder: string;
  expire_month: string;
  expire_year: string;
  cvv: string;
  id_v_card: string;
}

export enum CardCategoryType {
  "CREDITCARD",
  "DEBITCARD",
}

export class Voucher {
  type: string;
  number: string;
  mail: string;
}

export class Booking {
  id: string;
  created: string;
  modfied: string;
  lines: BookingLines[];
  is_surface: boolean;
}

export class BookingLines {
  id: string;
  created: string;
  updated: string;
  modified: string;
  issued: string;
  cancelled: string;
  voided: string;
  last_void_date: string;
  booking_reference: BookingReference;
  holder: Traveller;
  travellers: Traveller[];
  travel: Travel;
  provider: TravelConnector;
  tickets: TravelTicket[];
  payments: Payment[];
  booking_options: BookingLineOptions;
}

export class BookingReference {
  locator: string;
  third_locator: string;
  ghost_locator: string;
  security_code: string;
  expedient: string;
  status: string;
  external_view: string;
}

export class Travel {
  id: string;
  source_i_d: string;
  servicesGroup: ServicesGroup;
}

export class ServicesGroup {
  id: string;
  short_name: string;
  long_name: string;
  extensions: [{}];
}

export class TravelConnector {
  id: string;
  short_name: string;
  long_name: string;
  type: string;
  extension: [{}];
  subtype: string;
  active: boolean;
  default_priority: number;
}

export class TravelTicket {
  id: string;
  short_name: string;
  long_name: string;
  extensions: [{}];
}

export class BookingLineOptions {
  allow_pax_name: boolean;
  allow_pax_docs: boolean;
}

export class EmitQuery {
  token: string;
  target?: EmitTargetType;
  payment: Payment;
  expected_amount: number;
}

export enum EmitTargetType {
  "test",
  "production",
}

export class FareType {
  id?: string;
  object: {
    provider?: string;
    farebasis?: string;
    fare_type?: FareTypeEnum;
    fare_code?: string;
    fare_condition?: number;
  };
}
export interface Provider {
  context: number;
  id: string;
  sourceId: string;
  special?: string;
}

export class JourneysReferences {
  segments_ref: string[];
  solution_id: string;
  price: number;
}

export interface SeatsAvailabilityRes {
  version: number;
  token: string;
  solutions: SeatsAvailabilityResSolution[];
}

export interface SeatsAvailabilityResSolution {
  line_i_d: string;
  locator: string;
  segments: SeatAvailSegment[];
}

export interface SeatAvailSegment {
  id: string;
  source_i_d: string;
  origin: {
    long_name?: string;
    city_name?: string;
    country_name?: string;
    iata: string;
  };
  destination: {
    long_name?: string;
    city_name?: string;
    country_name?: string;
    iata: string;
  };
  departure_date_time: string;
  arrival_date_time: string;
  duration: number;
  origin_terminal: number;
  destination_terminal: number;
  company: {
    long_name?: string;
    iata: string;
  };
  seat_map: SeatMap;
}

export interface SeatMap {
  columns: SeatAvailColumn[];
  first_row: number;
  last_row: number;
  exceptions: any[];
  avail: SeatAvailRow[];
}

export interface SeatAvailColumn {
  code: string;
  position: string;
}
export interface SeatAvailRow {
  id: string;
  object: SeatAvail[];
}

export interface SeatAvail {
  id?: string;
  object?: {
    short_name?: string;
    long_name?: string;
    description?: string;
    source_i_d?: string;
    type?: string;
    currency?: string;
    fee?: number;
    price?: number;
    total?: number;
    included?: boolean;
    mandatory?: boolean;
    selected?: boolean;
    category?: string;
    distribution?: string;
    pax_references?: string[];
    seg_references?: string[];
    row?: number;
    column?: string;
    characteristics?: Characteristics;
  };
  selected?: boolean;
  taken?: boolean;
  aisle?: boolean;
}

export interface Characteristics {
  A?: string;
  CH?: string;
  FC?: string;
  L?: string;
  "1D"?: string;
  LS?: string;
  OW?: string;
  V?: string;
  W?: string;
}

export class ConfirmSeatsRQ {
  query: ConfirmSeatsQuery;
  delegation?: string;
  paymentId?: number;
}

export class ConfirmSeatsQuery {
  token: string;
  target?: string;
  payment?: Payment;
  expected_amount: number;
  seats: SeatQuery[];
}

export class SeatQuery {
  row: number;
  column: string;
  segment: string;
  pax: string;
}

export class MultifamilyRS {
  token: string;
  fares: Fares[];
}

export class Fares {
  base: number;
  taxes: number;
  amount: number;
  total: number;
  fare_basis: string;
  cabin: string;
  cabin_name: string;
  service_class: string;
  fare_type: string;
  fare_types: string[];
  fare_code: string;
  baggage: boolean;
  refund: boolean;
  cabin_trip: string;
  currency: CurrencyModel;
  journeys: string[];
  journeys_ids: string[];
  family: string;
  families: string[];
  services: any;
  touched?: boolean;
  id: string;
  total_price?: string;
}

export class CurrencyModel {
  iso: string;
  sign: string;
  position: string;
}
