import { UtilsService } from "./../../../../core/services/utils.service";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { UntypedFormGroup, NgModel } from "@angular/forms";
import { Booked } from "@models";
import {
  NgbDate,
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbInputDatepicker,
} from "@ng-bootstrap/ng-bootstrap";
import { ExcelService } from "@services";
import * as moment from "moment";
import { Subscription } from "rxjs";

@Component({
  selector: "app-booked-search",
  templateUrl: "./booked-search.component.html",
  styleUrls: ["./booked-search.component.scss"],
})
export class BookedSearchComponent implements OnInit, OnChanges {
  @Input() form: UntypedFormGroup;
  @Input() statusOptions;
  @Input() isUser: boolean;
  @Input() bookeds: Booked[];
  @Input() isEmployee? = false;
  @Input() place: "user" | "user-company" | "company";
  @Input() service: string;
  @Input() nextTravelsChecked
  @Input() loadingData: boolean;
  @Output() fromDateEmit = new EventEmitter();
  @Output() toDateEmit = new EventEmitter();
  @Output() fromCreationDateEmit = new EventEmitter();
  @Output() toCreationDateEmit = new EventEmitter();
  @Output() nextBookingsEmit = new EventEmitter();
  @Output() changeTypeEmit = new EventEmitter();
  @Output() changeComponentEmit = new EventEmitter();
  @Output() pageEmit = new EventEmitter();
  @Output() selectChange = new EventEmitter<string>();
  fromDate: any;
  toDate: any;
  createdFromDate: any;
  createdToDate: any;
  hoveredDate: NgbDateStruct;
  originalBookeds: Booked[] = [];
  @ViewChild("d1") bookedInput: NgbInputDatepicker;
  @ViewChild("d2") createdInput: NgbInputDatepicker;
  @ViewChild(NgModel) datePick: NgModel;
  @ViewChild("bookedRange") bookedRange: ElementRef;
  @ViewChild("createdAtRange") createdAtRange: ElementRef;
  headers: any;
  typeOptions: { text: string; value: string; tabIndex: number }[];
  typeComponent: any[];
  subscription: Subscription[] = [];

  constructor(
    private ngbDateParser: NgbDateParserFormatter,
    private excelService: ExcelService,
    private utilsService: UtilsService
  ) {
    // Tabs index are the index of ngbNav on user-my-bookeds.component.html
    this.typeOptions = [
      { text: "common.all", value: "all", tabIndex: 1 },
      { text: "common.hotel", value: "hotel", tabIndex: 2 },
      { text: "common.flight", value: "flight", tabIndex: 3 },
      { text: "common.train", value: "train", tabIndex: 4 },
      { text: "common.car", value: "car", tabIndex: 5 },
      { text: "common.other", value: "expense", tabIndex: 6 },
    ];

    this.typeComponent = [
      { text: "common.bookeds", value: "booked" },
      { text: "company.company-detail.request", value: "request" },
      { text: "company.company-detail.authorizations", value: "authorization" },
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['loadingData']) {
      this.loadingData = changes['loadingData'].currentValue;
    }
  }

  ngOnInit() {
    this.originalBookeds = this.bookeds;
    if (this.service === "car") {
      this.typeComponent.splice(2, 1);
    } else if (this.service === "expense") {
      this.typeComponent.splice(1, 2);
    }
  }

  get f() {
    return this.form.controls;
  }

  getClassCol() {
    switch (this.place) {
      case "user":
        return "col-md-6 col-xs-12";
      case "user-company":
        return "col-md-4 col-12";
      case "company":
        return "col-md-3 col-12";
    }
  }
  cleanBookedFilters() {
    this.f.modelHotel.setValue("");
    this.f.statusOptions.setValue("active");
    this.fromDate = null;
    this.toDate = null;
    this.createdFromDate = null;
    this.createdToDate = null;
    if (document.getElementById("typeOptions")) {
      document.getElementById("typeOptions")["value"] = "0";
    }
    if (document.getElementById("statusOptionType")) {
      document.getElementById("statusOptionType")["value"] = "0";
    }
    this.emitDates();
    this.bookedRange.nativeElement.value = "";
    this.createdAtRange.nativeElement.value = "";
    this.changeType(0);
  }

  emitDates() {
    this.fromDateEmit.emit(this.fromDate);
    this.toDateEmit.emit(this.toDate);
    this.fromCreationDateEmit.emit(this.createdFromDate);
    this.toCreationDateEmit.emit(this.createdToDate);
    this.utilsService.userBookingsFilters.fromDate = this.fromDate
      ? `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`
      : null;
    this.utilsService.userBookingsFilters.toDate = this.toDate
      ? `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`
      : null;
    this.utilsService.userBookingsFilters.createdFromDate = this.createdFromDate
      ? `${this.createdFromDate.year}-${this.createdFromDate.month}-${this.createdFromDate.day}`
      : null;
    this.utilsService.userBookingsFilters.createdToDate = this.createdToDate
      ? `${this.createdToDate.year}-${this.createdToDate.month}-${this.createdToDate.day}`
      : null;
  }

  setSelected(component) {
    const res = component.value === "booked" ? true : false;
    return res;
  }

  onDateSelection(type: string, date: NgbDate) {
    if (type === "booked") {
      if (!this.fromDate && !this.toDate) {
        this.fromDate = date;
      } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
        this.toDate = date;
        this.bookedInput.close();
        this.bookedRange.nativeElement.value =
          this.ngbDateParser.format(this.fromDate) +
          " a " +
          this.ngbDateParser.format(this.toDate);
        this.emitDates();
      } else {
        this.toDate = null;
        this.fromDate = date;
      }
    } else {
      if (!this.createdFromDate && !this.createdToDate) {
        this.createdFromDate = date;
      } else if (
        this.createdFromDate &&
        !this.createdToDate &&
        date.after(this.createdFromDate)
      ) {
        this.createdToDate = date;
        this.createdInput.close();
        this.createdAtRange.nativeElement.value =
          this.ngbDateParser.format(this.createdFromDate) +
          " a " +
          this.ngbDateParser.format(this.createdToDate);
        this.emitDates();
      } else {
        this.createdToDate = null;
        this.createdFromDate = date;
      }
    }
  }

  isHovered(date: NgbDate, type: string) {
    if (type === "booked") {
      return (
        this.fromDate &&
        !this.toDate &&
        this.hoveredDate &&
        date.after(this.fromDate) &&
        date.before(this.hoveredDate)
      );
    } else {
      return (
        this.createdFromDate &&
        !this.createdToDate &&
        this.hoveredDate &&
        date.after(this.createdFromDate) &&
        date.before(this.hoveredDate)
      );
    }
  }

  isInside(date: NgbDate, type: string) {
    if (type === "booked") {
      return date.after(this.fromDate) && date.before(this.toDate);
    } else {
      return (
        date.after(this.createdFromDate) && date.before(this.createdToDate)
      );
    }
  }

  isRange(date: NgbDate, type: string) {
    if (type === "booked") {
      return (
        date.equals(this.fromDate) ||
        date.equals(this.toDate)
      );
    } else {
      return (
        date.equals(this.createdFromDate) ||
        date.equals(this.createdToDate)
      );
    }
  }

  nextBookings(event: any) {
    this.selectChange.emit(event);
    if (event.checked) {
      this.originalBookeds = this.bookeds;
      let nextBookings = [];
      this.bookeds.forEach((b: any) => {
        if (
          (moment(
            new Date(b.checkIn || b.departDate || b.beginDate)
          ).isSameOrAfter(moment(new Date()), "day") &&
            ((b.status && b.status === "active") || !b.status)) ||
          (moment(new Date(b.returnDate)).isSameOrAfter(
            moment(new Date()),
            "day"
          ) &&
            ((b.status && b.status === "active") || !b.status))
        ) {
          nextBookings.push(b);
        }
      });
      nextBookings = nextBookings.sort((a: any, b: any) => {
        return moment(a.checkIn || a.departDate || a.beginDate).diff(
          moment(b.checkIn || b.departDate || b.beginDate)
        );
      });
      this.bookeds = nextBookings;
    } else {
      this.bookeds = this.originalBookeds;
    }
    this.nextBookingsEmit.emit(this.bookeds);
  }

  downloadTable() {
    this.excelService.createBookingsExcel("", { bookings: this.bookeds });
  }

  canClean() {
    return (
      this.f.modelHotel.value !== "" ||
      this.f.statusOptions.value !== "active" ||
      this.fromDate ||
      this.toDate ||
      this.createdFromDate ||
      this.createdToDate
    );
  }

  changeStatusOption(event) {
    this.f.statusOptions.setValue(this.statusOptions[event.target.value].value);
  }

  changeType(event) {
    this.changeTypeEmit.emit(this.typeOptions[event]);
  }

  changeComponent(event) {
    this.changeComponentEmit.emit(this.typeComponent[event]);
  }

  emitPage() {
    this.pageEmit.emit();
  }
}
