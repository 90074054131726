<div class="row" [ngClass]="!firstSearch && openModal ? 'full-container scroll' : ''">
  <div class="col-12">
    <div class="card m-0">
      <div class="card-body" [ngClass]="{ 'modal-open-custom': openModal }">
        <form [ngClass]="!firstSearch && !openModal ? 'is-collapsed' : ''">
          <div class="row d-flex first-row">
            <div class="input-style mr-3 destiny">
              <div class="form-group mb-1">
                <label>{{
                  "search.search-homepage.destiny" | translate
                }}</label>
                <app-google-autocomplete
                  [readOnly]="!firstSearch && !openModal"
                  (setAddress)="getAddress($event)"
                  [locationTxt]="searchConfig.locationText"
                  [openModal]="openModal"
                  adressType="geocode"
                  class="google"
                ></app-google-autocomplete>
              </div>
            </div>
            <div class="input-style mr-3 date">
              <div class="form-group input-long mb-1">
                <label for="date_form">{{
                  "search.search-homepage.date-entry" | translate
                }}</label>
                <div class="input-group">
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="dInput.toggle()"
                      type="button"
                    >
                      <img src="/assets/img/svg/calendar-orange.svg" />
                    </button>
                  </div>
                  <input
                    id="date_form"
                    class="form-control"
                    type="text"
                    placeholder="{{
                      'search.search-homepage.checkin' | translate
                    }}"
                    name="dpInput"
                    [(ngModel)]="searchConfig.checkIn"
                    ngbDatepicker
                    #dInput="ngbDatepicker"
                    (click)="dInput.toggle()"
                    (ngModelChange)="onDateChange()"
                    [minDate]="getInMinDate()"
                    [maxDate]="getInMaxDate()"
                  />
                </div>
              </div>
            </div>
            <div class="input-style date">
              <div class="form-group input-long mb-1">
                <label for="date_to">{{
                  "search.search-homepage.date-departure" | translate
                }}</label>
                <div
                  class="input-group"
                  [ngClass]="{ 'disabled-input': !searchConfig.checkIn }"
                >
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="dOutput.toggle()"
                      mat-button
                      [disabled]="!searchConfig.checkIn"
                      type="button"
                    >
                      <img src="/assets/img/svg/calendar-orange.svg" />
                    </button>
                  </div>
                  <input
                    id="date_to"
                    class="form-control"
                    type="text"
                    placeholder="{{
                      'search.search-homepage.checkout' | translate
                    }}"
                    name="dpOutput"
                    [(ngModel)]="searchConfig.checkOut"
                    ngbDatepicker
                    #dOutput="ngbDatepicker"
                    (click)="dOutput.toggle()"
                    mat-button
                    (ngModelChange)="onDateChange()"
                    [disabled]="!searchConfig.checkIn"
                    [minDate]="getOutMinDate()"
                    [maxDate]="maxDateNgStruct"
                  />
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="firstSearch || openModal" class="row" style="margin: 0 auto;">
            <div class="container-room">
              <div class="room-project-container">
                <div class="room-container">
                  <label class="project-label">{{'project.my-projects.travelers' | translate}}</label>
                  <div class="room-summary" (click)="toggleRoomModal()">
                    <img class="room-summary-icon-left" src="assets/img/svg/orange-person.svg" alt="Person" />
                    <span class="room-summary-text">{{ getOccupancySummary() }}</span>
                  </div>
                  <div class="room-modal-overlay" *ngIf="roomModalOpen" (click)="closeRoomModal()"></div>
                  <div class="room-modal-container" [ngClass]="{ 'modal-open-custom': openModal }" *ngIf="roomModalOpen">
                    <div class="room-modal" (click)="$event.stopPropagation()">
                      <div class="modal-content">
                        <div *ngFor="let room of searchConfig.occupancies; let i = index" class="room-card">
                          <div style="display: flex;justify-content: space-between;">
                            <h4 style="margin: 0;align-self: center;">{{ "hotel.room" | translate }} {{ i + 1 }}</h4>
                            <button class="remove-room" (click)="removeRoom(i)" *ngIf="searchConfig.occupancies.length > 1 && i > 0">
                              {{ "train.form-train.deleteRoom" | translate }}
                            </button>
                          </div>
                          <div *ngFor="let traveler of (travelers[i] || []); let j = index"
                                class="traveler-input"
                                [ngClass]="{
                                  'single-traveler': travelers[i].length === 1 && traveler === defaultUser.uuid,
                                  'multi-traveler': travelers[i].length > 1 || traveler !== defaultUser.uuid
                                }">
                            
                            <input type="text" [value]="getTravelerName(traveler)" [readonly]="i === 0 && traveler === defaultUser.uuid" />
                            <button class="remove-btn" (click)="removeTraveler(i, j)" *ngIf="!(i === 0 && traveler === defaultUser.uuid)">X</button>
                          </div>
                          
                          <div style="font-size: 10px">
                            <mat-form-field appearance="fill" class="traveler-selector">
                              <mat-label>{{ "train.form-train.addTraveler" | translate }}</mat-label>
                              <mat-select [(value)]="selectedTraveler[i]" (selectionChange)="addTraveler(i, $event.value)">
                                <mat-option *ngFor="let user of getAvailableUsers(i); trackBy: trackByUuid" [value]="user.uuid">
                                  {{ user.name }} {{ user.lastname }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="add-room-container">
                          <button class="add-room-btn" (click)="addRoom()">{{ "hotel.add-another-room" | translate }}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="project-container">
                  <label class="project-label">{{'project.project-assign' | translate}}</label>
                  <div class="d-flex align-items-center">
                    <div class="input-group" [ngClass]="{ 'disabled-input': loadingProjects || !searchConfig.checkIn || !searchConfig.checkOut }">
                      <select
                        name=""
                        id=""
                        class="form-control form-control--squared select-input custom-select"
                        [(ngModel)]="selectedProject"
                        [disabled]="loadingProjects || !searchConfig.checkIn || !searchConfig.checkOut"
                      >
                        <option [ngValue]="null" disabled selected>{{'project.project-select' | translate}}</option>
                
                        <option *ngFor="let project of projects" [ngValue]="project">
                          {{ project.title }}
                        </option>
                      </select>
                    </div>

                    <button *ngIf="!loadingProjects" class="btn no-focus-border" (click)="openTravelDialog()"
                      [disabled]="loadingProjects || !searchConfig.checkIn || !searchConfig.checkOut">
                      <img src="assets/img/svg/add-booking-circle.svg" alt="Add booking" />
                    </button>
                    <i style="margin-left: 0.5rem; font-size: 1.5rem;" class="ft-loader" *ngIf="loadingProjects"></i>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div *ngIf="firstSearch || openModal" class="row">
            <div class="col-md-12 col-sm-12">
              <div>
                <div>
                  <button
                    id="submit-button"
                    [disabled]="!searchConfig.checkIn || !searchConfig.checkOut || !searchConfig.latitude || ! searchConfig.longitude || !allTravelersSelected()"
                    type="submit"
                    class="btn-search custom-btn m-0"
                    (click)="sendData()"
                  >
                    {{ "search.search-homepage.search" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
