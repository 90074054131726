<mat-accordion
  *ngIf="flightLleegoService.travelerForm != null"
  class="formsContainer"
  multi
>
  <mat-expansion-panel
    *ngFor="
      let travelerControl of flightLleegoService.travelerForm.controls;
      index as i
    "
    [expanded]="true"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ "flight.detail-flight.passenger" | translate }} {{ i + 1
        }}{{
          travelerControl.value.name != "" ||
          travelerControl.value.surname != ""
            ? " - "
            : ""
        }}{{ travelerControl.value.name }}
        {{ travelerControl.value.surname }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="formContainer" [formGroup]="travelerControl">
      <div class="row">
        <div class="col-4">
          <mat-form-field appearance="none">
            <label>{{ "common.name" | translate }} *</label>
            <input
              type="text"
              formControlName="name"
              matInput
              required
              class="form-control form-control--no-border"
              [ngClass]="{ error: travelerControl.controls['name'].invalid }"
            />
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field appearance="none">
            <label>{{ "train.user-form.surname" | translate }} *</label>
            <input
              type="text"
              formControlName="surname"
              matInput
              required
              class="form-control form-control--no-border"
              [ngClass]="{ error: travelerControl.controls['surname'].invalid }"
            />
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field appearance="none">
            <label>{{ "train.user-form.surname2" | translate }} </label>
            <input type="text" formControlName="lastSurname" matInput class="form-control form-control--no-border"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="genderContainer">
            <label>{{ "user.details.gender" | translate }} *</label>
            <mat-radio-group formControlName="gender"
            [ngClass]="{
              error: travelerControl.controls['gender'].invalid
            }">
              <mat-radio-button value="Male">
                {{ "common.male" | translate }}
              </mat-radio-button>
              <mat-radio-button value="Female">
                {{ "common.female" | translate }}
              </mat-radio-button>
              <!-- <mat-radio-button value="O"> Otro </mat-radio-button> -->
            </mat-radio-group>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-8">
          <mat-form-field appearance="none" id="email">
            <div>
              <label>{{ "common.email" | translate }} *</label>
            </div>
            <input
              type="email"
              formControlName="email"
              matInput
              required
              class="form-control form-control--no-border"
              [ngClass]="{ error: travelerControl.controls['email'].invalid }"
            />
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field appearance="none">
            <label>{{ "user.form.phone" | translate }} *</label>
            <input
              type="string"
              formControlName="phoneNumber"
              matInput
              required
              class="form-control form-control--no-border"
              [ngClass]="{
                error: travelerControl.controls['phoneNumber'].invalid
              }"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row" id="birthDate">
        <div class="col-4">
          <mat-form-field appearance="none">
            <label>{{ "user.form.birthdate" | translate }} *</label>
            <input type="text" matInput style="display: none" />
            <input
              id="birthDate"
              readonly
              [minDate]="minDateNgStruct"
              [maxDate]="maxDateNgStruct"
              name="birthDate"
              ngbDatepicker
              #dateInput="ngbDatepicker"
              (click)="dateInput.toggle()"
              formControlName="birthDate"
              class="form-control form-control--no-border"
              [ngClass]="{
                error: travelerControl.controls['birthDate'].invalid
              }"
            />
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field appearance="none">
            <label>{{ "user.form.nationality" | translate }} *</label>
            <mat-select
              formControlName="nationality"
              required
              class="icon-arrow-rtl form-control form-control--no-border"
              [ngClass]="{
                error: travelerControl.controls['nationality'].invalid
              }"
            >
              <mat-option *ngFor="let country of countries" [value]="country">
                {{ country.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field appearance="none">
            <label>{{ "user.form.issuanceCountry" | translate }} *</label>
            <mat-select
              formControlName="expeCountry"
              required
              class="icon-arrow-rtl form-control form-control--no-border"
              [ngClass]="{
                error: travelerControl.controls['expeCountry'].invalid
              }"
            >
              <mat-option *ngFor="let country of countries" [value]="country">
                {{ country.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <mat-form-field appearance="none">
            <label>{{ "user.form.documentType" | translate }} *</label>
            <mat-select
              formControlName="documentType"
              required
              class="icon-arrow-rtl form-control form-control--no-border"
              required
              [ngClass]="{
                error: travelerControl.controls['documentType'].invalid
              }"
            >
              <mat-option value="IDENTITY_CARD">
                {{ "user.details.dni" | translate }}
              </mat-option>
              <mat-option value="NIE">
                {{ "user.form.nie" | translate }}
              </mat-option>
              <mat-option value="PASSPORT">
                {{ "user.details.passport" | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field appearance="none">
            <label>{{ "user.form.number" | translate }} *</label>
            <input
              type="string"
              formControlName="documentNumber"
              matInput
              required
              class="form-control form-control--no-border"
              [ngClass]="{
                error: travelerControl.controls['documentNumber'].invalid
              }"
            />
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field appearance="none">
            <label>{{ "user.form.issuanceDate" | translate }} *</label>
            <input type="text" matInput style="display: none" />
            <input
              id="issuanceDate"
              readonly
              [minDate]="minDateNgStruct"
              [maxDate]="maxDateNgStruct"
              name="issuanceDate"
              ngbDatepicker
              #dateInput2="ngbDatepicker"
              (click)="dateInput2.toggle()"
              formControlName="issuanceDate"
              class="form-control form-control--no-border"
              [ngClass]="{
                error: travelerControl.controls['issuanceDate'].invalid
              }"
            />
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field appearance="none">
            <label>{{ "user.form.expiryDate" | translate }} *</label>
            <input type="text" matInput style="display: none" />
            <input
              id="expiryDate"
              readonly
              [minDate]="maxDateNgStruct"
              name="expiryDate"
              ngbDatepicker
              #dateInput3="ngbDatepicker"
              (click)="dateInput3.toggle()"
              formControlName="expiryDate"
              class="form-control form-control--no-border"
              [ngClass]="{
                error: travelerControl.controls['expiryDate'].invalid
              }"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <label style="margin-bottom: 0px !important;">{{ "flight.detail-flight.ff-number" | translate }}</label>
          <span (click)="openFfCardModal(i)" class="icon-edit ml-1" style="cursor: pointer; padding-left: 0.5rem;">
            <i class="ft-edit"></i>
          </span>
        
          <mat-form-field appearance="none" *ngIf="getFfCardsArray(i) && getFfCardsArray(i).controls.length > 0" formGroupName="documents">
            <mat-select [formControl]="getSelectedFfCardControl(i)">
              <mat-option [value]="-1">{{ "my-bookeds.train-flight.no-add-card" | translate }}</mat-option>
              <mat-option *ngFor="let card of getFfCardsArray(i).controls; let j = index" [value]="j">
                {{ card.get('cardName').value }} - {{ card.get('cardNumber').value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="none" *ngIf="getFfCardsArray(i) && getFfCardsArray(i).controls.length === 0" formGroupName="documents">
            <mat-select placeholder="{{ 'my-bookeds.train-flight.no-cards-available' | translate }}" disabled>
              <mat-option disabled>
                {{ "my-bookeds.train-flight.no-cards-available" | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-4" *ngIf="canUseResident">
          <mat-form-field appearance="none">
            <label>{{ "common.city" | translate }} *</label>
            <mat-select
              formControlName="city"
              required
              class="icon-arrow-rtl form-control form-control--no-border"
              [ngClass]="{
                error: travelerControl.controls['city'].invalid
              }"
            >
              <mat-option *ngFor="let municipality of munipalitiesList" [value]="municipality.municipality">
                {{ municipality.municipality }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<!-- <button style="float: left" mat-button matStepperPrevious>
  {{ "flight.detail-flight.previous" | translate }}
</button> -->
<div class="p-4 mb-3" *ngIf="flightLleegoService.travelerForm != null">
  <button
    (click)="
      flightLleegoService.travelerForm.valid
        ? goNextStep()
        : null
    "
    class="custom-btn"
    [ngClass]="{
      disabled:
        !flightLleegoService.travelerForm.valid
    }"
    [matTooltip]="
      !flightLleegoService.travelerForm.valid
        ? getInvalidString()
        : null
    "
  >
    {{ "flight.result-flight.continue" | translate }}
  </button>
</div>
