import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UntypedFormArray } from "@angular/forms";
import {
  FlightAvailReq,
  FlightAvailRes,
  PricingRes,
  SeatsAvailabilityRes,
  StandAloneCatalogueRes,
} from "@core/models";
import { environment } from "@env/environment";
import { from, Observable } from "rxjs";
import { TokenService } from "./token.service";

@Injectable({
  providedIn: "root",
})
export class FlightLleegoService {
  travelerForm: UntypedFormArray;

  constructor(private http: HttpClient, private tokenService: TokenService) {}

  postFlightAvail(flightAvailReq: FlightAvailReq) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<FlightAvailRes>(
      `${environment.baseUrlApi}/grandpa/avail`,
      flightAvailReq,
      { headers: reqHeaders }
    );
  }

  getFlightPricing(flightPricingReq: any): Observable<PricingRes> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    let params = new HttpParams()
      .set("token", flightPricingReq.token)
      .set("solutionID0", flightPricingReq.solutionID0)
      .set("journey00", flightPricingReq.journey00)
      .set("userUuid", flightPricingReq.userUuid)
      .set("passengers", flightPricingReq.passengers);
    if (flightPricingReq.resident && flightPricingReq.resident !== 'none') {
      params = params.set("resident", flightPricingReq.resident);
    }
    if (flightPricingReq.journey01 != null) {
      params = params.set("journey01", flightPricingReq.journey01);
    }
    return this.http.get<PricingRes>(
      `${environment.baseUrlApi}/grandpa/pricing`,
      {
        headers: reqHeaders,
        params,
      }
    );
  }

  getFlightPolicy(flightPolicyReq: any): Observable<any> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    let params = new HttpParams()
      .set("token", flightPolicyReq.token)
      .set("solutionID0", flightPolicyReq.solutionID0)
      .set("journey00", flightPolicyReq.journey00)
      .set("userUuid", flightPolicyReq.userUuid);
    if (flightPolicyReq.journey01 != null) {
      params = params.set("journey01", flightPolicyReq.journey01);
    }
    return this.http.get<any>(`${environment.baseUrlApi}/grandpa/policies`, {
      headers: reqHeaders,
      params,
    });
  }

  getFlightStandalonecatalogue(
    flightBaggagesReq: any
  ): Observable<StandAloneCatalogueRes> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    let params = new HttpParams()
      .set("token", flightBaggagesReq.token)
      .set("solutionID0", flightBaggagesReq.solutionID0)
      .set("journey00", flightBaggagesReq.journey00)
      .set("userUuid", flightBaggagesReq.userUuid);
    if (flightBaggagesReq.journey01 != null) {
      params = params.set("journey01", flightBaggagesReq.journey01);
    }
    return this.http.get<StandAloneCatalogueRes>(
      `${environment.baseUrlApi}/grandpa/baggages`,
      { headers: reqHeaders, params }
    );
  }

  postFlightBooking(flightReq: any): Observable<any> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(
      `${environment.baseUrlApi}/grandpa/book`,
      flightReq,
      { headers: reqHeaders }
    );
  }

  putFlightEmit(bookingId: string, flightReq: any) {
    let params = new HttpParams();
    params = params.append("locator", flightReq.params.locator);
    params = params.append("payment", flightReq.params.payment);
    if (flightReq.params.userUuid) {
      params = params.append("userUuid", flightReq.params.userUuid);
    }
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.put<any>(
      `${environment.baseUrlApi}/grandpa/emit/${bookingId}`,
      flightReq.body,
      { headers: reqHeaders, params }
    );
  }

  putFlightCancel(
    lleegoReservationUuid: string,
    bookedUuid: string,
    userUuid: string,
    locator: string,
    companyUuid?: string
  ) {
    const token = this.tokenService.getToken();
    // const reqHeaders = {
    //   Authorization: `JWT ${token}`,
    //   "Content-Type": "application/x-www-form-urlencoded",
    // };
    // let formData = new FormData();
    // formData.append("userUuid", userUuid);
    // return this.http.put<any>(
    //   `${environment.baseUrlApi}/grandpa/cancel/${lleegoReservationUuid}?locator=${locator}`,
    //   formData,
    //   { headers: reqHeaders }
    // );

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `JWT ${token}`);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("uuid", bookedUuid);
    urlencoded.append("userUuid", userUuid);
    urlencoded.append("companyUuid", companyUuid);

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow" as RequestRedirect,
    };

    const promise = fetch(
      `${environment.baseUrlApi}/grandpa/cancel/${lleegoReservationUuid}?locator=${locator}`,
      requestOptions
    );
    return from(promise);
  }

  seatsAvailability(
    lleegoBookingID: string,
    userUuid?: string
  ): Observable<SeatsAvailabilityRes> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    let params = new HttpParams();
    params = params.append("userUuid", userUuid);
    return this.http.get<SeatsAvailabilityRes>(
      `${environment.baseUrlApi}/grandpa/seats/availability?bookingID=${lleegoBookingID}`,
      { headers: reqHeaders, params }
    );
  }

  putSeatAvailabilityConfirm(
    bookingID: string,
    confirmSeatVyoo: any,
    userUuid?: string
  ) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    let params = new HttpParams();
    params = params.append("userUuid", userUuid);
    return this.http.put<any>(
      `${environment.baseUrlApi}/grandpa/seats/confirm?bookingID=${bookingID}`,
      confirmSeatVyoo,
      { headers: reqHeaders, params }
    );
  }

  getMultifamily(multifamilyReq: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    let params = new HttpParams()
      .set("token", multifamilyReq.token)
      .set("travelID", multifamilyReq.travelID)
      .set("journey0", multifamilyReq.journey0)
      .set("userUuid", multifamilyReq.userUuid);

    if (multifamilyReq.journey1 != null) {
      params = params.set("journey1", multifamilyReq.journey1);
    }

    return this.http.get<any>(`${environment.baseUrlApi}/grandpa/multifamily`, {
      headers: reqHeaders,
      params,
    });
  }
}
