import { Injectable, OnDestroy } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { LetItGoType } from "@core/models/letitgo-type";
import { ConfigService } from "@core/services/config.service";
import { Agency, Company, Config, Hub } from "@models";
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "app/app.reducer";
import * as CryptoJS from "crypto-js";
import * as moment from "moment";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { stringSimilarity } from "string-similarity-js";
import { BookedService } from "./booked.service";
import { NgxToastrService } from "./ngx.toastr.service";
import { io } from "socket.io-client";
import { environment } from "@env/environment";

@Injectable({
  providedIn: "root",
})
export class UtilsService implements OnDestroy {
  configHotels: Config;
  configFlights: Config;
  getConfigsubscription: Subscription[] = [];
  maxHotelPercentage;
  maxFlightPercentage;
  loadingFlights = false;
  reloadingFlights = false;
  loadingTrains = false;
  loadingHotels = false;
  letItGoTimer = 10000;
  haveRequest = false;
  bookeds: any[] = [];
  secretAESKey = "e34a7203f57bc250b6336dc6a1bfeca2"; // "Vy00tR1p" in md5
  imageChanged$ = new Subject<string>();
  userChanged$ = new Subject<boolean>();
  allLoaded = false;
  nearbyDeparture: boolean;
  nearbyArrival: boolean;
  userBookingsFilters = {
    fromDate: null,
    toDate: null,
    createdFromDate: null,
    createdToDate: null,
  };
  socket = io(environment.socketUrlApi);
  private reloadSubject = new Subject<void>();
  reload$ = this.reloadSubject.asObservable();
  correlationId: string[] = [];
  selectedUsers: any[] = [];
  bookingInfo: any = {};
  hotel: any;
  selectedRooms: any[] = [];
  private backNavigation = false;
  private processedUuids: Set<string> = new Set();
  private projectData: { selectedProject: any, allProjects: any[] } = { selectedProject: null, allProjects: [] };
  private userSubject = new BehaviorSubject<any>(null);
  user$ = this.userSubject.asObservable();

  constructor(
    private ngbDateParser: NgbDateParserFormatter,
    private bookingService: BookedService,
    private configService: ConfigService,
    private translate: TranslateService,
    private ngxToastrService: NgxToastrService,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.getConfigsubscription.push(
      this.store
        .select("auth")
        .pipe(take(1))
        .subscribe((auth) => {
          if (auth.user && auth.user.type?.toUpperCase() === "EMPLOYEE") {
            this.getPercentages();
          }
        })
    );
  }

  triggerReload() {
    this.reloadSubject.next();
  }

  getInDate(maxDateNgStruct, checkOut) {
    const date = checkOut ? checkOut : maxDateNgStruct;
    const dateMoment = moment(
      this.ngbDateParser.format(date),
      "DD/MM/YYYY"
    ).add(-1, "days");
    const ngbStruct: NgbDateStruct = {
      year: dateMoment.year(),
      month: dateMoment.month() + 1,
      day: dateMoment.date(),
    };
    return ngbStruct;
  }

  getOutDate(minDateNgStruct, checkIn) {
    const date = checkIn ? checkIn : minDateNgStruct;
    const dateMoment = moment(
      this.ngbDateParser.format(date),
      "DD/MM/YYYY"
    ).add(1, "days");
    const ngbStruct: NgbDateStruct = {
      year: dateMoment.year(),
      month: dateMoment.month() + 1,
      day: dateMoment.date(),
    };
    return ngbStruct;
  }

  getOutDateSame(minDateNgStruct, checkIn) {
    const date = checkIn ? checkIn : minDateNgStruct;
    const dateMoment = moment(this.ngbDateParser.format(date), "DD/MM/YYYY");
    const ngbStruct: NgbDateStruct = {
      year: dateMoment.year(),
      month: dateMoment.month() + 1,
      day: dateMoment.date(),
    };
    return ngbStruct;
  }

  departureBeforeReturn(dateDepartureAux, dateReturnAux, f) {
    const dateDeparture = moment(dateDepartureAux);
    const dateReturn = moment(dateReturnAux);
    if (dateReturn.isSame(dateDeparture)) {
      if (f.timeDeparture.value && f.timeReturn.value) {
        return f.timeDeparture.value.hour < f.timeReturn.value.hour;
      } else {
        return f.timeReturn.value && f.timeReturn.value.hour > 0;
      }
    } else {
      return true;
    }
  }

  toFormData(obj, form, namespace?) {
    const fd = form || new FormData();
    let formKey;
    if (obj["bookedWithoutCard"] === false) {
      fd.append("bookedWithoutCard", obj["bookedWithoutCard"]);
    }
    for (const property in obj) {
      if (obj.hasOwnProperty(property) && obj[property]) {
        if (namespace) {
          formKey = namespace + "[" + property + "]";
        } else {
          formKey = property;
        }

        // if the property is an object, but not a File, use recursivity.
        if (obj[property] instanceof Date) {
          fd.append(formKey, obj[property].toISOString());
        } else if (
          typeof obj[property] === "object" &&
          !(obj[property] instanceof File)
        ) {
          if (property === "suppliers" && Array.isArray(obj[property])) {
            for (let i = 0; i < obj[property].length; i++) {
              const arrayKey = formKey + "[" + i + "]";
              fd.append(arrayKey, obj[property][i]);
            }
          } else {
            this.toFormData(obj[property], fd, formKey);
          }
        } else {
          // if it's a string or a File object
          fd.append(formKey, obj[property]);
        }
      }
    }

    return fd;
  }

  editCompany(company: Company, isCompany: boolean, reloadCompanies?) {
    const extras: NavigationExtras = {
      state: {
        loading: false,
        company: company,
        maxHotelPercentage: 100,
        maxFlightPercentage: this.maxFlightPercentage,
        isCreate: false,
      },
    };

    this.router.navigate(
      ["companies/" + company.uuid + "/edit-company"],
      extras
    );
  }

  editAgency(agency: Agency, isAgency: boolean, reloadAgencies?) {
    const navigationExtra: NavigationExtras = {
      state: {
        agency: agency,
        loading: false,
        maxHotelPercentage: this.maxHotelPercentage,
        maxFlightPercentage: this.maxFlightPercentage,
      },
    };
    this.router.navigate([`/agencies/${agency.uuid}/edit`], navigationExtra);
  }

  editHub(hub: Hub, isHub: boolean, reloadHubs?) {
    const navigationExtras: NavigationExtras = {
      state: {
        hub: hub,
        loading: false,
      },
    };
    this.router.navigate([`/hubs/${hub.uuid}/edit`], navigationExtras);
  }

  getPercentages() {
    this.getConfigsubscription.push(
      this.configService.getPercentageVyoo("percentageCompany").subscribe(
        (data: Config) => {
          this.configHotels = data;
          if (!this.configHotels.value) {
            this.configHotels.value = [17];
          }
          this.maxHotelPercentage = this.configHotels.value
            ? this.configHotels.value[0]
            : 17;
        },
        (err) => {
          this.ngxToastrService.typeInfo(
            "Error",
            this.translate.instant("common.some-error")
          );
        }
      ),
      this.configService.getPercentageVyoo("flightCommissions").subscribe(
        (data: Config) => {
          this.configFlights = data;
          if (!this.configFlights.value) {
            this.configFlights.value = [
              {
                local_emission: 5,
                eu_emission: 7,
                world_emission: 9,
                percentage: 5,
              },
            ];
          }

          this.maxFlightPercentage = this.configFlights.value[0].percentage;
        },
        (err) => {
          this.ngxToastrService.typeInfo(
            "Error",
            this.translate.instant("common.some-error")
          );
        }
      )
    );
  }

  sendEmailOperations(type: LetItGoType, data: any, userUuid?: string) {
    let text = "";
    switch (type) {
      case LetItGoType.hotel:
        text = `Se ha intentado realizar una reserva para ${
          data.hotel.totalRooms
        } habitación/es para ${data.hotel.room.adults} adultos en régimen de ${
          data.hotel.room.roomName || "NO CONSTA"
        } para el hotel ${data.hotel.name} ( ${
          data.hotel.address || ""
        } ), con entrada el día ${data.checkIn} y salida el día ${
          data.checkOut
        }. \nEl precio total con el que se ha intentado realizar esta acción ha sido de ${
          data.totalPrice
        }€.`;
        if (data.hotel.totalRooms > 1) {
          text += `\nEl precio de la habitacion nº${
            data.hotel.room.roomNumber + 1
          } (${data.hotel.room.roomName})`
          if (data.hotel.room.bedType) {
            text += ` (${data.hotel.room.bedType})`;
          }
          text +=` ha sido de: ${
            data.hotel.room.price
          }€`;
        }

        if (data.unavail) {
          text += `\n Actualmente no hay disponibilidad para las fechas elegidas.`;
        }

        if (data.insurance) {
          text += `\nAdemás, ha contratado un seguro ${data.insurance} `;
        }

        if (data.breakfast == true) {
          text += `La habitación seleccionada contiene desayuno incluido.`;
        } else {
          text += `La habitación seleccionada no contiene desayuno incluido. `;
        }

        if(data.boardName) {
          text += `Ya que el regimen de la habitación es ${data.boardName}.`
        }

        if (data.cancellation == true && data.cancellationDate) {
          text += `Además contiene cancelación con las siguientes caracteristicas: ${data.cancellationDate[0].text}`;
        } else {
          text += `Además la habitación no continene cancelación`;
        }

        if (data.costCenter) {
          text += `\n El centro de coste es: ${data.costCenter}`;
        }

        if (data.customCode) {
          text += `\n El código interno personalizado de la reserva es: ${data.customCode}`;
        }

        if (data.comments) {
          text += `\n El usuario añadió este comentario para el hotel: ${data.comments}`;
        }

        text += `\nEl usuario espera la subida de su bono, en caso de que no exista disponibilidad, contacte con él para recomendarle una opción que se ajuste a su búsqueda. Esta reserva se ha intentado realizar desde WEB`;
        break;

      case LetItGoType.flight:
        if (data.price) {
          data.price = parseFloat(data.price).toFixed(2);
        }
        if (data.lleegoBookingID == null) {
          text = `Se ha intentado realizar una reserva de vuelo para ${
            data.travellers
          } ${data.travellers > 1 ? "pasajeros" : "pasajero"} con itinerario ${
            data.itinerary
          }, para las fechas (formato Salida Ida - Salida Vuelta) ${
            data.dates
          }.`;

          if (data.stops != null && data.stops > 0) {
            text += `\nEl vuelo tiene escalas. ${data.stopsText}`;
          } else {
            text += `\nEl vuelo no tiene escalas.`;
          }
          
          if(data.unavail) {
            text += `\n Actualmente no existe disponibilidad para el vuelo seleccionado.`;
          }
          // if (data.seats != null && data.seats.length > 0) {
          //   text += `\nEl usuario ha seleccionado los siguientes asientos: `;
          //   if (Array.isArray(data.seats)) {
          //     data.seats.forEach((seat) => {
          //       text += `\n${seat.row}${seat.column} - Trayecto ${seat.segment}`;
          //     });
          //   } else {
          //     text += data.seats;
          //   }
          // }

          if(data.fares) {
            text+= `\n La tarifa seleccionada ha sido ${data.fares.fareName} con un precio de ${data.fares.price}€`
          }

          if (data.baggages != null && data.baggages.length > 0) {
            data.baggages.forEach((baggage) => {
              if(baggage.journey){
              text += `\nEl usuario ha seleccionado una maleta para el trayecto de ${baggage.journey} de tipo ${baggage.name} y precio ${baggage.price} €.`;
              }else {
                text += `\nEl usuario ha seleccionado una maleta de tipo ${baggage.name} y precio ${baggage.price} €.`;

              }
              data.price = parseFloat(data.price) + parseFloat(baggage.price);
            });
          }

          if(data.travelerList) {
            text+=`\n La lista de viajeros es la siguiente:`
            data.travelerList.forEach((traveler) => {
              if(traveler.flightCard) {
              text+= `\n ${traveler.name} ${traveler.surnames[0]} ${traveler.surnames.length > 1 ? traveler.surnames[1] : ''} y su tarjeta de vuelo es ${traveler.flightCard}`
              } else {
                text+= `\n ${traveler.name} ${traveler.surnames[0]} ${traveler.surnames.length > 1 ? traveler.surnames[1] : ''} y no sabemos su tarjeta de vuelo.`
              }
            })
          }

          if (data.customCode) {
            text += `\n El código interno personalizado de la reserva es: ${data.customCode}`;
          }
          if (data.costCenter) {
            text += `\n El centro de coste es: ${data.costCenter}`;
          }
          if (data.annotationsExtras) {
            text += `\n Se han solicitado extras: ${data.annotationsExtras}`;
          }

          if(data.canUseResident) {
            text += `\n Estos usuarios tienen un descuento por residente, ya que son de: ${data.canUseResident}`;
          }

          text += `\nEl precio total con el que se ha intentado realizar esta acción ha sido de ${data.price} €.\nEl usuario espera la subida de su bono, en caso de que
                  no exista disponibilidad, contacte con él para recomendarle una opción que se ajuste a su búsqueda. Esta reserva se ha intentado realizar desde WEB`;
        } else {
          text = `Se ha realizado correctamente una reserva de vuelo con itinerario ${data.itinerary}, para las fechas (formato Salida Ida - Llegada Vuelta) ${data.dates}, con localizador ${data.locator} e ID de Lleego ${data.lleegoBookingID}.`;

          if (data.stops != null && data.stops > 0) {
            text += `\nEl vuelo tiene escalas. ${data.stopsText}`;
          } else {
            text += `\nEl vuelo no tiene escalas.`;
          }

          if(data.unavail) {
            text += `\n Actualmente no existe disponibilidad para el vuelo seleccionado.`;
          }
          // if (data.seats != null && data.seats.length > 0) {
          //   text += `\nEl usuario ha seleccionado los siguientes asientos: `;
          //   if (Array.isArray(data.seats)) {
          //     data.seats.forEach((seat) => {
          //       text += `\n${seat.row}${seat.column} - Trayecto ${seat.segment}`;
          //     });
          //   } else {
          //     text += data.seats;
          //   }
          // }

          if(data.fares) {
            text+= `\n La tarifa seleccionada ha sido ${data.fares.fareName} con un precio de ${data.fares.price}€`
          }

          if (data.baggages != null && data.baggages.length > 0) {
            data.baggages.forEach((baggage) => {
              if(baggage.journey){
              text += `\nEl usuario ha seleccionado una maleta para el trayecto de ${baggage.journey} de tipo ${baggage.name} y precio ${baggage.price}.`;
              } else {
                text += `\nEl usuario ha seleccionado una maleta de tipo ${baggage.name} y precio ${baggage.price}.`;
              }
              data.price = parseFloat(data.price) + parseFloat(baggage.price);
            });
          }

          if(data.travelerList) {
            text+=`\n La lista de viajeros es la siguiente:`
            data.travelerList.forEach((traveler) => {
              if(traveler.flightCard) {
              text+= `\n ${traveler.name} ${traveler.surnames[0]} ${traveler.surnames.length > 1 ? traveler.surnames[1] : ''} y su tarjeta de vuelo es ${traveler.flightCard}`
              } else {
                text+= `\n ${traveler.name} ${traveler.surnames[0]} ${traveler.surnames.length > 1 ? traveler.surnames[1] : ''} y no sabemos su tarjeta de vuelo.`
              }
            })
          }

          if (data.customCode) {
            text += `\n El código interno personalizado de la reserva es: ${data.customCode}`;
          }
          if (data.costCenter) {
            text += `\n El centro de coste es: ${data.costCenter}`;
          }

          text += `\nEl precio total con el que se ha intentado realizar esta acción ha sido de ${data.price} €.\nEl usuario espera la confirmación de sus asientos, en caso de que
                  no exista disponibilidad, contacte con él para recomendarle una opción que se ajuste a sus preferencias. Esta reserva se ha intentado realizar desde WEB`;
        }
        break;

      case LetItGoType.train:
        if (data.price) {
          data.price = parseFloat(data.price).toFixed(2);
        }
        if (data.travelers && data.travelers.length > 1) {
          data.travelers = data.travelers.join(" y ");
        } else if (data.travelers) {
          data.travelers = data.travelers[0];
        }
        text = `Se ha intentado realizar una reserva de tren con itinerario ${data.itinerary}, para ${data.travelers} para las fechas ${data.dates}.
                El precio con el que se ha intentado realizar esta acción ha sido de ${data.price} €.`;
        if (
          data.seats != null &&
          data.seats.outwardSeats != null &&
          data.seats.outwardSeats.length > 0
        ) {
          text += `\nEl usuario ha seleccionado asientos para el trayecto de ida: `;
          if (data.seats.outwardSeats != null) {
            text += `. Los de ida han sido: `;
            data.seats.outwardSeats.forEach((seat) => {
              text += `\nVagón ${seat.car_code} - Asiento ${seat.seat_code} - Tipo ${seat.type}`;
            });
          }
          if (data.seats.returnSeats.length > 0) {
            text += `\nEl usuario ha seleccionado asientos para el trayecto de vuelta: `;
            data.seats.returnSeats.forEach((seat) => {
              text += `\nVagón ${seat.car_code} - Asiento ${seat.seat_code} - Tipo ${seat.type}`;
            });
          }

        }
        if (data.rates) {
          text+=`\nLas tarifas del viaje son las siguientes:`
           data.rates.forEach((rate) => {
            text+= `\n${rate.name} con un precio de ${rate.price}€`
           })
        }

        if(data.passengers) {
          text+= `\nLos pasajeros son los siguientes:`
          data.passengers.forEach((passenger) => {
            if(passenger.passengerRenfeCard) {
              text+= `\n${passenger.name} ${passenger.firstSurname} ${passenger.secondSurname} y la tarjeta renfe es ${passenger.passengerRenfeCard}.`
            } else {
              text+= `\n${passenger.name} ${passenger.firstSurname} ${passenger.secondSurname} y desconocemos la tarjeta Renfe.`
            }
          })
        }

        if (data.customCode) {
          text += `\n El código interno del personalizado de la reserva es: ${data.customCode}`;
        }

        if (data.costCenter) {
          text += `\n El centro de coste es: ${data.costCenter}`;
        }
        text += `\nEl usuario espera la subida de su bono, en caso de que no exista disponibilidad, contacte con él para recomendarle una opción que se ajuste a su búsqueda. Esta reserva se ha intentado realizar desde WEB`;
        break;
      default:
        break;
    }
    if (data.insurance) {
      text += ` Además, ha contratado un seguro ${data.insurance} `;
    }
    this.getConfigsubscription.push(
      this.bookingService
        .sendEmailOperations({
          text: text,
          userUuid: userUuid ? userUuid : null,
        })
        .subscribe(() => {
          return;
        })
    );
  }

  sortArrayByDate(array: any) {
    const sortedArray: any = array.sort((a, b) => {
      if (a.createdAt > b.createdAt) {
        return -1;
      }
      if (a.createdAt < b.createdAt) {
        return 1;
      }
      return 0;
    });
    return sortedArray;
  }

  sortArrayByAlphabet(array: any) {
    const sortedArray: any = array.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    return sortedArray;
  }

  encrypt(value: any) {
    return CryptoJS.AES.encrypt(value, this.secretAESKey);
  }

  generateRedsysToken(cardData) {
    return this.encrypt(JSON.stringify(cardData));
  }

  decrypt(value: any) {
    const decrypted = CryptoJS.AES.decrypt(value, this.secretAESKey);
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  getUuidTracking(index: number, item: any) {
    if (item.uuid != null) {
      return item.uuid;
    } else {
      console.log("uuid not found");
    }
  }

  storeParamInLocalStorageNoEncrypt(param: { title: string; value: any }) {
    const value = param.value;
    localStorage.setItem(param.title, JSON.stringify({ value }).toString());
  }

  storeParamInLocalStorage(param: { title: string; value: any }) {
    const value = param.value;
    localStorage.setItem(
      param.title,
      this.encrypt(JSON.stringify({ value })).toString()
    );
  }

  getLocalStorageItem(key: string) {
    const item = localStorage.getItem(key);
    if (item == null || item === "undefined") {
      return null;
    }
    const decrypted = this.decrypt(item);
    if (decrypted !== "") {
      return JSON.parse(decrypted);
    } else {
      return item;
    }
  }

  removeItemFromLocalStorage(key: string) {
    localStorage.removeItem(key);
  }

  inputIsInList(value: string, list: string[]): number {
    const similarityIdxs = [];
    list.forEach((s) => {
      const similarityScore = stringSimilarity(value, s);
      similarityIdxs.push(similarityScore);
    });
    const maxIdx = similarityIdxs.indexOf(Math.max(...similarityIdxs));
    return maxIdx;
  }

  setSelectedUsers(users: any[]) {
    this.selectedUsers = users;
  }

  getSelectedUsers() {
    return this.selectedUsers;
  }

  setBookingInfo(info: any) {
    this.bookingInfo = info;
  }

  getBookingInfo() {
    return this.bookingInfo;
  }

  setHotelInfo(hotel: any) {
    this.hotel = hotel;
  }

  getHotelInfo() {
    return this.hotel;
  }

  setSelectedRooms(rooms: any[]) {
    this.selectedRooms = rooms;
  }

  getSelectedRooms() {
    return this.selectedRooms;
  }

  ngOnDestroy() {
    this.getConfigsubscription.forEach((s) => s.unsubscribe());
  }

  imageChanged(image: string) {
    this.imageChanged$.next(image);
  }

  userChanged() {
    this.userChanged$.next(true);
  }

  openChat() {
    window["HubSpotConversations"].widget.open();
  }

  setBackNavigation(state: boolean) {
    this.backNavigation = state;
  }

  isBackNavigation(): boolean {
    return this.backNavigation;
  }
  
  isAdmin(userType: string) {
    return userType === 'employee' || userType === 'company' || userType === 'agency' || userType === 'hub'
  }

  hasProcessedUuid(uuid: string): boolean {
    return this.processedUuids.has(uuid);
  }

  addProcessedUuid(uuid: string) {
    this.processedUuids.add(uuid);
  }

  clearProcessedUuids() {
    this.processedUuids.clear();
  }

  setProjectData(data: { selectedProject: any, allProjects: any[] }) {
    this.projectData = data;
  }

  getProjectData() {
    return this.projectData;
  }

  clearProjectData() {
    this.projectData = { selectedProject: null, allProjects: [] };
  }
  
  updateUser(user: any) {
    this.userSubject.next(user);
  }

  clearUserData() {
    this.userSubject.next(null);
  }

  getNavigationRoute(user?: any, company?: any): string {
    const companyUuid = company ? company.uuid : this.getLocalStorageItem("companyUuid")?.value
    const roleToRouteMap = {
      EMPLOYEE: companyUuid ? "/companies/" + companyUuid : "/companies/",
      AGENCY: companyUuid ? "/companies/" + companyUuid : "/companies/",
      HUB: companyUuid ? "/companies/" + companyUuid : "/companies/",
      COMPANY: "/my-company/details",
      USER: "/home"
    };
  
    const userType = user?.type?.toUpperCase();
    const accessType = user?.accessType?.toUpperCase();
    return roleToRouteMap[userType] || roleToRouteMap[accessType];
  }
}
